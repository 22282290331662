import axios from "axios";
import { orderBy } from "lodash";
import { dispatchType } from "../dispatchTypes";
import { constants, URLS, x_tenant_id, auth_token } from "../../shared/constants";
import { TeacherApiCall } from "../../api";

export type CourseData = {
  userRefId: string;
  courseId?: number;
  gradeId?: number;
  sectionId?: number;
};

export type SyllabusData = {
  userRefId: string;
  courseId: number;
  syllabusId: string;
  label: string;
  gradeId: number | null;
  sectionId: number | null;
  index: number;
};

export type InnerSyllabusData = {
  index: number;
  userRefId: string;
  courseId: number;
  syllabusId: string;
  label: string;
  unitIndex: number;
  gradeId: number;
  sectionId: number;
};

export type MarkComplete = {
  staffId: number;
  syllabusId: number;
};
export type CourseList = {};

export const getCourseSuccess = (data: any) => ({
  type: dispatchType.curriculum.courses,
  payload: data,
});

export const getCourseSyllabusSuccess = (data: any) => ({
  type: dispatchType.curriculum.courseSyllabus,
  payload: data,
});

export const updateSyllabus = (data: any) => ({
  type: dispatchType.curriculum.updateInnerCollapsible,
  payload: data,
});

export const setViewStatus =(data: boolean) =>({
  type: dispatchType.curriculum.setViewStatus,
  payload: data,
})
export const updateInnerSyllabus = (data: any) => ({
  type: dispatchType.curriculum.updateInnerSyllabus,
  payload: data,
});

export const setCurrentStep = (data: any) => ({
  type: dispatchType.stepper,
  payload: data,
});

export const updateTopicComplete = (data: any) => ({
  type: dispatchType.curriculum.completeStatus,
  payload: data,
});

export const getCourses = (data: CourseData) => {
  return (dispatch: any) => {
    TeacherApiCall.get(URLS.CURRICULUM.COURSES + data.userRefId)
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            getCourseSuccess({
              courses: orderBy(response.data.data, ["classId"], ["asc"]),
            })
          );
        }
      });
  };
};

export const getCoursesList = (milestone: boolean, data: CourseData) => {
  const URL = milestone
    ? URLS.MILE_STONE.LIST
    : URLS.CURRICULUM.COURSE_SYLLABUS;
  return (dispatch: any) => {
    TeacherApiCall
      .get(
        URL +
        "?userRefId=" +
        data.userRefId +
        "&courseId=" +
        data.courseId +
        "&gradeId=" +
        data.gradeId +
        "&sectionId=" +
        data.sectionId, {
      }
      )
      .then((response) => {
        if (response.status === 200) {
          let isUnitFirst = false;
          if (
            response.data.data.length &&
            response.data.data[0].hasOwnProperty("unitName")
          ) {
            isUnitFirst = true;
          }
          dispatch(
            getCourseSyllabusSuccess({
              coursesList: response.data.data,
              isUnitFirst,
            })
          );
        }
      });
  };
};

export const getOpenedCollapse = (milestone: boolean, data: SyllabusData) => {
  let URL = milestone ? URLS.MILE_STONE.LIST : URLS.CURRICULUM.COURSE_SYLLABUS;
  if (milestone) {
    URL =
      URL +
      "?userRefId=" +
      data.userRefId +
      "&gradeId=" +
      data.gradeId +
      "&courseId=" +
      data.courseId +
      "&sectionId=" +
      data.sectionId +
      "&syllabusId=" +
      data.syllabusId +
      "&label=" +
      data.label;
  } else {
    URL =
      URL +
      "?gradeId=" +
      data.gradeId +
      "&courseId=" +
      data.courseId +
      "&syllabusId=" +
      data.syllabusId;
  }
  return (dispatch: any) => {
    TeacherApiCall.get(URL)
      .then((response) => {
        dispatch(
          updateSyllabus({
            index: data.index,
            syllabus: response.data.data,
            insideCollapsible: true,
          })
        );
      });
  };
};

export const getOpenedInnerCollapse = (
  milestone: boolean,
  data: InnerSyllabusData
) => {
  let URL = milestone ? URLS.MILE_STONE.LIST : URLS.CURRICULUM.COURSE_SYLLABUS;
  if (milestone) {
    URL =
      URL +
      "?userRefId=" +
      data.userRefId +
      "&gradeId=" +
      data.gradeId +
      "&courseId=" +
      data.courseId +
      "&sectionId=" +
      data.sectionId +
      "&syllabusId=" +
      data.syllabusId +
      "&label=" +
      data.label;
  } else {
    URL =
      URL +
      "?gradeId=" +
      data.gradeId +
      "&courseId=" +
      data.courseId +
      "&syllabusId=" +
      data.syllabusId;
  }
  return (dispatch: any) => {
    TeacherApiCall.get(URL)
      .then((response) => {
        dispatch(
          updateInnerSyllabus({
            index: data.index,
            unitIndex: data.unitIndex,
            syllabus: response.data.data,
            insideCollapsible: true,
          })
        );
      });
  };
};

export const updateTopicStatus = (data: MarkComplete) => {
  return (dispatch: any) => {
    TeacherApiCall.put(URLS.MILE_STONE.MARK_AS_COMPLETE, data)
      .then((response) => {
        console.log("Update Topic Status", response);
        dispatch(
          updateTopicComplete({
            syllabusId: data.syllabusId,
          })
        );
        // dispatch(
        //   updateSyllabus({
        //     index: data.index,
        //     syllabus: response.data.data,
        //     insideCollapsible: true,
        //   })
        // );
      });
  };
};
