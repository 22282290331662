import { API } from "../utils/API";

export const Analyze = async (
    bookId,
    type,
    subType,
    interactiveId,
    assetId,
    pageNumber
) => {
    let isAdmin = localStorage.getItem('isAdmin');
    let data = {
        p: pageNumber,
    };
    if (interactiveId) {
        data.int = interactiveId;
    }
    if (assetId) {
        data.ast = assetId;
    }
    let getAnalytics = JSON.parse(localStorage.getItem('analytics'));
    if (!getAnalytics || getAnalytics.length == 0 || getAnalytics == undefined || getAnalytics == null) {
        let analytics = [];
        if (!isAdmin) {
            await analytics.push({
                book: bookId,
                type: type,
                subType: subType,
                data: data,
            });
            localStorage.setItem('analytics', JSON.stringify(analytics));
        } else {
            return true;
        }
    } else {
        if (!isAdmin) {
            await getAnalytics.push({
                book: bookId,
                type: type,
                subType: subType,
                data: data,
            });
            localStorage.setItem('analytics', JSON.stringify(getAnalytics));
        } else {
            return true;
        }

    }

};

export const saveAnalytics = async () => {
    let getAnalytics = JSON.parse(localStorage.getItem("analytics"));
    if (getAnalytics) {
        if (getAnalytics.length != 0 || getAnalytics != undefined || getAnalytics != null) {
            const response = await API("post", `/api-dashboards/sync/action-logs`, {
                create: JSON.parse(localStorage.getItem("analytics")),
            });
            localStorage.removeItem("analytics");
            return response;
        } else {
            return true;
        }
    }
};
