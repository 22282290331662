import { dispatchType as TYPES } from "../dispatchTypes";

export type Action = {
  type: string;
  payload?: any;
};

export type AcademicYear = {
  statusId: number;
  academicStatusId: number;
  acadamicId: number;
  acadamicYear: string;
  startDate: Date;
  endDate: Date;
  academicStatus: string;
  isEditable: boolean;
};

const academicYears = (state = {}, action: Action) => {
  switch (action.type) {
    case TYPES.academicYears.getAcademic: {
      return {
        ...state,
        ...(action.payload || {}),
      };
    }
    case TYPES.revoke:
      return {};

    default:
      return state;
  }
};

export default academicYears;
