import { dispatchType as TYPES } from "../dispatchTypes";

export const SET_SNACKBAR = "teamly/settings/SET_SNACKBAR";

const initialState = {
    notifyOpen: false,
    notifyTitle: '',
    notifyDesc: ''
};

export type Action = {
  type: string;
  payload?: any;
};

export type NotificationReducer = {
    notifyOpen: boolean;
    CalendarID: number;
    CalendarDateFrom: string;
    Title: string;
    CalendarJobID: number;
    StatusID: number;
    Description: string;
    RepeatID: number;
}

const notificationReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_SNACKBAR:
      const { notifyOpen, notifyTitle, notifyDesc } = action.payload;
      return {
        ...state, notifyOpen, notifyTitle, notifyDesc
      };
      
    case TYPES.revoke:
      return initialState;

    default:
      return state;
  }
};

export default notificationReducer;
