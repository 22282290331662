import { LibraryApiCall } from "../../api";
import { URLS } from "../../shared/constants";
import { dispatchType } from "../dispatchTypes";

export const categoriesList = (data: any) => ({
  type: dispatchType.category,
  payload: data,
});

export const getCategoryList = () => {
  return (dispatch: any) => {
    LibraryApiCall
      .get(URLS.CATEORY)
      .then(async (response: any) => {
        if ((response?.status === 200 || response?.status === 201) && response.data.data) {
          const catList = response.data.data
          dispatch(categoriesList(catList?.length ? {list: [...catList]} : { list: []}))
        } else {
          return {}
        }
      })
  };
}
