import React, { useEffect } from 'react'
import { useOnDraw } from "../Helpers/CanvasHook"
import { useAppState } from "../Context";

const Canvas = ({ isLoading, isMobileView, generalNewWidth }) => {


    const { currentPage, turnOnHighlight, highlightColor, highlightedItems, turnOnPenTool, setTurnOnPenTool, pennedItems, setPennedItems, generalWidth, penLineHeight, penColor, stageToRestore, setStageToRestore, setPenColor, setPenLineHeight, setIsEraserOn, isEraserOn, clearAllStage, setClearAllStage } = useAppState("reader");

    const { penColors, highlightColors, onMouseDown, setCanvasRef } = useOnDraw(onDraw, isMobileView, currentPage);

    function onDraw(ctx, point, prevPoint) {
        let colorToPen = penColors.filter((color) => color.color1 === penColor);
        drawLine(prevPoint, point, ctx, turnOnPenTool ? colorToPen[0]?.color2 : highlightColors[highlightColor - 1]?.color2, lineHeightForDrawing())
    };

    function lineHeightForDrawing() {
        if (isMobileView) {
            if (turnOnPenTool) {
                return penLineHeight
            } else {
                return 8
            }
        } else {
            if (turnOnPenTool) {
                return penLineHeight
            } else {
                return 15
            }
        }
    }

    function drawLine(start, end, ctx, color, width) {
        if (turnOnHighlight || turnOnPenTool) {
            start = start ?? end;
            ctx.beginPath();
            ctx.lineWidth = isEraserOn ? 15 : width;
            ctx.strokeStyle = color;
            ctx.moveTo(start.x, start.y);
            ctx.lineTo(end.x, end.y);
            ctx.stroke();
        }
    }

    const coordinatesForClient = (x, y) => {
        let widthRatio = (window.innerWidth - 20) / 621;
        let heightRatio = ((window.innerWidth - 20) * 788 / generalWidth) / 788;
        if (isMobileView) {
            return {
                x: x * widthRatio,
                y: y * heightRatio
            }
        } else {
            return {
                x: x,
                y: y
            }
        }
    };

    async function clearCanvas() {
        let can = document.getElementById("drawing-board");
        let ctx = can.getContext("2d");
        ctx.clearRect(0, 0, can.width, can.height);
    }

    async function applyHighlightLoop() {

        let filteredHighlights = [];
        let thisPageHighlights = await highlightedItems.filter((item) => {
            if (item.pageNumber === currentPage) {
                return filteredHighlights.push(item.points);
            }
        });

        let can = document.getElementById("drawing-board");
        let ctx = can.getContext("2d");
        // ctx.clearRect(0, 0, can.width, can.height);

        for (var i = 0; i < filteredHighlights.length; i++) {
            ctx.beginPath();

            if (filteredHighlights[i].length > 0) {
                for (var j = 0; j < filteredHighlights[i].length; j++) {
                    ctx.strokeStyle = highlightColors[parseInt(thisPageHighlights[i].color) - 1]?.color2;

                    ctx.lineWidth = isMobileView ? 8 : 15;

                    let getCords = await coordinatesForClient(filteredHighlights[i][j].x, filteredHighlights[i][j].y)
                    ctx.lineTo(getCords.x, getCords.y);
                    if (j + 1 === filteredHighlights[i].length && filteredHighlights[i].length > 0) {//Found Same Length Condition
                        if (filteredHighlights[i + 1] !== undefined) {//Applying for i ! greater than filteredHighlights[i].length or not undefined
                            ctx.strokeStyle = highlightColors[parseInt(thisPageHighlights[i].color) - 1]?.color2;

                            let getCords = await coordinatesForClient(filteredHighlights[i + 1][0].x, filteredHighlights[i + 1][0].y);

                            ctx.moveTo(getCords.x, getCords.y);
                        }
                    }
                }
            }
            ctx.stroke();
            ctx.beginPath();
        }
    };

    async function restoreToPreviousStage(dataToRestore) {
        let canRestore = document.getElementById("drawing-board");
        let ctxOfRestoration = canRestore.getContext("2d");
        if (dataToRestore.length > 0) {
            var toDisplayOldPens = false;
            for (let index = 0; index < dataToRestore.length; index++) {
                const eachEvent = dataToRestore[index];

                if (eachEvent.page === currentPage || eachEvent.type === "clearAll") {
                    ctxOfRestoration.beginPath();

                    if (eachEvent.type === "clearAll") {
                        if (eachEvent.isOldPenExist === false) {
                            ctxOfRestoration.clearRect(0, 0, canRestore.width, canRestore.height);
                        }
                        if (eachEvent.isOldPenExist === true) {
                            toDisplayOldPens = true;
                        } else {
                            toDisplayOldPens = false;
                        }
                    }

                    if (eachEvent.type === "pen") {
                        let getCords = await coordinatesForClient(eachEvent.points[0].x, eachEvent.points[0].y);
                        ctxOfRestoration.moveTo(getCords.x, getCords.y);

                        setTurnOnPenTool(true);

                        setPenColor(penColors[parseInt(eachEvent.color) - 1].color1);

                        setPenLineHeight(eachEvent.thickness);

                        setIsEraserOn(false);

                        ctxOfRestoration.strokeStyle = penColors[parseInt(eachEvent.color) - 1]?.color2;
                        ctxOfRestoration.lineWidth = eachEvent.thickness;

                        for (let pointIndex = 0; pointIndex < eachEvent.points.length; pointIndex++) {
                            const eachPoint = eachEvent.points[pointIndex];
                            let getCords = await coordinatesForClient(eachPoint.x, eachPoint.y);
                            ctxOfRestoration.lineTo(getCords.x, getCords.y);
                        }

                        if (eachEvent.isOldPenExist === true) {
                            toDisplayOldPens = true;
                        } else {
                            toDisplayOldPens = false;
                        }
                    }

                    if (eachEvent.type === "eraser") {
                        let getCords = await coordinatesForClient(eachEvent.points[0].x, eachEvent.points[0].y);
                        ctxOfRestoration.moveTo(getCords.x, getCords.y);

                        setIsEraserOn(true);

                        setPenLineHeight(isMobileView ? 8 : 15);

                        for (let pointIndex = 0; pointIndex < eachEvent.points.length; pointIndex++) {
                            const eachPoint = eachEvent.points[pointIndex];
                            let getCords = await coordinatesForClient(eachPoint.x - 10, eachPoint.y - 10);
                            ctxOfRestoration.clearRect(getCords.x - 10, getCords.y - 10, 25, 25);
                        }

                        if (eachEvent.isOldPenExist === true) {
                            toDisplayOldPens = true;
                        } else {
                            toDisplayOldPens = false;
                        }
                    }

                    ctxOfRestoration.stroke();
                    ctxOfRestoration.beginPath();
                }
            }
            // if (toDisplayOldPens == true) {
            showOldPens(clearAllStage, pennedItems);
            // }
        }
        let getPenStack = localStorage.getItem("penStack");
        let parsedStack = JSON.parse(getPenStack);

        if (dataToRestore.length == 0 && parsedStack && parsedStack.length == 0 && clearAllStage.length !== 0) {
            showOldPens(clearAllStage);
            // setClearAllStage([]);
        }
    };

    async function showOldPens(oldPens) {
        let thisPageOldPens = oldPens.filter((eachEvent) => eachEvent.page === currentPage)
        applyPenLoop(thisPageOldPens);
    }

    async function applyPenLoop(pennedItems) {

        let filteredPens = [];
        let thisPagePens = await pennedItems.filter((item) => {
            if (item.page === currentPage) {
                return item.data.strokes.filter((eachStroke) => {
                    return filteredPens.push(eachStroke.points);
                });
            }
        });

        let can = document.getElementById("drawing-board");
        let ctx = can.getContext("2d");
        // ctx.clearRect(0, 0, can.width, can.height);
        for (var i = 0; i < filteredPens.length; i++) {
            ctx.beginPath();

            if (filteredPens[i].length > 0) {
                for (var j = 0; j < filteredPens[i].length; j++) {
                    ctx.strokeStyle = penColors[parseInt(thisPagePens[0].data.strokes[i].color) - 1]?.color2;

                    ctx.lineWidth = thisPagePens[0].data.strokes[i].thickness;
                    if (filteredPens[i][j].x === null && filteredPens[i][j].y === null) {
                        let getCords = await coordinatesForClient(filteredPens[i][j + 1]?.x, filteredPens[i][j + 1]?.y);
                        ctx.moveTo(getCords.x, getCords.y);
                    } else {
                        let getCords = await coordinatesForClient(filteredPens[i][j].x, filteredPens[i][j].y);
                        ctx.lineTo(getCords.x, getCords.y);
                        if (j + 1 === filteredPens[i].length && filteredPens[i].length > 0) {//Found Same Length Condition
                            if (filteredPens[i + 1] !== undefined) {//Applying for i ! greater than filteredPens[i].length or not undefined
                                ctx.strokeStyle = penColors[parseInt(thisPagePens[0].data.strokes[i].color) - 1]?.color2;
                                let getCords = await coordinatesForClient(filteredPens[i + 1][0].x, filteredPens[i + 1][0].y);
                                ctx.moveTo(getCords.x, getCords.y);
                            }
                        }
                    }
                }
            }
            ctx.stroke();
            ctx.beginPath();
        }
    };

    useEffect(() => {
        setTimeout(async () => {
            clearCanvas();
            await applyHighlightLoop(highlightedItems);
            await applyPenLoop(pennedItems);
            await restoreToPreviousStage(stageToRestore);
        }, 600);
    }, [currentPage, highlightedItems, pennedItems, isLoading, stageToRestore])



    return (
        <canvas id="drawing-board" className={`${(isEraserOn && turnOnPenTool) && "eraser-cursor"} ${(turnOnPenTool && !isEraserOn) && "pen-cursor"}`} ref={setCanvasRef} onMouseDown={onMouseDown} onTouchStart={onMouseDown} />
    )
}

export default Canvas