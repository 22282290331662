import { dispatchType as TYPES } from "../dispatchTypes";

export type Action = {
  type: string;
  payload?: any;
};

export interface OverviewReducer {
  overviewHeader?: OverviewHeader;
  classDetails?: ClassDetail[];
}

export interface ClassDetail {
    classid:       number;
    classname:     string;
    courseGroupName: string;
    coursename:    string;
    courseid:      number;
    studentsCount: string;
    colourcode: string;
    grade: string;
    section: string;
    sectionid: string;
    es_gradeid: string;
    tabs : string;
    coursedisplayname: string;
}

export interface OverviewHeader {
  totalClasses: number;
  totalSubjects: number;
  totalStudents: number;
}

const overviewReducer = (state = [], action: Action) => {
  switch (action.type) {
    case TYPES.overview.class: {
      return {
        ...state,
        ...(action.payload || {}),
      };
    }
    case TYPES.revoke:
      return [];

    default:
      return state;
  }
};

export default overviewReducer;
