import { dispatchType as TYPES } from "../dispatchTypes";

export type Action = {
  type: string;
  payload?: any;
};

export type LoginReducer = {
  userName: string;
  password: string;
  token: string;
  userData: any;
  currentUserRole: any;
  rememberMe: string;
  phoneNumber: string;
  isAuth: boolean;
  otp: string;
  error: string;
  userRoleId: number;
  privileges: any;
  isSetPass:boolean;
  isOrgSetPass:boolean;
};

export type userRole = {
  userRoleId: number;
  userRole: string;
  description: string;
  statusId: number;
  updatedBy: number;
  updatedOn: string;
};

const initstate = {
  userName: "",
  password: "",
  phoneNumber: "",
  userData: "",
  rememberMe: "",
  currentUserRole: "",
  email: "",
  token: "",
  otp: "",
  error: null,
  isAuth: false,
  isSetPass:false,
  isOrgSetPass:false,
};

const loginReducer = (state = initstate, action: Action) => {
  switch (action.type) {
    case TYPES.login.loginUser: {
      return {
        ...state,
        ...(action.payload || {}),
      };
    }
    case TYPES.login.loginUserMobile: {
      return {
        ...state,
        ...(action.payload || {}),
      };
    }
    case TYPES.login.forgotPassword: {
      return {
        ...state,
        ...(action.payload || {}),
      };
    }
    case TYPES.login.otp: {
      return {
        ...state,
        ...(action.payload || {}),
      };
    }
    case TYPES.login.changePassword: {
      return {
        ...state,
        ...(action.payload || {}),
      };
    }
    case TYPES.login.resetEmail: {
      return {
        ...state,
        ...{ email: "" },
      };
    }
    case TYPES.login.resetPhoneNumber: {
      return {
        ...state,
        ...{ phoneNumber: "" },
      };
    }
    case TYPES.login.newPasswordSeted: {
      return {
        ...state,
        ...{ isSetPass: false },
      };
    }
    case TYPES.login.orgNewPasswordSeted: {
      return {
        ...state,
        ...{ isOrgSetPass: false },
      };
    }

    case TYPES.login.setError: {
      return {
        ...state,
        ...(action.payload || {}),
      };
    }

    case TYPES.login.userType: {
      return {
        ...state,
        ...(action.payload || {}),
      };
    }
    case TYPES.revoke:
      if (state.rememberMe) {
        return {
          ...initstate,
          rememberMe: state.rememberMe,
          userName: state.userName,
          password: state.password,
        };
      } else {
        return {
          ...initstate,
          rememberMe: state.rememberMe,
        };
      }

    default:
      return state;
  }
};

export default loginReducer;
