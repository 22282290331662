const devRouteList = {
    dashboard:1,
    calendar:2,
    curriculum:3,
    school:4,
    contentLibrary:5,
    reports:6,
    users:7,
    myClasses:8,
    notification:9,
    noticeBoard:10,
    whiteBoard:11,
    myProfile:12,
    supportTickets:13,
    offlineSetup:14,
    admissionManagement:15,
    feeManagement:16,
    templateManagement:17,
    otherStaff:18,
    topVaultManagement:19,
    assessment:20,
    visitor:30,
    Basic:31,
    notificationManagement:33,
    advanceFeeManagement: 27
  }
  const qaRouteList = {
    dashboard:1,
    calendar:2,
    curriculum:3,
    school:4,
    contentLibrary:5,
    reports:6,
    users:7,
    myClasses:8,
    notification:9,
    noticeBoard:10,
    whiteBoard:11,
    myProfile:12,
    supportTickets:13,
    offlineSetup:14,
    admissionManagement:15,
    feeManagement:16,
    templateManagement:17,
    otherStaff:18,
    topVaultManagement:19,
    assessment:20,
    visitor:30,
    advanceFeeManagement: 27,
  }
  const stagingRouteList = {
    dashboard:1,
    calendar:2,
    curriculum:3,
    school:4,
    contentLibrary:5,
    reports:6,
    users:7,
    myClasses:8,
    notification:9,
    noticeBoard:10,
    whiteBoard:11,
    myProfile:12,
    supportTickets:13,
    offlineSetup:14,
    admissionManagement:15,
    feeManagement:16,
    templateManagement:17,
    otherStaff:18,    
    topVaultManagement:19,
    assessment:20,
    visitor:24,
    Basic:25,
    notificationManagement:58,
    advanceFeeManagement: 27,
    online:29,
    offline:30
  }
  const productionRouteList = {
    dashboard:1,
    calendar:2,
    curriculum:3,
    school:4,
    contentLibrary:5,
    reports:6,
    users:7,
    myClasses:8,
    notification:9,
    noticeBoard:10,
    whiteBoard:11,
    myProfile:12,
    supportTickets:13,
    offlineSetup:14,
    admissionManagement:15,
    feeManagement:16,
    templateManagement:17,
    otherStaff:18,
    topVaultManagement:19,
    assessment:20,
    visitor:24,
    Basic:25,
    advanceFeeManagement: 27,
    notificationManagement:28,
    online:29,
    offline:30
  }


//Assess routes are temporarily added along with LSM Dashboard routes, need to remove this in future.
const tempAssessRoutes = [ 
    "/assess",
    "/assess/poc",
    "/assess/createnewquestion",
    "/assess/editnewquestion",
    "/assess/duplicateQuestion"
]

  const teacherRoutes = {
    "dashboard": ["/teacher/dashboard", "/teacher/dashboard/notify", "/teacher/overview", "/", "/teacher", "/login/teacher", "/teacher/account-info/*", "/teacher/common-preferences/", "/teacher/support-feedback/", "/verify-otp/Teacher", ...tempAssessRoutes],
    "calendar": ["/teacher/CalendarEhancementTeacher", "/teacher/CalendarEhancementTeacher/:index/:classid/:classname/:courseid/:calendarId/session", "/visitorregister/parent-appointment", "/visitorregister/teacher-appointment/home"], // "/teacher/mycalendar" Old calender route
    "myClasses":[
        "/teacher/classes/:index/:classid/:classname/:courseid/:studentsCount/overview",
        "/teacher/classes/:index/:classid/:classname/:courseid/:studentsCount/students",
        "/teacher/classes/:index/:classid/:classname/:courseid/:studentsCount/milestones",
        "/teacher/classes/:index/:classid/:classname/:courseid/:studentsCount/timetable",
        "/teacher/classes/:index/:classid/:classname/:courseid/:studentsCount/assessments",
        "/teacher/classes/:index/:classid/:classname/:courseid/:studentsCount/liveclass",
        "/teacher/content/ELAlibrary/:index/:classid/:courseid/:gradeid/:chpaterid",
        "/teacher/class-attendance","/teacher/absentYesterday",
        "/teacher/classes/:index/:classid/:classname/:courseid/:studentcount/:tabs",
        "/teacher/subject-attendance",
        '/teacher/classes/assessments/Rankcard',
        "/teacher/classes/homework/view",
        "/teacher/studentGrp/:stdGrpId",
    ],
    "curriculum": ["/teacher/curriculum", "/teacher/CurriculumVersionHist", "/teacer/account-info/CurriculumPlan", "/teacher/create-curriculum/:gradeId/:courseid"],
    "reports":[
        "/teacher/reports/gradebook","/teacher/StudentGradebook/:studentId/:classId/:gradeID/:templateType/:academicId/:gradeBookTemplateID/:gradeBookType","/teacher/reports/studentReport","/teacher/reports/studentReport/:studentId","/teacher/reports/download/studentReport/:studentId"
    ],
    "whiteBoard":["/teacher/whiteboard"],
    "noticeBoard":["/teacher/noticeBoard"],
    "myProfile":[
        "/teacher/account-info/support","/teacher/account-info/details","/teacher/all-downloads","/teacher/account-info/leave-request","/teacher/account-info/topVault",  "/teacher/leave-request/","/teacher/account-info/visit-log", "/topvault/teacher","/teacher/account-info/CurriculumPlan"
    ],
    "contentLibrary":[
        "/teacher/content/contentlibrary",
        "/teacher/content/contentlibrary/:_type?",
        "/teacher/content/contentlibrary/:_type?/:_title?/:_id?/:_key?",
        "/teacher/content/details/:_type/:_contentId/:_contentLocation",
        "/teacher/content/lessonplan/:_contentId/:_contentLocation","/teacher/content/read/:_contentType/:_type/:_contentId/:_contentLocation",
        "/teacher/content/interactivities/:_contentId/:_contentLocation",
        "/teacher/content/myResources",
        "/teacher/content/publicResources",
        "/teacher/contentlibrary/underUtilised",
        "/teacher/content/myResources/:_type?",
        "/teacher/content/myResources/:_type?/:_title?/:_id?/:_key?",
        "/teacher/content/publicResources/:_type?",
        "/teacher/content/publicResources/:_type?/:_title?/:_id?/:_key?",
        "/teacher/content/:contentCategoryId/h5p-content/:h5pId/:contentMapId/:contentLocation/edit",
        "/teacher/content/:contentCategoryId/h5p-content",
        "/teacher/content/underUtilised",
        "/teacher/content/ELAlibrary/:index/:classid/:courseid/:gradeid/:chpaterid?",
        "/teacher/CalendarEhancementTeacher/:index/:classid/:classname/:courseid/:calendarId/session/contentlibrary/:_type?",
        "/teacher/CalendarEhancementTeacher/:index/:classid/:classname/:courseid/:calendarId/session/read/:_contentType/:_type/:_contentId/:_contentLocation",
        "/teacher/CalendarEhancementTeacher/:index/:classid/:classname/:courseid/:calendarId/session/lessonplan/:_contentId/:_contentLocation",
        "/teacher/CalendarEhancementTeacher/:index/:classid/:classname/:courseid/:calendarId/session/interactivities/:_contentId/:_contentLocation",
        "/teacher/CalendarEhancementTeacher/:index/:classid/:classname/:courseid/:calendarId/session/contentlibrary/:_type?/:_title?/:_id?/:_key?",
        "/teacher/CalendarEhancementTeacher/:index/:classid/:classname/:courseid/:calendarId/session/details/:_type/:_contentId/:_contentLocation",
        "/teacher/CalendarEhancementTeacher/:index/:classid/:classname/:courseid/:calendarId/session/:contentCategoryId/h5p-content/:h5pId/:contentMapId/:contentLocation/edit",
        "/teacher/CalendarEhancementTeacher/:index/:classid/:classname/:courseid/:calendarId/session/:contentCategoryId/h5p-content",
    ],
    "notification":["/teacher/content/underUtilised"]
    // "GradeBook":["/teacher/reports/gradebook","/teacher/StudentGradebook/:studentId/:classId/:gradeID/:templateType/:academicId/:gradeBookTemplateID/:gradeBookType"],
    // "studentReport":["/teacher/reports/studentReport","/teacher/reports/studentReport/:studentId","/teacher/reports/download/studentReport/:studentId"],    
   // "Attendance":["/teacher/subject-attendance","/teacher/class-attendance","/teacher/absentYesterday"],
  }
  const adminRoutes = {
    dashboard: [
      "/",
      "/admin/dashboard",
      "/admin/dashboard/notify",
      "/admin",
      "/login/Admin",
      "/verify-otp/Admin",
      "/admin/gate-staff",
      ...tempAssessRoutes,
      "/admin/calendarEnhance",
      "/admin/notificationgateway",
      "/admin/notificationgateway/admin",
      "/admin/notificationgateway/dashboard",
      "/admin/notificationgateway/triggers",
      "/admin/notificationgateway/history",
      "/admin/notificationgateway/inactivegateways",
      "/admin/notificationgateway/rechargePlans",
      "/admin/notificationgateway/admin-pre-setup",
      "/admin/notificationgateway/details/:UUID/:serviceTypeId/:pending/:processed/:failed"
    ],
    school: [
      "/admin/school",
      "/admin/basic-information",
      "/admin/gradesStreams",
      "/admin/infrastructure",
      "/admin/create-curriculum",
      // "/admin/school/timetableList",
      "/admin/school/timetableListEnhancement",
      "/admin/adminRoles",
      "/admin/create-curriculum/:gradeId/:courseid",
      "/admin/grade-setup",
      "/admin/add-department",
      "/admin/academic-setup",
      "/admin/teacherAccounts",
      "/admin/student-details-format",
      "/admin/timetable",
      "/admin/timetable-enhanced",
      "/admin/timetable/enhanced-slot",
      "/admin/classDashboard",
      "/admin/teacher-timetable",
      "/admin/basic/promoteStudents",
      "/admin/records/:tab?",
      "calendarEnhancement",
      "/admin/classAndTeacherTimetable",
      "/admin/timetableMultiDownload",
      "/admin/teachertimetableView",
      "/admin/school/teachertimetableDownload"
    ],
    curriculum: [
      "/admin/create-curriculum",
      "/admin/create-curriculum/:gradeId",
      "/admin/create-curriculum/:gradeId/:courseid",
      "/teacher/create-curriculum/:gradeId/:courseid"
    ],
    calendar: ["/admin/teacherCalendarView", "/admin/calendarEnhance"], // "/admin/calendar", old admin calendar route
    contentLibrary: [
      "/admin/content/contentlibrary",
      "/admin/content/contentlibrary/:_type?",
      "/admin/content/contentlibrary/:_type?/:_title?/:_id?/:_key?",
      "/admin/content/details/:_type/:_contentId/:_contentLocation",
      "/admin/content/lessonplan/:_contentId/:_contentLocation",
      "/admin/content/interactivities/:_contentId/:_contentLocation",
      "/admin/content/read/:_contentType/:_type/:_contentId/:_contentLocation",
      "/admin/content/myResources",
      "/admin/content/publicResources",
      "/admin/contentlibrary/underUtilised",
      "/admin/content/myResources/:_type?",
      "/admin/content/myResources/:_type?/:_title?/:_id?/:_key?",
      "/admin/content/publicResources/:_type?",
      "/admin/content/publicResources/:_type?/:_title?/:_id?/:_key?",
      "/admin/content/:contentCategoryId/h5p-content/:h5pId/:contentMapId/:contentLocation/edit",
      "/admin/content/:contentCategoryId/h5p-content",
    ],
    reports: [
      "/admin/reports/gradebook",
      "/admin/gradebook/create-new-template",
      "/admin/gradebook/view/:templateId/:type",
      "/admin/gradebook/preview-template",
      "/admin/reports/studentReport",
      "/admin/reports/download/studentReport/:studentId",
      "/admin/reports/studentReport/:studentId",
      "/admin/StudentGradebook/:studentId/:classId/:gradeID/:templateType/:academicId/:gradeBookTemplateID/:gradeBookType",
      "/admin/comingSoon",
      "/admin/template/preview",
    ],
    myProfile: ["/admin/account-info/support", "/admin/all-downloads", "/admin/settings", "/admin/settings/communication", "/admin/settings/questionPaper","/admin/settings/student-details-format","/admin/settings/notification-communication"],
    offlineSetup: ["/admin/offlinedownload"],
    supportTickets: [
      "/admin/teacher-inquiry/lesson",
      "/admin/teacher-inquiry/leave",
      "/admin/teacher-inquiry/support",
      "/admin/teacher-inquiry/qpApproval",
      "/admin/teacher-inquiry/curriculumPlan",
    ],
    feeManagement: [
      "/erp/fees/dashboard",
      "/erp/fees/allocation",
      "/erp/fees/collection",
      "/erp/fees/feesetup",
    ],
    advanceFeeManagement: [
      "/erp/adv/fees",
      "/erp/adv/fees/dashboard",
      "/erp/adv/fees/allocation",
      "/erp/adv/fees/collection",
      "/erp/adv/fees/feesetup"
    ],
    admissionManagement: [
      "/admissions",
      "/admissions/dashboard",
      "/admissions/currentapplications",
      "/admissions/admittedapplications",
      "/admissions/inactiveapplications",
      "/admissions/postsetup",
    ],
    users: ["/admin/teacherStudent"],
    notification: ["/admin/contentlibrary/underUtilised"],
    templateManagement: ["/erp/template"],
    topVaultManagement: ["/topvault"],
    visitor: [
      "/visitorregister/Dashboard",
      "/visitorregister/admin-pre-setup",
      "/visitorregister/post-setup",
      "/visitorregister/admin-pre-setup/newVisitorForm",
      "/visitorregister/gate-staff/visitor-list",
      '/visitorregister/gate-staff/add-visitor',
      '/visitorregister/gate-staff/visitor-slip',
      '/visitorregister/gate-staff/visitor-visit',
      '/visitorregister/gate-staff/home'
    ],
    
    notificationManagement: [
      "/admin/notificationgateway",
      "/admin/notificationgateway/admin",
      "/admin/notificationgateway/dashboard",
      "/admin/notificationgateway/triggers",
      "/admin/notificationgateway/history",
      "/admin/notificationgateway/inactivegateways",
      "/admin/notificationgateway/rechargePlans",
      "/admin/notificationgateway/admin-pre-setup",
      "/admin/notificationgateway/details/:UUID/:serviceTypeId/:pending/:processed/:failed",
    ],
  };

  const superAdminRoutes = {
    "dashboard": ["/orgsuperadmin", "/orgsuperadmin/dashboard","/login/orgsuperadmin"],
    "organisation": ["/orgsuperadmin/organisation/org-info","/orgsuperadmin/organisation/branch-setup"],
  }

  export const getRouteList = () => {
    let navList = devRouteList
    if (process.env.REACT_APP_ENV === "development") {
        navList = devRouteList
    }
    if (process.env.REACT_APP_ENV === "qa") {
        navList = qaRouteList
    }
    if (process.env.REACT_APP_ENV === "staging") {
        navList = stagingRouteList
    }
    if (process.env.REACT_APP_ENV === "production" || process.env.REACT_APP_ENV === "nprd") {
        navList = productionRouteList
    }
    return navList
  }

  const orgSuperAdminRoutes = {
    dashboard:1,
    organisation:2
  }
  export const getRouteForEnvironment = (isAdmin, isOrgSuperAdmin) => {
    let navList = isOrgSuperAdmin ? orgSuperAdminRoutes : getRouteList()
    var list = {}
    if(isAdmin){
        for (const key in navList){
            if (key in adminRoutes){
                list[navList[key]] = adminRoutes[key]
            }
        }
    } else if(isOrgSuperAdmin) {
        for (const key in navList){
            if (key in superAdminRoutes){
                list[navList[key]] = superAdminRoutes[key]
            }
        }
    } else{
        for (const key in navList){
            if (key in teacherRoutes){
                list[navList[key]] = teacherRoutes[key]
            }
        }
    }    
    return list;  
  }