import { dispatchType as TYPES } from "../dispatchTypes";
export interface Section {
    classId: number;
    className: string;
    gradeName: string;
    gradeId: number;
    sectionName: string;
    statusId: number;
}

export interface Grades {
    gradeId: number;
    gradeName: string;
    gradeStatus: number;
    classes: Section[];
    isEditable: boolean;
    hasStream: boolean;
}

export interface MandatorySubject {
    courseName: string;
    gradeID: number;
    courseID: number;
    courseDisplayName?:string;
}

export interface OptionalSubject {
    courseName: string;
    gradeID: number;
    courseID: number;
    courseDisplayName?:string;
}

export interface CourseForGrades {
    mandatorySubjects: MandatorySubject[];
    optionalSubjects: OptionalSubject[];
}

export interface DeptMandSubs {
    departmentname: string;
    departmentid: number;
    es_gradeid: number;
    es_courseid: number;
    gradename: string;
    coursename: string;
}

export interface SectionList {
    SectionID: number;
    SectionName: string;
    Description: string;
    statusID: number;
    updatedBy: number;
    updatedOn: Date;
}

export interface GradeNameUpdate {
    gradeId: number;
    gradeName: string;
    userId: number;
}

export interface GradeStatusUpdate {
    gradeIds: string[];
    currentStatus: boolean;
}

export interface SectionCreate {
    sectionId: number;
    classId: number;
    sectionName: string;
    departmentID: number;
    courses: string[];
    Mcourses: string[];
    userId: number;
    gradeId: number;
    gradeName: string;
}

export type Action = {
    type: string;
    payload?: any;
};

export type GradeReducer = {
    gradeList: any[]
};

const initstate = {
    gradeList: []
};


const gradeReducer = (state = initstate, action: Action) => {
    switch (action.type) {
      case TYPES.grade.gradeList: {
        return {
          ...state, gradeList: action.payload,
        };
      }
      default:
        return state;
    }
  };
  
  export default gradeReducer;