export const parseJwt = (token) => {
    try {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    } catch (e) {
        return null;
    }
};


/**
    Below the function is not working some of the instance such as QA... so we are using the above parseJwt function
*/
// const parseJwt = (token) => {
//     try {
//         return JSON.parse(window.atob(token.split(".")[1]));
//     } catch (e) {
//         return null;
//     }
// };



// validate the JWT token if expired or not
// if token expired, redirect to login page
const AuthTokenVerify = (props) => {
    var authToken = JSON.parse(localStorage.getItem("state"))?.login?.token || '';
    // history.listen((location, action) => {
    if (authToken) {
        const decodedJwt = parseJwt(authToken);
        if (decodedJwt) {
            if (decodedJwt.exp * 1000 < Date.now()) {
                props.logOut();
            }
        }
    }
    //});
    return <div></div>;
};

export default AuthTokenVerify;
