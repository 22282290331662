import { SaServiceApiCall, TeacherApiCall } from ".";

export const getReminderNotify = async (calendarId: number[]) => {
    try {
    const res = await TeacherApiCall.get(`teacher/getReminderPopUp?calendarID=${calendarId}`);
    if (res.status === 200 || res.status === 201) {
      return res.data.data;
    }
    return {}
  } catch (e) {
      console.error(e)
      return null
  }
};

export const putNotifyDone = async (calendarId: number) => {
  try {
  const res = await TeacherApiCall.put(`teacher/reminderPopUp/${calendarId}`);
  if (res.status === 200 || res.status === 201) {
    return res.data;
  }
  return {}
} catch (e) {
    console.error(e)
    return null
}
};

export const getAppointmentNotify = async (calendarId:any) => {
  try {
  const res = await SaServiceApiCall.post(`calendar-appointment/getCalendarDetailsForReminder`,calendarId);
  if (res.status === 200 || res.status === 201) {
    return res.data;
  }
  return {}
} catch (e) {
    console.error(e)
    return null
}
};