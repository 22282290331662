export const loadingReducer = (state = { loaderVal: false }
    , action: { type: string }) => {
    if (action?.type.trim().toLowerCase() === "startLoading".trim().toLowerCase()) {
        return {
            ...state,
            loaderVal: true,
        };
    }
    else if (action?.type.trim().toLowerCase() === "stopLoading".trim().toLowerCase()) {
        return {
            ...state,
            loaderVal: false,
        }
    }
    else {
        return null;
    }
}