import Logo from "../../Logo/Logo";
import loginLeftImg from "../../../../assets/img/loginLeftImg.svg";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../../redux/reducers";
import { userRoleId as roleIds } from "../../../../shared/constants";
import LoginBottomLeft from "../../../../assets/img/LoginBottomLeft.png";
import LoginTopRight from "../../../../assets/img/LoginTopRight.png";
import loginTopRightDefault from "../../../../assets/img/loginTopRightDefault.png";
import { useLocation } from "react-router-dom";

const LeftPane = () => {
  const userRoleId = useSelector(
    (state: ReduxState) => state?.login?.userRoleId
  );
  const location = useLocation();
  console.log(location.pathname)
  return (
    <>
      <Logo userRoleId={userRoleId} />
      {/* Admin */}
      {location.pathname === "/" ? 
        <div className="loginLeftCenterContent d-flex flex-wrap align-content-center justify-content-center h-100 position-relative">        
          <img className="tchLoginTpRig" src={loginTopRightDefault} />
          <img className="tchLoginBotLft" src={LoginBottomLeft} />
          <h4>Welcome to Top School</h4>
          <p>A smart learning management system <br></br>to support the teaching and learning <br></br>of your school.</p>
        </div>
      : (
      userRoleId === roleIds.adminId  || location.pathname === '/set-password' ?
      <div className="loginLeftCenterContent d-flex flex-wrap align-content-center justify-content-center h-100">
        <div className="loginLeftCntImg">
          <img src={loginLeftImg} />
        </div>
        <h4>Welcome to TopSchool</h4>
        <p>Your one stop platform to create <br></br>curriculum plans, set up school admin,<br></br>view progress analytics and much more</p>
      </div> : userRoleId === roleIds.teacherId ?
      <div className="loginLeftCenterContent d-flex flex-wrap align-content-center justify-content-center h-100 position-relative">        
        <img className="tchLoginTpRig" src={LoginTopRight} />
        <img className="tchLoginBotLft" src={LoginBottomLeft} />
        <h4>Welcome to Top School</h4>
        <p>Your one stop platform to create <br></br>curriculum plans, set up school teacher,<br></br>view progress analytics and much more</p>
      </div> : 
        <div className="loginLeftCenterContent d-flex flex-wrap align-content-center justify-content-center h-100">
        <div className="loginLeftCntImg">
          <img src={loginLeftImg} />
        </div>
        <h4>Welcome to TopSchool</h4>
        <p>Your one stop platform to manage all <br></br>branches as a Super admin.<br></br>view their progress analytics and much more</p>
      </div>
      )}
    </>
  );
};
export default LeftPane;
