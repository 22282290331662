import PdfViewer from '../../lib/pdfViewer/index';
import"./index.css";

export default function PdfViewerExposed(){
    const URL = window.localStorage.getItem("PdfUrlFromNative");
    if(!URL){
        return null;
    }
    return <div> <PdfViewer bookUrl={URL}/></div>

}