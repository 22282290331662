import { API } from "../utils/API";

export const fetchPens = async (currentPage, bookID) => {
    let response = await API("post", `/api-library/books/${bookID}/drawings/fetch`, {
        pages: currentPage?.length ? [currentPage] : []
    });
    return sortPenItemsPageNumberWise(response.data);
};

export const sortPenItemsPageNumberWise = async (pennedItems) => {
    let pageWiseSorted = await pennedItems.sort((a, b) => a.page - b.page);
    return sortPennedItemsBasedOnAXIS(pageWiseSorted);
}

export const sortPennedItemsBasedOnAXIS = async (pennedItems) => {
    let axisWiseSorted = await pennedItems.sort((a, b) => {
        if (a.page === b.page) {
            if (a.data.points[0].y !== b.data.points[0].y) {
                return a.data.points[0].y - b.data.points[0].y;
            } else {
                return a.data.points[0].x - b.data.points[0].x;
            }
        }
    });
    return sortPennedItems(axisWiseSorted)
};

export const sortPennedItems = async (pennedItems) => {
    var count = 1;
    for (var i = 0; i < pennedItems.length; i++) {
        if (i == 0) {
            pennedItems[0].count = count;
        } else {
            if (pennedItems[i - 1].page == pennedItems[i].page) {
                count++;
            } else {
                count = 1;
            }
            pennedItems[i].count = count;
        }
    }
    return pennedItems;
};