import { dispatchType as TYPES } from "../dispatchTypes";

const initialState = {
  open: false,
  buttonType: "",
  buttonText: "",
  message: '',
  count:""
};

export type ConfirmationReducer = {
  open?: boolean,
  buttonType?: 'red' | 'green' | 'yellow' | 'accent',
  buttonText?: string,
  onOkay: () => void
  onCancel?: () => void
  message?: string
  count:number
  module?:any
}

export type ConfirmationAction = Omit<ConfirmationReducer, 'open'>

export type Action = {
  type: string;
  payload?: ConfirmationAction;
};

const confirmationReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case TYPES.resources.open:
      return {
        open: true,
        ...(action.payload || {})
      };

    case TYPES.resources.close:
        return initialState; 

    case TYPES.revoke:
      return initialState;

    default:
      return state;
  }
};

export default confirmationReducer;