import { dispatchType as TYPES } from "../dispatchTypes";

export type Action = {
  type: string;
  payload?: any;
};

export type CurriculumReducer = {
  courses: [];
  stepper: number;
  coursesList: [];
  isView: boolean;
};

const initstate = {
  courses: [],
  coursesList: [],
  isView: false,
  isUnitFirst: false,
  stepper: 1,

};

const curriculumReducer = (state = initstate, action: Action) => {
  switch (action.type) {
    case TYPES.curriculum.courses: {
      return {
        ...state,
        ...(action.payload || {}),
      };
    }
    case TYPES.curriculum.setViewStatus:{
      return {
          ...state,
          isView:action.payload
      }

    }
    case TYPES.curriculum.courseSyllabus: {
      console.log("unit first", action.payload, state);
      action.payload.coursesList = action.payload.coursesList.map(
        (course, index) => {
          let courseObj: any = {};
          if (action.payload.isUnitFirst) {
            courseObj["curriculum"] = course;
          } else {
            courseObj["curriculum"] = course;
            courseObj["chapters"] = [];
          }
          return courseObj;
        }
      );

      return {
        ...state,
        ...(action.payload || {}),
      };
    }
    case TYPES.curriculum.updateInnerCollapsible: {
      let updatedCourseList = state.coursesList.map((data, index) => {
        if (index === action.payload.index) {
          data["chapters"] = action.payload.syllabus;
        }
        return data;
      });
      return {
        ...state,
        coursesList: updatedCourseList,
      };
    }

    case TYPES.curriculum.updateInnerSyllabus: {
      let updatedCourseList = state.coursesList.map((data, index) => {
        if (index === action.payload.unitIndex) {
          data["chapters"][action.payload.index]["topics"] =
            action.payload.syllabus;
        }
        return data;
      });
      return {
        ...state,
        coursesList: updatedCourseList,
      };
    }

    case TYPES.stepper: {
      return {
        ...state,
        stepper: action.payload,
      };
    }

    case TYPES.curriculum.completeStatus: {
      if(action.payload.type === "Unit") {
        return {
          ...state,
          coursesList: [
            ...state.coursesList.slice(0, action.payload.unitIndex),
            {
              ...state.coursesList[action.payload.unitIndex],
              chapters: [
                ...state.coursesList[action.payload.unitIndex].chapters.slice(0, action.payload.chapterIndex),
                {
                  ...state.coursesList[action.payload.unitIndex].chapters[action.payload.chapterIndex],
                  topics: [
                    ...state.coursesList[action.payload.unitIndex].chapters[action.payload.chapterIndex].topics.slice(0, action.payload.index),
                    {
                      ...state.coursesList[action.payload.unitIndex].chapters[action.payload.chapterIndex].topics[action.payload.index],
                      statusId: 3
                    },
                    ...state.coursesList[action.payload.unitIndex].chapters[action.payload.chapterIndex].topics.slice(action.payload.index+1)
                  ]
                },
                ...state.coursesList[action.payload.unitIndex].chapters.slice(action.payload.chapterIndex+1)
              ]
            },
            ...state.coursesList.slice(action.payload.unitIndex+1)
          ]
        }
      } else {
        return {
          ...state,
          coursesList: [
            ...state.coursesList.slice(0, action.payload.unitIndex),
            {
              ...state.coursesList[action.payload.unitIndex],
              chapters: [
                ...state.coursesList[action.payload.unitIndex].chapters.slice(0, action.payload.chapterIndex),
                {
                  ...state.coursesList[action.payload.unitIndex].chapters[action.payload.chapterIndex],
                  statusId: 3,
                },
                ...state.coursesList[action.payload.unitIndex].chapters.slice(action.payload.chapterIndex+1)
              ]
            },
            ...state.coursesList.slice(action.payload.unitIndex+1)
          ]
        }
      }
  }

    case TYPES.revoke:
      return initstate;

    default:
      return state;
  }
};

export default curriculumReducer;
