import React, { useEffect, useState, useRef, useCallback } from "react";
import * as pdfJS from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

import PageLoader from "./PageLoader";
import { useAppState } from "../Context";
import { Interactives } from "../Helpers/Interactive";
import { interactivesDetailService } from "../Services/interactivesDetailService";
import { fetchResources } from "../Services/fetchResources";
import Bookmark from "./Bookmark";
import { separateByTypes } from "../utils/SortInteractives";
import ZoomComponent from "./ZoomComponent";
import Canvas from "./Canvas";
import Loader from '../../../common/Loader'
import Fullscreen from "./Fullscreen";
// import { fetchHighlights } from "../Services/fetchHighlights";
// import { saveReadProgress } from "../Services/saveReadProgress";
// import { addData, deleteBookById, getBookById } from "../utils/Dexie";
import { transcriptions } from "../i18n/languages";
import SearchModal from "./SearchModal";
import CanvasTwo from "./CanvasTwo";
import { API } from "../utils/API";
import RightArrowBlue from '../Assets/rightArrowBlue.svg'
import pdfZoomIn from "../Assets/pdfZoomIn.svg";
import pdfZoomout from "../Assets/pdfZoomOut.svg";
import pdfReset from "../Assets/pdfReset.svg";
import fullIcon from "../Assets/pdfFullViewIcon.svg";
import printIcon from "../Assets/pdfPrint.svg";
import pdfRightIcon from "../Assets/pdfForward.svg";
import ToogleSinglePage from "./ToggleSinglePage";
let heightToWidthRatio = [1.4142, 1];
let generalNewWidth;

export default function PdfViewer({
  url,
  isMobileView,
  pdfContainerStyling,
  controlsConfig
}) {
  pdfJS.GlobalWorkerOptions.workerSrc = pdfjsWorker;
  var PDFDoc = null;
  const [pdfRef, setPdfRef] = useState();
  const canvasRef = useRef();
  const canvas2Ref = useRef();
  const keyDownRef = useRef();
  const prevButtonRef = useRef();
  const nextButtonRef = useRef();
  const [interactivesToRemove, setInteractivesToRemove] = useState([]);
  const [showDrawingCanvas, setShowDrawingCanvas] = useState(false);
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  let isCommentHidden = localStorage.getItem('commentHidden');
  let bookLoadedFinally = false;
  const { currentPage, setCurrentPage, isLoading, setIsLoading, highlightedItems, lastReadPages, setlastReadPages, allInteractivities, setAllInteractivities, languagePrefered, searchValue, setSearchValue, searchResult, setSearchResult, isSearching, setIsSearching, resources, setResources, searchStatus, setSearchStatus, showSearch, textDataToSearch, setTextDataToSearch, generalWidth, setGeneralWidth, currentPageArray, setCurrentPageArray, currentPageTwo, setCurrentPageTwo, isDualPage, setIsDualPage, viewMode, setViewMode, scaleSize, setScaleSize, isPageRendering, setIsPageRendering, bookDetails, setBookDetails, setTotalPages, totalPages, fullScreenIcon, setFullScreenIcon } = useAppState("reader");
  const [lastPageNumber, setLastPageNumber] = useState();
  const [isSmartTV, setIsSmartTV] = useState(false);
  const [jumpToPageInput,setJumpToPageInput]= useState(1);

  // let reader_pdf_url = process.env.reader_pdf_url;
  const renderPage = useCallback((pageNum, pdf = pdfRef, scaleSize,isDualPage) => {
    if (!pageNum) {
      pageNum = 1;
    }
    pdf && pdf.getPage(pageNum).then(function (page) {
          setlastReadPages((prev) => [...prev, pageNum]);
          setIsPageRendering(true);

      // let getViewMode = localStorage.getItem("viewMode");
      // console.log('getViewMode: ', getViewMode);

          // if (pageNum === 1) {//Only handle first page
          //   setIsDualPage(false);
          //   localStorage.removeItem("isDualPage");
          // } else {
            if (isDualPage) {
              if (pageNum !== lastPageNumber && !isMobileView) {
                renderSecondPage(pdf, pageNum + 1,scaleSize);
                // setIsDualPage(true);
                // localStorage.setItem("isDualPage", true);
              }
            }
      // }
      const canvas = canvasRef.current;
      var ctx = canvas.getContext("2d");

          var scales = { 1: 3.2, 2: 4 }
      var defautlScale = 4;
          var scale = scales[window.devicePixelRatio] || defautlScale;

      var pageWidthScale = document.getElementById("pdfViewerParent").clientWidth / page.view[2];
      var pageHeightScale = document.getElementById("pdfViewerParent").clientHeight / page.view[3];
      var viewport = page.getViewport({ scale: scaleSize});
      
          canvas.height = viewport.height ;
          canvas.width = viewport.width;
          var isPortrait = viewport.height > viewport.width;
          // isBookPortrait = isPortrait;
          let bookDimension = {
            isBookPortrait: isPortrait,
            width: viewport.width,
            height: viewport.height
          }
          localStorage.setItem("bookDimension", JSON.stringify(bookDimension));

          var displayWidth = Math.min(pageWidthScale, pageHeightScale);


      let getDrawingCanvas = document.getElementById('drawing-board');
          setShowDrawingCanvas(true);
          if (isPortrait) {
            if (isMobileView) {
              let newWidth = (viewport.width * 788) / viewport.height;
              // generalNewWidth = newWidth;
              // setGeneralWidth(newWidth);
              let windowWidth = window.innerWidth - 46;
              let windowHeight = ((window.innerWidth - 20) * 788) / newWidth;
              // if (scaleS) =
              //Harshjeet
              canvas.height = windowHeight;
              canvas.width = windowWidth;
              // canvas.style.width = windowWidth + "px";
              // canvas.style.height = windowHeight + "px";
              // getDrawingCanvas.width = windowWidth;
              // getDrawingCanvas.height = windowHeight;

              // localStorage.setItem("bookWidth", newWidth);
            } else {
              let newWidth = (viewport.width * 788) / viewport.height;
              // canvas.style.width = newWidth + "px";
              // canvas.style.height = "788px";
              getDrawingCanvas.width = newWidth;
              getDrawingCanvas.height = "788";

              // localStorage.setItem("bookWidth", newWidth);

              // if (pageNum !== 1) {
                // turnOnDualPage(canvas, newWidth);
              // }
            }

          } else {
            if (isMobileView) {
              // let newWidth = (viewport.width * 788) / viewport.height;
              // generalNewWidth = newWidth;
              // setGeneralWidth(newWidth);
              // let windowWidth = window.innerWidth - 20;
              // let windowHeight = ((window.innerWidth - 20) * 788) / newWidth;

              // canvas.style.width = windowWidth + "px";
              // canvas.style.height = windowHeight + "px";
              // getDrawingCanvas.width = windowWidth;
              // getDrawingCanvas.height = windowHeight;

              // localStorage.setItem("bookWidth", newWidth);
            } else {
              let newWidth = (viewport.width * 600) / viewport.height;
              canvas.style.width = newWidth + "px";
              canvas.style.height = "600px";
              getDrawingCanvas.width = newWidth;
              getDrawingCanvas.height = "600";

              localStorage.setItem("bookWidth", newWidth);
            }

          }
          let parentarea = document.getElementById("area");
          // let padVal = arr.get(scaleSize.toString());
          // let decPadVal = decrementArr.get(scaleSize.toString());
          const parentDiv = document.getElementById("pdfViewerParent");
      
            if (scaleSize > 1) {
              const width = isDualPage ? viewport.width + viewport.width : viewport.width;
              parentarea.style.width = `${width}px`;
              // parentarea.style.width = `${parentarea.offsetWidth * (scale)}px`;
              const offsetX = (width - parentDiv.clientWidth) / 2;
              parentDiv.scrollLeft = offsetX;
            }   
          const renderContext = {
            canvasContext: ctx,
            viewport: viewport,
          };


          let renderTask = page.render(renderContext);

          renderTask.promise.then(() => {
            setIsPageRendering(false);
          })

          // setTimeout(() => {
          //   checkIsTelevisions();
          // }, 1000);

        });
    },
    [pdfRef,currentPage]
  );


  const checkIsTelevisions = () => {
    if (!isSmartTV) {
      if (window.innerWidth > 1920) {
        let getPageMode = document.getElementById("tooglePageMode");
        getPageMode.click();
        getPageMode.disabled = true;
        setIsSmartTV(true);
      }
    }
  };


  const turnOnDualPage = (leftCanvas, newWidth) => {

    //Adjsuting Canvas Two
    let theCanvasTwo = document.getElementById("canvas2");
    // theCanvasTwo.style.width = newWidth + "px";
    // theCanvasTwo.style.height = "788px";

  };

  const renderSecondPage = (pdf, pageNumToRender,scaleSize) => {
    setCurrentPageTwo(pageNumToRender);
    pdf &&
      pdf.getPage(pageNumToRender).then(function (page) {
        const canvasTwo = canvas2Ref.current;
        let getDrawingCanvas2 = document.getElementById('drawing-board2');
        var ctx2 = canvasTwo.getContext("2d");

        var viewport = page.getViewport({ scale: scaleSize });
        let customeHeight = 0;
        if(pdfContainerStyling?.height?.includes("px")){
          customeHeight = pdfContainerStyling?.height?.replace("px","");
        }else if(pdfContainerStyling?.height?.includes("vh")){
          customeHeight = pdfContainerStyling?.height?.replace("vh","");
          customeHeight = customeHeight * window.innerHeight / 100
        }
        canvasTwo.height = viewport.height ;
        canvasTwo.width = viewport.width;
        var isPortrait = viewport.height > viewport.width;

        if (isPortrait) {
          let newWidth = (viewport.width * 788) / viewport.height;
          // canvasTwo.style.width = newWidth + "px";
          // canvasTwo.style.height = "788px";
          getDrawingCanvas2.width = newWidth;
          getDrawingCanvas2.height = "788";
        } else {
          let newWidth = (viewport.width * 600) / viewport.height;
          canvasTwo.style.width = newWidth + "px";
          canvasTwo.style.height = "600px";
          getDrawingCanvas2.width = newWidth;
          getDrawingCanvas2.height = "600";

          localStorage.setItem("bookWidth", newWidth);
        }

        const renderContext = {
          canvasContext: ctx2,
          viewport: viewport,
        };

        let renderTask = page.render(renderContext);


      });
  };



  useEffect(() => {
    renderPage(currentPage, pdfRef,scaleSize,isDualPage);
  }, [pdfRef, currentPage,isDualPage,scaleSize]);



  useEffect(() => {
    // calculateButtonPosition();
  
    setIsLoading(true);
    (async () => {
      try {
        // const book = await getBookById(bookID)
        let loadingTask;
        // if (book && book.version_id == bookVersionId) {
        //   loadingTask = pdfJS.getDocument({
        //     data: book.data
        //   });
        // } else {
          console.log('url:===> ', url);
          loadingTask = pdfJS.getDocument({
            url: url,
            // httpHeaders: {
            //   Authorization: `Bearer ${localStorage.getItem("idToken")}`,
            //   disableAutoFetch: false,
            //   disableStream: false
            // },
          });
        // }
        loadingTask.promise
          .then(
            (loadedPdf) => {
              PDFDoc = loadedPdf
              setLastPageNumber(loadedPdf.numPages);
              loadedPdf.getData().then(async (data) => {
                setLoadingPercentage(10);
                setLoadingPercentage(20);
                setLoadingPercentage(30);
                setLoadingPercentage(40);
                setLoadingPercentage(50);
                setLoadingPercentage(99);
                // const book = await getBookById(bookID)
                // if (book && book.version_id != bookVersionId) {
                //   await deleteBookById(bookID)
                // }
                setIsLoading(false);
                // // Check available quota in IndexedDB using the Storage Manager API
                // if ('storage' in navigator && 'estimate' in navigator.storage) {
                //   navigator.storage.estimate().then(async (estimate) => {
                //     const { usage, quota } = estimate;
                //     if (!book || (book && book.version_id != bookVersionId)) {
                //       if (quota > data.length + 104857600) {
                //         try {
                //           await addData({ book_id: bookID, version_id: bookVersionId, data })
                //         } catch (error) {
                //           console.log(error)
                //         }
                //       }
                //     }
                //   });
                // } else {
                //   console.log('The Storage Manager API is not supported in this browser.');
                // }
              })
              setPdfRef(loadedPdf);
              setTotalPages(loadedPdf.numPages);
              setIsDualPage(loadedPdf.numPages  > 1 && !controlsConfig.onlySinglePage )
              extractText(loadedPdf);

            },
            function (reason) {
              console.error(reason);
            }
          )
          .finally(() => {
            bookLoadedFinally = true;
          });
        loadingTask.onProgress = function (data) {
          setLoadingPercentage(data.loaded * 100 / data.total);
          if (Math.round(data.loaded * 100 / data.total) > 50 && bookLoadedFinally === true) {
            setIsLoading(false);
          }
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }, [url]);

  const extractText = async (pdfRef) => {
    if (pdfRef.numPages) {
      let extactedData = [];
      for (let i = 1; i <= pdfRef.numPages; i++) {

        await new Promise((resolve) => {
          requestAnimationFrame(resolve); // Yield control to the browser
        });

        pdfRef.getPage(i).then(async function (page) {
          const textContent = await page.getTextContent();
          const pageText = await textContent.items.map((textItem) => textItem.str).join(' ');
          extactedData.push({
            page: i,
            text: pageText,
          });
        });
      }
      setTextDataToSearch(extactedData);
    }
  };

  const checkForRedirection = () => {
    let isRedirectToExist = localStorage.getItem("redirectTo");
    if (isRedirectToExist) {
      if (parseInt(isRedirectToExist) === 1 || parseInt(isRedirectToExist) % 2 === 0) {
        setCurrentPage(parseInt(isRedirectToExist));
        localStorage.removeItem("redirectTo");
      } else {
        setCurrentPage(parseInt(isRedirectToExist) - 1);
        localStorage.removeItem("redirectTo");
      }
    }
    else {
      let lastVisitedPage = localStorage.getItem("pageIndex");
      if (lastVisitedPage) {
        if (parseInt(lastVisitedPage) === 1 || parseInt(lastVisitedPage) % 2 === 0) {
          setCurrentPage(parseInt(lastVisitedPage));
          localStorage.removeItem("pageIndex");
        } else {
          setCurrentPage(parseInt(lastVisitedPage) - 1);
          localStorage.removeItem("pageIndex");
        }
      }
    }
  };

  const calculateButtonPosition = () => {
    const canvas = canvasRef.current;
    const prevButton = prevButtonRef.current;
    const nextButton = nextButtonRef.current;

    if (canvas && prevButton && nextButton) {
      const canvasWidth = canvas.clientWidth;
      const buttonWidth = prevButton.clientWidth;
      const margin = 10;
      const prevButtonLeft = canvas.offsetLeft + margin;
      const prevButtonTop =
        canvas.offsetTop + canvas.height / 2 - buttonWidth / 2;
      const nextButtonLeft =
        canvas.offsetLeft + canvasWidth - buttonWidth - margin;
      const nextButtonTop = prevButtonTop;

      prevButton.style.left = prevButtonLeft + "px";
      prevButton.style.top = prevButtonTop + "px";
      nextButton.style.left = nextButtonLeft + "px";
      nextButton.style.top = nextButtonTop + "px";
    }
  };
  const nextPage = () => {
    if (totalPages > 0 && currentPage < totalPages) {
      if (isDualPage ) {
        if((currentPage + 1) !== totalPages ){
          setCurrentPage(currentPage + 2);
          setJumpToPageInput(currentPage+2)
          localStorage.setItem("pageIndex", currentPage + 2)
        }
      } else {
        setCurrentPage(currentPage + 1);
        setJumpToPageInput(currentPage+1)

        localStorage.setItem("pageIndex", currentPage + 1)
    }
    }
  }

  const prevPage = () => {
    if (currentPage > 1) {
      if (isDualPage) {
        if (currentPage - 2 === 0) {
          setCurrentPage(1);
          setJumpToPageInput(1)

        } else {
          setCurrentPage(currentPage - 2);
          setJumpToPageInput(currentPage-2)

          localStorage.setItem("pageIndex", currentPage - 2)
        }
      } else {
        setCurrentPage(currentPage - 1);
        setJumpToPageInput(currentPage-1)
        localStorage.setItem("pageIndex", currentPage - 1)
      }
    }

  }

  const handleKeyDown = (event) => {
    if (event.key === "ArrowRight") {
      nextPage();
      event.preventDefault();
    }
    if (event.key === "ArrowLeft") {
      prevPage();
      event.preventDefault();
    }
    if (event.defaultPrevented) {
      return;
    }
    
  };

  // const clearCurrentInteractives = async () => {
  //   let getEachElement = await document.querySelectorAll(".interactive");

  //   getEachElement.length > 0
  //     ? getEachElement.forEach(async (element) => {
  //       await element.remove();
  //     })
  //     : interactivesToRemove.filter((eachInteractivity) => {
  //       let getEachElement = document.getElementById(eachInteractivity._id);
  //       getEachElement.remove();
  //     });
  // };

  // const fetchInteractives = async () => {
  //   let getInteractives = await interactivesDetailService(isAdmin, bookID, bookVersionId, null);
  //   let sortedInteractivities = await separateByTypes(getInteractives);
  //   setAllInteractivities(sortedInteractivities);
  // };

  // const fetchResourcesOfBook = async () => {
  //   let fetchedResources = await fetchResources(isAdmin, bookID);
  //   setResources(fetchedResources);
  // };


  // const fetchBookDetails = async () => {
  //   let response = await API("get", `/api-books/books/${bookID}/details`);
  //   setBookDetails(response);
  // };
  const arr = new Map();
  arr.set("1", ["9vh", "4vh"]);
  arr.set("1.1", ["16vh", "8vh", "6vh"]);
  arr.set("1.2000000000000002", ["24vh", "11vh", "6vh"]);
  arr.set("1.3000000000000003", ["34vh", "14vh", "6vh"]);
  arr.set("1.4000000000000004", ["39vh", "17vh", "6vh"]);
  arr.set("1.5000000000000004", ["47vh", "20vh", "10vh"]);
  arr.set("1.6000000000000005", ["55vh", "23vh", "6vh"]);
  arr.set("1.7000000000000006", ["63vh", "26vh", "6vh"]);
  arr.set("1.8000000000000007", ["70vh", "29vh", "6vh"]);
  arr.set("1.9000000000000008", ["78vh", "32vh", "6vh"]);

  const decrementArr = new Map();
  decrementArr.set("2.000000000000001", ["72vh", "29vh"]);
  decrementArr.set("1.9000000000000008", ["64vh", "26vh"]);
  decrementArr.set("1.8000000000000007", ["56vh", "23vh"]);
  decrementArr.set("1.7000000000000006", ["48vh", "20vh"]);
  decrementArr.set("1.6000000000000005", ["40vh", "16vh"]);
  decrementArr.set("1.5000000000000004", ["32vh", "14vh"]);
  decrementArr.set("1.4000000000000004", ["24vh", "10vh"]);
  decrementArr.set("1.3000000000000003", ["16vh", "6vh"]);
  decrementArr.set("1.2000000000000002", ["8vh", "4vh"]);
  decrementArr.set("1.1", ["0vh", "0vh"]);

  function handleScale(actionType) {
    let parentarea = document.getElementById("area");
    const parentDiv = document.getElementById("pdfViewerParent");
    // const canvas = canvasRef.current;
    // let getDrawingCanvas = document.getElementById('drawing-board');
    let scale = scaleSize;
    if (actionType == "increment") {
      scale = scaleSize + 0.25;
      setScaleSize((prev) => prev + 0.25);

      if (scale > 1) {
        parentarea.style.width = `${parentarea.offsetWidth * (scale)}px`;
        // canvas.style.width = `${parentarea.offsetWidth * (scale)}px`;
        // getDrawingCanvas.style.width = `${parentarea.offsetWidth * (scale)}px`;
        const offsetX = (parentarea.offsetWidth - parentDiv.clientWidth) / 2;
        parentDiv.scrollLeft = offsetX;
      }
    } else {
      scale = scaleSize - 0.25;
      setScaleSize((prev) => prev - 0.25);
      if (scale > 1) {
        parentarea.style.width = `${parentarea.offsetWidth * (scale)}px`;
        // canvas.style.width = `${parentarea.offsetWidth * (scale)}px`;
        // getDrawingCanvas.style.width = `${parentarea.offsetWidth * (scale)}px`;
        const offsetX = (parentarea.offsetWidth - parentDiv.clientWidth) / 2;
        parentDiv.scrollLeft = offsetX;
      }
    }
    //@Harshjeet
    if (isMobileView && scale === 1) {
      parentDiv.style.overflow = "visible !important";
    } else {
      parentDiv.style.overflow = "auto";
    }
  }
  function restorePadding() {
    let parentarea = document.getElementById("area");
    parentarea.style.padding = "0px 0px";
  }

  useEffect(() => {
    if (isDualPage) {
      localStorage.setItem("isDualPage", isDualPage);
    }
    // localStorage.setItem("lastRodePage", lastReadPages);

    document.addEventListener("keydown", handleKeyDown);
    // addEditorEvents({ Elements });
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentPage, prevPage, nextPage, isDualPage]);

  // useEffect(() => {
  //   fetchInteractives();
  //   fetchResourcesOfBook();
  //   fetchBookDetails();
  // }, [])

  // useEffect(() => {
  //   // resetPenTool();
  //   if (allInteractivities.length > 0) {
  //     renderInteractives();
  //   }
  // }, [allInteractivities, currentPage])

  // const renderInteractives = async () => {
  //   let getIsDualPage = localStorage.getItem("isDualPage");
  //   let interactivityToRender = await allInteractivities.filter((eachInteractivity) => {
  //     if (getIsDualPage && !isMobileView) {
  //       return eachInteractivity.content.page === currentPage || eachInteractivity.content.page === currentPage + 1;
  //     } else {
  //       return eachInteractivity.content.page === currentPage
  //     }
  //   });
  //   Interactives(interactivityToRender, currentPage, isCommentHidden, isMobileView, window.innerWidth - 20, ((window.innerWidth - 20) * 788) / generalNewWidth, isDualPage);
  //   setInteractivesToRemove(interactivityToRender);
  // };

  // useEffect(() => {
  //   localStorage.setItem("lastRodePage", lastReadPages);
  //   clearCurrentInteractives();
  // }, [currentPage]);

  // useEffect(() => {
  //   if (!isAdmin) {
  //     let interval;
  //     if (lastReadPages.length > 0) {
  //       interval = setInterval(async function () {
  //         let isSaved = await saveReadProgress(bookID, lastReadPages);
  //         if (isSaved) {
  //           setlastReadPages([]);
  //         }
  //       }, 30000);
  //     }
  //     return () => {
  //       clearInterval(interval);
  //     };
  //   }
  // }, [lastReadPages]);

  // useEffect(() => {
  //   checkForRedirection();
  // }, [isLoading])

  // function searchInPDF(query) {
  //   const abortController = new AbortController();
  //   const { signal } = abortController;

  //   setIsSearching(true);
  //   var searchResults = [];

  //   async function searchOnPage(pageNum) {
  //     if (signal.aborted) {
  //       return;
  //     }

  //     const promises = [];

  //     for (let i = 0; i < textDataToSearch.length; i++) {
  //       setIsSearching(true);
  //       if (signal.aborted) {
  //         setIsSearching(false);
  //         return;
  //       }

  //       await new Promise((resolve) => {
  //         requestAnimationFrame(resolve); // Yield control to the browser
  //       });
  //       let pageNumber = await i;
  //       let pageText = await textDataToSearch[i].text;
  //       if (pageText.toLowerCase().includes(query.toLowerCase())) {
  //         searchResults.push({
  //           page: pageNumber,
  //           text: pageText,
  //         });
  //       }
  //     }

  //     try {
  //       setIsSearching(true);
  //       // await Promise.all(promises);
  //       // All pages have been searched, do something with the results
  //       setSearchResult(searchResults);
  //       setSearchStatus({ searchStatus: "searchStatus" });
  //       setIsSearching(false);
  //       return searchResults;
  //     } catch (error) {
  //       if (error.name === 'AbortError') {
  //         // Search was cancelled, ignore the error
  //       } else {
  //         console.error("Error searching pages:", error);
  //       }
  //     }
  //   }

  //   function cancelSearch() {
  //     abortController.abort();
  //     setIsSearching(false);
  //   }

  //   // Start searching from the first page
  //   searchOnPage(1);
  //   return {
  //     cancelSearch
  //   }
  // }
  const blockInvalidChar = e => {
    const regex =  /^[0-9]*$/;
    if(e.keyCode == 8 || e.key ==="Enter"){

    }else if (!regex.test(e.key)) {
      e.preventDefault();
    }
    
  }

  const handleLeftPageNum = (e) => {
    let newValue = e.target.value;
    if (newValue.length <= 5) {
      setJumpToPageInput(newValue);
      if (parseInt(newValue) <= totalPages) {
        setJumpToPageInput(parseInt(newValue));
      }
    }
  };
  const handleEnter = (e) => {
    if (e.key === "Enter") {
        setCurrentPage(jumpToPageInput);
    }
  };
const customStylings = {...(isMobileView ?  {} : fullScreenIcon ? {height:"calc(100vh -58px)"} :  pdfContainerStyling)};
  return (
    <>
     <Loader val={isLoading}/>
      {/* {!isLoading && isPageRendering && (
        <div className="centered-flex">
          <PageLoader
            className="centered-flex-loader"
            height="20px"
            width="20px"
          />
        </div>
      )} */}
      <div style={{display:"flex",width:"100%"}}>
        <div
          id="wrap"
          ref={keyDownRef}
          className={`white-bg-flex ${isLoading ? "invisible" : "visible" }`}
        >
          <div className="full-width-relative-flex pdf">
            <div style={{margin:"auto", display:"flex",position:"relative",justifyContent:'space-around'}}>
              {/* {!isMobileView && pdfRef && scaleSize === 1 && <button
                  ref={prevButtonRef}
                  data-tip={`${transcriptions.reader.Tooltip.prev[languagePrefered]}`}
                  id="prev"
                  className="prev-btn"
                  disabled={currentPage === 1 || isPageRendering ? true : false}
                  onClick={prevPage}
                >
                <img src={RightArrowBlue} height={50} width={50} />
                </button>} */}
                  <div
                id="area"
                className={`canvas-wrapper-container`}
                tabIndex="0"
                style={{
                  // transform: `scale(${scaleSize})`,
                  // transition: "transform 0.3s ease",
                  // transformOrigin: "center center",
                  width: '100%',
                  // left:"50%",
                  // transform: "translate(-50%, 0%)",
                  height: "100%" , // 83.333333% is equivalent to Tailwind's h-5/6
                  overflow: "visible",
                 ...customStylings  
                }}
              >
                <div id="canvas-container" className={`canvas-container`} style={{display :"flex",justifyContent:"center",width:"100%"}}> 
                  <div id="leftPage" className={`book-mark-container`} >
                    {/* {!isAdmin && !isPromotional && (
                      <Bookmark
                        bookID={bookID}
                        bookMarkedPages={bookMarkedPages}
                        currentPage={currentPage}
                        markUnMark={markUnMark} isMobileView={isMobileView}
                        setScaleSize={setScaleSize}
                        scaleSize={scaleSize}
                      />
                    )} */}
                    <canvas
                      id="the-canvas"
                      ref={canvasRef}
                      className="the-canvas"
                    ></canvas>
                    {/* following canvas is for drawind board  */}
                    <Canvas isLoading={isLoading} isMobileView={isMobileView} generalNewWidth={generalNewWidth} />
                  </div>
                  {totalPages > 1 ?  <div id="rightPage" className={`book-mark-container`} style={{display:isDualPage ? "block" :  "none"}}>
                    {/* {!isAdmin && !isPromotional && (
                      <Bookmark
                        bookID={bookID}
                        bookMarkedPages={bookMarkedPages}
                        currentPage={currentPage + 1}
                        markUnMark={markUnMark} isMobileView={isMobileView}
                        setScaleSize={setScaleSize}
                        scaleSize={scaleSize}
                      />
                    )} */}
                    <canvas
                      id="canvas2"
                      ref={canvas2Ref}
                      className="the-canvas"
                    >
                    </canvas>
                    <CanvasTwo isLoading={isLoading} isMobileView={isMobileView} generalNewWidth={generalNewWidth} />
                  </div>:null}
                </div>
                  </div>
              {/* {!isMobileView && pdfRef && scaleSize === 1 && <button
                    ref={nextButtonRef}
                    data-tip={`${transcriptions.reader.Tooltip.next[languagePrefered]}`}
                    id="next"
                    className="next-btn"
                    disabled={
                      pdfRef._pdfInfo.numPages === currentPage || isPageRendering ? true : false
                    }
                    onClick={nextPage}
                  >
                <img src={RightArrowBlue} height={50} width={50} />
                  </button>} */}
            </div>
          </div>
        </div>
      </div>
      {/* {
        showSearch && <SearchModal bookInfo={bookInfo} searchInPDF={searchInPDF} textDataToSearch={textDataToSearch} />
      } */}
    </>
  );
}
