import { API } from "../utils/API";

export const saveCanvas = async (bookId, clearCanvas) => {
    let getStack = localStorage.getItem('penStack');
    if (getStack) {
        let parsedStack = await JSON.parse(getStack);
        if (parsedStack.length > 0) {
            let payload = await modifyPayloadForServer(parsedStack[parsedStack.length - 1], clearCanvas);
            let recieved = await sendToServer(payload, bookId);
            localStorage.removeItem('penStack');
            window.parent.postMessage({ action: "CheckPenStack" }, "*");
            return recieved;
        }
    }
};

export const hitClearCanvas = async (clearCanvas) => {
    clearCanvas();
};

export const modifyPayloadForServer = async (stack, clearCanvas) => {
    let strokes = [];
    let erasers = [];
    let payload = {};
    let stage = stack[Object.keys(stack)];
    for (let i = 0; i < stage.length; i++) {
        if (stage[i].type === "clearAll") {
            strokes = [];
            erasers = [];
            hitClearCanvas(clearCanvas);
        }
        if (stage[i].type === "pen") {
            let newStroke = {};
            newStroke.color = stage[i].color;
            newStroke.page = stage[i].page;
            newStroke.thickness = stage[i].thickness;
            newStroke.opacity = 0.7;
            newStroke.points = stage[i].points;
            strokes.push(newStroke);
        }
        if (stage[i].type === "eraser") {
            let newEraser = {};
            newEraser.points = stage[i].points;
            newEraser.page = stage[i].page;
            erasers.push(newEraser);
        }
    }
    payload.type = "pen";
    payload.strokes = strokes;
    payload.erasers = erasers;
    return payload;
};

export const sendToServer = async (payload, bookId) => {
    let response = await API("post", `/api-library/v2/books/${bookId}/drawings`, payload);
    return response;
};