import mixpanel, { Mixpanel } from 'mixpanel-browser';
import { MIXPANEL_MODULES, MIXPANEL_PLATFORM, userRoleId, constants } from "../shared/constants";
import store from "../redux/store";

let adminMixPanel : Mixpanel;
let teacherMixpanel : Mixpanel;
  
export default function mixPanelTrack(eventName:string, data: any) {
  if(!adminMixPanel || !teacherMixpanel) {
    adminMixPanel = mixpanel.init(constants.mixPanelAdminToken || '', {}, 'Admin')
    teacherMixpanel = mixpanel.init(constants.mixPanelTeacherToken || '', {}, 'Teacher') 
  }

  const userRoleID = store.getState().login?.userData?.UserRoleID;
  const orgID = store.getState().tenant?.organizationId;
  const schName = store.getState().tenant?.instituteName;
  const tenantID = store.getState().tenant?.tenantId;
  const userID = store.getState().login?.userData?.userId;
  const userName = store.getState().login?.userData?.firstName + " " + store.getState().login?.userData?.lastName;
  const schoolID = store.getState().tenant?.contentCategoryGroupId;
  if(data&&tenantID&&userID){
    data["distinct_id"] = tenantID + "_" + userID;
  }
  

  if(eventName == 'Page Viewed') {
    data["Organization ID"] = orgID;
    data["School Name"] = schName;
    data["Tenant ID"] = tenantID;
    data["User ID"] = userID;
    data["User Name"] = userName;
    data["Platform"] = MIXPANEL_PLATFORM.WEB;
    data["School ID"] = schoolID;
    
    if (constants.enableMixPanel && MIXPANEL_MODULES[eventName]) userRoleID == userRoleId.adminId ?  adminMixPanel.register(data) : teacherMixpanel.register(data);
    if (constants.enableMixPanel && MIXPANEL_MODULES[eventName]) userRoleID == userRoleId.adminId ?  adminMixPanel.track(eventName, data) : teacherMixpanel.track(eventName, data);
  } else {
    data["School Name"] = schName;
    if (constants.enableMixPanel && MIXPANEL_MODULES[eventName]) userRoleID == userRoleId.adminId ?  adminMixPanel.track(eventName, data) : teacherMixpanel.track(eventName, data);
  }
}

