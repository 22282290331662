async function JsonToStringify(data) {
    return JSON.stringify(data);
};

async function StringifyToJson(data) {
    return JSON.parse(data);
}

export async function getStack() {
    let getCurrentStack = await localStorage.getItem('penStack');
    if (getCurrentStack) {
        let parsedData = await StringifyToJson(getCurrentStack);
        return parsedData;
    } else {
        let createStack = await localStorage.setItem('penStack', JSON.stringify([]));
        let getCreatedStack = await localStorage.getItem('penStack');
        let parsedData = await StringifyToJson(getCreatedStack);
        return parsedData;
    }
};

export async function AddNewDataToStack(newData) {

    let currentStack = await getStack();//I'll get main array

    //Defining new Object (This will be kept in stack)
    let newObject = {};

    //Find length of current stack so that you can define new stage name
    let lengthOfCurrentStack = currentStack.length;

    let lastStageData = []; // I'll have last stage data in this array

    if (lengthOfCurrentStack >= 1) {
        //If stack already has some old stage get last stage data
        let lastStackObject = currentStack[lengthOfCurrentStack - 1];

        let lastStageName = Object.keys(currentStack[lengthOfCurrentStack - 1])[0];

        let valueOfLastStage = lastStackObject[lastStageName];

        valueOfLastStage.length && valueOfLastStage.forEach((eachEvent) => lastStageData.push(eachEvent));

    }

    lastStageData.push(newData);

    //So new stage name will be : length of current stack + 1
    let stageName = `stage${lengthOfCurrentStack + 1}`;

    newObject[stageName] = lastStageData;

    currentStack.push(newObject);

    return SaveStack(currentStack);
};

export async function CreateAClearAllStageInStack(isOldPenExisting) {

    let currentStack = await getStack();//I'll get main array

    let newObject = {};

    //Find length of current stack so that you can define new stage name
    let lengthOfCurrentStack = currentStack.length;

    let lastStageData = []; // I'll have last stage data in this array

    if (lengthOfCurrentStack >= 1) {
        //If stack already has some old stage get last stage data
        let lastStackObject = currentStack[lengthOfCurrentStack - 1];

        let lastStageName = Object.keys(currentStack[lengthOfCurrentStack - 1])[0];

        let valueOfLastStage = lastStackObject[lastStageName];

        valueOfLastStage.length && valueOfLastStage.forEach((eachEvent) => lastStageData.push(eachEvent));

    }

    if (isOldPenExisting) {
        lastStageData.push({
            type: "clearAll",
            isOldPenExist: false
        });
    } else {
        lastStageData.push({
            type: "clearAll",
            isOldPenExist: false
        });
    }


    //So new stage name will be : length of current stack + 1
    let stageName = `stage${lengthOfCurrentStack + 1}`;

    newObject[stageName] = lastStageData;

    currentStack.push(newObject);

    return SaveStack(currentStack);

}

export async function RemoveFromStack() {
    let currentStack = await getStack();
    currentStack.pop();
    window.parent.postMessage({ action: "CheckPenStack" }, "*");
    return SaveStack(currentStack);
};

export async function SaveStack(newStack) {
    let stringifiedStack = await JsonToStringify(newStack);
    localStorage.setItem('penStack', stringifiedStack);
    window.parent.postMessage({ action: "CheckPenStack" }, "*");
    return;
};