import { SaServiceApiCall } from "../../api";
import { URLS } from "../../shared/constants";
import { dispatchType } from "../dispatchTypes";
import { AcademicYear } from "../reducers/academicYears";

export const academicYearDetails = (data: AcademicYear | {}) => ({
  type: dispatchType.academicYears.getAcademic,
  payload: data,
});

export const updateCurrentAY = () => {
  return (dispatch: any) => {
    SaServiceApiCall
      .post(URLS.ACADEMIC.LIST, { page: 1 })
      .then(async (response: any) => {
        if ((response?.status === 200 || response?.status === 201) && response.data.data?.academicData?.length > 0) {
          const currentAY = response.data.data?.academicData.filter((data: any) => data?.academicStatusId == 0)
          dispatch(academicYearDetails(currentAY?.length ? currentAY[0] : {}))
        } else {
          return {}
        }
      })
  };
}
