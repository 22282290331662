import { matchPath } from "react-router-dom";
import { getRouteForEnvironment } from "./routeConfig";
const useRoutesCheck= (isAdmin, isOrgSuperAdmin) => {
  const routes = getRouteForEnvironment(isAdmin, isOrgSuperAdmin)
  
    const canAccess = (path, jwtId) => {
      return jwtId.some((id)=>{
        const routeMatch = routes[id]?.some((paths)=>{
          const match = matchPath(path, {
              path: paths,
              exact: true,
              strict: false
            });
            return !!match
          })
          return routeMatch
        })
      }
    return [canAccess]
}
export default useRoutesCheck;