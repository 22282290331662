import { useState } from "react";

const usePdfReader = () => {
  const [rend, setRend] = useState();
  const [scaleSize, setScaleSize] = useState(1);
  const [marginSection, setMarginSection] = useState((scaleSize * 40) / 1.1);
  const [highlightColor, setHighlightColor] = useState("#f7e58a");
  const [highlightModal, setHighlightModal] = useState(false);
  const [loadedSpine, setLoadedSpine] = useState([]);
  const [highlightedItems, setHighlightedItems] = useState([]);
  const [renditionWidth, setRenditionWidth] = useState("100%");
  const [loading, setLoading] = useState(false);
  const [isPageRendering, setIsPageRendering] = useState(true);
  const [showMenu, setShowMenu] = useState(false);
  const [noteColor, setNoteColor] = useState({
    color1: "#ADA7F6",
    color2: "#9F82F0",
  });
  const [addNote, setAddNote] = useState(false);
  const [selectedStickyNoteId, setSelectedStickyNoteId] = useState();
  const [noteValue, setNoteValue] = useState("");
  const [showSlider, setShowSlider] = useState(false);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [tableOfContent, setTableOfContent] = useState([]);
  const [showSideBar, setShowSideBar] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [highlightDeleted, setHighlightDeleted] = useState(false);
  const [stickyNotes, setStickyNotes] = useState([]);
  const [coordinatesOfStickyNote, setCoordinatesOfStickyNote] = useState({});
  const [turnOnHighlight, setTurnOnHighlight] = useState(false);
  const [thumbnails, setThumbnails] = useState([]);
  const [bookmarkedPages, setBookmarkedPages] = useState([]);
  const [currentSlide, setCurrentSlide] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [epubCFI, setEpubCFI] = useState(null);
  const [epubCFIContent, setEpubCFIContent] = useState(null);
  const [currentPageArray, setCurrentPageArray] = useState([]);
  const [isDualPage, setIsDualPage] = useState(true);
  const [viewMode, setViewMode] = useState("dual");
  const [fullScreenIcon,setFullScreenIcon] = useState(false);

  const [showAudioModal, setShowAudioModal] = useState({
    show: false,
    src: null,
    analyzeData: {}
  });
  const [showImageModal, setShowImageModal] = useState({
    show: false,
    src: null,
  });
  const [showVideoModal, setShowVideoModal] = useState({
    show: false,
    src: null,
    analyzeData: {}
  });
  const [showDocModal, setShowDocModal] = useState({ show: false, src: null });
  const [showExcerciseModal, setShowExcerciseModal] = useState({
    show: false,
    type: null,
    data: [],
  });
  const [pageThumbFrom, setPageThumbFrom] = useState(null);
  const [pageThumbTo, setPageThumbTo] = useState(null);
  const [marginToKeepForLeftPage, setMarginToKeepForLeftPage] = useState(null);
  const [showGameModal, setShowGameModal] = useState({
    show: false,
    type: null,
    data: [],
  });
  const [showHtmlExerciseModal, setShowHtmlExerciseModal] = useState({
    show: false,
    type: null,
    data: [],
  });
  const [selectedElement, setSelectedElement] = useState();
  const [allStickyNotes, setAllStickyNotes] = useState([]);
  const [lastReadPages, setlastReadPages] = useState([]);
  const [totalPages,setTotalPages]=useState(0);
  const [allNotes, setAllNotes] = useState([]);
  const [allInteractivities, setAllInteractivities] = useState([]);

  const [turnOnPenTool, setTurnOnPenTool] = useState(false);
  const [pennedItems, setPennedItems] = useState([]);
  const [combinedPens, setCombinedPens] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [languagePrefered, setLanguagePrefered] = useState(localStorage.getItem("selectedLang") || "en");
  const [resources, setResources] = useState([]);
  const [showResourceBar, setShowResourceBar] = useState(false);
  const [searchStatus, setSearchStatus] = useState({ searchStatus: null });
  const [textDataToSearch, setTextDataToSearch] = useState([]);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1090);
  const [generalWidth, setGeneralWidth] = useState(0);
  const [currentPageTwo, setCurrentPageTwo] = useState();
  const [penToolBar, setPenToolBar] = useState(false);
  const [penLineHeight, setPenLineHeight] = useState(1);
  const [penColor, setPenColor] = useState("#000000");
  const [isEraserOn, setIsEraserOn] = useState(false);
  const [stageToRestore, setStageToRestore] = useState([]);
  const [clearAllStage, setClearAllStage] = useState([]);
  const [showUnlockResoucesPopUp, setshowUnlockResoucesPopUp] = useState(false);
  const [bookDetails, setBookDetails] = useState({});

  const sortByLatestFirst = (array, type) => {
    return array.sort(function (a, b) {
      const dateA = new Date(type ? a.updatedAt : a.createdAt);
      const dateB = new Date(type ? b.updatedAt : b.createdAt);
      if (dateA > dateB) {
        return -1;
      } else if (dateA < dateB) {
        return 1;
      } else {
        return 0;
      }
    });
  };


  return {
    currentPage,
    setAddNote,
    marginToKeepForLeftPage,
    setMarginToKeepForLeftPage,
    tableOfContent,
    setRenditionWidth,
    scaleSize,
    setScaleSize,
    marginSection,
    setMarginSection,
    highlightColor,
    setHighlightColor,
    setHighlightModal,
    setShowMenu,
    addNote,
    setShowSlider,
    showSlider,
    noteColor,
    turnOnHighlight,
    setTurnOnHighlight,
    showExcerciseModal,
    setShowExcerciseModal,
    showSideBar,
    setShowSideBar,
    rend,
    setRend,
    selectedElement,
    setSelectedElement,
    renditionWidth,
    setCurrentPage,
    isLoading,
    setIsLoading,
    setTableOfContent,
    setNoteColor,
    loadedSpine,
    setLoadedSpine,
    highlightModal,
    selectedStickyNoteId,
    setSelectedStickyNoteId,
    noteValue,
    setNoteValue,
    firstPage,
    setFirstPage,
    lastPage,
    setLastPage,
    highlightDeleted,
    setHighlightDeleted,
    stickyNotes,
    setStickyNotes,
    coordinatesOfStickyNote,
    setCoordinatesOfStickyNote,
    thumbnails,
    setThumbnails,
    bookmarkedPages,
    setBookmarkedPages,
    currentSlide,
    setCurrentSlide,
    showAudioModal,
    setShowAudioModal,
    showGameModal,
    setShowGameModal,
    showHtmlExerciseModal,
    setShowHtmlExerciseModal,
    marginToKeepForLeftPage,
    setMarginToKeepForLeftPage,
    pageThumbTo,
    setPageThumbTo,
    pageThumbFrom,
    setPageThumbFrom,
    showDocModal,
    setShowDocModal,
    showMenu,
    showVideoModal,
    setShowVideoModal,
    showImageModal,
    setShowImageModal,
    loading,
    setLoading,
    isPageRendering,
    setIsPageRendering,
    highlightedItems,
    setHighlightedItems,
    epubCFI,
    setEpubCFI,
    epubCFIContent,
    setEpubCFIContent,
    allStickyNotes,
    setAllStickyNotes,
    lastReadPages,
    setlastReadPages,
    allNotes,
    setAllNotes,
    allInteractivities,
    setAllInteractivities,
    turnOnPenTool,
    setTurnOnPenTool,
    pennedItems,
    setPennedItems,
    combinedPens,
    setCombinedPens,
    languagePrefered,
    setLanguagePrefered,
    searchValue,
    setSearchValue,
    showSearch,
    setShowSearch,
    searchResult,
    setSearchResult,
    isSearching,
    setIsSearching,
    resources,
    setResources,
    showResourceBar,
    setShowResourceBar,
    searchStatus,
    setSearchStatus,
    textDataToSearch,
    setTextDataToSearch,
    sortByLatestFirst,
    isMobileView,
    generalWidth,
    setGeneralWidth,
    currentPageArray,
    setCurrentPageArray,
    currentPageTwo,
    setCurrentPageTwo,
    isDualPage,
    setIsDualPage,
    viewMode,
    setViewMode,
    penToolBar,
    setPenToolBar,
    penLineHeight,
    setPenLineHeight,
    penColor,
    setPenColor,
    isEraserOn,
    setIsEraserOn,
    stageToRestore,
    setStageToRestore,
    clearAllStage,
    setClearAllStage,
    showUnlockResoucesPopUp,
    setshowUnlockResoucesPopUp,
    bookDetails,
    setBookDetails,
    setTotalPages,
    totalPages,
    setFullScreenIcon,
    fullScreenIcon
  };
};

export default usePdfReader;
