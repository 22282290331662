import { useState } from 'react'

const useBookState = () => {
    const [bookDetails, setBookDetails] = useState({
        bookId: null,
        bookVersionId: null,
        bookUrl: null,
        bookTOC: [],
        bookThumbnails: [],
        bookThumbFrom: null,
        bookThumbTo: null,
        bookTableOfContents: []
    });
    return {
        bookDetails,
        setBookDetails
    }
}

export default useBookState;