import { useEffect, useRef } from "react";
import { useAppState } from "../Context";
import { API } from "../utils/API";
import { sortHighlightedItemsPageNumberWise } from "../Services/fetchHighlights";
import { sortPenItemsPageNumberWise } from "../Services/fetchPens";
import { Analyze } from "./Analyze";
import { AddNewDataToStack } from "./CanvasStack";

export function useOnDraw(onDraw, isMobileView, currentPage) {
    let lastHighlight = [];
    const { highlightColor, turnOnHighlight, setTurnOnHighlight, highlightedItems, setHighlightedItems, turnOnPenTool, setTurnOnPenTool, pennedItems, setPennedItems, sortByLatestFirst, generalWidth, penLineHeight, setPenLineHeight, penColor, setPenColor, isEraserOn, setIsEraserOn, combinedPens, setCombinedPens, clearAllStage, setClearAllStage } = useAppState("reader");
    const { bookDetails, setBookDetails } = useAppState("bookDetails");


    const canvasRef = useRef(null);
    const prevPointRef = useRef(null);
    const isDrawingRef = useRef(false);

    const mouseMoveListenerRef = useRef(null);
    const touchMoveListenerRef = useRef(null);
    const mouseUpListenerRef = useRef(null);
    const touchUpListenerRef = useRef(null);

    const highlightColors = [
        { color1: "#74EE15", color2: 'rgba(116, 238, 21, 0.5)' },
        { color1: "#FFE700", color2: 'rgba(255, 231, 0, 0.5)' },
        { color1: "#CC00FF", color2: 'rgba(204, 0, 255, 0.5)' },
        { color1: "#00FFFF", color2: 'rgba(0, 255, 255, 0.5)' },
        { color1: "#CF8B2D", color2: 'rgba(207, 139, 45, 0.5)' },
    ];

    const penColors = [
        { color1: "#74EE15", color2: 'rgba(116, 238, 21)' },
        { color1: "#FFE700", color2: 'rgba(255, 231, 0)' },
        { color1: "#CC00FF", color2: 'rgba(204, 0, 255)' },
        { color1: "#00FFFF", color2: 'rgba(0, 255, 255)' },
        { color1: "#CF8B2D", color2: 'rgba(207, 139, 45)' },
        { color1: "#000000", color2: 'rgba(0, 0, 0)' },
    ];

    function uniqueByKey(arr, key) {
        const seen = new Set();
        return arr.filter((item) => {
            const itemKey = item[key];
            if (!seen.has(itemKey)) {
                seen.add(itemKey);
                return true;
            }
            return false;
        });
    }

    const coordinatesForServer = (x, y) => {
        let widthRatio = (window.innerWidth - 20) / 621;
        let heightRatio = ((window.innerWidth - 20) * 788 / generalWidth) / 788;
        if (isMobileView) {
            return {
                x: x / widthRatio,
                y: y / heightRatio
            }
        } else {
            return {
                x: x,
                y: y
            }
        }
    };

    async function modifyCoordinatesForServer(mobileHighlights) {
        let highlightsForServer = [];
        let filteringHighlights = await mobileHighlights.filter(async (eachPoints) => {
            let getCords = await coordinatesForServer(eachPoints.x, eachPoints.y);
            highlightsForServer.push({
                x: getCords.x,
                y: getCords.y
            })
        })
        return highlightsForServer;
    };

    useEffect(() => {
        let sCTX = canvasRef.current.getContext('2d');
        if (isEraserOn) {
            sCTX.globalCompositeOperation = "destination-out";
        } else {
            sCTX.globalCompositeOperation = "source-over";
        }
        function initMouseMoveListener() {
            const mouseMoveListener = (e) => {
                if (isDrawingRef.current) {
                    const point = computePointInCanvas(e.clientX, e.clientY);
                    const ctx = canvasRef.current.getContext('2d');
                    if (onDraw) onDraw(ctx, point, prevPointRef.current);
                    prevPointRef.current = point;
                    lastHighlight.push(point);
                }
            };
            mouseMoveListenerRef.current = mouseMoveListener;
            window.addEventListener('mousemove', mouseMoveListener);
        }

        function initTouchMoveListener() {
            const touchMoveListener = (e) => {
                if (isDrawingRef.current) {
                    const point = computePointInCanvas(e.changedTouches[0].clientX, e.changedTouches[0].clientY);
                    const ctx = canvasRef.current.getContext('2d');
                    if (onDraw) onDraw(ctx, point, prevPointRef.current);
                    prevPointRef.current = point;
                    lastHighlight.push(point);
                }
            };
            touchMoveListenerRef.current = touchMoveListener;
            window.addEventListener('touchmove', touchMoveListener);
        }

        function initMouseUpListener() {
            const listener = () => {
                isDrawingRef.current = false;
                prevPointRef.current = null;
                if (lastHighlight.length > 0) {
                    if (turnOnHighlight) {
                        saveHighlight();
                        setTurnOnHighlight(false);
                        setTurnOnPenTool(false);
                    }
                    if (turnOnPenTool) {
                        savePen();
                        setTurnOnHighlight(false);
                        // setTurnOnPenTool(false);
                    }
                    else {
                        lastHighlight = [];
                        setTurnOnHighlight(false);
                        setTurnOnPenTool(false);
                    }
                }
            }
            mouseUpListenerRef.current = listener;
            window.addEventListener('mouseup', listener);
        }

        function initTouchUpListener() {
            const touchUpListener = async () => {
                isDrawingRef.current = false;
                prevPointRef.current = null;
                if (lastHighlight.length > 0) {
                    if (turnOnHighlight) {
                        if (isMobileView) {
                            let getHighlightsToSave = await modifyCoordinatesForServer(lastHighlight);
                            saveHighlight(getHighlightsToSave);
                            setTurnOnHighlight(false);
                            setTurnOnPenTool(false);
                        }
                    }
                    if (turnOnPenTool) {
                        let getPensToSave = await modifyCoordinatesForServer(lastHighlight);
                        savePen(getPensToSave);
                        setTurnOnHighlight(false);
                        // setTurnOnPenTool(false);
                    }
                    else {
                        lastHighlight = [];
                        setTurnOnHighlight(false);
                        setTurnOnPenTool(false);

                    }
                }
            }
            touchUpListenerRef.current = touchUpListener;
            window.addEventListener('touchend', touchUpListener);
        }

        function computePointInCanvas(clientX, clientY) {
            if (canvasRef.current) {
                const boundingRect = canvasRef.current.getBoundingClientRect();
                return {
                    x: clientX - boundingRect.left,
                    y: clientY - boundingRect.top
                }
            } else {
                return null;
            }
        }

        function removeListeners() {
            if (mouseMoveListenerRef.current) {
                window.removeEventListener("mousemove", mouseMoveListenerRef.current);
            }
            if (touchMoveListenerRef.current) {
                window.removeEventListener("touchmove", touchMoveListenerRef.current);
            }
            if (mouseUpListenerRef.current) {
                window.removeEventListener("mouseup", mouseUpListenerRef.current);
            }
            if (touchUpListenerRef.current) {
                window.removeEventListener("touchend", touchUpListenerRef.current);
            }
        }

        initMouseMoveListener();
        initMouseUpListener();

        if (isMobileView) {
            initTouchMoveListener();
            initTouchUpListener();
        }
        return () => {
            removeListeners();
        }
    }, [onDraw]);

    async function saveHighlight(getHighlightsToSave) {
        const response = await API("post", `/api-books/books/${bookDetails.bookId}/highlights`, {
            type: "pdf",
            points: getHighlightsToSave ? getHighlightsToSave : lastHighlight,
            color: highlightColor.toString(),
            pageNumber: currentPage,
        })
        if (!response.message) {
            //TODO: Handle Response
            // setHighlightedItems((prev) => [...prev, response]);
            updateHighlightedItems(response);

        }
        lastHighlight = [];
        Analyze(bookDetails.bookId, "tul", "hlt", null, null, currentPage);
    }

    async function checkIsPennedItemsHaveCurrentPagePens() {
        let penItems = pennedItems;
        let newPenItems = penItems.filter((eachPenItem) => {
            return eachPenItem.page === currentPage;
        });
        if (newPenItems.length || clearAllStage.length) {
            return true;
        } else {
            return false;
        }
    }

    async function savePen(getPensToSave) {
        if (isEraserOn) {
            let newEraser = {
                type: "eraser",
                points: getPensToSave ? getPensToSave : lastHighlight,
                page: currentPage,
                thickness: penLineHeight,
                isOldPenExist: await checkIsPennedItemsHaveCurrentPagePens()
            }
            let isAdded = AddNewDataToStack(newEraser);
            // setClearAllStage((prev) => [...prev, []])
            // const response = await API("post", `/api-library/books/${bookDetails.bookId}/drawings/apply-eraser`, {
            //     page: currentPage,
            //     points: getPensToSave ? getPensToSave : lastHighlight,
            // })
            // if (!response.message) {
            //     updatePenAfterErase(response);
            //     setIsEraserOn(false);
            //     setPenLineHeight(1);
            // }
        } else {
            let newPen = {
                type: "pen",
                points: getPensToSave ? getPensToSave : lastHighlight,
                color: `${penColors.findIndex(x => x.color1 === penColor) + 1}`,
                page: currentPage,
                thickness: penLineHeight,
                isOldPenExist: await checkIsPennedItemsHaveCurrentPagePens()
            };
            let isAdded = AddNewDataToStack(newPen);
            // setClearAllStage((prev) => [...prev, []])
            // const response = await API("post", `/api-library/books/${bookDetails.bookId}/drawings`, {
            //     type: "pen",
            //     points: getPensToSave ? getPensToSave : lastHighlight,
            //     color: `${penColors.findIndex(x => x.color1 === penColor) + 1}`,
            //     page: currentPage,
            //     thickness: penLineHeight
            // })
            // if (!response.message) {
            //     //TODO: Handle Response
            //     updatePennedItems(response);

            // }
        }
        lastHighlight = [];
        Analyze(bookDetails.bookId, "tul", "pen", null, null, currentPage);
    }

    async function updateHighlightedItems(response) {
        let previousHighlight = await highlightedItems;
        previousHighlight.push(response);
        let sortedHighlights = await sortHighlightedItemsPageNumberWise(previousHighlight);
        let timeWiseSorted = await sortByLatestFirst(sortedHighlights);
        setHighlightedItems(timeWiseSorted);
    }

    async function updatePenAfterErase(response) {
        let previousPens = await pennedItems;
        let removedCurrentPagePen = await previousPens.filter((eachPen) => eachPen.page !== currentPage);
        let combinedPens = removedCurrentPagePen.concat(response);
        let sortedPens = await sortPenItemsPageNumberWise(combinedPens);
        let timeWiseSorted = await sortByLatestFirst(sortedPens);

        let combinePens = await uniqueByKey(timeWiseSorted, "page");
        let timeWiseSortedCombinedPens = await sortByLatestFirst(combinePens);
        setCombinedPens(timeWiseSortedCombinedPens);

        setPennedItems(timeWiseSorted);
    }

    async function updatePennedItems(response) {
        let previousPens = await pennedItems;
        previousPens.push(response);
        let sortedPens = await sortPenItemsPageNumberWise(previousPens);
        let timeWiseSorted = await sortByLatestFirst(sortedPens);

        let combinePens = await uniqueByKey(timeWiseSorted, "page");
        let timeWiseSortedCombinedPens = await sortByLatestFirst(combinePens);
        setCombinedPens(timeWiseSortedCombinedPens);

        setPennedItems(timeWiseSorted);
    }

    function setCanvasRef(ref) {
        canvasRef.current = ref;
    }

    function onMouseDown() {
        if (turnOnHighlight || turnOnPenTool) {
            isDrawingRef.current = true;
            lastHighlight = [];
        }
    }

    return {
        penColors, highlightColors, setCanvasRef, onMouseDown
    }
};