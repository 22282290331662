import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import "tw-elements";

import AddStickyNote from "./AddStickyNote";
import Highlight from "./Highlight";
import NavigationOnOff from "./NavigationOnOff";
import ShowHighlightModal from "./ShowHighlightModal";
import VerticalDivider from "./VerticalDivider";
import Fullscreen from "./Fullscreen";
import ToogleSinglePage from "./ToggleSinglePage";
import Comment from "./Comment";

import { useAppState } from "../Context";

import { API } from "../utils/API";
import { saveReadProgress } from "../Services/saveReadProgress";
import PenTool from "./PenTool";
import { transcriptions } from "../i18n/languages";
import Search from "./Search";
import ZoomComponent from "./ZoomComponent";
import { saveCanvas } from "../Helpers/SaveCanvas";
import { sortPenItemsPageNumberWise } from "../Services/fetchPens";
import * as pdfJS from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry"; //pdfjs-dist/build/pdf.worker.entry
import printJS from 'print-js'
import Logo from "../Assets/logo.png"
import Loader from "../../../../src/common/Loader";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Print from "../../../../src/assets/img/printIcon.svg"
import pdfZoomIn from "../Assets/pdfZoomIn.svg";
import pdfZoomout from "../Assets/pdfZoomOut.svg";
import pdfZoomInDisabled from '../Assets/selectedZoomIn.svg';
import pdfZoomOutDisabled from '../Assets/selectedZoomOut.svg';
import pdfReset from "../Assets/pdfReset.svg";
import pdfRightIcon from "../Assets/pdfForward.svg";
import printIcon from "../Assets/pdfPrint.svg";
import { Alert, Snackbar } from "@mui/material";
// const Alert = React.forwardRef<HTMLDivElement | AlertProps>(function Alert(
//   props,
//   ref
// ) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });
const Header = ({
  // isAdmin,
  // bookId,
  // rendition,
  showTableOfContent,
  // changingAddNote,
  windowWidth,
  // bookMarkedPages,
  // windowHeight,
  currentPage,
  // isBookPortrait,
  // isPromotional,
  // bookName,
  // bookInfo,
  controlsConfig,
  // setCombinedPens,
  // pennedItems,
  // setPennedItems,
  bookURL
}) => {
  // console.log('controlsConfig: ', controlsConfig);
  // const { dualPage=true,fullScreen=true,zoomInOut=true,print=true,jumpToPage=true } = controlsConfig;

  const perPageFunction = () => {
    if (windowWidth <= 850 && windowWidth > 500) {
      return 4;
    } else {
      if (windowWidth <= 500) {
        return 3;
      } else {
        if (windowWidth > 850 && windowWidth < 1000) {
          return 6;
        } else {
          return 12;
        }
      }
    }
  };
  const BaseURL = process.env.reader_pdf_url;
  const languagePrefered = localStorage.getItem("selectedLang") || "en";
  let slidesPerPage = perPageFunction();
  const [currentRangeValue, setCurrentRangeValue] = useState(slidesPerPage);
  const [commentHidden, setCommentHidden] = useState(false);
  const [leftPageNum, setLeftPageNum] = useState(currentPage);
  const [rightPageNum, setRightPageNum] = useState();
  const [snackBarToggle, setSnackBarToggle] = useState({ isOpen: false, text: "" });
  const [openModal, setOpenModal] = useState(false);
  // let finalBookName = localStorage.getItem("bookName") || bookName;
  const [pageLoad, setPageLoad] = useState(false);
  var thePdf = null;
  var scale = 1;
  const {
    currentPages,
    // tableOfContent,
    addNote,
    setRenditionWidth,
    scaleSize,
    setScaleSize,
    setHighlightModal,
    setShowSlider,
    showSlider,
    noteColor,
    showExcerciseModal,
    setCurrentPage,
    setShowExcerciseModal,
    showSearch,
    setShowSearch,
    resources,
    setResources,
    showResourceBar,
    setShowResourceBar,
    setShowSideBar,
    textDataToSearch,
    isMobileView,
    setPenColor,
    setIsEraserOn,
    setTurnOnPenTool,
    setPenToolBar,
    isDualPage,
    totalPages,
    setFullScreenIcon,
    fullScreenIcon
  } = useAppState("reader");

  // const saveLastRodePage = async () => {
  //   if (!isAdmin) {
  //     let lastRodePage = localStorage.getItem("lastRodePage");
  //     let integerArray = await lastRodePage.split(",").map(item => parseInt(item));
  //     if (lastRodePage && integerArray !== null) {
  //       let response = await saveReadProgress(bookId, integerArray);
  //       if (response) {
  //         window.location.replace("/private/library");
  //       }
  //     } else {
  //       window.location.replace("/private/library");
  //     }
  //   }
  // };
  function handleScale(actionType) {
    let parentarea = document.getElementById("area");
    // let padVal = arr.get(scaleSize.toString());
    // let decPadVal = decrementArr.get(scaleSize.toString());
    const parentDiv = document.getElementById("pdfViewerParent");

    if (actionType == "increment") {

      const scale = scaleSize + 0.25;
      setScaleSize(scale);
      if (scale > 1) {
        // console.log('parentarea.offsetWidth: ', parentarea.offsetWidth);
        // parentarea.style.width = `${parentarea.offsetWidth * (scale)}px`;
        // console.log('scale: ', scale);
        // const offsetX = (parentarea.offsetWidth - parentDiv.clientWidth) / 2;
        // console.log('parentDiv.clientWidth: ', parentDiv.clientWidth);
        // console.log('parentarea.offsetWidth: ', parentarea.offsetWidth);
        // console.log('offsetX: ', offsetX);
        // parentDiv.scrollLeft = offsetX;
      }
    } else {
      const scale = scaleSize - 0.25;
      setScaleSize(scale);
      if (scale > 1) {
        parentarea.style.width = `${parentarea.offsetWidth * (scale)}px`;
        const offsetX = (parentarea.offsetWidth - parentDiv.clientWidth) / 2;
        parentDiv.scrollLeft = offsetX;
      }
    }
  }

  function restorePadding() {
    let parentarea = document.getElementById("area");
    parentarea.style.padding = "0px 0px";
  }

  const showOrHideComments = (e, doc) => {
    let getAllComments = doc.querySelectorAll(".comment");
    getAllComments.forEach((eachComment) => {
      if (e.target.checked) {
        eachComment.style.display = "none";
        localStorage.setItem("commentHidden", true);
        setCommentHidden(true);
      } else {
        eachComment.style.display = "block";
        localStorage.setItem("commentHidden", false);
        setCommentHidden(false);
      }
    });
  };

  const showComments = (e) => {
    var gettingIframes = document.querySelectorAll("iframe");
    if (gettingIframes.length > 1) {
      var doc0 = gettingIframes[0].contentWindow.document;
      var doc1 = gettingIframes[1].contentWindow.document;
      showOrHideComments(e, doc0);
      showOrHideComments(e, doc1);
    } else {
      var doc = gettingIframes[0].contentWindow.document;
      showOrHideComments(e, doc);
    }
  };

  useEffect(() => {
    setCommentHidden(false);
    localStorage.setItem("commentHidden", false);
  }, []);


  useEffect(() => {
    setLeftPageNum(currentPage)
  }, [currentPage])

  // let totalContentPages = (bookInfo.pageThumbTo - bookInfo.pageThumbFrom) + 1;
  const blockInvalidChar = e => {
    const regex = /^[0-9]*$/;
    if (e.keyCode == 8 || e.key === "Enter") {

    } else if (!regex.test(e.key)) {
      e.preventDefault();
    }

  }

  const handleLeftPageNum = (e) => {
    let newValue = e.target.value;
    if (newValue.length <= 5) {
      setLeftPageNum(newValue);
      if (parseInt(newValue) <= totalPages) {
        setLeftPageNum(parseInt(newValue));
      }
    }
  };
  const handleEnter = (e, whichPage) => {
    if (e.key === "Enter") {

      if (leftPageNum > totalPages || leftPageNum <= 0 || leftPageNum === "") {
        setSnackBarToggle({ isOpen: true, text: "Page Not Found" });
        return;
      }
      if (whichPage === 0) {
        setCurrentPage(isDualPage && leftPageNum === totalPages ? leftPageNum - 1 : leftPageNum);
        // saveHistory(pageToRedirect - 1);
        // setCurrentRangeValue(pageToRedirect - 1);
        // setRightPageNum(leftPageNum + 1);
      } else {
        // let pageToRedirect = rightPageNum ;
        // setCurrentPage(pageToRedirect - 2);
        // saveHistory(pageToRedirect - 2);
        // setLeftPageNum(rightPageNum - 1);
        // setCurrentRangeValue(pageToRedirect - 2);
      }
      // checkDisability();
    }
  };

  const printPDF = () => {
    setPageLoad(true);
    setOpenModal(true);
    var URL = bookURL;
    pdfJS.getDocument(URL).promise.then(function (pdf) {

      thePdf = pdf;
      var viewer = document.getElementById('pdf-viewer');
      for (var page = 1; page <= pdf.numPages; page++) {
        var canvas = document.createElement("canvas");
        canvas.className = 'pdf-page-canvas';
        canvas.style.width = "100%"
        canvas.style.height = "100%"

        viewer.appendChild(canvas);
        renderPage(page, canvas);
      }
      setTimeout(() => {
        printJS({ printable: 'pdf-viewer', type: 'html' }) // print function
        setOpenModal(false);
        setPageLoad(false);
      }, 3000)
    });
  }

  function renderPage(pageNumber, canvas) {
    thePdf.getPage(pageNumber).then(function (page) {
      var viewport = page.getViewport({ scale: scale });
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      if (controlsConfig.isWaterMark && controlsConfig.contentLocation) {
        var ctx = canvas.getContext("2d");

        var background = new Image();
        background.src = Logo;
        background.onload = function () {
          ctx.drawImage(background, 80, 110, 500, 500);
        }
      }
      page.render({ canvasContext: canvas.getContext('2d'), viewport: viewport });
    });

  } const prevPage = () => {
    if (currentPage > 1) {
      if (isDualPage) {
        if (currentPage - 2 === 0) {
          setCurrentPage(1);

        } else {
          setCurrentPage(currentPage - 2);
          localStorage.setItem("pageIndex", currentPage - 2)
        }
      } else {
        setCurrentPage(currentPage - 1);
        localStorage.setItem("pageIndex", currentPage - 1)
      }
    }
  }
  const nextPage = () => {
    if (totalPages > 0 && currentPage < totalPages) {
      if (isDualPage) {
        if ((currentPage + 1) !== totalPages) {
          setCurrentPage(currentPage + 2);
          localStorage.setItem("pageIndex", currentPage + 2)
        }
      } else {
        setCurrentPage(currentPage + 1);
        localStorage.setItem("pageIndex", currentPage + 1)
      }
    }
  }
  const handleClose = () => {
    setSnackBarToggle({ isOpen: false, text: "" });
  }
  return (<>
    <Loader val={pageLoad} />
    <Snackbar
      open={snackBarToggle.isOpen}
      autoHideDuration={3000}
      onClose={handleClose}
      data-testid="snackBar"
      className="snackbar_css"
    >
      <Alert
        onClose={handleClose}
        severity="error"
        sx={{ width: "100%" }}
      >
        {snackBarToggle.text}
      </Alert>
    </Snackbar>
    {
      !isMobileView &&
      <nav id="navBar" >
        <div className="navBar-container">
          {/* {!fullScreenIcon ||
            (!isAdmin && (
              <button
                onClick={(e) => saveLastRodePage()}
                className="mr-8 cursor-pointer"
              >
                <img
                  src={`${BaseURL}/public/images/leftArrow.png`}
                  className="sm:block h-8 w-34 pt-2"
                />
              </button>
            ))} */}
          {/* <div className="flex">
            {isAdmin && <div className="3xl:w-56 xl:w-40 xl:block hidden"></div>}
            {!isAdmin && (
              <button
                onClick={(e) => saveLastRodePage()}
                className="text-xl font-bold text-blue-600"
              >
                <img
                  src={`${BaseURL}/public/images/mainLogoLight.svg`}
                  className="sm:block h-14 w-34"
                />
                <img
                  src={`${BaseURL}/public/images/singleLogo.svg`}
                  className="h-10 w-10 pt-2 sm:hidden"
                />
              </button>
            )}
          </div> */}
          {/* <div
            data-tip={finalBookName}
            className="m-auto pt-1 hidden md:block 4xl:ml-[calc(35vw_-_55px)] 3xl:ml-[calc(33vw_-_55px)] xl:ml-[calc(29vw_-_10px)] ml-[calc(35vw_-_10px)]"
          >
            <a className="text-xl font-bold text-sky-600 cursor-pointer">
              {finalBookName?.length < 25
                ? finalBookName
                : finalBookName?.slice(0, 25) + "..." ||
                "Name Of Book"}
            </a>
          </div> */}
          <div className="control-group">
            {/* <div className="shrink-0">
              {!isAdmin && !isPromotional && (
                <Search showSearch={showSearch} setShowSearch={setShowSearch} bookInfo={bookInfo} textDataToSearchLength={textDataToSearch.length} />
              )}
            </div>
            <div>
              {!isAdmin && !isPromotional && (
                <Highlight
                  bookId={bookId}
                  rendition={rendition}
                  windowWidth={windowWidth}
                  windowHeight={windowHeight}
                  changingAddNote={changingAddNote}
                />
              )}
            </div>
            <div className="shrink-0">
              {!isAdmin && !isPromotional && (
                <PenTool
                  bookId={bookId}
                  rendition={rendition}
                  windowWidth={windowWidth}
                  windowHeight={windowHeight}
                  changingAddNote={changingAddNote}
                />
              )}
            </div>
            <div>
              {!isAdmin && !isPromotional && (
                <AddStickyNote
                  changingAddNote={changingAddNote}
                  isAdmin={isAdmin}
                  bookId={bookId}
                  isBookPortrait={isBookPortrait}
                  isMobileView={isMobileView}
                />
              )}
            </div>
            <div>
              {!isAdmin && !isPromotional && (
                <ShowHighlightModal setHighlightModal={setHighlightModal} />
              )}
            </div>
            {isAdmin && (
            <div class="flex justify-center my-3.5">
              <div>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    onChange={(e) => showComments(e)}
                    checked={commentHidden}
                  />
                  <label
                    class="form-check-label inline-block text-gray-800 cursor-pointer"
                    for="flexSwitchCheckDefault"
                    onClick={(e) => showComments(e)}
                  >{`${commentHidden ? "Show Comments" : "Hide Comments"
                    }`}</label>
                </div>
              </div>
            </div>
          )}
          {isAdmin && (
            <div className="shrink-0">
              <Comment
                currentPage={currentPage}
                showExcerciseModal={showExcerciseModal}
                setShowExcerciseModal={setShowExcerciseModal}
              />
            </div>
          )} */}
            {/* <div className="shrink-0">
              <NavigationOnOff
                // bookId={bookId}
                setShowSlider={setShowSlider}
                showSlider={showSlider}
              />
            </div> */}
            <div className="group">
              <img src={scaleSize <= 1 ? pdfZoomInDisabled : pdfZoomIn}  data-tip={`${transcriptions.header.Tooltip.zoomOut[languagePrefered]}`} id="responsiveImage" alt="pdfZoomIn" height={30} width={30} onClick={(e) => { if (scaleSize > 1) { handleScale("decrement"); } }} />
              {/* </button> */}
              <img src={scaleSize >= 3 ? pdfZoomOutDisabled : pdfZoomout} data-tip={`${transcriptions.header.Tooltip.zoomIn[languagePrefered]}`} id="responsiveImage" alt="pdfZoomout" height={30} width={30} onClick={(e) => {
                if (scaleSize < 3) {
                  handleScale("increment");
                }
              }} />
              {/* <span className="pdf-mobile-footer_reset"> */}
              <div className="reset-btn" onClick={(e) => {
                setScaleSize(1);
                restorePadding();
              }}>
                <img src={pdfReset} id="responsiveImage" alt="pdfReset" height={20} width={20} />
                Reset
              </div>
            </div>
            <div className="group" >
              <button
                data-tip={`${transcriptions.reader.Tooltip.prev[languagePrefered]}`}
                id="prev"
                className="pdf-footer_back-btn"
                disabled={currentPage === 1}
                onClick={prevPage}

              >
                <img src={pdfRightIcon} height={25} width={25} style={{ opacity: currentPage === 1 ? 0.7 : 1 }} />
              </button>
              {controlsConfig?.jumpToPage && totalPages ? <div className="jump-page-control">
                {/* <button className={`${historyDisable && "grayscale"} mx-2`} onMouseOver={(e) => checkDisability(e)} data-tip="Previous History" onClick={(e) => handleHistory(e, "prev", currentPage)} disabled={historyDisable}><img className={`${isMobileView ? "w-[25px]" : "sm:h-[50px] sm:w-auto w-[120px]"}`} src={`${process.env.reader_pdf_url}/public/images/HistoryIcon.svg`} /></button> */}
                <div className="jump-control-container">
                  {currentPage && <input className="jump-input" onKeyDown={blockInvalidChar} type="number" maxLength={5} value={leftPageNum} onChange={(e) => handleLeftPageNum(e)} onKeyPress={(e) => handleEnter(e, 0)} />}
                  {/* {currentPage && isDualPage && <span className="px-1">-</span>}
                  {currentPage && isDualPage && <input className="px-2 fbinput border-b sm:w-[60px] focus:outline-none focus:border-primaryColor" type="number" onKeyDown={blockInvalidChar} maxLength={5} value={rightPageNum} onChange={(e) => handleRightPageNum(e)} onKeyPress={(e) => handleEnter(e, 1)} />} */}
                  <span className="flex"> / {totalPages}</span>
                </div>
                {/* <button className={`${isNextHistoryBtnDisabled && "grayscale"} mx-2 scale-x-[-1]`} onMouseOver={(e) => checkDisability(e)} data-tip="Next History" onClick={(e) => handleHistory(e, "next", currentPage)} disabled={isNextHistoryBtnDisabled}><img className={`${isMobileView ? "w-[25px]" : "sm:h-[50px] sm:w-auto w-[120px]"}`} src={`${process.env.reader_pdf_url}/public/images/HistoryIcon.svg`} /></button> */}
              </div> : null}
              <button
                data-tip={`${transcriptions.reader.Tooltip.next[languagePrefered]}`}
                id="next"
                //className="next-btn"
                disabled={totalPages === (isDualPage ? currentPage - 1 : currentPage)}
                onClick={nextPage}
              >
                <img src={pdfRightIcon} height={25} width={25} style={{ opacity: (isDualPage ? totalPages - 1 : totalPages) === currentPage ? 0.7 : 1 }} />
              </button>
            </div>
            <div className="group">
              {!fullScreenIcon && controlsConfig?.isPrintable && <div><img src={printIcon} onClick={() => printPDF()} style={{ width: "30px", height: "30px", cursor: "pointer" }} /> </div>}
              {totalPages > 1 && controlsConfig?.dualPage ? <> <div className="group-divider"></div>
                <ToogleSinglePage /> </> : null}
              <div className="group-divider"></div>
              {controlsConfig?.fullScreen ? <div style={{ flexShrink: 0, marginLeft: "10px" }}>
                <Fullscreen
                  fullScreenIcon={fullScreenIcon}
                  setFullScreenIcon={setFullScreenIcon}
                />
              </div>
                : null}
            </div>

            {/* <button onClick={() => printPDF()}>Print</button> */}

            {/* {controlsConfig?.zoomInOut ? <div>
              <ZoomComponent
                setScaleSize={setScaleSize}
                scaleSize={scaleSize}
              />
            </div> : null} */}
            {/* <div>
                <Zoom setScaleSize={setScaleSize} scaleSize={scaleSize} />
              </div> */}
            {/* <div>
            <VerticalDivider />
          </div> */}

            {!isMobileView && <ReactTooltip />}

            {/* {tableOfContent.length !== 0 && (
              <div className="mt-2.5 sm:block">
                {!isMobileView && <ReactTooltip place="bottom" />}
                <button
                  data-tip={`${transcriptions.header.Tooltip.toc[languagePrefered]}`}
                  className="mt-"
                  onClick={(e) => showTableOfContent()}
                >
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 24 24"
                    fill="#00AAD3"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM13 18H11V16H13V18ZM13 14H11V8H13V14ZM12 5C11.45 5 11 4.55 11 4C11 3.45 11.45 3 12 3C12.55 3 13 3.45 13 4C13 4.55 12.55 5 12 5Z"
                      fill="#00AAD3"
                    />
                  </svg>
                </button>
              </div>
            )} */}
            {/* <div className="mt-2.5 sm:block shrink-0">
              {!isMobileView && <ReactTooltip place="bottom" />}
              <button data-tip="Resources" className="mt-1" onClick={(e) => {
                setShowSideBar(false);
                setShowResourceBar(!showResourceBar);
              }}>
                <img src={`${process.env.reader_pdf_url}/public/images/resourceBarIcon.svg`} />
              </button>
            </div> */}
          </div>
        </div>
      </nav>
    }

    {openModal && <div id='pageContainer'>
      <div style={{ visibility: "hidden", width: "1100px", height: "1150px", overflowY: "auto", marginLeft: "75px", position: "relative" }} id='pdf-viewer'>
      </div>
    </div>
    }

    {/* {
      isMobileView &&
      <nav id="navBarMobile" className="mobileNav bg-white relative shadow-[0px_1px_17px_1px_rgba(188,188,188,1)] z-[999]">
        <button onClick={(e) => saveLastRodePage()} className='cursor-pointer'>
          <img src={`${BaseURL}/public/images/leftArrow.png`} className="h-8 w-34" />
        </button>
        <button onClick={(e) => saveLastRodePage()} className="text-xl font-bold text-blue-600" >
          <img src={`${BaseURL}/public/images/singleLogo.svg`} className="h-10" />
        </button>
        <div data-tip={finalBookName}>
          <a className="text-sm font-bold text-sky-600 cursor-pointer">
            {finalBookName.length < 25 ? finalBookName : finalBookName.slice(0, 25) + "..." || "Name Of Book"}
          </a>
        </div>
      </nav>
    }
    {
      isMobileView &&
      <div id="navBarMobileSideBar" className="mobileSideBar">
        <div className="shrink-0">
          {!isAdmin && !isPromotional && (
            <Search showSearch={showSearch} setShowSearch={setShowSearch} bookInfo={bookInfo} textDataToSearchLength={textDataToSearch.length} />
          )}
        </div>
        <div className="shrink-0">
          {!isAdmin && !isPromotional && (
            <Highlight
              bookId={bookId}
              rendition={rendition}
              windowWidth={windowWidth}
              windowHeight={windowHeight}
              changingAddNote={changingAddNote}
            />
          )}
        </div>
        <div className="shrink-0">
          {!isAdmin && !isPromotional && (
            <PenTool
              bookId={bookId}
              rendition={rendition}
              windowWidth={windowWidth}
              windowHeight={windowHeight}
              changingAddNote={changingAddNote}
            />
          )}
        </div>
        <div className="shrink-0">
          {!isAdmin && !isPromotional && (
            <AddStickyNote
              changingAddNote={changingAddNote}
              isAdmin={isAdmin}
              bookId={bookId}
              isBookPortrait={isBookPortrait}
              isMobileView={isMobileView}
            />
          )}
        </div>
        <div className="shrink-0">
          {!isAdmin && !isPromotional && (
            <ShowHighlightModal setHighlightModal={setHighlightModal} />
          )}
        </div>
        <div className="shrink-0">
          <NavigationOnOff
            bookId={bookId}
            setShowSlider={setShowSlider}
            showSlider={showSlider}
          />
        </div>
        <div className="shrink-0">
          <Fullscreen
            bookId={bookId}
            fullScreenIcon={fullScreenIcon}
            setFullScreenIcon={setFullScreenIcon}
          />
        </div>
        {tableOfContent.length !== 0 && (
          <div className="mt-2.5 sm:block shrink-0">
            {!isMobileView && <ReactTooltip place="bottom" />}
            <button
              data-tip={`${transcriptions.header.Tooltip.toc[languagePrefered]}`}
              className="mt-"
              onClick={(e) => showTableOfContent()}
            >
              <svg
                width="25"
                height="25"
                viewBox="0 0 24 24"
                fill="#00AAD3"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM13 18H11V16H13V18ZM13 14H11V8H13V14ZM12 5C11.45 5 11 4.55 11 4C11 3.45 11.45 3 12 3C12.55 3 13 3.45 13 4C13 4.55 12.55 5 12 5Z"
                  fill="#00AAD3"
                />
              </svg>
            </button>
          </div>
        )}
        <div className="mt-2.5 sm:block shrink-0">
          {!isMobileView && <ReactTooltip place="bottom" />}
          <button data-tip="Resources" className="mt-1" onClick={(e) => {
            setShowSideBar(false);
            setShowResourceBar(!showResourceBar);
          }}>
            <img src={`${process.env.reader_pdf_url}/public/images/resourceBarIcon.svg`} />
          </button>
        </div>
      </div>
    } */}
  </>);
};

export default Header;
