import React, { FC, Fragment } from 'react'
import ReactDOM from 'react-dom'
import CircularProgress from '@mui/material/CircularProgress';
import { Modal, } from '@material-ui/core'
import Spinner from '../shared/Spinner/index';
import './Loader.css'

interface Val {
    val: boolean
}

const Loader: FC<Val> = (props) => {
    const { val } = props
    const LoadingModal = () => <Modal className='modals' open={true}>
        <Fragment>
            {/* <CircularProgress size={60} style={{ color: "#009ef7" }} /> */}
            <Spinner/>
        </Fragment>
        
    </Modal>
    if (val) {
        const isload = document?.getElementById('loader')
        return (
            <div>
                {isload && ReactDOM.createPortal(<LoadingModal />, document?.getElementById('loader') as HTMLDivElement)}
            </div>
        )
    }
    else {
        return <></>
    }
}

export default Loader
