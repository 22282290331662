import { dispatchType } from "../dispatchTypes";
import { URLS, MSG, userRoleId } from "../../shared/constants";
import { loadPrivileges } from "../../shared/helper/helper";
import { SET_SNACKBAR } from "../reducers/snackbarReducer";
import { getCourses } from "./curriculumActionTypes";
import { AuthApiCall, CyborgServiceApiCall } from "../../api";
import { updateCurrentAY } from "./academicYears";
import { getCategoryList } from "./categoryActionTypes";
import { getPubilcationList } from "./publicationActionTypes";
import { topvaultDetails } from "./topvaultActionTypes";

export type LoginUserData = {
  userName: string;
  password: string;
  rememberMe: string;
  token: string;
  isAuth: boolean;
  id: number;
  organizationId?: number;
  deviceType?:string;
};

export type LoginUserMobileData = {
  phoneNumber: string;
  userRoleId: number;
};

export type forgotPasswordEmailData = {
  email?: string;
  userRoleId: number;
};

export type otpData = {
  phoneNumber: string;
  otp: string;
  token?: string;
  isAuth?: boolean;
  userRoleId: number;
};

export type changeData = {
  password: string;
  id: number;
  email: string;
};

export type snackBarData = {
  snackbarOpen: boolean;
  snackbarType: any;
  snackbarMessage: string;
  isHtmlText?: boolean;
};

export type notificationData = {
  notifyOpen: boolean;
  CalendarID: number;
  CalendarDateFrom: string;
  Title: string;
  CalendarJobID: number;
  StatusID: number;
  Description: string;
  RepeatID: number;
};
export type userRole = {
  userRoleId: number;
  userRole: string;
  description: string;
  statusId: number;
  updatedBy: number;
  updatedOn: string;
};

export const userType = (data: userRole) => ({
  type: dispatchType.login.userType,
  payload: data,
});

export const loginUser = (data: LoginUserData) => ({
  type: dispatchType.login.loginUser,
  payload: data,
});

export const loginUserMobile = (data: LoginUserMobileData) => ({
  type: dispatchType.login.loginUserMobile,
  payload: data,
});

export const forgotPasswordEmail = (data: forgotPasswordEmailData) => ({
  type: dispatchType.login.forgotPassword,
  payload: data,
});

export const validatedOtp = (data: otpData) => ({
  type: dispatchType.login.otp,
  payload: data,
});

export const changePasswordSuccess = () => ({
  type: dispatchType.login.changePassword,
});

export const resetEmail = () => ({
  type: dispatchType.login.resetEmail,
});

export const resetPhoneNumber = () => ({
  type: dispatchType.login.resetPhoneNumber,
});

export const resetNewPassword = () =>({
  type: dispatchType.login.newPasswordSeted
})

export const resetOrgNewPassword = () =>({
  type: dispatchType.login.orgNewPasswordSeted
})
export const setSnackbar = (data: snackBarData) => ({
  type: SET_SNACKBAR,
  payload: data,
});

export const setNotification = (data: notificationData) => ({
  type: SET_SNACKBAR,
  payload: data,
});

export const setError = (data: any) => ({
  type: dispatchType.login.setError,
  payload: data,
});

export const login = (data: LoginUserData) => {
  return (dispatch: any) => {
    AuthApiCall
      .post(URLS.LOGIN, data)
      .then(async (response) => {
        if ((response?.status === 200 || response?.status === 201) 
          && response?.data?.status !== "400") { // added "400" status check to block login even if the header status is success 
          if (response.data.data.isGateStaff) {
            dispatch(
              setError({
                error: "Please try logging through mobile!!",
              })
            );
          } else {
          if(response.data.message === 'Kindly set the new password'){
            const privileges = await loadPrivileges(response?.data.data);
          dispatch(
            loginUser({
              ...data,
              password: window.btoa(data.password),
              userData: response.data.data,
              privileges: privileges,
              isSetPass :true,
            })
          );
          }
          else{
          localStorage.setItem('auth_token', response?.data?.token);
          const privileges = await loadPrivileges(response?.data.data);
          dispatch(
            loginUser({
              ...data,
              password: window.btoa(data.password),
              userData: response.data.data,
              token: response.data.token,
              isAuth: true,
              rememberMe: data.rememberMe,
              privileges: privileges
            })
          );
          dispatch(getCourses({
            userRefId: response.data.data?.userRefId
          }))
          dispatch(updateCurrentAY())
          dispatch(getCategoryList())
          dispatch(getPubilcationList())
          dispatch(topvaultDetails(  response.data.data.UserRoleID == userRoleId.teacherId ?
            {loggedInUserID: response.data?.data?.userId, loggedUserRoleID: response.data.data.UserRoleID, topvaultUserID : response.data?.data?.userId, topvaultUserRoleID : response.data.data.UserRoleID, topvaultUserName : response.data?.data?.firstName ? response.data?.data?.firstName : ' ' + " " + response.data?.data?.lastName ? response.data?.data?.lastName : ''  } :
            {loggedInUserID: response.data?.data?.userId, loggedUserRoleID: response.data.data.UserRoleID }))
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: "success",
              snackbarMessage: "Logged in successfully",
            })
          );
          }
          }
        } else {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage: response.data.message,
            })
          );
          dispatch(
            setError({
              error: MSG.USERNAME_INVALID,
            })
          );
        }
      })
      .catch((error) => {
        if(error?.response?.status == 403) {
          dispatch(
            setError({
              error: error?.response?.data?.response?.message?.message,
            })
          );
        } else {
          dispatch(
            setError({
              error: MSG.USERNAME_INVALID,
            })
          );
        }
      });
  };
};

export const loginOrgSuperAdmin = (data: LoginUserData) => {
  return (dispatch: any) => {
    CyborgServiceApiCall
      .post(URLS.ORG_ADMIN_LOGIN, data)
      .then(async (response) => {
        if ((response.status === 200 || response.status === 201) && response.data.status !== "404" && response.data.status !== "403") { //403 for Wrong Domain Access 
          if(response.data.data.isPasswordExpires){
            const privileges = await loadPrivileges(response?.data.data);
          dispatch(
            loginUser({
              ...data,
              password: window.btoa(data.password),
              userData: response.data.data,
              privileges: privileges,
              isOrgSetPass :true,
            })
          );
          }
          else{
          localStorage.setItem('auth_token', response?.data?.token);
          const privileges = await loadPrivileges(response?.data.data);
          dispatch(
            loginUser({
              ...data,
              password: window.btoa(data.password),
              userData: response.data.data,
              token: response.data.token,
              isAuth: true,
              rememberMe: data.rememberMe,
              privileges: privileges
            })
          );
          dispatch(getCourses({
            userRefId: response.data.data?.userRefId
          }))
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: "success",
              snackbarMessage: "Logged in successfully",
            })
          );
          }
        } else {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage: response.data.message,
            })
          );
          dispatch(
            setError({
              error: MSG.USERNAME_INVALID,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setError({
            error: MSG.USERNAME_INVALID,
          })
        );
      });
  };
};

export const loginMobile = (data: LoginUserMobileData) => {
  return (dispatch: any) => {
    AuthApiCall
      .post(URLS.MOBILE_LOGIN, data)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(loginUserMobile({ ...data }));
        }
      })
      .catch((error) => {
        dispatch(
          setError({
            error: MSG.MOBILE_NUMER,
          })
        );
      });
  };
};

export const validateOtp = (data: otpData) => {
  return (dispatch: any) => {
    AuthApiCall
      .post(URLS.VALIDATE_OTP, data)
      .then(async (response) => {
        if (response.status === 200 || response.status === 201) {
          localStorage.setItem('auth_token', response?.data?.token);
          const privileges = await loadPrivileges(response?.data.data);

          dispatch(
            validatedOtp({
              ...data,
              userData: response.data.data,
              phoneNumber: "",
              token: response.data.token,
              isAuth: true,
              privileges: privileges
            })
          );
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: "success",
              snackbarMessage: response.data.message,
            })
          );
        } else {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage: response.data.message,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setError({
            error: error?.response?.data?.message,
          })
        );
      });
  };
};

export const forgotPassword = (data: forgotPasswordEmailData) => {
  return (dispatch: any) => {
    // axios
    AuthApiCall
      .post(URLS.FORGOT_PASSWORD, data)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          // alert(response.data.message);
          dispatch(forgotPasswordEmail({ ...data }));
          setTimeout(() => {
            dispatch(resetEmail());
          }, 5000);
        }
      })
      .catch((error) => {
        dispatch(
          setError({
            error: MSG.FORGOT_EMAIL,
          })
        );
      });
  };
};

export const orgSuperAdminForgotPassword = (data: forgotPasswordEmailData) => {
  return (dispatch: any) => {
    // axios
    CyborgServiceApiCall
      .post(URLS.ORGSUPERADMIN_FORGOT_PASSWORD, data)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(forgotPasswordEmail({ ...data }));
          setTimeout(() => {
            dispatch(resetEmail());
          }, 5000);
        }
      })
      .catch((error) => {
        dispatch(
          setError({
            error: MSG.FORGOT_EMAIL,
          })
        );
      });
  };
};

export const changePassword = (data: changeData) => {
  return (dispatch: any) => {
    AuthApiCall
      .post(URLS.CHANGE_PASSWORD, data)
      .then((response: any) => {
        if (response.status === "200" || response.status === "201" || response.status === 200 || response.status === 201) {
          dispatch(changePasswordSuccess());
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: "success",
              snackbarMessage: response.data.message,
            })
          );
        } else {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage: response.data.message,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setError({
            error: MSG.INVALID_USER,
          })
        );
      });
  };
};

export const logout = () => ({
  type: dispatchType.revoke,
});
