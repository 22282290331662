import { SaServiceApiCall } from "../";
export const getAcademicYearData = async () => {
  try {
    const res = await SaServiceApiCall.get(`student-promotion/getAcademicYear`);
    if (res.status === 200 || res.status === 201) {

      return res.data.data;
    }
    return {}
  } catch (e) {
    console.error(e)
    return null
  }
};

export const getAcademicListApi = async (req: any) => {
  try {
    const res = await SaServiceApiCall.post('schoolAdmin/listAcademicYear', req);
    if (res.status === 200 || res.status === 201) {
      return res.data.data;
    }
    return {};
  } catch (e) {
    console.error(e);
    return null;
  }
};
export const postAcademicData = async (req: any) => {
  try {
    const res = await SaServiceApiCall.post('student-promotion/academicSetup', req);
    if (res.status === 200 || res.status === 201) {
      return res.data.data;
    }
    return {};
  } catch (e) {
    console.error(e);
    return null;
  }
};


export const academicYearStatus = async () => {
  try {
    const res = await SaServiceApiCall.get('academic/archive/status');
    if (res.status === 200 || res.status === 201) {
      return res.data;
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const academicYeararchive = async (obj: any) => {
  try {
    const res = await SaServiceApiCall.post(`academic/archive`, obj);
    if (res.status === 200 || res.status === 201) {
      return res.data;
    }
    return {};
  } catch (e) {
    console.error(e);
    return null;
  }
};