import React, { useEffect, useState, useRef, useCallback } from "react";
import * as pdfJS from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

import PageLoader from "./PageLoader";
import { useAppState } from "../Context";
import { Interactives } from "../Helpers/Interactive";
import { interactivesDetailService } from "../Services/interactivesDetailService";
import { fetchResources } from "../Services/fetchResources";
import Bookmark from "./Bookmark";
import { separateByTypes } from "../utils/SortInteractives";
import ZoomComponent from "./ZoomComponent";
import Canvas from "./Canvas";
import Loader from '../../../common/Loader'
import Fullscreen from "./Fullscreen";
// import { fetchHighlights } from "../Services/fetchHighlights";
// import { saveReadProgress } from "../Services/saveReadProgress";
// import { addData, deleteBookById, getBookById } from "../utils/Dexie";
import { transcriptions } from "../i18n/languages";
import SearchModal from "./SearchModal";
import CanvasTwo from "./CanvasTwo";
import { API } from "../utils/API";
import RightArrowBlue from '../Assets/rightArrowBlue.svg'
import pdfZoomIn from "../Assets/pdfZoomIn.svg";
import pdfZoomout from "../Assets/pdfZoomOut.svg";
import pdfZoomInDisabled from '../Assets/selectedZoomIn.svg';
import pdfZoomOutDisabled from '../Assets/selectedZoomOut.svg';
import pdfReset from "../Assets/pdfReset.svg";
import fullIcon from "../Assets/pdfFullViewIcon.svg";
import printIcon from "../Assets/pdfPrint.svg";
import pdfRightIcon from "../Assets/pdfForward.svg";
import ToogleSinglePage from "./ToggleSinglePage";
import { Alert, Snackbar } from "@mui/material";
let heightToWidthRatio = [1.4142, 1];
let generalNewWidth;

let ViewerHeightAsPerScreen = window.innerHeight * 80 / 100;
if (ViewerHeightAsPerScreen > 533) {
  ViewerHeightAsPerScreen = 533;
}
export default function PdfViewerMobile({
  url,
  isMobileView,
  pdfContainerStyling,
  controlsConfig
}) {
  pdfJS.GlobalWorkerOptions.workerSrc = pdfjsWorker;
  var PDFDoc = null;
  const [pdfRef, setPdfRef] = useState();
  const canvasRef = useRef();
  const canvas2Ref = useRef();
  const keyDownRef = useRef();
  const prevButtonRef = useRef();
  const nextButtonRef = useRef();
  const [interactivesToRemove, setInteractivesToRemove] = useState([]);
  const [showDrawingCanvas, setShowDrawingCanvas] = useState(false);
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  let isCommentHidden = localStorage.getItem('commentHidden');
  let bookLoadedFinally = false;
  const { currentPage, setCurrentPage, isLoading, setIsLoading, highlightedItems, lastReadPages, setlastReadPages, allInteractivities, setAllInteractivities, languagePrefered, searchValue, setSearchValue, searchResult, setSearchResult, isSearching, setIsSearching, resources, setResources, searchStatus, setSearchStatus, showSearch, textDataToSearch, setTextDataToSearch, generalWidth, setGeneralWidth, currentPageArray, setCurrentPageArray, currentPageTwo, setCurrentPageTwo, isDualPage, setIsDualPage, viewMode, setViewMode, scaleSize, setScaleSize, isPageRendering, setIsPageRendering, bookDetails, setBookDetails, setTotalPages, totalPages, fullScreenIcon, setFullScreenIcon } = useAppState("reader");
  const [lastPageNumber, setLastPageNumber] = useState();
  const [isSmartTV, setIsSmartTV] = useState(false);
  const [jumpToPageInput, setJumpToPageInput] = useState(1);
  const [snackBarToggle, setSnackBarToggle] = useState({ isOpen: false, text: "" });

  // let reader_pdf_url = process.env.reader_pdf_url;
  const renderPage = useCallback((pageNum, pdf = pdfRef) => {
    if (!pageNum) {
      pageNum = 1;
    }
    pdf && pdf.getPage(pageNum).then(function (page) {
      setlastReadPages((prev) => [...prev, pageNum]);
      setIsPageRendering(true);

      let getViewMode = localStorage.getItem("viewMode");

      const canvas = canvasRef.current;
      var ctx = canvas.getContext("2d");

      var viewport = page.getViewport({ scale: 2 });

      var isPortrait = viewport.height > viewport.width;
      // isBookPortrait = isPortrait;
      let bookDimension = {
        isBookPortrait: isPortrait,
        width: viewport.width,
        height: viewport.height
      }
      localStorage.setItem("bookDimension", JSON.stringify(bookDimension));


      let getDrawingCanvas = document.getElementById('drawing-board');
      setShowDrawingCanvas(true);
      console.log('isPortrait: ', isPortrait);
      if (isPortrait) {
        if (isMobileView) {
          // generalNewWidth = newWidth;
          // setGeneralWidth(newWidth);
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          canvas.style.width = "100%";
          canvas.style.height = "100%";
          // getDrawingCanvas.width = windowWidth;
          // getDrawingCanvas.height = windowHeight;

          // localStorage.setItem("bookWidth", newWidth);
        } else {
          // let newWidth = (viewport.width * 788) / viewport.height;
          // // canvas.style.width = newWidth + "px";
          // // canvas.style.height = "788px";
          // getDrawingCanvas.width = newWidth;
          // getDrawingCanvas.height = "788";

          // // localStorage.setItem("bookWidth", newWidth);

          // // if (pageNum !== 1) {
          //   // turnOnDualPage(canvas, newWidth);
          // // }
        }

      } else {
        if (isMobileView) {
          // let newWidth = (viewport.width * 788) / viewport.height;
          // generalNewWidth = newWidth;
          // setGeneralWidth(newWidth);
          // let windowWidth = window.innerWidth - 20;
          // let windowHeight = ((window.innerWidth - 20) * 788) / newWidth;

          // canvas.style.width = windowWidth + "px";
          // canvas.style.height = windowHeight + "px";
          // getDrawingCanvas.width = windowWidth;
          // getDrawingCanvas.height = windowHeight;

          // localStorage.setItem("bookWidth", newWidth);
        } else {
          let newWidth = (viewport.width * 600) / viewport.height;
          canvas.style.width = newWidth + "px";
          canvas.style.height = "600px";
          getDrawingCanvas.width = newWidth;
          getDrawingCanvas.height = "600";

          localStorage.setItem("bookWidth", newWidth);
        }

      }

      const renderContext = {
        canvasContext: ctx,
        viewport: viewport,
      };


      let renderTask = page.render(renderContext);

      renderTask.promise.then(() => {
        setIsPageRendering(false);
      })

      // setTimeout(() => {
      //   checkIsTelevisions();
      // }, 1000);

    });
  },
    [pdfRef, currentPage]
  );


  const checkIsTelevisions = () => {
    if (!isSmartTV) {
      if (window.innerWidth > 1920) {
        let getPageMode = document.getElementById("tooglePageMode");
        getPageMode.click();
        getPageMode.disabled = true;
        setIsSmartTV(true);
      }
    }
  };


  const turnOnDualPage = (leftCanvas, newWidth) => {

    //Adjsuting Canvas Two
    let theCanvasTwo = document.getElementById("canvas2");
    // theCanvasTwo.style.width = newWidth + "px";
    // theCanvasTwo.style.height = "788px";

  };

  // const renderSecondPage = (pdf, pageNumToRender,scaleSize) => {
  //   setCurrentPageTwo(pageNumToRender);
  //   pdf &&
  //     pdf.getPage(pageNumToRender).then(function (page) {
  //       const canvasTwo = canvas2Ref.current;
  //       let getDrawingCanvas2 = document.getElementById('drawing-board2');
  //       var ctx2 = canvasTwo.getContext("2d");

  //       var viewport = page.getViewport({ scale: scaleSize });
  //       canvasTwo.height = viewport.height;
  //       canvasTwo.width = viewport.width;
  //       var isPortrait = viewport.height > viewport.width;

  //       if (isPortrait) {
  //         let newWidth = (viewport.width * 788) / viewport.height;
  //         // canvasTwo.style.width = newWidth + "px";
  //         // canvasTwo.style.height = "788px";
  //         getDrawingCanvas2.width = newWidth;
  //         getDrawingCanvas2.height = "788";
  //       } else {
  //         let newWidth = (viewport.width * 600) / viewport.height;
  //         canvasTwo.style.width = newWidth + "px";
  //         canvasTwo.style.height = "600px";
  //         getDrawingCanvas2.width = newWidth;
  //         getDrawingCanvas2.height = "600";

  //         localStorage.setItem("bookWidth", newWidth);
  //       }

  //       const renderContext = {
  //         canvasContext: ctx2,
  //         viewport: viewport,
  //       };

  //       let renderTask = page.render(renderContext);


  //     });
  // };



  useEffect(() => {
    renderPage(currentPage, pdfRef);
  }, [pdfRef, currentPage, renderPage, isDualPage]);



  useEffect(() => {
    // calculateButtonPosition();

    setIsLoading(true);
    (async () => {
      try {
        // const book = await getBookById(bookID)
        let loadingTask;
        // if (book && book.version_id == bookVersionId) {
        //   loadingTask = pdfJS.getDocument({
        //     data: book.data
        //   });
        // } else {
        console.log('url:===> ', url);
        loadingTask = pdfJS.getDocument({
          url: url,
          // httpHeaders: {
          //   Authorization: `Bearer ${localStorage.getItem("idToken")}`,
          //   disableAutoFetch: false,
          //   disableStream: false
          // },
        });
        // }
        loadingTask.promise
          .then(
            (loadedPdf) => {
              PDFDoc = loadedPdf
              setLastPageNumber(loadedPdf.numPages);
              loadedPdf.getData().then(async (data) => {
                setLoadingPercentage(10);
                setLoadingPercentage(20);
                setLoadingPercentage(30);
                setLoadingPercentage(40);
                setLoadingPercentage(50);
                setLoadingPercentage(99);
                // const book = await getBookById(bookID)
                // if (book && book.version_id != bookVersionId) {
                //   await deleteBookById(bookID)
                // }
                setIsLoading(false);
                // // Check available quota in IndexedDB using the Storage Manager API
                // if ('storage' in navigator && 'estimate' in navigator.storage) {
                //   navigator.storage.estimate().then(async (estimate) => {
                //     const { usage, quota } = estimate;
                //     if (!book || (book && book.version_id != bookVersionId)) {
                //       if (quota > data.length + 104857600) {
                //         try {
                //           await addData({ book_id: bookID, version_id: bookVersionId, data })
                //         } catch (error) {
                //           console.log(error)
                //         }
                //       }
                //     }
                //   });
                // } else {
                //   console.log('The Storage Manager API is not supported in this browser.');
                // }
              })
              setPdfRef(loadedPdf);
              setTotalPages(loadedPdf.numPages);
              setIsDualPage(false)
              extractText(loadedPdf);

            },
            function (reason) {
              console.error(reason);
            }
          )
          .finally(() => {
            bookLoadedFinally = true;
          });
        loadingTask.onProgress = function (data) {
          setLoadingPercentage(data.loaded * 100 / data.total);
          if (Math.round(data.loaded * 100 / data.total) > 50 && bookLoadedFinally === true) {
            setIsLoading(false);
          }
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }, [url]);

  const extractText = async (pdfRef) => {
    if (pdfRef.numPages) {
      let extactedData = [];
      for (let i = 1; i <= pdfRef.numPages; i++) {

        await new Promise((resolve) => {
          requestAnimationFrame(resolve); // Yield control to the browser
        });

        pdfRef.getPage(i).then(async function (page) {
          const textContent = await page.getTextContent();
          const pageText = await textContent.items.map((textItem) => textItem.str).join(' ');
          extactedData.push({
            page: i,
            text: pageText,
          });
        });
      }
      setTextDataToSearch(extactedData);
    }
  };

  const checkForRedirection = () => {
    let isRedirectToExist = localStorage.getItem("redirectTo");
    if (isRedirectToExist) {
      if (parseInt(isRedirectToExist) === 1 || parseInt(isRedirectToExist) % 2 === 0) {
        setCurrentPage(parseInt(isRedirectToExist));
        localStorage.removeItem("redirectTo");
      } else {
        setCurrentPage(parseInt(isRedirectToExist) - 1);
        localStorage.removeItem("redirectTo");
      }
    }
    else {
      let lastVisitedPage = localStorage.getItem("pageIndex");
      if (lastVisitedPage) {
        if (parseInt(lastVisitedPage) === 1 || parseInt(lastVisitedPage) % 2 === 0) {
          setCurrentPage(parseInt(lastVisitedPage));
          localStorage.removeItem("pageIndex");
        } else {
          setCurrentPage(parseInt(lastVisitedPage) - 1);
          localStorage.removeItem("pageIndex");
        }
      }
    }
  };

  const calculateButtonPosition = () => {
    const canvas = canvasRef.current;
    const prevButton = prevButtonRef.current;
    const nextButton = nextButtonRef.current;

    if (canvas && prevButton && nextButton) {
      const canvasWidth = canvas.clientWidth;
      const buttonWidth = prevButton.clientWidth;
      const margin = 10;
      const prevButtonLeft = canvas.offsetLeft + margin;
      const prevButtonTop =
        canvas.offsetTop + canvas.height / 2 - buttonWidth / 2;
      const nextButtonLeft =
        canvas.offsetLeft + canvasWidth - buttonWidth - margin;
      const nextButtonTop = prevButtonTop;

      prevButton.style.left = prevButtonLeft + "px";
      prevButton.style.top = prevButtonTop + "px";
      nextButton.style.left = nextButtonLeft + "px";
      nextButton.style.top = nextButtonTop + "px";
    }
  };
  const nextPage = () => {
    if (totalPages > 0 && currentPage < totalPages) {
      if (isDualPage) {
        if ((currentPage + 1) !== totalPages) {
          setCurrentPage(currentPage + 2);
          setJumpToPageInput(currentPage + 2)
          localStorage.setItem("pageIndex", currentPage + 2)
        }
      } else {
        setCurrentPage(currentPage + 1);
        setJumpToPageInput(currentPage + 1)

        localStorage.setItem("pageIndex", currentPage + 1)
      }
    }
  }

  const prevPage = () => {
    if (currentPage > 1) {
      if (isDualPage) {
        if (currentPage - 2 === 0) {
          setCurrentPage(1);
          setJumpToPageInput(1)

        } else {
          setCurrentPage(currentPage - 2);
          setJumpToPageInput(currentPage - 2)

          localStorage.setItem("pageIndex", currentPage - 2)
        }
      } else {
        setCurrentPage(currentPage - 1);
        setJumpToPageInput(currentPage - 1)
        localStorage.setItem("pageIndex", currentPage - 1)
      }
    }

  }

  const handleKeyDown = (event) => {
    if (event.key === "ArrowRight") {
      nextPage();
      event.preventDefault();
    }
    if (event.key === "ArrowLeft") {
      prevPage();
      event.preventDefault();
    }
    if (event.defaultPrevented) {
      return;
    }

  };

  // const clearCurrentInteractives = async () => {
  //   let getEachElement = await document.querySelectorAll(".interactive");

  //   getEachElement.length > 0
  //     ? getEachElement.forEach(async (element) => {
  //       await element.remove();
  //     })
  //     : interactivesToRemove.filter((eachInteractivity) => {
  //       let getEachElement = document.getElementById(eachInteractivity._id);
  //       getEachElement.remove();
  //     });
  // };

  // const fetchInteractives = async () => {
  //   let getInteractives = await interactivesDetailService(isAdmin, bookID, bookVersionId, null);
  //   let sortedInteractivities = await separateByTypes(getInteractives);
  //   setAllInteractivities(sortedInteractivities);
  // };

  // const fetchResourcesOfBook = async () => {
  //   let fetchedResources = await fetchResources(isAdmin, bookID);
  //   setResources(fetchedResources);
  // };


  // const fetchBookDetails = async () => {
  //   let response = await API("get", `/api-books/books/${bookID}/details`);
  //   setBookDetails(response);
  // };




  function handleScale(actionType) {
    let canvas = document.getElementById("the-canvas");
    let leftPage = document.getElementById("leftPage");
    const parentDiv = document.getElementById("area");
    let scale = scaleSize;
    if (actionType == "increment") {
      scale = scale + 0.1;
      setScaleSize(scale);
      if (scale > 1) {
        const canvasPercent = (((parentDiv.offsetWidth * (scale) * 100) / leftPage.offsetWidth) - 100) + 100;
        canvas.style.width = `${canvasPercent}%`;
        leftPage.style.width = `${parentDiv.offsetWidth * (scale)}px`;
        leftPage.style.height = `${parentDiv.offsetHeight * (scale)}px`;
        const offsetX = ((parentDiv.offsetWidth * scale) - parentDiv.clientWidth) / 2;
        parentDiv.scrollLeft = offsetX;
      }
    } else {
      scale = scale - 0.1;
      setScaleSize(scale);
      if (scale > 1) {
        const canvasPercent = (((parentDiv.offsetWidth * (scale) * 100) / leftPage.offsetWidth) - 100) + 100;
        canvas.style.width = `${canvasPercent}%`;
        leftPage.style.width = `${parentDiv.offsetWidth * (scale)}px`;
        leftPage.style.height = `${parentDiv.offsetHeight * (scale)}px`;
        const offsetX = ((parentDiv.offsetWidth * scale) - parentDiv.clientWidth) / 2;
        parentDiv.scrollLeft = offsetX;
      }
    }
    if (scale === 1) {
      resetPdfViewer()
    }
  }

  const resetPdfViewer = () => {
    let canvas = document.getElementById("the-canvas");
    let leftPage = document.getElementById("leftPage");
    const parentDiv = document.getElementById("area");
    setScaleSize(1);
    leftPage.style.width = `${parentDiv.offsetWidth }px`;
    leftPage.style.height = `${parentDiv.offsetHeight}px`;
    canvas.style.width = "100%";
  }


  useEffect(() => {
    if (isDualPage) {
      localStorage.setItem("isDualPage", isDualPage);
    }
    // localStorage.setItem("lastRodePage", lastReadPages);

    // document.addEventListener("keydown", handleKeyDown);
    // addEditorEvents({ Elements });
    return () => {
      // document.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentPage, prevPage, nextPage, isDualPage]);

  // useEffect(() => {
  //   fetchInteractives();
  //   fetchResourcesOfBook();
  //   fetchBookDetails();
  // }, [])

  // useEffect(() => {
  //   // resetPenTool();
  //   if (allInteractivities.length > 0) {
  //     renderInteractives();
  //   }
  // }, [allInteractivities, currentPage])

  // const renderInteractives = async () => {
  //   let getIsDualPage = localStorage.getItem("isDualPage");
  //   let interactivityToRender = await allInteractivities.filter((eachInteractivity) => {
  //     if (getIsDualPage && !isMobileView) {
  //       return eachInteractivity.content.page === currentPage || eachInteractivity.content.page === currentPage + 1;
  //     } else {
  //       return eachInteractivity.content.page === currentPage
  //     }
  //   });
  //   Interactives(interactivityToRender, currentPage, isCommentHidden, isMobileView, window.innerWidth - 20, ((window.innerWidth - 20) * 788) / generalNewWidth, isDualPage);
  //   setInteractivesToRemove(interactivityToRender);
  // };

  // useEffect(() => {
  //   localStorage.setItem("lastRodePage", lastReadPages);
  //   clearCurrentInteractives();
  // }, [currentPage]);

  // useEffect(() => {
  //   if (!isAdmin) {
  //     let interval;
  //     if (lastReadPages.length > 0) {
  //       interval = setInterval(async function () {
  //         let isSaved = await saveReadProgress(bookID, lastReadPages);
  //         if (isSaved) {
  //           setlastReadPages([]);
  //         }
  //       }, 30000);
  //     }
  //     return () => {
  //       clearInterval(interval);
  //     };
  //   }
  // }, [lastReadPages]);

  // useEffect(() => {
  //   checkForRedirection();
  // }, [isLoading])

  // function searchInPDF(query) {
  //   const abortController = new AbortController();
  //   const { signal } = abortController;

  //   setIsSearching(true);
  //   var searchResults = [];

  //   async function searchOnPage(pageNum) {
  //     if (signal.aborted) {
  //       return;
  //     }

  //     const promises = [];

  //     for (let i = 0; i < textDataToSearch.length; i++) {
  //       setIsSearching(true);
  //       if (signal.aborted) {
  //         setIsSearching(false);
  //         return;
  //       }

  //       await new Promise((resolve) => {
  //         requestAnimationFrame(resolve); // Yield control to the browser
  //       });
  //       let pageNumber = await i;
  //       let pageText = await textDataToSearch[i].text;
  //       if (pageText.toLowerCase().includes(query.toLowerCase())) {
  //         searchResults.push({
  //           page: pageNumber,
  //           text: pageText,
  //         });
  //       }
  //     }

  //     try {
  //       setIsSearching(true);
  //       // await Promise.all(promises);
  //       // All pages have been searched, do something with the results
  //       setSearchResult(searchResults);
  //       setSearchStatus({ searchStatus: "searchStatus" });
  //       setIsSearching(false);
  //       return searchResults;
  //     } catch (error) {
  //       if (error.name === 'AbortError') {
  //         // Search was cancelled, ignore the error
  //       } else {
  //         console.error("Error searching pages:", error);
  //       }
  //     }
  //   }

  //   function cancelSearch() {
  //     abortController.abort();
  //     setIsSearching(false);
  //   }

  //   // Start searching from the first page
  //   searchOnPage(1);
  //   return {
  //     cancelSearch
  //   }
  // }

  const blockInvalidChar = e => {
    const regex = /^[0-9]*$/;
    if (e.keyCode == 8 || e.key === "Enter") {

    } else if (!regex.test(e.key)) {
      e.preventDefault();
    }

  }

  const handleLeftPageNum = (e) => {
    let newValue = e.target.value;
    if (newValue.length <= 5) {
      setJumpToPageInput(newValue);
      if (parseInt(newValue) <= totalPages) {
        setJumpToPageInput(parseInt(newValue));
      }
    }
  };
  const handleEnter = (e) => {
    if (e.key === "Enter") {
      if ( jumpToPageInput <= 0 || jumpToPageInput === "" || jumpToPageInput > totalPages) {
        setSnackBarToggle({ isOpen: true, text: "Page Not Found" });
        return;
      }
      setCurrentPage(jumpToPageInput);
    }
  };
  const handleClose = () => {
    setSnackBarToggle({ isOpen: false, text: "" });
  }
  const customStylings = { ...(isMobileView ? {} : fullScreenIcon ? { height: "calc(100vh -58px)" } : pdfContainerStyling) };
  return (
    <>
      <Loader val={isLoading} />
      <Snackbar
      open={snackBarToggle.isOpen}
      autoHideDuration={3000}
      onClose={handleClose}
      data-testid="snackBar"
      className="snackbar_css"
    >
      <Alert
        onClose={handleClose}
        severity="error"
        sx={{ width: "100%" }}
      >
        {snackBarToggle.text}
      </Alert>
    </Snackbar>
      {/* {!isLoading && isPageRendering && (
        <div className="centered-flex">
          <PageLoader
            className="centered-flex-loader"
            height="20px"
            width="20px"
          />
        </div>
      )} */}
      <div style={{ display: "flex", width: "100%" }}>
        <div
          id="wrap"
          ref={keyDownRef}
          className={`white-bg-flex ${isLoading ? "invisible" : "visible"}`}
        >
          <div className="full-width-relative-flex pdf">
            <div style={{ margin: "auto", display: "flex", position: "relative", justifyContent: 'space-between' }}>
              {!isMobileView && pdfRef && scaleSize === 1 && <button
                ref={prevButtonRef}
                data-tip={`${transcriptions.reader.Tooltip.prev[languagePrefered]}`}
                id="prev"
                className="prev-btn"
                disabled={currentPage === 1 || isPageRendering ? true : false}
                onClick={prevPage}
              >
                <img src={RightArrowBlue} height={50} width={50} />
              </button>}
              <div
                id="area"
                className={`canvas-wrapper-container`}
                tabIndex="0"
                style={{
                  width: '100%',
                  minWidth:"340px",
                  height: ViewerHeightAsPerScreen,
                  overflow: scaleSize  == 1 ? "visible" :"auto",
                  ...customStylings
                }}
              >
                <div id="canvas-container" className={`canvas-container`} style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                  <div id="leftPage" style={{ height: ViewerHeightAsPerScreen }} className={`book-mark-container`} >

                    <canvas
                      id="the-canvas"
                      ref={canvasRef}
                      className="the-canvas"
                    ></canvas>
                  </div>
                  {/* <div id="rightPage" className={`book-mark-container`} style={{display:!isDualPage && "none"}}>

                    <canvas
                      id="canvas2"
                      ref={canvas2Ref}
                      className="the-canvas"
                    >
                    </canvas>
                    <CanvasTwo isLoading={isLoading} isMobileView={isMobileView} generalNewWidth={generalNewWidth} />
                  </div> */}
                </div>
              </div>
              {isMobileView &&
                <div className="pdf-mobile-footer">
                  <div>
                    <img src={scaleSize <= 1 ? pdfZoomInDisabled : pdfZoomIn} id="responsiveImage" alt="pdfZoomIn" height={30} width={30} onClick={(e) => { if (scaleSize > 1) { handleScale("decrement"); } }} />
                    {/* </button> */}
                    <img src={scaleSize >= 3 ? pdfZoomOutDisabled : pdfZoomout} id="responsiveImage" alt="pdfZoomout" height={30} width={30} onClick={(e) => {
                      if (scaleSize < 3) {
                        handleScale("increment");
                      }
                    }} />
                    {/* <span className="pdf-mobile-footer_reset"> */}
                    <img src={pdfReset} id="responsiveImage" alt="pdfReset" height={24} width={24} onClick={(e) => {
                      if (
                        scaleSize <= 2.000000000000001 ||
                        scaleSize >= 0.6000000000000001
                      ) {
                        resetPdfViewer();
                      }
                    }} />
                  </div>
                  <div className="pdf-forward-backward_sect">
                    {pdfRef && <button
                      ref={prevButtonRef}
                      data-tip={`${transcriptions.reader.Tooltip.prev[languagePrefered]}`}
                      id="prev"
                      className="pdf-footer_back-btn"
                      disabled={currentPage === 1 || isPageRendering ? true : false}
                      onClick={prevPage}
                    >
                      <img src={pdfRightIcon} height={25} width={25} style={{ opacity: currentPage === 1 ? 0.7 : 1 }} />
                    </button>}

                    {totalPages > 0  && <div className="jump-control-container">
                      {currentPage &&  <input className="jump-input" onKeyDown={blockInvalidChar} type="number" maxLength={5} value={jumpToPageInput} onChange={(e) => handleLeftPageNum(e)} onKeyPress={(e) => handleEnter(e)} />}
                      <span className="flex"> / {totalPages}</span>
                    </div>}
                    {pdfRef && <button
                      ref={nextButtonRef}
                      data-tip={`${transcriptions.reader.Tooltip.next[languagePrefered]}`}
                      id="next"
                      //className="next-btn"
                      disabled={totalPages === (isDualPage ? currentPage - 1 : currentPage)}
                      onClick={nextPage}
                    >
                      <img src={pdfRightIcon} height={25} width={25} style={{ opacity: (isDualPage ? totalPages - 1 : totalPages) === currentPage ? 0.7 : 1 }} />
                    </button>}
                  </div>
                  {/* <div class="full-screen-control"><Fullscreen
                    fullScreenIcon={fullScreenIcon}
                    setFullScreenIcon={setFullScreenIcon}
                  /> </div> */}
                  <div className="pdf-mobile-footer_print-viewIcon">
                    <img src={printIcon} alt="pdfPrint" />
                    {/* <div>
                      {totalPages > 1 && controlsConfig?.dualPage ? <ToogleSinglePage /> : null}
                    </div> */}
                    {controlsConfig?.fullScreen && <div style={{ flexShrink: 0 }}>
                      <Fullscreen
                        isMobileView={true}
                        fullScreenIcon={fullScreenIcon}
                        setFullScreenIcon={setFullScreenIcon}
                      />
                    </div>}
                    {/* <img src={fullIcon} alt="viewIcon" onClick={() => setIsFullscreen(!isFullScreen)} /> */}
                  </div>

                </div>
                // </div>

              }
              {!isMobileView && pdfRef && scaleSize === 1 && <button
                ref={nextButtonRef}
                data-tip={`${transcriptions.reader.Tooltip.next[languagePrefered]}`}
                id="next"
                className="next-btn"
                disabled={
                  pdfRef._pdfInfo.numPages === currentPage || isPageRendering ? true : false
                }
                onClick={nextPage}
              >
                <img src={RightArrowBlue} height={50} width={50} />
              </button>}
            </div>
          </div>
        </div>
      </div>
      {/* {
        showSearch && <SearchModal bookInfo={bookInfo} searchInPDF={searchInPDF} textDataToSearch={textDataToSearch} />
      } */}
    </>
  );
}
