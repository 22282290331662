import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { Redirect, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { ReduxState } from "../../redux/reducers";
import { logout } from "../../redux/actions/loginActionTypes";
import logoutImg from "../../assets/img/logout.png";
import LeftPane from "../../components/UI/Layout/LeftPane/LeftPane";
import "./AppLayout.css";
import "../../components/Auth/Login/Login.css";
import { mixPanelPageName, userRoleId } from "../../shared/constants";
import { RootStore } from "../../redux/store";
import { parseJwt } from "../../common/AuthVerify"
import useRoutesCheck from "../../common/useRoutesCheck";
import { getRouteList } from "../../common/routeConfig"
import mixPanelTrack from "../../hooks/mixPanelTrack";
import AcademicSwitchModal from "../../shared/AcademicDropdown/AcademicSwitchModal";

const AppLayout: React.FC = (props: any) => {
  const isAuth = useSelector((state: ReduxState) => state?.login?.isAuth);
  const isAdmin = useSelector((state: RootStore) => state?.login?.userRoleId) === 1;
  const isPromotionInProgress = useSelector((state: ReduxState) => state?.login?.userData.isPromotionInProgress);
  const isOrgSuperAdmin = useSelector((state: RootStore) => state?.login?.userRoleId) === userRoleId.superadminId;
  const [ canAccess ] = useRoutesCheck(isAdmin, isOrgSuperAdmin)
  const roleId = useSelector(
    (state: ReduxState) => state?.login?.userData.UserRoleID
  );
  const [isLoading, setIsLoading] = React.useState(true)
  const tenantId = useSelector((state: RootStore) => state?.tenant?.tenantId);
  const isOnline = useSelector((state: RootStore) => state?.tenant?.IS_ONLINE);
  const userID = useSelector((state: RootStore) => state?.login?.userData?.userId);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const routeList:any = getRouteList();
  const query = new URLSearchParams(window.location.search);
  const tenant_id = query.get('tenantId');
  const authToken = localStorage.getItem("auth_token");
  const availableModules = parseJwt(authToken)?.data?.availableModules ?? [];
  const fmsRoute = availableModules.includes(routeList["feeManagement"]) ? process.env.REACT_APP_FMS_BASIC_ROUTE : availableModules.includes(routeList["advanceFeeManagement"]) ? process.env.REACT_APP_FMS_ADVANCE_ROUTE : "";
  
  let obj = {
                "Page Name" : mixPanelPageName[location.pathname.toString()],
                "Page Variant" : ""
              }
  mixPanelTrack("Page Viewed", obj);

  React.useEffect(() => {
    if (window.innerWidth < 768 && tenant_id) {
      setTimeout(() => {
        setIsLoading(false)
      }, 3000);
    } else {
      setIsLoading(false)
    }
  }, [])

  if (
    isAuth &&
    !location.pathname.includes("/error/404") &&
    !location.pathname.includes("/comingSoon") &&
    !location.pathname.includes("/noaccess") &&
    !canAccess(location.pathname, availableModules)
  ) {
    if (isPromotionInProgress) {
      <Redirect exact to="/admin/promote-progress" />
    } else if (isAdmin) {
      return (
        <Redirect
          to={
              availableModules.includes(routeList["dashboard"]) 
              ? "/admin/dashboard"
              : availableModules.includes(routeList["feeManagement"])
              ? "/erp/fees/dashboard"
              : availableModules.includes(routeList["advanceFeeManagement"])
              ? "/erp/adv/fees/dashboard"
              : availableModules.includes(routeList["admissionManagement"])
              ? "/admissions"
              : "/noaccess"
          }
          exact
        />
      );
     } else if(isOrgSuperAdmin) {
      <Redirect to="/orgsuperadmin/dashboard" />;
     } else {
      return <Redirect to="/noaccess" />;
    }
  }

  return (
    <>
      <main className={isAuth ? "app-bg-color" : "auth-bg-color"}>
        <Grid
          container
          className="d-flex flex-wrap align-content-stretch minHvh"
        >
          {(!isAuth || !(!!tenantId || isOrgSuperAdmin || isOnline == 0)) ? (
            <>
              {location.pathname !== "/admin/basic-information" &&
              location.pathname !== "/admin/infrastructure" &&
              location.pathname !== "/admin/add-department" &&
              location.pathname !== "/admin/grade-setup" &&
              location.pathname !== "/admin/student" &&
              location.pathname !== "/admin/calendarEnhance" &&
              location.pathname !== "/admin/admin-roles" &&
              location.pathname !== "/mbl/logout" ? (
                <Grid className={isLoading ? "" : "loginLeftSect"}>
                  {!isLoading && <LeftPane />}
                </Grid>
              ) : (
                ""
              )}              
              <Grid
                className={
                  location.pathname !== "/admin/basic-information" &&
                  location.pathname !== "/admin/infrastructure" &&
                  location.pathname !== "/admin/add-department" &&
                  location.pathname !== "/admin/grade-setup" &&
                  location.pathname !== "/admin/student" &&
                  location.pathname !== "/admin/calendarEnhance" &&
                  location.pathname !== "/admin/admin-roles"
                    ? "bg-white loginRightSect"
                    : "bg-white loginRightSect adMin"
                }
                sx={{
                  backgroundColor: "#fff",
                }}
              >
                {props.children}
              </Grid>
            </>
          ) : (
            <Grid xs={12} item>
              {/* {(location.pathname !== "/admin/content/contentlibrary" && roleId === userRoleId.adminId) && 
              (
                <button
                  onClick={() => {
                    dispatch(logout());
                    history.push("/");
                  }}
                  className="float-right logoutBtn"
                >
                  <img src={logoutImg} />
                </button>
              )} */}
              {props.children}
            </Grid>
          )}
        </Grid>
      </main>
      {isPromotionInProgress && isAdmin && isAuth && <AcademicSwitchModal open={true} />}
    </>
  );
};

export default AppLayout;
