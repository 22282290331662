import { TenantApiCall, AuthApiCall, CyborgServiceApiCall } from ".";

export const getTenantDetails = async (host: string) => {
  try {
    const res = await TenantApiCall.get(`tenants/${encodeURIComponent(host)}`);
    if (res.status === 200 || res.status === 201) {
      return res.data;
    } 
    return {};
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const verifyToken = async (token: string) => {
  try {
    const res = await AuthApiCall.post(`auth/mobileTokenVerification`,{token:token});
    if (res.status === 200 || res.status === 201) {
      return res.data;
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const changeNewPassword = async (obj:any) => {
  try {
    const res = await AuthApiCall.post(`auth/setPassword`,obj);
    if (res.status === 200 || res.status === 201) {
      return res.data;
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const changeOrgNewPassword = async (obj:any) => {
  try {
    const res = await CyborgServiceApiCall.post(`auth/setPassword`,obj);
    if (res.status === 200 || res.status === 201) {
      return res.data;
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
};