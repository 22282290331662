import { dispatchType } from "../dispatchTypes";
import axios from "axios";
import {
  constants,
  URLS,
  x_tenant_id,
  auth_token,
} from "../../shared/constants";
import moment from "moment";
import {TeacherApiCall, SaServiceApiCall} from "../../api";

export type CalendarInput = {
  startDate: string;
  endDate: string;
  currentCategory: any;
  teacherId: string;
  gradeId: any;
  sectionId: any;
  catagoryId: any;
  classId: number;
  userId:number;
};

export type TimeTableInput = {
  startDate: string;
  endDate: string;
  currentCategory: any;
  teacherId: string;
  gradeId: any;
  sectionId: any;
  catagoryId: any;
  classId: string;
};

export const getEventsSuccess = (data: any) => ({
  type: dispatchType.calendar.getEvents,
  payload: data,
});

export const getAdminEventsSuccess = (data: any) => ({
  type: dispatchType.calendar.getAdminEvents,
  payload: data,
});

export const getFiltersSuccess = (data: any) => ({
  type: dispatchType.calendar.getFilters,
  payload: data,
});

export const getTimetableSuccess = (data: any) => ({
  type: dispatchType.calendar.getTimeTable,
  payload: data,
});

export const getEvents = (data: CalendarInput, staffId: number) => {
  return async (dispatch: any) => {
    let allData: { events: any; minTime: number; maxTime: number };
    allData = {
      events: [],
      minTime: -1,
      maxTime: -1,
    };
    
    //  if (!!data?.currentCategory?.length && (data?.currentCategory?.includes(4) || data?.currentCategory?.includes(5) || data?.currentCategory?.includes(6))) {
      const teacherCategoryId = data?.currentCategory?.filter(
        (id: number) => id === 4 || id === 5 || id === 6);
      const teacherResponse = await TeacherApiCall.get(
          URLS.CALENDAR.GET_EVENTS +
          `?startDate=${data.startDate}&endDate=${
            data.endDate
        }&categoryId=${teacherCategoryId.length > 0 ? teacherCategoryId : 0}&userId=${
            data.teacherId
        }
        &classId=${data?.classId !== 0 ? data?.classId : Number(data.classId)}`,
      );
      if (
        teacherResponse?.status === 200 &&
        teacherResponse?.data?.data?.calendarData?.length > 0
      ) {
        let responseData = teacherResponse?.data?.data?.calendarData;
        let temp;
        let min = Math.min(...responseData.map((item: any) => {
            temp = item.start.split("T");
            return temp[1].split(":")[0];
          })
        );
        let max = Math.max(
          ...responseData.map((item: any) => {
            temp = item.end.split("T");
            return temp[1].split(":")[0];
          })
        );
        max += 1;
        allData = {
          events: teacherResponse?.data?.data?.calendarData,
          minTime: min,
          maxTime: max
        };
      }
    // }
    if (data.currentCategory.includes(3)) {
      const adminResponse = await SaServiceApiCall.get(
          URLS.CALENDAR.GET_ADMIN_Events +
          `?startDate=${data.startDate}&endDate=${data.endDate}&categoryId=${[
            3,
          ]}`,
      );
      if (
        adminResponse?.status === 200 &&
        adminResponse?.data?.data?.calendarData?.length > 0
      ) {
        const responseData = adminResponse?.data?.data?.calendarData;
        let temp;
        let min = Math.min(
          ...responseData.map((item: any) => {
            temp = item.start.split("T");
            return temp[1].split(":")[0];
          })
        );
        let max = Math.max(
          ...responseData.map((item: any) => {
            temp = item.end.split("T");
            return temp[1].split(":")[0];
          })
        );
        max += 1;
        allData = {
          events: [
            ...allData.events,
            ...adminResponse?.data?.data?.calendarData,
          ],
          minTime:
            allData.minTime === -1
              ? min
              : allData.minTime < min
              ? allData.minTime
              : min,
          maxTime:
            allData.maxTime === -1
              ? max
              : allData.maxTime > max
              ? allData.maxTime
              : max,
        };
      }
    }
    if (data.currentCategory.includes(2)) {
      const teacherExamResponse = await TeacherApiCall.get(
          URLS.CALENDAR.GET_TEACHER_EXAM +
          `?startDate=${data.startDate}&endDate=${data.endDate}&categoryId=${[
            2,
          ]}&userId=${staffId}`, // For exams we need to pass staffId(userRefId)  
      );
      if (
        teacherExamResponse?.status === 200 &&
        teacherExamResponse?.data?.data?.length > 0
      ) {
        const responseData = teacherExamResponse.data.data;
        let temp;
        let min = Math.min(
          ...responseData.map((item: any) => {
            temp = item.start.split("T");
            return temp[1].split(":")[0];
          })
        );
        let max = Math.max(
          ...responseData.map((item: any) => {
            temp = item.end.split("T");
            return temp[1].split(":")[0];
          })
        );
        max += 1;
        allData = {
          events: [...allData.events, ...teacherExamResponse.data.data],
          minTime:
            allData.minTime === -1
              ? min
              : allData.minTime < min
              ? allData.minTime
              : min,
          maxTime:
            allData.maxTime === -1
              ? max
              : allData.maxTime > max
              ? allData.maxTime
              : max,
        };
      }
    }
    if (!!data?.currentCategory?.length && data?.currentCategory?.includes(1)) {
      const teacherEventResponse = await TeacherApiCall.get(
          URLS.CALENDAR.GET_TEACHER_EVENT +
          `?startDate=${data.startDate}&endDate=${data.endDate}&categoryId=${[
            1,
          ]}&userId=${data.teacherId}`,
      );
      if (
        teacherEventResponse?.status === 200 &&
        teacherEventResponse?.data?.data?.length > 0
      ) {
        const responseData = teacherEventResponse?.data?.data;
        let temp;
        let min = Math.min(
          ...responseData.map((item: any) => {
            temp = item.start.split("T");
            return temp[1].split(":")[0];
          })
        );
        let max = Math.max(
          ...responseData.map((item: any) => {
            temp = item.end.split("T");
            return temp[1].split(":")[0];
          })
        );
        max += 1;
        allData = {
          events: [...allData.events, ...teacherEventResponse.data.data],
          minTime:
            allData.minTime === -1
              ? min
              : allData.minTime < min
              ? allData.minTime
              : min,
          maxTime:
            allData.maxTime === -1
              ? max
              : allData.maxTime > max
              ? allData.maxTime
              : max,
        };
      }
    }
    dispatch(getEventsSuccess(allData));
  };
};

export const getAdminEvents = (data: CalendarInput) => {
  return (dispatch: any) => {
    if (data?.gradeId === "") {
      if (data?.catagoryId?.length != 0 && !data?.catagoryId?.includes(4)) {
        SaServiceApiCall
          .get(
              URLS.CALENDAR.GET_ADMIN_Events +
              `?startDate=${data.startDate}&endDate=${data.endDate}&categoryId=${data?.catagoryId}`,
          )
          .then((response) => {
            if (
              response?.status === 200 &&
              !!response?.data?.data?.calendarData?.length
            ) {
              let responseData = response?.data?.data?.calendarData;
              let temp;
              let min = Math.min(
                ...responseData.map((item: any) => {
                  temp = item.start.split("T");
                  return temp[1].split(":")[0];
                })
              );
              let max = Math.max(
                ...responseData.map((item: any) => {
                  temp = item.end.split("T");
                  return temp[1].split(":")[0];
                })
              );
              max += 1;
              dispatch(
                getEventsSuccess({
                  events: response?.data?.data?.calendarData,
                  minTime: min,
                  maxTime: max,
                })
              );
            } else {
              dispatch(
                getEventsSuccess({
                  events: [],
                  minTime: 9,
                  maxTime: 16,
                })
              );
            }
          });
      } else if (data?.catagoryId?.length === 0) {
        SaServiceApiCall
          .get(
              URLS.CALENDAR.GET_ADMIN_Events +
              `?startDate=${data.startDate}&endDate=${
                data.endDate
              }&categoryId=${0}`,
          )
          .then((response) => {
            if (
              response?.status === 200 &&
              !!response?.data?.data?.calendarData?.length
            ) {
              let responseData = response?.data?.data?.calendarData;
              let temp;
              let min = Math.min(
                ...responseData.map((item: any) => {
                  temp = item.start.split("T");
                  return temp[1].split(":")[0];
                })
              );
              let max = Math.max(
                ...responseData.map((item: any) => {
                  temp = item.end.split("T");
                  return temp[1].split(":")[0];
                })
              );
              max += 1;
              dispatch(
                getEventsSuccess({
                  events: response?.data?.data?.calendarData,
                  minTime: min,
                  maxTime: max,
                })
              );
            } else {
              dispatch(
                getEventsSuccess({
                  events: [],
                  minTime: 9,
                  maxTime: 16,
                })
              );
            }
          });
      } else {
        let arr = data?.catagoryId?.filter(function (item: any) {
          return item !== 4;
        });
        SaServiceApiCall
          .get(
              URLS.CALENDAR.GET_ADMIN_Events +
              `?startDate=${data.startDate}&endDate=${data.endDate}${
                arr.length != 0 ? `&categoryId=${arr}` : `&categoryId=${0}`
              }`,
          )
          .then((response) => {
            if (response?.status === 200 && !!response?.data?.data?.calendarData?.length) {
              let responseData = response?.data?.data?.calendarData;
              let temp;
              let min = Math.min(
                ...responseData.map((item: any) => {
                  temp = item.start.split("T");
                  return temp[1].split(":")[0];
                })
              );
              let max = Math.max(
                ...responseData.map((item: any) => {
                  temp = item.end.split("T");
                  return temp[1].split(":")[0];
                })
              );
              max += 1;
              dispatch(
                getEventsSuccess({
                  events: response?.data?.data?.calendarData,
                  minTime: min,
                  maxTime: max,
                })
              );
            } else {
              dispatch(
                getEventsSuccess({
                  events: [],
                  minTime: 9,
                  maxTime: 16,
                })
              );
            }
          });
      }
    } else {
      SaServiceApiCall
        .get(
            URLS.CALENDAR.GET_ADMIN_Events +
            `?startDate=${data.startDate}&endDate=${data.endDate}${
              data?.gradeId === ""
                ? `&categoryId=${0}`
                : data.catagoryId === ""
                ? `&categoryId=${0}`
                : data.catagoryId != ""
                ? `&categoryId=${data.catagoryId}`
                : `&categoryId=${0}`
            }${data?.gradeId !== "" ? `&gradeId=${data?.gradeId}` : ""}${
              data?.sectionId !== "" ? `&sectionId=${data?.sectionId}` : ""
            }`,
        )
        .then((response) => {
          if (
            response?.status === 200 &&
            !!response?.data?.data?.calendarData?.length
          ) {
            let responseData = response?.data?.data?.calendarData;
            let temp;
            let min = Math.min(
              ...responseData.map((item: any) => {
                temp = item.start.split("T");
                return temp[1].split(":")[0];
              })
            );
            let max = Math.max(
              ...responseData.map((item: any) => {
                temp = item.end.split("T");
                return temp[1].split(":")[0];
              })
            );
            max += 1;
            dispatch(
              getEventsSuccess({
                events: response?.data?.data?.calendarData,
                minTime: min,
                maxTime: max,
              })
            );
          } else {
            dispatch(
              getEventsSuccess({
                events: [],
                minTime: 9,
                maxTime: 16,
              })
            );
          }
        });
    }
  };
};

const getWeekDays = (dateString: any = new Date()) => {
  let currData = moment(dateString);
  const currentData = currData.startOf("week").toDate();
  return [
    currData.startOf("week").toDate(),
    new Date(currentData.setDate(currentData.getDate() + 1)),
    new Date(currentData.setDate(currentData.getDate() + 1)),
    new Date(currentData.setDate(currentData.getDate() + 1)),
    new Date(currentData.setDate(currentData.getDate() + 1)),
    new Date(currentData.setDate(currentData.getDate() + 1)),
    currData.endOf("week").toDate(),
  ];
};

export const getTimetable = (data: TimeTableInput) => {
  return (dispatch: any) => {
    TeacherApiCall
      .get(
          URLS.CALENDAR.GET_EVENTS +
          `?startDate=${data.startDate}&endDate=${
            data.endDate
          }&categoryId=4&userId=${data.teacherId}&classId=${
            data.classId
          }&isTimeTable=1`,
      )
      .then((response: any) => {
        if (response?.status === 200) {
          const getWeekCollection = getWeekDays();
          const dateMapper: any = {};
          getWeekCollection.forEach((data, index) => {
            dateMapper[index] = moment(data).format("YYYY-MM-DD");
          });
          let responseData = response?.data?.data?.calendarData;
          let classTeacherData = responseData.data.teacherData;
          let temp;
          let min = Math.min(
            ...responseData.map((item: any) => {
              temp = item.start.split("T");
              return temp[1].split(":")[0];
            })
          );
          let max = Math.max(
            ...responseData.map((item: any) => {
              temp = item.end.split("T");
              return temp[1].split(":")[0];
            })
          );
          max += 1;

          let timeTable = responseData.map((timetableData: any) => {
            // let startDate: string = new Date(timetableData.start).toJSON();

            let startDate = timetableData.start;
            startDate =
              dateMapper[timetableData.dayNumber] +
              "T" +
              startDate.split("T")[1];
            //  console.log('start date', startDate);
            timetableData["start"] = startDate;
            let endDate = new Date(timetableData.end).toJSON();
            endDate =
              dateMapper[timetableData.dayNumber] + "T" + endDate.split("T")[1];
            timetableData["end"] = endDate;
            console.log("end date", endDate);
            return timetableData;
          });
          console.log("Time table ", timeTable);
          dispatch(
            getTimetableSuccess({
              events: timeTable,
              minTime: min,
              maxTime: max,
              classTeacher: {
                classTeacherFirstname: classTeacherData?.firstName || "",
                classTeacherLastname: classTeacherData?.lastName || "",
                profileImagePath: classTeacherData?.profilePhoto || "",
              },
            })
          );
        }
      })
      .catch((error: any) => {
        console.log("Error", error);
      });
  };
};

export const getFilters = (userRoleId: number) => {
  return (dispatch: any) => {
    TeacherApiCall
      .get(
          URLS.CALENDAR.GET_FILTERS +
          `?userRoleId=${userRoleId}`,
      )
      .then((response: any) => {
        if (response?.status === 200) {
          dispatch(
            getFiltersSuccess({
              filters: response.data.data,
            })
          );
        }
      });
  };
};

export const reload = (status?: boolean) => {
  return {
    type: dispatchType.calendar.reloadList,
    payload: !!status,
  };
};
