import axios from "axios";
import fileDownload from "js-file-download";
import { defaultPrivileges, adminPrivileges, privDomainIds } from "../constants";

export const hexToRgbA = (hex: any) => {
  var c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",1)"
    );
  }
  throw new Error("Bad Hex");
};

export const paddingNumber = (d: number) => {
  return d < 10 ? Number("0" + d.toString()) : Number(d.toString());
};

export const randomIntFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const fileToBase64 = (file: any, cb: any) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(null, reader.result);
  };
  reader.onerror = function (error) {
    cb(error, null);
  };
};

export const reorder = <T>(
  list: T[],
  startIndex: number,
  endIndex: number
): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const loadPrivileges = async (data: any) => {
  if (data?.UserRoleID == 1 && data?.isSuperAdmin) {
    return adminPrivileges;
  } else if (data?.UserRoleID == 1) {
    let privileges = defaultPrivileges;
    let school = data?.privilages?.filter((o: any) => o?.domainId == privDomainIds?.school);
    let user = data?.privilages?.filter((o: any) => o?.domainId == privDomainIds?.user);
    let calendar = data?.privilages?.filter((o: any) => o?.domainId == privDomainIds?.calendar);
    let curriculum = data?.privilages?.filter((o: any) => o?.domainId == privDomainIds?.curriculum);
    let timetable = data?.privilages?.filter((o: any) => o?.domainId == privDomainIds?.timetable);
    let admin = data?.privilages?.filter((o: any) => o?.domainId == privDomainIds?.admin);
    let feeManagement = data?.privilages?.filter((o:any) => o.domainId == privDomainIds.feeManagement);
    let admissionManagement = data?.privilages?.filter((o: any) => o.domainId == privDomainIds.admissionManagement);
    let feeCollection = data?.privilages?.filter((o:any) => o.domainId == privDomainIds.feeCollection);
    let gradeBook = data?.privilages?.filter((o:any) => o.domainId == privDomainIds.gradeBook);
    let report360 = data?.privilages?.filter((o:any) => o.domainId == privDomainIds.report360);
    let supportTickets = data?.privilages?.filter((o:any) => o.domainId == privDomainIds.supportTickets);
    let noticeBoard = data?.privilages?.filter((o:any) => o.domainId == privDomainIds.noticeBoard);
    let topVault = data?.privilages?.filter((o:any) => o.domainId == privDomainIds.topVault);
    let leaveRequest = data?.privilages?.filter((o:any) => o?.domainId == privDomainIds?.leaveRequest);
    let lessonPlan =data?.privilages?.filter((o:any) => o?.domainId == privDomainIds?.lessonPlan);
    let qpApproval =data?.privilages?.filter((o:any) => o?.domainId == privDomainIds?.qpApproval);
    let visitor =data?.privilages?.filter((o:any) => o?.domainId == privDomainIds?.visitorRegistor);
    let Downloadable = data?.privilages?.filter((o:any) => o?.domainId == privDomainIds?.downloadableReport);

    privileges.school.canEdit = school[0]?.isEditable || false;
    privileges.school.canView = school[0]?.isViewable || school[0]?.isEditable || false;
    privileges.user.canEdit = user[0]?.isEditable || false;
    privileges.user.canView = user[0]?.isViewable || user[0]?.isEditable || false;
    privileges.calendar.canEdit = calendar[0]?.isEditable || false;
    privileges.calendar.canView = calendar[0]?.isViewable || calendar[0]?.isEditable || false;
    privileges.curriculum.canEdit = curriculum[0]?.isEditable || false;
    privileges.curriculum.canView = curriculum[0]?.isViewable || curriculum[0]?.isEditable || false;
    privileges.curriculum.canApprove = curriculum[0]?.isApproval || false;
    privileges.timetable.canEdit = timetable[0]?.isEditable || false;
    privileges.timetable.canView = timetable[0]?.isViewable || timetable[0]?.isEditable || false;
    privileges.admin.canEdit = admin[0]?.isEditable || false;
    privileges.admin.canView = admin[0]?.isViewable || admin[0]?.isEditable || false;
    privileges.feeManagement.canEdit = feeManagement[0]?.isEditable || false;
    privileges.feeManagement.canView = feeManagement[0]?.isViewable || feeManagement[0]?.isEditable || false;
    privileges.feeManagement.canApprove = feeManagement[0]?.isApproval || false;
    privileges.feeCollection.canEdit = feeCollection[0]?.isEditable || false;
    privileges.feeCollection.canView = feeCollection[0]?.isViewable || feeCollection[0]?.isEditable || false;
    privileges.gradeBook.canEdit = gradeBook[0]?.isEditable || false;
    privileges.gradeBook.canView = gradeBook[0]?.isViewable || gradeBook[0]?.isEditable || false;
    privileges.report360.canEdit = report360[0]?.isEditable || false;
    privileges.report360.canView = report360[0]?.isViewable || report360[0]?.isEditable || false;
    privileges.report360.canPrint = report360[0]?.isPrintable || false;
    privileges.supportTickets.canApprove = supportTickets[0]?.isApproval || false;
    privileges.supportTickets.canView = supportTickets[0]?.isViewable || supportTickets[0]?.isApproval || false;
    privileges.leaveRequest.canApprove = leaveRequest[0]?.isApproval || false;
    privileges.leaveRequest.canView = leaveRequest[0]?.isViewable || leaveRequest[0]?.isApproval || false;
    privileges.lessonPlan.canApprove = lessonPlan[0]?.isApproval || false;
    privileges.lessonPlan.canView = lessonPlan[0]?.isViewable || lessonPlan[0]?.isApproval || false;
    privileges.noticeBoard.canView = noticeBoard[0]?.isViewable || noticeBoard[0]?.isEditable || false;
    privileges.noticeBoard.canEdit = noticeBoard[0]?.isEditable || false;
    privileges.admissionManagement.canEdit = admissionManagement[0]?.isEditable || false;
    privileges.admissionManagement.canView = admissionManagement[0]?.isViewable || admissionManagement[0]?.isEditable || false;
    privileges.topVault.canEdit = topVault[0]?.isEditable || false;
    privileges.topVault.canView = topVault[0]?.isViewable || topVault[0]?.isEditable || false;
    privileges.qpApproval.canView = qpApproval[0]?.isViewable || false;
    privileges.qpApproval.canEdit = qpApproval[0]?.isEditable || false;
    privileges.qpApproval.canApprove = qpApproval[0]?.isApproval || false;
    privileges.visitor.canEdit = visitor [0]?.isEditable || false;
    privileges.visitor.canView = visitor[0]?.isViewable || visitor[0]?.isEditable || false;
    privileges.downloadableReport.canView = Downloadable[0]?.isViewable || false;
    return privileges;
  } else {
    return null;
  }
}

export const handleFileDownload = (url: string, filename: string) => {
  axios.get(url, {
    responseType: 'blob',
  })
  .then((res) => {
    fileDownload(res.data, filename)
  })
}

export const getColorIndex = (index : number)=> {
  return index < 6 ? index : index % 10 < 6 ? index % 10 : index % 10 === 0 ? 0 : Math.abs((index % 10) - 6) 
}

export function timeDifference(
  date: number | string | Date,
  options: {
    justNowDuration?: number;
  } = {},
) {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;
  const current = Date.now();
  const elapsed = current - new Date(date).getTime();

  if (elapsed < msPerMinute) {
    // return options.justNowDuration && options.justNowDuration > elapsed
    //   ? 'just now'
    //   : `few seconds ago`;
    return `few seconds ago`
  }

  if (elapsed < msPerHour) {
    return `${Math.round(elapsed / msPerMinute)} minutes ago`;
  }

  if (elapsed < msPerDay) {
    return `${Math.round(elapsed / msPerHour)} hours ago`;
  }

  if (elapsed < msPerMonth) {
    return `${Math.round(elapsed / msPerDay)} days ago`;
  }

  if (elapsed < msPerYear) {
    return `${Math.round(elapsed / msPerMonth)} months ago`;
  }

  return `${Math.round(elapsed / msPerYear)} years ago`;
}
export function removeDuplicateIds(idKeyName : string , arr: any): any {
  return [...new Map(arr?.map((obj : any) => [obj[idKeyName], obj]))?.values()];
}
export const  stringify=(data:any)=> {
  try {
    return JSON.stringify(data);
  } catch (e) {
    console.log(e);
    return "";
  }
}

export const parse=(data:any)=> {
  try {
    return JSON.parse(data);
  } catch (e) {
    console.log(e);
    return null;
  }
}
export const stringWithoutHtml = (input:string) => {
  return input?.replace(/<[^>]*>/g, '');
}

export const removeSpaceFromString = (characters: string) => {
  if(!characters.includes("ql-formula")){
    const tagArray = characters?.split(/<\/[a-zA-Z]+>/);
    const completeTagArray: string[] = [];
    tagArray?.forEach((tag: string) => {
      if (String(tag)?.trim() !== '') {
        const closingTag = String(`</${tag?.match(/[a-zA-Z]+/)?.[0]}>`);
        const removeBreakTag: string = tag + closingTag
        completeTagArray.push(!removeBreakTag?.includes('<br>') ? (removeBreakTag) : '');
      }
    });
    return completeTagArray?.join('')
  }else{
    return characters
  }
}   

export const sendDataToReactNativeApp=(data:any)=>(window as any)?.ReactNativeWebView?.postMessage(data);

export const formatDate = (date:any) => {
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
  const day = date.getDate().toString().padStart(2, '0');
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  return `${day}${month}${year}`;
}
export const convertToCamelCase = (inputString: string) => {
  const words = inputString.split(' ');
  const camelCasedWords = words.map((word, index) =>
    index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
  );
  return camelCasedWords.join('');
}

export const convertUTCDateTimeToISO = (dateTime: any) => {
  var actualTime = new Date(dateTime);
  actualTime.setUTCHours(actualTime.getUTCHours() + 5);
  actualTime.setUTCMinutes(actualTime.getUTCMinutes() + 30);
  return actualTime.toISOString();
}

export const getUserFullName = (firstName: any, lastName: any) => {
  let fullName = '';
  
  if (firstName !== null) {
      fullName += firstName;
  }
  
  if (lastName !== null) {
      if (fullName !== '') {
          fullName += ' ';
      }
      fullName += lastName;
  }
  
  return fullName;
}

// convert text to ellipsis based on limit
export const getEllipsisText = (text:any, limit:number) => {
  return text ? text.length > limit ? text.slice(0, limit) + '...' : text: ''
}

// Check whether RTE field is empty or not
export const isRTEFieldEmpty = (data: any) => {
  return (/<p>\s*<\/p>|^<p><br><\/p>$/i?.test(data) || data === undefined || data === "")
}

export const dataTypeObj = {
  "Short Text(50 char)": 1,
  "Descriptive Text (100 char)": 2,
  "MCQ (Single Select)": 3,
  "Drop-down (Single Select)": 4,
  "Checkboxes (Multi Select)": 5,
  "Yes/No": 6,
  "Numeric": 7,
  "JPEG/PNG":8,
  "Date Picker": 9,
  "Email address": 10,
  "Phone Number": 11,
  "AadharNumber" : 12,
  "AdmissionNumber": 13,
  "Autofilled": 14,
  "Pincode": 15,
  "ContactNumber": 16,
  "Auto":17,
}
export const tab_status = {
  initial:"", // nothing to do with. It just initial state
  pending: "PENDING", // to trigger diffrerence
  review: "FOR_REVIEW", // to show modal for unsaved changes
  verified: "VERIFIED" // to go to next tab
  }
  //Default Field values for Predifined values in dynamic Form
const formatData = (arr: any) => arr.map((a: any) => a);
export const formMasterFields = {
  basicDetails_gender: formatData([
    { fieldValue: "Male", fieldLabel: "M" },
    { fieldValue: "Female", fieldLabel: "F" },
    { fieldValue: "Others", fieldLabel: "O" },
  ]),
  basicDetails_quota: formatData([
    { fieldValue: "General", fieldLabel: "General" },
    { fieldValue: "SC", fieldLabel: "SC" },
    { fieldValue: "ST", fieldLabel: "ST" },
    { fieldValue: "OBC", fieldLabel: "OBC" },
  ]),
  basicDetails_bloodGroup: formatData([
    { fieldValue: "", fieldLabel: "" },
    { fieldValue: "O+", fieldLabel: "O+" },
    { fieldValue: "O-", fieldLabel: "O-" },
    { fieldValue: "A+", fieldLabel: "A+" },
    { fieldValue: "A-", fieldLabel: "A-" },
    { fieldValue: "B+", fieldLabel: "B+" },
    { fieldValue: "B-", fieldLabel: "B-" },
    { fieldValue: "AB+", fieldLabel: "AB+" },
    { fieldValue: "AB-", fieldLabel: "AB-" },
  ]),
  guardianDetails_guardianRelation: formatData([
    { fieldValue: "Father", fieldLabel: "Father" },
    { fieldValue: "Mother", fieldLabel: "Mother" },
    { fieldValue: "Others", fieldLabel: "Others" },
  ]),
  siblingDetails_grade: [],
};

export const convertObjAndRemoveHypenV1 = (data:any) =>{
  const dataArray:any[] = [];
  const suffixMap :any = {
    "0":0,
    "-1":1,
    "-2":2
  }
  for(const key in data){
    const index = key.lastIndexOf('-');
    const baseKey = index != -1 ? key.substring(0,index) : key;
    const suffix = index !== -1 ? key.substring(index) : '0';
    if(dataArray[suffixMap[suffix]]){
      dataArray[suffixMap[suffix]][baseKey] = data[key];
    }else{
      const obj:any = {};
      obj[baseKey] = data[key];
      dataArray.push(obj);
    }
  }
  return dataArray;
}
// Converting Object to array without hyphen for the student creation payload 
export const convertObjAndRemoveHyphen = (data: string) => {
  const dataArray = [];
  let currentGroup = {};
  let currentSuffix = '';

  for (const key in data) {
    const index = key.lastIndexOf('-');
    const baseKey = index !== -1 ? key.substring(0, index) : key;
    const suffix = index !== -1 ? key.substring(index) : '';

    if (currentSuffix !== suffix) {
      if (Object.keys(currentGroup).length !== 0) {
        const newObj = {};
        for (const k in currentGroup) {
          const idx = k.lastIndexOf('-');
          const base = idx !== -1 ? k.substring(0, idx) : k;
          newObj[base] = currentGroup[k];
        }
        dataArray.push(newObj);
        currentGroup = {};
      }
      currentSuffix = suffix;
    }

    currentGroup[key] = data[key];
  }

  if (Object.keys(currentGroup).length !== 0) {
    const newObj = {};
    for (const k in currentGroup) {
      const idx = k.lastIndexOf('-');
      const base = idx !== -1 ? k.substring(0, idx) : k;
      newObj[base] = currentGroup[k];
    }
    dataArray.push(newObj);
  }
  return dataArray;
}
export const gradeBookTemplateTermNameValidation = new RegExp("^[a-zA-Z0-9!@#$%^&*()-=_+ ]{1,10}$");
export const grdBkTmpTermMrksValidation = new RegExp("^(?:[1-9]|[1-9][0-9]|1[0-2][0-9]|130)$");
export const grdBkTmpTestTypeDisplayNameValidation = new RegExp("^[a-zA-Z0-9!@#$%^&*()-=_.+ ]{1,26}$")
// export const grdBkTmpSubjectDisplayNameValidation = new RegExp("^[a-zA-Z0-9!@#$%^&*()-=_.+ ]{1,21}$") // commented due to unsupported for Marathi lang
export const grdBkTmpSubjectDisplayNameValidation = new RegExp("^[a-zA-Z0-9\u0900-\u097F!@#$%^&*()\\-=_.+ ]{1,21}$");
export const grdBkTmpActivityValidation = new RegExp("^[a-zA-Z0-9!@#$%^&*()-=_.+ ]{1,25}$")


// Get the no of gradebook terms from the subject
interface TermObject {
  term: string;
}

export const getGBTerms = (subjects: any[]) => {
  let uniqueTermsArray: string[] = [];
  // Loop through each subject
  subjects?.forEach(subject => {
      subject.terms.sort((a:any, b:any) => a.termId - b.termId);
      // Loop through each term in the subject's terms array
      subject.terms.forEach((termObj: TermObject) => {
          // Add the term to the uniqueTerms set
          uniqueTermsArray.push(termObj.term);
      });
  });
  return uniqueTermsArray;
}

// Function to check for AWS signed URL or not
export const isSignedURL = (url) => {
  // Regular expression to check for AWS signed URL parameters
  const awsSignedURLRegex = /(X-Amz-Algorithm|X-Amz-Credential|X-Amz-Date|X-Amz-Expires|X-Amz-Signature|X-Amz-SignedHeaders)=/i;
  
  // Check if the URL contains any AWS signed URL parameters
  return awsSignedURLRegex.test(url);
}

// GradeBook Instruction Helper
// HTML Content Processor for Line Count
export const processHTML = (html: any, allowableLines: number) => {
  // Create a temporary div to hold the HTML
  const div = document.createElement('div');
  div.innerHTML = html;

  // Extract text considering each <li> and <p> as new lines
  let textLines = [];
  div.querySelectorAll('li, p').forEach(element => {
    const textContent = element?.textContent?.replace(/<[^>]*>/g, '') || '';
    textLines.push(textContent.trim());
  });
  // Check if there are more than allowable lines
  return textLines.length >= allowableLines
        ? { status: false, count: textLines.length, message: `Exceeds the limit of ${allowableLines} lines.` }
        : { status: true, count: textLines.length, message: '' };
}

