import Logo from "../Assets/logo.png"
export const applyWaterMark = async (bookId) => {
    // let response = await API("get", `/api-library/library/user-books/${bookId}`);
    // let userId = response.accessIdentifier;
    let getArea = document.getElementById("area");
    let waterMark1 = await createWaterMark();
    let waterMark2 = await createWaterMark();
    var isDualPage = localStorage.getItem("isDualPage");
    console.log('isDualPage: ', isDualPage);
    // localStorage.setItem("watermark", userId);
    if (isDualPage) {
        addElementToLeft(waterMark1);
        addElementToRight(waterMark2);
    } else {
        addElementToLeft(waterMark1);
    }
};

const createWaterMark = () => {
    let container = document.createElement("div");
    let img = document.createElement("img");
    img.src = Logo;
    img.style.height="150px"
    img.style.width="150px"
    container.id = "waterMarkBySam";
    container.style.position = "absolute";
    container.style.left = "35%";
    container.style.top = "40%";
    container.style.display = "flex";
    container.style.justifyContent = "center";
    container.style.alignItems = "center";
    container.style.transform = "scale(3) ";
    // container.style.opacity = "1";
    container.style.fontWeight = "bold";
    container.style.userSelect = "none";
    container.style.zIndex = "1";
    container.appendChild(img)
    // container.style.backgroundImage=
    // container.innerHTML = textToWatermark;
    return container;
}


async function addElementToLeft(element) {
    let getLeftPage = document.getElementById("leftPage");
    if(!document.querySelector("#leftPage > #waterMarkBySam")){
        getLeftPage.appendChild(element);
    }
}
async function addElementToRight(element) {
    let getRightPage = document.getElementById("rightPage");
    if(!document.querySelector("#rightPage > #waterMarkBySam")){
        getRightPage.appendChild(element);
    }
}