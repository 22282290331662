import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { loadState } from "../localStorage";
import thunk from "redux-thunk";

import RootReducer from "./reducers/index";
const persistedState = loadState();

const store = createStore(
  RootReducer,
  persistedState,
  composeWithDevTools(applyMiddleware(thunk))
);

export type RootStore = ReturnType<typeof RootReducer>;

export default store;
