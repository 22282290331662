import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import AppStateProvider from "./Context/AppStateProvider";
import containers from "./Context/state";
import "./index.css";
import DigiBookReader from "./DigiBookReader";

const defaultControls = {dualPage:true,
  fullScreen:true,
  zoomInOut:true,
  print:true,
  jumpToPage:true,
  onlySinglePage:false
};
function App({bookUrl,pdfContainerStyling={},controlsConfig={}}) {
  const controlConfigUpdate = {...defaultControls,...controlsConfig}
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1090);

  return (
    <div id="pdf-viewer-container">
      <AppStateProvider containers={containers}>
        <DigiBookReader
          bookUrl={bookUrl}
          isMobileView={isMobileView}
          pdfContainerStyling={pdfContainerStyling}
          controlsConfig={controlConfigUpdate}
        />
      </AppStateProvider>
    </div>
  );
}
class PDFReaderElement extends HTMLElement {
  connectedCallback() {
    ReactDOM.render(<App />, this);
  }
}

customElements.define("pdfreader-element", PDFReaderElement);

export default App;
