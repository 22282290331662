import { useState } from "react";

const useAssetState = () => {
    const [docAsset, setdocAsset] = useState([]);
    const [audioAsset, setAudioAsset] = useState([]);
    const [videoAsset, setVideoAsset] = useState([]);
    const [imageAsset, setImageAsset] = useState([]);
    const [mcqAsset, setMcqAsset] = useState([]);
    const [trueFalseAsset, setTrueFalseAsset] = useState([]);
    const [fillBlankAsset, setFillBlankAsset] = useState([]);
    const [matchFollowingAsset, setMatchFollowingAsset] = useState([]);
    const [gameAsset, setGameAsset] = useState([]);
    return {
        docAsset,
        setdocAsset,
        audioAsset,
        setAudioAsset,
        videoAsset,
        setVideoAsset,
        imageAsset,
        setImageAsset,
        mcqAsset,
        setMcqAsset,
        trueFalseAsset,
        setTrueFalseAsset,
        fillBlankAsset,
        setFillBlankAsset,
        matchFollowingAsset,
        setMatchFollowingAsset,
        gameAsset,
        setGameAsset
    };
}

export default useAssetState