import { dispatchType as TYPES } from "../dispatchTypes";

export type Action = {
  type: string;
  payload?: any;
};

export type TenantReducer = {
  IS_ONLINE: any;
  contentCategoryGroupId: number;
  instituteName: string;
  statusId: number;
  updatedBy: string;
  updatedOn: string;
  instituteShortCode: string;
  tenantId: string;
  SubDomain: string;
  maintenance:any;
  isOrgSuperAdmin: boolean
};

const initstate = {
  contentCategoryGroupId: 0,
  instituteName: "",
  statusId: 0,
  updatedBy: "",
  updatedOn: "",
  instituteShortCode: "",
  tenantId: "",
  SubDomain: "",
  maintenance:[],
  isOrgSuperAdmin: null,
};

const tenantReducer = (state = initstate, action: Action) => {
  switch (action.type) {
    case TYPES.tenant.getTenant: {
      return {
        ...state,
        ...(action.payload || {}),
      };
    }
    case TYPES.revoke:
      return initstate;

    default:
      return state;
  }
};

export default tenantReducer;
