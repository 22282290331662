import logo from "../../../assets/img/teacherLoginLogo.png";
import superAdminLogo from "../../../assets/img/eSenseLogoSuperAdmin.svg"
import { Link } from "react-router-dom";
import { userRoleId } from "../../../shared/constants";

const Logo = (roleId:any) => {
  return (
    <Link
      to="/"
      className="pt-2 loginLogo">
      <img src={roleId.userRoleId === userRoleId.superadminId ? superAdminLogo : logo} alt="" />
      {/* <span>Top</span>
      <br />
      <p></p>
      <span>School</span> */}
      
    </Link>
  );
};
export default Logo;
