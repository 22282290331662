export const transcriptions = {
    loadingTitle: {
        en: "Loading",
        hi: "डाउनलोड हो रहा है",
        gu: "ડાઉનલોડ કરી રહ્યું છે",
        mr: "डाउनलोड करत आहे",
    },
    loadingMessage: {
        en: "It will take few seconds",
        hi: "इसमें कुछ सेकंड लगेंगे",
        gu: "તે થોડી સેકંડ લેશે",
        mr: "यास काही सेकंद लागतील",
    },
    header: {
        Tooltip: {
            highlight: {
                en: "Highlight",
                hi: "Highlight",
                gu: "Highlight",
                mr: "Highlight",
            },
            pen: {
                en: "Pen",
                hi: "Pen",
                gu: "Pen",
                mr: "Pen",
            },
            stickyNote: {
                en: "Sticky-Note",
                hi: "Sticky-Note",
                gu: "Sticky-Note",
                mr: "Sticky-Note",
            },
            showActivities: {
                en: "My Activities",
                hi: "My Activities",
                gu: "My Activities",
                mr: "My Activities"
            },
            showPages: {
                en: "Show Pages",
                hi: "Show Pages",
                gu: "Show Pages",
                mr: "Show Pages"
            },
            zoomInOut: {
                en: "Zoom In/Out",
                hi: "Zoom In/Out",
                gu: "Zoom In/Out",
                mr: "Zoom In/Out"
            },
            zoomIn: {
                en: "Zoom in",
                hi: "Zoom in",
                gu: "Zoom in",
                mr: "Zoom in"
            },
            zoomOut: {
                en: "Zoom out",
                hi: "Zoom out",
                gu: "Zoom out",
                mr: "Zoom out"
            },
            fullScreen: {
                en: "Full Screen",
                hi: "Full Screen",
                gu: "Full Screen",
                mr: "Full Screen"
            },
            exitFullScreen: {
                en: "Minimize",
                hi: "Minimize",
                gu: "Minimize",
                mr: "Minimize"
            },
            singlePage: {
                en: "Single Page",
                hi: "Single Page",
                gu: "Single Page",
                mr: "Single Page"
            },
            dualPage: {
                en: "Dual Page",
                hi: "Dual Page",
                gu: "Dual Page",
                mr: "Dual Page"
            },
            toc: {
                en: "Table Of Contents",
                hi: "Table Of Contents",
                gu: "Table Of Contents",
                mr: "Table Of Contents"
            }

        }
    },
    reader: {
        Tooltip: {
            bookmark: {
                en: "Bookmark",
                hi: "Bookmark",
                gu: "Bookmark",
                mr: "Bookmark"
            },
            next: {
                en: "Next",
                hi: "Next",
                gu: "Next",
                mr: "Next"
            },
            prev: {
                en: "Previous",
                hi: "Previous",
                gu: "Previous",
                mr: "Previous"
            },
            close: {
                en: "Close",
                hi: "Close",
                gu: "Close",
                mr: "Close"
            }
        }
    },
    showActivitiesPopup: {
        tabs: {
            highlights: {
                en: "Highlights",
                hi: "Highlights",
                gu: "Highlights",
                mr: "Highlights"
            },
            stickyNotes: {
                en: "Stickynotes",
                hi: "Stickynotes",
                gu: "Stickynotes",
                mr: "Stickynotes"
            },
            bookmarks: {
                en: "Bookmarks",
                hi: "Bookmarks",
                gu: "Bookmarks",
                mr: "Bookmarks"
            },
            assets: {
                en: "Assets",
                hi: "Assets",
                gu: "Assets",
                mr: "Assets"
            },
            pens: {
                en: "Pen",
                hi: "Pen",
                gu: "Pen",
                mr: "Pen"
            }
        },
        highlightTab: {
            highlightedNotes: {
                en: "Highlighted Notes",
                gu: "Highlighted Notes",
                hi: "Highlighted Notes",
                mr: "Highlighted Notes"
            },
            noHighlightsAdded: {
                en: "No Highlights Marks Added",
                gu: "No Highlights Marks Added",
                hi: "No Highlights Marks Added",
                mr: "No Highlights Marks Added"
            },
        },
        stickyNoteTab: {
            stickyNotes: {
                en: "Stickynotes",
                hi: "Stickynotes",
                gu: "Stickynotes",
                mr: "Stickynotes"
            },
            noStickyNotesAdded: {
                en: "No Sticky Notes Marks Added",
                gu: "No Sticky Notes Marks Added",
                hi: "No Sticky Notes Marks Added",
                mr: "No Sticky Notes Marks Added"
            }
        },
        bookmarkTab: {
            bookmarks: {
                en: "Bookmarks",
                hi: "Bookmarks",
                gu: "Bookmarks",
                mr: "Bookmarks"
            },
            noBookMarkAdded: {
                en: "No Bookmarks Added",
                hi: "No Bookmarks Added",
                gu: "No Bookmarks Added",
                mr: "No Bookmarks Added"
            }
        },
        assetsTab: {
            assets: {
                en: "Assests",
                hi: "Assests",
                gu: "Assests",
                mr: "Assests"
            },
            noAssetAdded: {
                en: "No Assets Added",
                hi: "No Assets Added",
                gu: "No Assets Added",
                mr: "No Assets Added"
            }
        },
        penTab: {
            pens: {
                en: "Pen",
                hi: "Pen",
                gu: "Pen",
                mr: "Pen"
            },
            noPensAdded: {
                en: "No Pen Marks Added",
                hi: "No Pen Marks Added",
                gu: "No Pen Marks Added",
                mr: "No Pen Marks Added"
            }
        },
        tableColumn: {
            srNo: {
                en: "Sr.",
                hi: "Sr.",
                gu: "Sr.",
                mr: "Sr."
            },
            page: {
                en: "Page",
                hi: "Page",
                gu: "Page",
                mr: "Page"
            },
            note: {
                en: "Note",
                hi: "Note",
                gu: "Note",
                mr: "Note"
            },
            bookmarkedPages: {
                en: "Bookmarked Pages",
                gu: "Bookmarked Pages",
                hi: "Bookmarked Pages",
                mr: "Bookmarked Pages"
            },
            location: {
                en: "Location",
                hi: "Location",
                gu: "Location",
                mr: "Location"
            },
            go: {
                en: "Go",
                hi: "Go",
                gu: "Go",
                mr: "Go"
            },
            remove: {
                en: "Remove",
                hi: "Remove",
                gu: "Remove",
                mr: "Remove"
            },

        },
        nonContentPage: {
            en: "Non-content page",
            hi: "Non-content page",
            gu: "Non-content page",
            mr: "Non-content page"
        },
        pageNo: {
            en: "Page No:",
            hi: "Page No:",
            gu: "Page No:",
            mr: "Page No:"
        },
        removePopup: {
            deleteHighlight: {
                en: "Delete Highlight",
                gu: "Delete Highlight",
                hi: "Delete Highlight",
                mr: "Delete Highlight"
            },
            deleteHighlightDescription: {
                en: "Are you sure you want to delete this highlight?",
                hi: "Are you sure you want to delete this highlight?",
                gu: "Are you sure you want to delete this highlight?",
                mr: "Are you sure you want to delete this highlight?"
            },
            deleteBookmark: {
                en: "Delete Bookmark",
                gu: "Delete Bookmark",
                hi: "Delete Bookmark",
                mr: "Delete Bookmark"
            },
            deleteBookmarkDescription: {
                en: "Are you sure you want to delete this bookmark?",
                hi: "Are you sure you want to delete this bookmark?",
                gu: "Are you sure you want to delete this bookmark?",
                mr: "Are you sure you want to delete this bookmark?"
            },
            deleteStickyNote: {
                en: "Delete Sticky Note",
                hi: "Delete Sticky Note",
                gu: "Delete Sticky Note",
                mr: "Delete Sticky Note"
            },
            deleteStickyNoteDescription: {
                en: "Are you sure you want to delete this sticky note?",
                hi: "Are you sure you want to delete this sticky note?",
                gu: "Are you sure you want to delete this sticky note?",
                mr: "Are you sure you want to delete this sticky note?"
            },
            deletePen: {
                en: "Delete Pen",
                hi: "Delete Pen",
                gu: "Delete Pen",
                mr: "Delete Pen"
            },
            deletePenDescription: {
                en: "Are you sure you want to delete this Pen?",
                hi: "Are you sure you want to delete this Pen?",
                gu: "Are you sure you want to delete this Pen?",
                mr: "Are you sure you want to delete this Pen?"
            },
            yesBtn: {
                en: "Yes",
                hi: "Yes",
                gu: "Yes",
                mr: "Yes"
            },
            cancelBtn: {
                en: "Cancel",
                hi: "Cancel",
                gu: "Cancel",
                mr: "Cancel"
            }

        }
    },
    stickyNotePopup: {
        addNote: {
            en: "Add a Note",
            gu: "Add a Note",
            hi: "Add a Note",
            mr: "Add a Note"
        },
        addBtn: {
            en: "Add",
            gu: "Add",
            hi: "Add",
            mr: "Add"
        },
        deleteBtn: {
            en: "Delete",
            hi: "Delete",
            gu: "Delete",
            mr: "Delete"
        }

    },
    searchPopup: {
        pageNo: {
            en: "Page: ",
            gu: "Page: ",
            hi: "Page: ",
            mr: "Page: "
        },
        nonContentPage: {
            en: "Non Content Page",
            gu: "Non Content Page",
            hi: "Non Content Page",
            mr: "Non Content Page"
        },
        searchPlaceholder: {
            en: "Start Typing to Search in Book",
            gu: "Start Typing to Search in Book",
            hi: "Start Typing to Search in Book",
            mr: "Start Typing to Search in Book"
        },
        searching: {
            en: "Searching",
            gu: "Searching",
            hi: "Searching",
            mr: "Searching"
        },
        startSearching: {
            en: "Start Searching",
            gu: "Start Searching",
            hi: "Start Searching",
            mr: "Start Searching"
        },
        noResultFound: {
            en: "No Result Found",
            gu: "No Result Found",
            hi: "No Result Found",
            mr: "No Result Found"
        },


    },
    penToolBar: {
        palette: {
            en: "Palette",
            hi: "Palette",
            gu: "Palette",
            mr: "Palette",
        },
        thickness: {
            en: "Thickness",
            hi: "Thickness",
            gu: "Thickness",
            mr: "Thickness",
        },
        eraser: {
            en: "Eraser",
            hi: "Eraser",
            gu: "Eraser",
            mr: "Eraser",
        },
        undo: {
            en: "Undo",
            hi: "Undo",
            gu: "Undo",
            mr: "Undo",
        },
        clearAll: {
            en: "Clear All",
            hi: "Clear All",
            gu: "Clear All",
            mr: "Clear All",
        },
    }
}