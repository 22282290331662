import { dispatchType as TYPES } from "../dispatchTypes";
export type Action = {
  type: string;
  payload?: any;
};

const initstate = {
  events: [],
  filters: [],
  minTime: null,
  maxTime: null,
  classTeacher: null,
  reloadList: false,
};

export type CalendarReducer = {
  reloadList: any;
  events: any;
  filters: any;
  minTime: any;
  maxTime: any;
  classTeacher: any;
};

export interface ClassResourcesReducer {
  calendarId: number;
  userId: number;
  uploadData: {
    data: string;
    fileName: string;
  }[];
}

const calendarReducer = (state = initstate, action: Action) => {
  switch (action.type) {
    case TYPES.calendar.getEvents: {
      return {
        ...state,
        ...(action.payload || {}),
      };
    }
    case TYPES.calendar.getFilters: {
      return {
        ...state,
        ...(action.payload || {}),
      };
    }
    case TYPES.calendar.getTimeTable: {
      return {
        ...state,
        ...(action.payload || {}),
      };
    }

    case TYPES.calendar.reloadList: {
      return {
        ...state,
        reloadList: !!action.payload,
      };
    }

    case TYPES.revoke:
      return initstate;

    default:
      return state;
  }
};

export default calendarReducer;
