import axios from 'axios'

export const API = async (requestMethod, path, body) => {
  const baseURL = process.env.api_url.substring(0, process.env.api_url.length - 1);
  const config = {
    headers: {
      "Authorization": `Bearer ${localStorage.getItem("idToken")}`
    }
  }
  if (requestMethod === "get") {
    return await axios.get(baseURL + path, config)
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        return err.message
      })
  }
  if (requestMethod === "post") {
    return await axios.post(baseURL + path, body, config)
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        return err
      })
  }
  if (requestMethod === "put") {
    return await axios.put(baseURL + path, body, config)
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        return err.message
      })
  }
  if (requestMethod === "delete") {
    if (!body) {
      return await axios.delete(baseURL + path, config)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          return err.message
        })
    }
    else {
      return await axios.delete(baseURL + path, body, config)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          return err.message
        })
    }
  }
}