interface InitialStateInterfce {
    reset: Boolean
}

interface Action {
    type :string,
    payload : any
}

const  initialState:InitialStateInterfce ={
    reset : false
}

export const resetFilterReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case 'RESET_FILTER':
            return {
                ...state,
                reset: action.payload?.reset ?? false,
            };
        default:
            return state;
    }
};

