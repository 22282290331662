import { LibraryApiCall } from "../../api";
import { URLS } from "../../shared/constants";
import { dispatchType } from "../dispatchTypes";

export const publicationsList = (data: any) => ({
  type: dispatchType.publication,
  payload: data,
});

export const getPubilcationList = () => {
  return (dispatch: any) => {
    LibraryApiCall
      .get(URLS.PUBLICATION)
      .then(async (response: any) => {
        if ((response?.status === 200 || response?.status === 201) && response.data.data) {
          const list = response.data.data
          dispatch(publicationsList(list?.length ? { list: [...list] } : { list: [] }))
        } else {
          return {}
        }
      })
  };
}
