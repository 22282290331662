import { dispatchType as TYPES } from "../dispatchTypes";

export const SET_SNACKBAR = "teamly/settings/SET_SNACKBAR";

const initialState = {
  snackbarOpen: false,
  snackbarType: "success",
  snackbarMessage: "",
  isHtmlText: false,
};

export type Action = {
  type: string;
  payload?: any;
};

export type SnackBarReducer = {
  snackbarOpen: boolean;
  snackbarType: any;
  snackbarMessage: string;
  isHtmlText?: boolean;
};

const snackBarReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_SNACKBAR:
      const { snackbarOpen, snackbarMessage, snackbarType, isHtmlText } =
        action.payload;
      return {
        ...state,
        snackbarOpen,
        snackbarType,
        snackbarMessage,
        isHtmlText,
      };

    case TYPES.revoke:
      return initialState;

    default:
      return state;
  }
};

export default snackBarReducer;
