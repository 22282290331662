import React, { lazy } from "react";
import ReactDOM from "react-dom";

import "./index.css";
import "./assets/css/timeline.css";
import "./assets/css/style.css";
import "./assets/css/media.css";
import "../node_modules/react-quill/dist/quill.snow.css";
import throttle from "lodash/throttle";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import asyncPdfViewerExposed from "./components/PdfViewerExposed";
import { loadState, saveState } from "./localStorage";
import CustomSnackbar from "./shared/Snackbar/CustomSnackbar";
const restrictedSentryEnvs: any = ["qa", "staging", "nprd"];
store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 1000)
);

if (process.env.NODE_ENV === "production") {
  console.log = () => { return null };
  console.error = () => { return null };
  console.debug = () => { return null };
}

//Sentry integration
if (!restrictedSentryEnvs?.includes(process.env.REACT_APP_ENV)) {
  // Sentry.init({
  //   dsn: process.env.REACT_APP_SENTRY_DSN,
  //   integrations: [new Integrations.BrowserTracing()],
  //   tracesSampleRate: 1.0,
  //   beforeSend: (event) => {
  //     if (window.location.hostname === 'localhost') {
  //       return null;
  //     }
  //     return event;
  //   },
  //   ignoreErrors:[
  //     "ResizeObserver loop limit exceeded"
  //   ],
  //   release: process.env.REACT_APP_VERSION,
  //   environment: process.env.REACT_APP_ENV
  // });
}

ReactDOM.render(
  //<React.StrictMode>
  window?.location?.pathname === "/pdf-viewer" ?
    <BrowserRouter>
      <Switch>
        <Route exact path="/pdf-viewer" component={asyncPdfViewerExposed} />
      </Switch>
    </BrowserRouter> :
    <Provider store={store}>
      <CustomSnackbar />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>,
  //</React.StrictMode>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
