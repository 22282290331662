interface InitialStateInterfce {
    searchFilter: any
    publication: any
}

interface Action {
    type :string,
    payload : any
}

const  initialState:InitialStateInterfce ={
    searchFilter : {},
    publication : {},
}

export const viewAllSearchFilterReducer = (state =initialState, action: Action) => {
    if (action?.type) {
        let PayloadObj = {};
        if (action.payload && (action.payload.searchFilter || action.payload.publication)) {
            let Objs: any = {};

            if (action.payload.publication) {
                Objs = JSON.parse(action.payload.publication) || {};
            }
            if (Object.keys(Objs).length !== 0 || Objs.publication) {
                PayloadObj = {
                    ...action.payload.searchFilter,
                    publicationId: Objs?.publication || "",
                    categoryId: Objs?.searchCategory || "",
                    gradeId: Objs?.gradeValue || "",
                    courseId: Objs?.subjectValue || "",
                    chapterId: Objs?.chapterValue || "",
                    topicId: Objs?.topicValue || ""
                };
            } else {
                PayloadObj = {
                    ...action.payload.searchFilter
                };
            }
        }
        return {
            ...state,
            searchFilter: PayloadObj,
        };
    } else {
        return {
            ...state,
            searchFilter: action?.payload?.searchFilter || {},
        };
    }
}
