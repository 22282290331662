import { API } from "../utils/API";

export const bookDetailService = async (isAdmin, bookID) => {
  if (isAdmin) {
    const response = await API("get", `/api-books/admin/books/${bookID}`);
    return response;
  } else {
    const response = await API("get", `/api-books/books/${bookID}`);
    return response;
  }
};

export const fetchBookMarkService = async (bookId) => {
  const response = await API("post", `/api-books/books/${bookId}/bookmarks/fetch`);
  if (response.data) {
    return response.data;
  }
};
