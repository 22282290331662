import React, { useEffect, useState } from 'react';
import { transcriptions } from '../i18n/languages';
import { useAppState } from "../Context";
import dualScreenEnabled from "../Assets/dualPageMode.svg";
import ReactTooltip from 'react-tooltip';

const ToggleSinglePage = ({ }) => {
  const { currentPage, setCurrentPage, isDualPage, setIsDualPage, viewMode, setViewMode, isMobileView,totalPages } = useAppState("reader");
  const languagePrefered = localStorage.getItem("selectedLang") || "en";
  // const [checked, setChecked] = useState(true);
  const handleToogle = (e) => {
    if (!isDualPage) {
      if(currentPage == totalPages){
        setCurrentPage(currentPage - 1);
      }else if (currentPage % 2 !== 0 && currentPage !== 1) {
        setCurrentPage(currentPage - 1);
      }
    }
      if (isDualPage) {  
        setIsDualPage(false);
        setViewMode("single");
        localStorage.removeItem("isDualPage");
        localStorage.setItem("viewMode", "single");
  
      }
      else {
        setIsDualPage(true);
        setViewMode("dual");
        localStorage.setItem("isDualPage", true);
        localStorage.removeItem("viewMode");
      }
  }

  return (
    <div style={{display:"flex"}}>
                  {!isMobileView && <ReactTooltip />}
      <button id="tooglePageMode" data-tip={`${!isDualPage ? `${transcriptions.header.Tooltip.dualPage[languagePrefered]}` : `${transcriptions.header.Tooltip.singlePage[languagePrefered]}`}`} type="toggleButton" onClick={(e) => {
         handleToogle(e);
      }}>
        {
          !isDualPage ?<svg width="24" height="24" viewBox="0 0 24 24" fill="#00AAD3" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 4L13 21C13 21.55 13.45 22 14 22L20 22C20.55 22 21 21.55 21 21L21 4C21 3.45 20.55 3 20 3L14 3C13.45 3 13 3.45 13 4ZM3 4L3 21C3 21.55 3.45 22 4 22L10 22C10.55 22 11 21.55 11 21L11 4C11 3.45 10.55 3 10 3L4 3C3.45 3 3 3.45 3 4Z" fill="black" />
        </svg> :
          <svg width="24" height="24" viewBox="0 0 24 24" fill="#00AAD3" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 4L6 21C6 21.55 6.73125 22 7.625 22L17.375 22C18.2688 22 19 21.55 19 21L19 4C19 3.45 18.2688 3 17.375 3L7.625 3C6.73125 3 6 3.45 6 4Z" fill="black" />
          </svg>
        }

      </button>
    </div>
  )
}

export default ToggleSinglePage