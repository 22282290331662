import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { getTenantDetails } from "../../api/tenant";
import maintenanceimg from "../../assets/img/maintenance.jpg";
import { setSnackbar } from "../../redux/actions/loginActionTypes";
import { offlineLicenseDetails } from "../../redux/actions/offlineLicense";
import { tenantDetails } from "../../redux/actions/tenantActionTypes";
import { constants } from "../../shared/constants";
import logoText from "../../assets/img/teacherDashWithText.svg";
import "./MaintenaceScreen.css";
import { ReduxState } from "../../redux/reducers";

type Props = {
  maintenance: any;
};
const MaintenaceScreen: React.FC<Props> = ({ maintenance }) => {
  const isMobileView = useSelector((state: ReduxState) => state?.mobileMenuStatus?.isMobileView);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const host = window.location.host;
  const dispatch = useDispatch();

  const getTenantDetailsApi = async () => {
    const response = await getTenantDetails(host);
    if (!!response && response?.status == "200") {
      if (response?.data?.maintenance !== []) {
        dispatch(tenantDetails(response?.data));
        dispatch(offlineLicenseDetails({ licenseStatus: true }));
      }
    } else if (
      !!response &&
      response?.status == "401" &&
      !constants?.isOnline &&
      response?.data?.maintenance !== []
    ) {
      dispatch(offlineLicenseDetails({ licenseStatus: false }));
      dispatch(tenantDetails(response?.data));
    } else {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: response?.message || "Invalid Tenant ID",
          isHtmlText: true,
        })
      );
    }
  };

  useEffect(() => {
    maintenance?.length &&
      maintenance?.map((d: any) => {
        if (d?.ConfigKey === "MAINTENANCE_NAME") {
          setTitle(d?.Default);
        }
        if (d?.ConfigKey === "MAINTENANCE_ALERT") {
          setDescription(d?.Default);
        }
        if (d?.ConfigKey === "MAINTENANCE_DATEFROM") {
          let date: any = moment(d?.Default);
          setStartTime(date);
        }
        if (d?.ConfigKey === "MAINTENANCE_DATETO") {
          let date: any = moment(d?.Default);
          setEndTime(date);
        }
      });
    getTenantDetailsApi();
  }, []);
  return (
   !isMobileView ?(
    <div className="maintenanceScreen">
      <div className="col-12">
      <img src={logoText} className="logoImg" />
      </div>
      <div className="row m-0">
       
        <div className="col-7 right d-flex flex-column">
          <div className="header">
        
           {title}
           <br></br>
            {/* <span>MAINTENANCE</span> */}
          </div>
          <div className="description"
      dangerouslySetInnerHTML={{__html: description}}
    />
          <div className="time">
            {moment(startTime).format("DD/MM/YYYY hh:mm A")} to{" "}
            {moment(endTime).format("DD/MM/YYYY hh:mm A")}
          </div>
        </div>
        <div className="col-5 left">
          <img src={maintenanceimg} />
        </div>
      </div>
    </div>
   ):(
    <>
     <div className="maintenanceScreen">
      <div className="col-12">
      <img src={logoText} className="logoImg" />
      </div>
      <div className="row m-0">
      <div className="col-12 left">
          <img src={maintenanceimg} style={{width:"100%",marginTop:"10px"}}/>
        </div>
        <div className="col-12 right d-flex flex-column">
          <div className="header">
        
           {title}
           <br></br>
            {/* <span>MAINTENANCE</span> */}
          </div>
          <div className="description"
      dangerouslySetInnerHTML={{__html: description}}
    />
          <div className="time">
            {moment(startTime).format("DD/MM/YYYY hh:mm A")} to{" "}
            {moment(endTime).format("DD/MM/YYYY hh:mm A")}
          </div>
        </div>
      </div>
    </div>
    </>
   )
  );
};

export default MaintenaceScreen;
