import { EpubCFI } from "epubjs";

let newCFI = new EpubCFI();

export class Node {
  constructor(data) {
    this.data = data;
    this.left = null;
    this.right = null;
  }
}
export class BinarySearchTree {
  constructor() {
    this.root = null;
    this.cfiList = [];
  }

  inorderRec(root) {
    if (root != null) {
      this.inorderRec(root.left);
      this.cfiList.push(root.data);
      this.inorderRec(root.right);
    }
  }

  insert(data) {
    var newNode = new Node(data);
    if (this.root === null) {
      this.root = newNode;
    } else {
      this.insertNode(this.root, newNode);
    }
  }

  insertNode(node, newNode) {
    if (newCFI.compare(node.data.cfi, newNode.data.cfi) > 0) {
      if (node.left === null) {
        node.left = newNode;
      } else {
        this.insertNode(node.left, newNode);
      }
    } else {
      if (node.right === null) {
        node.right = newNode;
      } else {
        this.insertNode(node.right, newNode);
      }
    }
  }

  returnCFIList() {
    this.inorderRec(this.root);
    return this.cfiList;
  }
}
