import { lazy, Suspense, useCallback, useEffect, useMemo, useState } from "react";
import {
  Route,
  Switch,
  Redirect,
  useParams,
  useHistory,
  useLocation
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "./shared/Spinner/index";
import AppLayout from "./hoc/AppLayout/AppLayout";
import { ReduxState } from "./redux/reducers";
import { userRoleId, constants, isReactNative } from "./shared/constants";
import { loadPrivileges } from "./shared/helper/helper";
import SocketContext, { socket } from "./context/socket";
import { getTenantDetails, verifyToken } from "./api/tenant";
import { tenantDetails } from "./redux/actions/tenantActionTypes";
import { orgSuperAdminDetails } from "./redux/actions/orgSuperAdminActionTypes";
import { offlineLicenseDetails } from "./redux/actions/offlineLicense";
import { RootStore } from "./redux/store";
import { setSnackbar, loginUser, logout } from "./redux/actions/loginActionTypes";
import AuthVerify, { parseJwt } from "./common/AuthVerify";
import EventBus from "./common/EventBus";
import ErrorBoundary from "./components/ErrorBoundartScreeen/ErrorBoundary";
import moment from "moment";
import MaintenaceScreen from "./components/Maintenance/MaintenaceScreen";
import SocketNotification from "./shared/SocketNotification/SocketNotification";
import { isMobileViewAction } from "./redux/actions/mobileFixedMenu";
import { getCategoryList } from "./redux/actions/categoryActionTypes";
import { getPubilcationList } from "./redux/actions/publicationActionTypes";
import { updateCurrentAY } from "./redux/actions/academicYears";
import Loader from "./common/Loader";
import Dynamic from "./modules/Admin/Pages/GradeBook/Modal/V2/DynamicPreviewTemplate";
import { getRouteList } from "./common/routeConfig";

const asyncHome = lazy(() => import("./components/Home/Home") as any);
const LoaderMbl = lazy(() => import("./components/Home/LoaderMbl") as any);
const asyncAddSchool = lazy(() => import("./components/Home/AddSchool") as any);
const asyncNoMatch = lazy(() => import("./components/Auth/NoMatch/NoMatch") as any);
const asyncLogin = lazy(() => import("./components/Auth/Login/Login") as any);
const asyncVerifyOtp = lazy(() => import("./components/Auth/VerifyOtp/VerifyOtp") as any);
const asyncForgotPassword = lazy(() => import("./components/Auth/ForgotPassword/ForgotPassword") as any);
const asyncPhoneLogin = lazy(() => import("./components/Auth/PhoneLogin/PhoneLogin") as any);
const asyncOverview = lazy(() => import("./components/Overview/Overview") as any);
const asyncCreatePassword = lazy(() => import("./components/Auth/CreatePassword/CreatePassword") as any);
const asyncSetPassword = lazy(() => import("./components/Auth/SetNewPassword/SetPassword") as any);
const asyncMyClasses = lazy(() => import("./components/MyClasses/Layout/Layout") as any);
const asyncAdminCreatePassword = lazy(() => import("./components/Auth/AdminCreatePassword/createPassword") as any);
const asyncAdmin = lazy(() => import("./modules/Admin/Layout/Layout") as any);
const asyncSuperAdmin = lazy(() => import("./modules/OrgSuperAdmin/Layout/Layout") as any);
const asyncInfrastructure = lazy(() => import("./components/Infrastructure/Infrastructure") as any);
const asyncOfflineLicense = lazy(() => import("./components/OfflineLicense/OfflineLicense") as any);
type RollTypes = "teacher" | "student" | "admin";
const FeeMangement = lazy(() => import("./modules/Admin/Pages/AdminLayout/Pages/FeeManagement/FreeManagement") as any);
const AdmissionManagement = lazy(() => import("./modules/Admin/Pages/AdminLayout/Pages/AdmissionManagement/AdmissionManagement") as any);
const asyncNoAccess = lazy(() => import("./components/Auth/NoAccess/NoAccess") as any);

function App() {
  const [isMaintenance, setIsMaintenance] = useState(false)
  const isAuth = useSelector((state: ReduxState) => state?.login?.isAuth);
  const roleId = useSelector((state: ReduxState) => state?.login?.userRoleId);
  const params = useParams<{ role: RollTypes,studentId:string ,classId:string,gradeID:string,templateType:string,academicId:string,gradeBookTemplateID:string,gradeBookType:string}>();
  // const origin = window.location.origin;
  const host =  window.location.host;
  const dispatch = useDispatch();
  const location = useLocation()
  const history = useHistory()
  const tenantId = useSelector((state: RootStore) => state?.tenant?.tenantId);
  const maintenance = useSelector((state: RootStore) => state?.tenant?.maintenance);
  const isOnline = useSelector((state: RootStore) => state?.tenant?.IS_ONLINE);
  const licenseStatus = useSelector((state: RootStore) => state?.offlineReducer?.licenseStatus);
  const isWalkthrough = !useSelector((state: ReduxState) => state?.login?.userData.loginStatus);
  const existingToken = useSelector((state: ReduxState) => state?.login?.token);
  const query = new URLSearchParams(window.location.search);
  const [isMobileView, setIsMobileView] = useState(false);
  const newToken = query.get('token');
  const tenant_id = query.get('tenantId');
  const isDownload = query.get("download");
  const templateId = query.get("templateId");
  const templateType = query.get("templateType");
  const isGradeBook = query.get("isGradeBook")
  const acId = query.get("acId");
  const acStId = query.get("acStId");
  const acYear = query.get("acYear");
  const termId=query.get("termId")
  const studentId= params?.studentId;
  const classId= params?.classId;
  const gradeId=params?.gradeID;
  const templateCode= params?.templateType === "null" ? 0 : params?.templateType;
  const academicId= params?.academicId;
  const isOrgSuperAdmin = useSelector((state : RootStore) => state?.orgsuperadmin?.isOrgSuperAdmin)
  const isGateStaff  = useSelector((state:RootStore)=>state?.login?.isGateStaff)
  const publications = useSelector((state: RootStore) => state?.publication?.list);
  const categories = useSelector((state: RootStore) => state?.category?.list);
  const loading = useSelector((state:any) => state?.loadingVal?.loaderVal);
  
  const authToken = localStorage.getItem("auth_token");
  const availableModules = parseJwt(authToken)?.data?.availableModules ?? [];
  const routeList:any = getRouteList();
  const fmsRoute = availableModules.includes(routeList["feeManagement"]) ? process.env.REACT_APP_FMS_BASIC_ROUTE : availableModules.includes(routeList["advanceFeeManagement"]) ? process.env.REACT_APP_FMS_ADVANCE_ROUTE : "";
  const handleLoginToken = async (newToken: string) => {
    let res = await verifyToken(newToken);
    if (res && res.status == "200") {
      const privileges = await loadPrivileges(res.data);
      localStorage.setItem('auth_token', newToken);
      dispatch(
        loginUser({
          ...res.data,
          password: window.btoa(res.data.password),
          userRoleId: res.data.UserRoleID,
          userData: res.data,
          token: newToken,
          isAuth: true,
          rememberMe: res.data.rememberMe,
          privileges: privileges
        })
      )
      if (res?.data?.isGateStaff) {
        window.location.href = "/visitorregister/gate-staff/home";
      }
      dispatch(updateCurrentAY())
      dispatch(getCategoryList())
      dispatch(getPubilcationList())
      
    } else {
      window.location.href = "/"
    }
  }
  if(isGateStaff){
    window.location.href= "/visitorregister/gate-staff/home"
  }
  const role = useMemo(() => {
    return params?.role;
  }, [params]);

  useEffect(() => {
    if (((!isOrgSuperAdmin) || (!(!!tenantId) || tenantId === "") && (isOnline === undefined || isOnline == 1)) && !(tenant_id && window.innerWidth < 768)) {
      getTenantDetailsApi();
    }
  }, [isOrgSuperAdmin, tenantId]);


  /* isMobileview  stored in Redux Functionlaity*/
  useEffect(() => {
    if (newToken && existingToken !== newToken && !isOrgSuperAdmin) {
      if(window.innerWidth < 768 || isDownload || isGradeBook) {
        if (!tenantId && constants?.isOnline) {
          //@ts-ignore
          dispatch(tenantDetails({ tenantId: tenant_id, IS_ONLINE: constants?.isOnline ? 1 : 0 }));
          window.location.reload();
        } else {
          handleLoginToken(newToken);
        }
      }
    }
    if((!publications?.length || !categories?.length) && isAuth) {
      dispatch(getCategoryList())
      dispatch(getPubilcationList())
    }
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 500);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Commented due to sentry issue logger occurrence check 
  // useEffect(() => {
  //   function lockOrientation() {
  //     if (window.screen.orientation) {
  //       window.screen.orientation.lock('portrait');
  //     }
  //   }
  //   lockOrientation();
  //   return () => {
  //     if (window.screen.orientation.unlock) {
  //       window.screen.orientation.unlock();
  //     }
  //   };
  // }, []);

  useEffect(() => {
    dispatch(isMobileViewAction(isMobileView))
  }, [isMobileView]);

  // redirecting login page if user is not logged in

  useEffect(() => {
    // below the routes we allowing if the user is not logged in
    const allowedRoutes = [
      '/set-password',
      '/create-password/:resetCode',
      '/admin-create-password',
      '/forgot-password/:role',
      '/verify-otp/:role',
      '/login/:role',
      '/phone-login/:role',
      '/admin/StudentGradebook/:studentId/:classId/:gradeId/:templateType/:academicId/:gradeBookTemplateID/:gradeBookType',
      '/teacher/StudentGradebook/:studentId/:classId/:gradeID/:templateType/:academicId/:gradeBookTemplateID/:gradeBookType',
      '/admin/reports/download/studentReport/:studentId',
      '/teacher/reports/download/studentReport/:studentId'
    ];
    const isAllowedRoute = allowedRoutes.some(route => {
      // Convert the route pattern to a regular expression
      const regex = new RegExp(`^${route.replace(/:[^/]+/g, '[^/]+')}$`);
      return regex.test(location?.pathname);
    });
    if (!isAuth && !isAllowedRoute) {
      history.push("/");
    }
  }, [isAuth]);

  const getTenantDetailsApi = async () => {
    const response = await getTenantDetails(host);
    if(!!response && response?.status == "200" && response.data.organizationName && response.data.organizationType) {
      if (!isOrgSuperAdmin) {
        dispatch(orgSuperAdminDetails({ isOrgSuperAdmin: true, organizationId: response.data.organizationID }));
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } else if (!!response && response?.status == "200") {
      if (!(!!tenantId) || tenantId === "") { 
        dispatch(offlineLicenseDetails({ licenseStatus: true })); 
        dispatch(tenantDetails(response?.data)); 
        setTimeout(() => { 
          window.location.reload(); 
        }, 1000); 
      }
    } else if (!!response && response?.status == "401" && !constants?.isOnline) {
      dispatch(offlineLicenseDetails({ licenseStatus: false }));
      dispatch(tenantDetails(response?.data));
    } else {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: response?.message || "Invalid Tenant ID",
          isHtmlText: true
        })
      );
    }
  };

  //Commented: below the condition added in the useEffect
  // if (newToken && existingToken !== newToken && !isOrgSuperAdmin) {
  //   if(window.innerWidth < 768 || isDownload || isGradeBook) {
  //     if (!tenantId && constants?.isOnline) {
  //       //@ts-ignore
  //       dispatch(tenantDetails({ tenantId: tenant_id }));
  //       window.location.reload();
  //     }else{
  //       handleLoginToken(newToken);
  //     }
  //   }
  // }

  const sessionLogOut = useCallback(() => {
    localStorage.clear();
    dispatch(logout());
    history.push("/");
  }, [dispatch]);

  useEffect(() => {
    EventBus.on("logout", () => {
      sessionLogOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [sessionLogOut]);

  useEffect(()=>{
    if(tenantId || (maintenance && maintenance?.length)){
      let start :any = '';
      let end :any ='';
      maintenance?.map((d:any)=>{
        if(d?.ConfigKey === 'MAINTENANCE_DATEFROM'){
          start = moment(d?.Default)
        }
        if(d?.ConfigKey === 'MAINTENANCE_DATETO'){
          end = moment(d?.Default)
        }
      })
      if(new Date().getTime() >= new Date(start).getTime() && new Date().getTime() <= new Date(end).getTime() ){
        setIsMaintenance(true)
      }
      else{
        setIsMaintenance(false)
      }
    }
  },[tenantId])

  let routes = (
    <Suspense fallback={<Spinner />}>
      {(!!tenantId || isOnline == 0) && licenseStatus ? (
        <Switch>
          <Route
            exact
            path="/set-password"
            component={asyncSetPassword}
          />
          <Route
            path="/create-password/:resetCode"
            component={asyncCreatePassword}
          />
          <Route
            exact
            path="/admin-create-password"
            component={asyncAdminCreatePassword}
          />
          <Route exact path="/infrastructure" component={asyncInfrastructure} />
          <Route exact path="/phone-login/:role" component={asyncPhoneLogin} />
          <Route
            exact
          path="/forgot-password/:role" 
            component={asyncForgotPassword}
          />
          <Route exact path="/verify-otp/:role" component={asyncVerifyOtp} />
          <Route exact path="/login/:role" component={asyncLogin} />
          <Route exact path="/" component={asyncHome} />
          {window.innerWidth < 768 && <Route path="/mbl/logout" component={LoaderMbl} />}
          <Route path="*" component={asyncNoMatch} />
        </Switch>
      ) : (!isAuth && isOrgSuperAdmin && licenseStatus ?
        <Switch>
          <Route
            exact
            path="/set-password"
            component={asyncSetPassword}
          />
          <Route exact path="/" component={asyncHome} />
          <Route exact path="/forgot-password/:role" component={asyncForgotPassword}
          />
          <Route
            path="/create-password/:resetCode"
            component={asyncCreatePassword}
          />
          <Route exact path="/login/:role" component={asyncLogin} />
        </Switch>
        : !isAuth && licenseStatus ? 
        <Switch>
          <Route exact path="/" component={asyncHome} />
          {window.innerWidth < 768 && <Route path="*" component={asyncAddSchool} />}
          {window.innerWidth > 767 && <Route path="*" component={asyncNoMatch} />}
        </Switch>
        : "")}        
    </Suspense>
  );
  if (isAuth && (!!tenantId || isOnline == 0) && roleId === userRoleId.teacherId && licenseStatus) {
    routes = (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route path="/teacher/overview" component={asyncOverview} />
          <Route path="/teacher" component={asyncMyClasses} />
          <Route path="/assess" component={AdmissionManagement}/>
          {/* commented this to prevent blank screen  */}
          {/* <Route path="/teacher/*" component={asyncNoMatch} />  */}
          <Route path="/error/404" component={asyncNoMatch} />
          <Route path="/noaccess" component={asyncNoAccess} />
          {(isDownload && isGradeBook) ?
          <Redirect to={`/teacher/StudentGradebook/${studentId}/${classId}/${gradeId}/${templateCode}/${academicId}/${params?.gradeBookTemplateID}/${params?.gradeBookType}?download=${isDownload}&isGradeBook=${isGradeBook}&termId=${termId}`} exact />
          :isWalkthrough ?
            <Redirect to="/teacher/overview" exact />
            :
            <Redirect to="/teacher/dashboard" exact />
          }
        </Switch>
      </Suspense>
    );
  } else if (isAuth && (!!tenantId || isOnline == 0) && roleId === userRoleId.adminId && licenseStatus) {
    routes = (
      <Suspense fallback={<Spinner />}>
        <Switch>
          {fmsRoute && <Route path={fmsRoute} component={FeeMangement} />}
          <Route path="/admissions" component={AdmissionManagement} />
          <Route path="/erp/template" component={AdmissionManagement} />
          <Route path="/admin" component={asyncAdmin} />
          {/* commented this to prevent blank screen  */}
          {/* <Route path="/admin/*" component={asyncNoMatch} />  */}
          <Route path="/error/404" component={asyncNoMatch} />
          <Route path="/noaccess" component={asyncNoAccess} />
          {(isGradeBook && isDownload)?
          <Redirect to={`/admin/StudentGradebook/${studentId}/${classId}/${gradeId}/${templateCode}/${academicId}/${params?.gradeBookTemplateID}/${params?.gradeBookType}?download=${isDownload}&isGradeBook=${isGradeBook}&acId=${acId}&acStId=${acStId}&acYear=${acYear}&termId=${termId}`} exact />
          :(isDownload && templateId && templateType) ?
           <Redirect to={`/admin/gradebook/preview-template?templateId=${templateId}&templateType=${templateType}&download=${isDownload}&acId=${acId}&acStId=${acStId}`} exact/>
           :<Redirect to="/admin"/>
          }
          <Redirect exact to={`/admin/template/preview?templateId=${templateId}`} />
        </Switch>
      </Suspense>
    );
  } else if(isAuth && roleId === userRoleId.superadminId && isOrgSuperAdmin && licenseStatus) {
    routes = (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route path="/orgsuperadmin" component={asyncSuperAdmin} />
          <Redirect to="/orgsuperadmin/dashboard" exact />
        </Switch>
      </Suspense>
    );
  } else if (!licenseStatus) {
    routes = (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route path="/" component={asyncOfflineLicense} />
          <Redirect to="/" exact />
        </Switch>
      </Suspense>
    );
  }
  return (
    // <ErrorBoundary > - Note : This feature under discussion 
    <SocketContext.Provider value={socket}>
      <SocketNotification />
      {isMaintenance ? <MaintenaceScreen maintenance={maintenance} /> :<AppLayout>
          {/* can be used to display the loader using redux state  */}
          <Loader val={loading} />
          {routes}
        </AppLayout>}
      {isAuth && <AuthVerify logOut={sessionLogOut} />}
    </SocketContext.Provider>
    // </ErrorBoundary>
  );
}
export default App;
