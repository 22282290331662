import React, { useEffect, useState } from "react";
import { addToolTip, Interactives } from "./Helpers/Interactive";

import PdfViewer from "./Components/PdfViewer";
import Header from "./Components/Header";
// import AudioModal from "./Components/AudioModal";
// import ImageModal from "./Components/ImageModal";
// import VideoModal from "./Components/VideoModal";
// import ExerciseModal from "./Components/ExerciseModal";
// import DocModal from "./Components/DocModal";
// import GameModal from "./Components/GameModal";
// import Slider from "./Components/Slider";
// import StickyNote from "./Components/StickyNote";
// import Sidebar from "./Components/Sidebar/Sidebar";
// import HighlightedItem from "./Components/HighlightedItem";

import { useAppState } from "./Context";

import { API } from "./utils/API";
import { BinarySearchTree } from "./utils/BST";
import { bookDetailService, fetchBookMarkService } from "./Services/bookDetailService";
import { interactivesDetailService } from "./Services/interactivesDetailService";
import { applyWaterMark } from "./utils/ApplyWatermark";
import { fetchHighlights } from "./Services/fetchHighlights";
import { Analyze, saveAnalytics } from "./Helpers/Analyze";
import { fetchPens, sortPenItemsPageNumberWise } from "./Services/fetchPens";
import ResourceBar from "./Components/Sidebar/ResourceBar";
import PenToolBar from "./Components/PenToolBar";
import { saveCanvas } from "./Helpers/SaveCanvas";
import { CreateAClearAllStageInStack } from "./Helpers/CanvasStack";
import UnlockResources from "./Components/UnlockResources";
import HtmlExerciseModal from "./Components/HtmlExerciseModal";
import PdfViewerMobile from "./Components/PdfViewerMobile";

let windowHeight = 0,
  windowWidth = 0,
  rendition;

const DigiBookReader = ({ bookUrl, isMobileView,controlsConfig,pdfContainerStyling }) => {
  var isBookPortrait = true;
  const bookId = localStorage.getItem("bookId");

  const { bookDetails, setBookDetails } = useAppState("bookDetails");
  const [elements, setElements] = useState({});
  const [bookInfo, setBookInfo] = useState([]);
  const [bookName, setBookName] = useState("");

  const { scaleSize, setScaleSize, currentPage, setCurrentPage, setAddNote, setStickyNotes, setHighlightModal, setShowMenu, addNote, setShowSideBar, rend, setRend, renditionWidth, setNoteColor, selectedStickyNoteId, setNoteValue, stickyNotes, coordinatesOfStickyNote, setCoordinatesOfStickyNote, allStickyNotes, setAllStickyNotes, allNotes, setAllNotes, pennedItems, setPennedItems, sortByLatestFirst, setPenToolBar, setCombinedPens, isDualPage, setPenColor, setIsEraserOn, setTurnOnPenTool, setIsPageRendering, setClearAllStage, setStageToRestore,setIsDualPage } = useAppState("reader");


  // const showTableOfContent = () => {
  //   setShowSideBar(!showSideBar);
  //   setShowResourceBar(false);
  //   if (showSideBar === false) {
  //     Analyze(bookId, "tul", "toc", null, null);
  //   }
  // };
  const redirectFromTableOfContent = (id) => {
    setCurrentPage(id);
    setShowSideBar(false);
  };
  const redirectToPage = async (pageIndex) => {
    if (pageIndex || pageIndex == 0) {
      await rendition.display(pageIndex);
      setRend(rendition);
    } else {
      await rendition.display();
      setRend(rendition);
    }
    setHighlightModal(false);
  };
  // const removeHighlight = async (id) => {
  //   if (id && id !== undefined && id !== null) {
  //     let highlightToRemove = highlightedItems.filter((eachItem) => eachItem._id === id);
  //     const response = await API(
  //       "delete",
  //       `/api-books/books/${bookID}/highlights/${id}`
  //     );
  //     if (response?.deleted.acknowledged === true) {
  //       let filteredHighlights = await highlightedItems.filter((eachItem) => eachItem._id !== id);
  //       let timeWiseSorted = await sortByLatestFirst(filteredHighlights);
  //       setHighlightedItems(timeWiseSorted);
  //       let can = document.getElementById("drawing-board");
  //       let ctx = can.getContext("2d");
  //       highlightToRemove[0].points.forEach((each) => {
  //         ctx.clearRect(each.x - 10, each.y - 10, 25, 25)
  //       })
  //     }
  //   } else {
  //     //TODO: Handle This case
  //   }
  // };
  // const removePen = async (id) => {
  //   if (id && id !== undefined && id !== null) {
  //     let penToRemove = pennedItems.filter((eachItem) => eachItem._id === id);
  //     const response = await API(
  //       "delete",
  //       `/api-library/books/${bookID}/drawings/${id}`
  //     );
  //     if (response?.deleted.acknowledged === true) {
  //       let filteredPens = await pennedItems.filter((eachItem) => eachItem._id !== id);
  //       let timeWiseSorted = await sortByLatestFirst(filteredPens);
  //       setPennedItems(timeWiseSorted);
  //       let can = document.getElementById("drawing-board");
  //       let ctx = can.getContext("2d");
  //       penToRemove[0].data.points.forEach((each) => {
  //         ctx.clearRect(each.x - 10, each.y - 10, 25, 25)
  //       })
  //     }
  //   } else {
  //     //TODO: Handle This case
  //   }
  // };



  const clearAllPens = async (pageNumber, clearType) => {
    if (clearType === "temporary") {

      let penItems = pennedItems;

      let getPenStack = localStorage.getItem("penStack");
      let parsedStack;
      if (getPenStack) {
        parsedStack = JSON.parse(getPenStack);
      }

      //Pens To Save Temporary
      let pensToPersistsAfterClearAll = await penItems.filter((eachPenItem) => {
        if (isDualPage && !pageNumber) {
          return eachPenItem.page === currentPage || eachPenItem.page === currentPage + 1;
        } else {
          return eachPenItem.page === (pageNumber ? pageNumber : currentPage);
        }
      });
      if (pensToPersistsAfterClearAll.length) {
        CreateAClearAllStageInStack(true);
        setClearAllStage(pensToPersistsAfterClearAll);
      } else {
        CreateAClearAllStageInStack(false);
      }

      // let currentClearAllStage = clearAllStage;
      // console.log("pensToPersistsAfterClearAll", pensToPersistsAfterClearAll)
      // currentClearAllStage.push(pensToPersistsAfterClearAll);




      // Pens to Show On Page
      let newPenItems = penItems.filter((eachPenItem) => {
        if (isDualPage && !pageNumber) {
          return eachPenItem.page !== currentPage && eachPenItem.page !== currentPage + 1;
        } else {
          return eachPenItem.page !== (pageNumber ? pageNumber : currentPage);
        }
      });
      let combinePens = await uniqueByKey(newPenItems, "page");
      let timeWiseSortedCombinedPens = await sortByLatestFirst(combinePens);
      setCombinedPens(timeWiseSortedCombinedPens);
      setPennedItems(newPenItems);
      setStageToRestore([]);
      setTimeout(() => {
        setIsPageRendering(false);
      }, 1500);



    }
    else {
      //If Clearll is called
      if (isDualPage && !pageNumber) {
      }
      let penItems = pennedItems;
      let newPenItems = penItems.filter((eachPenItem) => {
        if (isDualPage && !pageNumber) {
          return eachPenItem.page !== currentPage && eachPenItem.page !== currentPage + 1;
        } else {
          return eachPenItem.page !== (pageNumber ? pageNumber : currentPage);
        }
      });
      let combinePens = await uniqueByKey(newPenItems, "page");
      let timeWiseSortedCombinedPens = await sortByLatestFirst(combinePens);
      setCombinedPens(timeWiseSortedCombinedPens);
      setPennedItems(newPenItems);
      setTimeout(() => {
        setIsPageRendering(false);
      }, 1500);
    }
  };


  function countUniqueValues(array, keyToCount) {
    let uniqueValues = [];

    for (let i = 0; i < array.length; i++) {
      const value = array[i][keyToCount];
      if (!uniqueValues.includes(value)) {
        uniqueValues.push(value);
      }
    }

    return uniqueValues;
  }

  const afterUpdationOfCanvas = async (response, numberOfUniqueValues) => {
    let penItems = pennedItems;
    let removedCurrentPagePen = await penItems.filter((eachPen) => {
      if (numberOfUniqueValues.length > 1) {
        return eachPen.page !== numberOfUniqueValues[0] || eachPen.page !== numberOfUniqueValues[1];
      } else {
        return eachPen.page !== numberOfUniqueValues[0];
      }
    });
    let combinedSPens = removedCurrentPagePen.concat(response);
    let sortedPens = await sortPenItemsPageNumberWise(combinedSPens);
    let timeWiseSorted = await sortByLatestFirst(sortedPens);

    setCombinedPens(timeWiseSorted);

    setPennedItems(timeWiseSorted);
  };

  const resetPenTool = async () => {
    setPenColor("#000000");
    setIsEraserOn(false);
    setTurnOnPenTool(false);
    setPenToolBar(false);
    let response = await saveCanvas(bookId, clearAllPens);
    if (response) {
      let numberOfUniqueValues = await countUniqueValues(response, "page");
      afterUpdationOfCanvas(response, numberOfUniqueValues);
    }
  };

  // const fetchBookDetails = async () => {
  //   let getBookDetails = await bookDetailService(isAdmin, bookID);
  //   try {
  //     if (getBookDetails.pageThumbs.length > 0) {
  //       setBookDetails((prevState) => ({
  //         bookThumbnails: getBookDetails.pageThumbs,
  //         bookThumbsFrom: getBookDetails.pageThumbFrom,
  //         bookThumbTo: getBookDetails.pageThumbTo,
  //         bookTableOfContents: getBookDetails.chapters,
  //         ...prevState,
  //       }));
  //       setTableOfContent(getBookDetails.chapters);
  //       setBookInfo(getBookDetails);
  //       setBookName(getBookDetails.name);
  //       localStorage.setItem("bookName", getBookDetails.name);
  //     }
  //   } catch (error) {
  //     console.log("Error In FetchBook Details API", error);
  //   }
  // };
  // const fetchBookMarks = async () => {
  //   let getBookMarks = await fetchBookMarkService(bookID);
  //   if (getBookMarks.length > 0) {
  //     bookMarkedPages = await sortByLatestFirst(getBookMarks);
  //     setBookmarkedPages(bookMarkedPages);
  //   }
  // };
  // const windowMessageListner = () => {
  //   window.addEventListener("message", (event) => {
  //     // if(event.origin === process.env.CROSS_SITE){
  //     if (event.data?.action === "playVideo") {
  //       let { src, currentTime = 0 } = event.data;
  //       let video = document.createElement("video");
  //       let source = document.createElement("source");
  //       source.src = src;
  //       video.width = 0;
  //       video.height = 0;
  //       video.currentTime = currentTime;
  //       video.disablePictureInPicture = "true";
  //       video.controlsList = "nodownload noplaybackrate";
  //       video.appendChild(source);
  //       document.body.appendChild(video);
  //       video.autoplay = true;
  //       let requestMethod =
  //         video.requestFullScreen ||
  //         video.webkitRequestFullScreen ||
  //         video.mozRequestFullScreen ||
  //         video.msRequestFullScreen;
  //       if (requestMethod) {
  //         requestMethod.call(video);
  //       }
  //     } else if (event.data?.action === "playAudio") {
  //       setShowAudioModal({ show: true, src: event.data.src, analyzeData: event.data.analyzeData });
  //     } else if (event.data?.action === "showImage") {
  //       setShowImageModal({ show: true, src: event.data.src });
  //     } else if (event.data?.action === "showVideo") {
  //       setShowVideoModal({ show: true, src: event.data.src, analyzeData: event.data.analyzeData });
  //     } else if (event.data?.action === "showDoc") {
  //       setShowDocModal({ show: true, src: event.data.src });
  //     } else if (event.data?.action === "showMCQ") {
  //       setShowExcerciseModal({
  //         show: true,
  //         type: "mcq",
  //         data: event.data.data,
  //       });
  //     } else if (event.data?.action === "showTrueFalse") {
  //       setShowExcerciseModal({
  //         show: true,
  //         type: "tf",
  //         data: event.data.data,
  //       });
  //     } else if (event.data?.action === "showFillBlank") {
  //       setShowExcerciseModal({
  //         show: true,
  //         type: "fb",
  //         data: event.data.data,
  //       });
  //     } else if (event.data?.action === "showMatchFollowing") {
  //       setShowExcerciseModal({
  //         show: true,
  //         type: "mf",
  //         data: event.data.data,
  //       });
  //     } else if (event.data?.action === "showComment") {
  //       setShowExcerciseModal({
  //         show: true,
  //         type: "comment",
  //         data: event.data.data,
  //       });
  //     } else if (event.data?.action === "showGame") {
  //       setShowGameModal({
  //         show: true,
  //         type: "comment",
  //         data: event.data.data,
  //       });
  //     } else if (event.data?.action === "showHtmlExercise") {
  //       setShowHtmlExerciseModal({
  //         show: true,
  //         type: "comment",
  //         data: event.data.data,
  //       });
  //     } else if (event.data?.action === "showUnlockResource") {
  //       setshowUnlockResoucesPopUp(true);
  //     }
  //     // }
  //   });
  //   window.addEventListener("click", function (e) {
  //     let getNavigationSwitch = document.getElementById("navigator");
  //     let getNavigationSection = document.getElementById("navigationSection");
  //     if (getNavigationSwitch && getNavigationSection) {
  //       if (!getNavigationSwitch.contains(e.target) && !getNavigationSection.contains(e.target)) {
  //         setShowSlider(false);
  //       }
  //     }
  //   });
  // };

  // const fetchHighlightsForBook = async () => {
  //   if (!isAdmin) {
  //     let fetchedHighlights = await fetchHighlights(null, bookID);
  //     if (fetchedHighlights.length > 0) {
  //       let timeWiseSorted = await sortByLatestFirst(fetchedHighlights);
  //       setHighlightedItems(timeWiseSorted);
  //     }
  //   }
  // };

  function uniqueByKey(arr, key) {
    const seen = new Set();
    return arr.filter((item) => {
      const itemKey = item[key];
      if (!seen.has(itemKey)) {
        seen.add(itemKey);
        return true;
      }
      return false;
    });
  }

  // const fetchPensForBook = async () => {
  //   if (!isAdmin) {
  //     let fetchedPens = await fetchPens(null, bookID);
  //     if (fetchedPens.length > 0) {
  //       let timeWiseSorted = await sortByLatestFirst(fetchedPens);
  //       setPennedItems(timeWiseSorted);
  //       let combinePens = await uniqueByKey(timeWiseSorted, "page");
  //       let timeWiseSortedCombinedPens = await sortByLatestFirst(combinePens);
  //       setCombinedPens(timeWiseSortedCombinedPens);
  //     }
  //   }
  // };

  const Elements = {
    elements,
    add: ({ id, ...data }) => {
      let tmp = elements;
      tmp[id] = data;
      setElements(tmp);
    },
    update: ({ id, ...data }) => {
      let tmp = elements;
      tmp[id] = { ...tmp[id], ...data };
      setElements(tmp);
    },
    remove: ({ id }) => {
      let tmp = elements;
      delete tmp[id];
      setElements(tmp);
    },
  };
  // useEffect(() => {
  //   windowMessageListner();
  //   windowWidth = window.innerWidth;

  //   if (bookID && bookUrl && bookVersionId) {
  //     setBookDetails({
  //       bookId: bookID,
  //       bookVersionId: bookVersionId,
  //       bookUrl: bookUrl,
  //     });
  //   }
  //   fetchBookDetails();
  //   fetchBookMarks();
  //   fetchHighlightsForBook();
  //   fetchPensForBook();
  //   !isAdmin && !isPromotional && applyWaterMark(bookID);
  // }, []);

  useEffect(()=>{
    if(controlsConfig.onlySinglePage){
      setIsDualPage(false);
    }
  },[])
  useEffect(()=>{
    if(controlsConfig.isWaterMark && controlsConfig.contentLocation)
    applyWaterMark();
  },[isDualPage]);
  // useEffect(() => {
  //   if (selectedElement) {
  //     // console.log(
  //     //   "Got the selected Element & sending message to getArea with element",
  //     //   selectedElement
  //     // );
  //     window.postMessage({ selectedElement, currentPage }, "*");
  //     setSelectedElement(null);
  //   }
  // }, [selectedElement]);

  //StickyNoteCodeHere
  // const fetchStickyNoteForBook = async () => {
  //   const response = await API("post", `/api-books/books/${bookId}/sticky-notes/fetch`, {
  //     "pageNumbers": []
  //   });
  //   let timeWiseSorted = await sortByLatestFirst(response.data, "StickyNote");
  //   setAllStickyNotes(timeWiseSorted);
  // };
  // useEffect(() => {
  //   fetchStickyNoteForBook();
  //   setInterval(() => {
  //     saveAnalytics();
  //   }, 30000);
  // }, [])

  //StickyNoteCodeHere


  //BOokMarkCodeHere

  // const removeBookMark = async (pageNo) => {
  //   let filteredBookmark = bookMarkedPages.filter((bookmark) => bookmark.pageNumber === pageNo);
  //   if (filteredBookmark) {
  //     const response = await API("delete", `/api-books/books/${bookID}/bookmarks/${filteredBookmark[0]._id}`);
  //     if (response) {
  //       let sam = bookMarkedPages.filter((el) => el.pageNumber !== pageNo);
  //       bookMarkedPages = sam;
  //       setBookmarkedPages(sam);
  //     }
  //   }
  // };

  // const addBookMark = async (pageNo) => {
  //   const response = await API("post", `/api-books/books/${bookID}/bookmarks`, {
  //     "pageNumber": pageNo
  //   });
  //   if (response) {
  //     var newBookMarkObj = {};
  //     newBookMarkObj.pageNumber = response.pageNumber;
  //     newBookMarkObj._id = response._id;
  //     newBookMarkObj.user = response.user;
  //     newBookMarkObj.updatedAt = response.updatedAt;
  //     newBookMarkObj.createdAt = response.createdAt;
  //     // setBookmarkedPages(bookmarks);
  //     bookMarkedPages.push(newBookMarkObj);
  //     bookMarkedPages = await sortByLatestFirst(bookMarkedPages);
  //     setBookmarkedPages((prev) => [...prev, bookmarkedPages]);
  //     Analyze(bookID, "tul", "bmk", null, null, currentPage);
  //   }
  // };

  // const changeImage = (pageNo, bImage) => {
  //   if (bImage.src === `${process.env.reader_pdf_url}/public/images/blackbookMark.svg`) {
  //     bImage.src = `${process.env.reader_pdf_url}/public/images/redBookMark.svg`;
  //     addBookMark(pageNo);
  //   }
  //   else {
  //     bImage.src = `${process.env.reader_pdf_url}/public/images/blackbookMark.svg`;
  //     removeBookMark(pageNo);
  //   }
  // };

  // const markUnMark = (e, pageNo) => {
  //   let getBookMarkImage = document.getElementById(`bookmarkImage${pageNo}`);
  //   changeImage(pageNo, getBookMarkImage);
  // };

  //BOokMarkCodeHere


  return (
    <>
      {
        <Header
          currentPage={currentPage}
          // isAdmin={isAdmin}
          // bookId={bookId}
          rendition={rend}
          // showTableOfContent={showTableOfContent}
          redirectFromTableOfContent={redirectFromTableOfContent}
          windowWidth={windowWidth}
          windowHeight={windowHeight}
          // changingAddNote={changingAddNote}
          // bookMarkedPages={bookMarkedPages}
          isBookPortrait={isBookPortrait}
          // isPromotional={isPromotional}
          // bookName={bookName}
          // bookInfo={bookInfo}
          scaleSize={scaleSize}
          setScaleSize={setScaleSize}
          bookURL={bookUrl}
          controlsConfig={controlsConfig}
          // setCombinedPens={setCombinedPens}
          // pennedItems={pennedItems}
          // setPennedItems={setPennedItems}
          // resetPenTool={resetPenTool}
        />
      }
      <div id="pdfViewerParent" className="pdfViewerContainer">
        {isMobileView ? 
        <PdfViewerMobile
        url={bookUrl}
        Elements={Elements}
        isBookPortrait={isBookPortrait}
        pdfContainerStyling={{}}
        isMobileView={isMobileView}
        scaleSize={scaleSize}
        setScaleSize={setScaleSize}
        resetPenTool={resetPenTool}
        controlsConfig={controlsConfig}
      />
        :<PdfViewer
        url={bookUrl}
        Elements={Elements}
        isBookPortrait={isBookPortrait}
        pdfContainerStyling={pdfContainerStyling}
        // bookInfo={bookInfo}
        isMobileView={isMobileView}
        scaleSize={scaleSize}
        setScaleSize={setScaleSize}
        resetPenTool={resetPenTool}
        controlsConfig={controlsConfig}
      /> }
        
        {/* {showSideBar && (
          <Sidebar
            redirectToPage={redirectToPage}
            redirectFromTableOfContent={redirectFromTableOfContent}
            tableOfContent={tableOfContent}
            isMobileView={isMobileView}
          />
        )}
        {showResourceBar && <ResourceBar bookInfo={bookInfo} isMobileView={isMobileView} />}
        {
          penToolBar && <PenToolBar bookId={bookId} clearAllPens={clearAllPens} resetPenTool={resetPenTool} />
        } */}
      </div>

      {/* {showSlider && (
        <Slider
          windowWidth={windowWidth}
          loadedSpine={loadedSpine}
          redirectToPage={redirectToPage}
          setLoading={setLoading}
          renditionWidth={renditionWidth}
          setShowSlider={setShowSlider}
          showSlider={showSlider}
          bookId={bookId}
          pageThumbFrom={pageThumbFrom}
          pageThumbTo={pageThumbTo}
          thumbnails={thumbnails}
          currentSlide={currentSlide}
          setCurrentSlide={setCurrentSlide}
          bookInfo={bookInfo}
          setBookInfo={setBookInfo}
          handleRedirection={handleRedirection}
          isMobileView={isMobileView}
        />
      )}
      {highlightModal && (
        <HighlightedItem
          bookID={bookID}
          highlightedItems={highlightedItems}
          setHighlightedItems={setHighlightedItems}
          setHighlightModal={setHighlightModal}
          removeHighlight={removeHighlight}
          redirectToHighlightedItem={redirectToHighlightedItem}
          bookMarkedPages={bookMarkedPages}
          redirectToPage={redirectToPage}
          renditionWidth={renditionWidth}
          windowWidth={windowWidth}
          stickyNotes={stickyNotes}
          removeStickyNote={removeStickyNote}
          setSelectedStickyNoteId={setSelectedStickyNoteId}
          removeBookMark={removeBookMark}
          handleRedirection={handleRedirection}
          allStickyNotes={allStickyNotes}
          setAllStickyNotes={setAllStickyNotes}
          bookInfo={bookInfo}
          setBookInfo={setBookInfo}
          pennedItems={pennedItems}
          setPennedItems={setPennedItems}
          removePen={removePen}
          isMobileView={isMobileView}
          combinedPens={combinedPens}
          setCombinedPens={setCombinedPens}
          clearAllPens={clearAllPens}
        />
      )}
      {showMenu && (
        <StickyNote
          setShowMenu={setShowMenu}
          selectedStickyNoteId={selectedStickyNoteId}
          updateStickyData={updateStickyData}
          noteValue={noteValue}
          setNoteValue={setNoteValue}
          removeStickyNote={removeStickyNote}
        />
      )}
      {showAudioModal.show && (
        <AudioModal
          isAdmin={isAdmin}
          showAudioModal={showAudioModal}
          setShowAudioModal={setShowAudioModal}
        />
      )}
      {showImageModal.show && (
        <ImageModal
          showImageModal={showImageModal}
          setShowImageModal={setShowImageModal}
        />
      )}
      <div>
        {showVideoModal.show && (
          <VideoModal
            showVideoModal={showVideoModal}
            setShowVideoModal={setShowVideoModal}
          />
        )}
      </div>
      {showDocModal.show && (
        <DocModal
          showDocModal={showDocModal}
          setShowDocModal={setShowDocModal}
        />
      )}
      {showExcerciseModal.show && (
        <ExerciseModal
          type={showExcerciseModal.type}
          showExcerciseModal={showExcerciseModal}
          setShowExcerciseModal={setShowExcerciseModal}
        />
      )}
      {showGameModal.show && (
        <GameModal
          type={showGameModal.type}
          showGameModal={showGameModal}
          setShowGameModal={setShowGameModal}
        />
      )}
      {showHtmlExerciseModal.show && (
        <HtmlExerciseModal
          type={showHtmlExerciseModal.type}
          showHtmlExerciseModal={showHtmlExerciseModal}
          setShowHtmlExerciseModal={setShowHtmlExerciseModal}
        />
      )}
      {
        showUnlockResoucesPopUp && <UnlockResources />
      } */}
    </>
  );
};

export default DigiBookReader;
