import { dispatchType as TYPES } from "../dispatchTypes";

export type Action = {
  type: string;
  payload?: any;
};

export type CreateCurriculumReducer = {
  chapters: [];
};

const initstate = {
  chapters: [],
  isUnitFirst: false,
  esenseTopSchoolData: false,
};

const createCurriculumReducer = (state = initstate, action: Action) => {
  // console.log("Action payload ", action, TYPES.createCurriculum.delete);
  // if(TYPES.createCurriculum.delete === "DELETE_CHAPTER"){
  //   console.log("Update ", {
  //     ...state,
  //     chapters: [
  //       ...state.chapters.slice(0, 0),
  //       {
  //         curriculum: [
  //           ...state.chapters[0].curriculum.slice(
  //             0,
  //             action.payload.chapterIndex
  //           ),
  //           ...state.chapters[0].curriculum.slice(
  //             action.payload.chapterIndex + 1
  //           ),
  //         ],
  //       },
  //     ],
  //   });
  // }

  switch (action?.type) {
    case TYPES?.createCurriculum?.chapters: {
      if (typeof action?.payload?.chapterIndex !== "undefined" && state?.chapters?.length) {
        return {
          ...state,
          chapters: [
            ...state?.chapters?.slice(0, 0),
            {
              curriculum: [
                ...state?.chapters[0]?.curriculum?.slice(
                  0,
                  action?.payload?.chapterIndex
                ),
                {
                  ...state?.chapters[0]?.curriculum[action.payload.chapterIndex],
                  topics: action?.payload?.topics,
                },
                ...state?.chapters[0]?.curriculum?.slice(
                  action?.payload?.chapterIndex + 1
                ),
              ],
            },
          ],
        };
      } else {
        return {
          ...state,
          ...(action.payload || {}),
        };
      }
    }
    case TYPES.createCurriculum.delete: {
      return {
        ...state,
        chapters: [
          ...state.chapters.slice(0, 0),
          {
            curriculum: [
              ...(state?.chapters[0]?.curriculum?.slice(
                0,
                action.payload.chapterIndex
              ) || []),
              ...(state?.chapters[0]?.curriculum?.slice(
                action.payload.chapterIndex + 1
              ) || []),
            ],
          },
        ],
      }
      // if (
      //   typeof action.payload.chapterIndex !== "undefined" &&
      //   typeof action.payload.topicType === "Chapters"
      // ) {
      //   alert("resudle");
      //   debugger;
      //   console.log("Update ", {
      //     ...state,
      //     chapters: [
      //       ...state.chapters.slice(0, 0),
      //       {
      //         curriculum: [
      //           ...state.chapters[0].curriculum.slice(
      //             0,
      //             action.payload.chapterIndex
      //           ),
      //           ...state.chapters[0].curriculum.slice(
      //             action.payload.chapterIndex + 1
      //           ),
      //         ],
      //       },
      //     ],
      //   });
      //   return {
      //     ...state,
      //     chapters: [
      //       ...state.chapters.slice(0, 0),
      //       {
      //         curriculum: [
      //           ...state.chapters[0].curriculum.slice(
      //             0,
      //             action.payload.chapterIndex
      //           ),
      //           ...state.chapters[0].curriculum.slice(
      //             action.payload.chapterIndex + 1
      //           ),
      //         ],
      //       },
      //     ],
      //   };
      // } else if (
      //   typeof action.payload.chapterIndex !== "undefined" &&
      //   (action.payload.topicType === "Topics" ||
      //     action.payload.topicType === "Learning Objectives" ||
      //     action.payload.topicType === "Outcomes")
      // ) {
      //   console.log("reducer ", action)
      //   return {
      //     ...state,
      //     chapters: [
      //       ...state.chapters.slice(0, 0),
      //       {
      //         curriculum: [
      //           ...state.chapters[0].curriculum.slice(
      //             0,
      //             action.payload.chapterIndex
      //           ),
      //           ...state.chapters[0].curriculum.slice(
      //             action.payload.chapterIndex + 1
      //           ),
      //         ],
      //       },
      //     ],
      //   };
      // }
    }
    case TYPES.createCurriculum.getTopSchoolChapter: {
      return {
        ...state,
        esenseTopSchoolData: action.payload,
      };
    }

    case TYPES.revoke:
      return initstate;

    default:
      return state;
  }
};

export default createCurriculumReducer;
