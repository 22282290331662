import useAssetState from "./useAssetState";
import useBookState from "./useBookState";
import usePdfReader from "./usePdfReader";


const containers = {
  reader: usePdfReader,
  bookDetails: useBookState,
  assetDetails: useAssetState
};

export default containers;
