import React from "react";
import io from "socket.io-client";
import { SOCKET_URL } from "../shared/constants";

const rawStateInfo:any= localStorage.getItem("state")
const stateInfo = JSON.parse(rawStateInfo)
let userId = stateInfo?.login?.userData?.userId
let tenantId = stateInfo?.tenant?.tenantId
let shortCode = stateInfo?.tenant?.instituteShortCode
export const socket = io.connect(SOCKET_URL, {
  transports: ["websocket"],
  forceNew: false,
  query:{tenantId: tenantId, userId:userId, shortCode: shortCode, schemaName : `${shortCode}_${tenantId}`}
});
const SocketContext = React.createContext(socket);

export default SocketContext;