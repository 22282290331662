import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAppointmentNotify } from "../../api/reminderNotification";
import ReminderNotification from "../../components/Calendar/Modal/Notification/ReminderNotifcation";
import SocketContext from "../../context/socket";
import { RootStore } from "../../redux/store";
import { x_tenant_id } from "../constants";

const SocketNotification: React.FC = () => {
  const userId = useSelector(
    (state: RootStore) => state?.login?.userData?.userId
  );
  const tenantId = x_tenant_id;
  const socket = React.useContext(SocketContext);
  const [openNotification, setOpenNotification] = useState(false);
  const [notifyData, setNotifyData] = useState([]);
  const [socketData, setsocketData] = useState([]);

  const notificationToggle = (id: number) => {
    if (openNotification && notifyData.length) {
      let reducedData = notifyData.filter((el:any) => el?.CalendarJobID !== id);
      if (!!reducedData) {
        setOpenNotification(true);
        setNotifyData(reducedData);
      } else {
        setNotifyData([]);
        setOpenNotification(false);
      }
    } else {
      setOpenNotification(false);
    }
  };
  
  // Old code reference before migrate to notification fn to kafka

  // const getAppointmentNotifyApi = async () => {
  //   let obj = { calendarID: socketData };
  //   const res: any = await getAppointmentNotify(obj);
  //   if (
  //     (res?.status === "200" || res?.status === "201") &&
  //     !!res?.data?.length
  //   ) {
  //     setOpenNotification(true);
  //     setNotifyData(res?.data);
  //   }
  // };

  const getAppointmentNotifyApi = async () => {
    const res: any = socketData
      setOpenNotification(true);
      setNotifyData(res);
  };

  // Old code reference before migrate to notification fn to kafka

  // useEffect(() => {
  //   // emit USER_ONLINE event
  //   socket.emit("appointment", userId, tenantId);

  //   socket.on("appointment", (response: any) => {
  //     socket.emit("appointment_calendarId", userId, tenantId);
  //   });

  //   // subscribe to socket events
  //   socket.on("appointment_calendarId", (response: any) => {
  //     setsocketData(
  //       response?.map((d: any) => d?.toString()).join(",")
  //     );
  //     // if (response?.length > 0) {
  //     // } else {
  //     //   socket.emit("appointment_calendarId", userId, tenantId);
  //     // }
  //   });

  //   // subscribe to socket events
  //   socket.on("error_handler", (response: any) => {
  //     socket.emit("appointment", userId, tenantId);
  //     socket.emit("appointment_calendarId", userId, tenantId);
  //   });
  // }, [socket]);

  // useEffect(() => {
  //   if (!!socketData?.length) getAppointmentNotifyApi();
  // }, [socketData]);


  useEffect(() => {
    socket.emit("Appointment_Notification", userId, tenantId);

    // subscribe to socket events
    socket.on("Appointment_Notification", (response: any) => {
      let p: any = []
      if(response && Object.keys(response).length){
        p.push(response)
      }
      setsocketData(p);
    });

    // subscribe to socket events
    socket.on("error_handler", (response: any) => {
      socket.emit("Appointment_Notification", userId, tenantId);
    });
  }, [socket]);

  useEffect(() => {
    if (!!socketData?.length) getAppointmentNotifyApi();
  }, [socketData]);

  return (
    <div>
      {openNotification && (
        <ReminderNotification
          open={true}
          toggleNotification={notificationToggle}
          notifyData={notifyData}
          onCloseAll={() => {
            setOpenNotification(false);
            setNotifyData([]);
          }}
          isReminder={false}
        />
      )}
    </div>
  );
};

export default SocketNotification;
