import { API } from "../utils/API";

export const fetchHighlights = async (currentPage, bookID) => {
    let response = await API("post", `/api-books/books/${bookID}/highlights/fetch`, {
        pageNumbers: currentPage?.length ? [currentPage] : []
    });
    return sortHighlightedItemsPageNumberWise(response.data);
};

export const sortHighlightedItemsPageNumberWise = async (highlightedItems) => {
    let pageWiseSorted = await highlightedItems.sort((a, b) => a.pageNumber - b.pageNumber);
    return sortHighlightedItemsBasedOnAXIS(pageWiseSorted);
}

export const sortHighlightedItemsBasedOnAXIS = async (highlightedItems) => {
    let axisWiseSorted = await highlightedItems.sort((a, b) => {
        if (a.pageNumber === b.pageNumber) {
            if (a.points[0].y !== b.points[0].y) {
                return a.points[0].y - b.points[0].y;
            } else {
                return a.points[0].x - b.points[0].x;
            }
        }
    });
    return sortHighlightedItems(axisWiseSorted)
};

export const sortHighlightedItems = async (highlightedItems) => {
    var count = 1;
    for (var i = 0; i < highlightedItems.length; i++) {
        if (i == 0) {
            highlightedItems[0].count = count;
        } else {
            if (highlightedItems[i - 1].pageNumber == highlightedItems[i].pageNumber) {
                count++;
            } else {
                count = 1;
            }
            highlightedItems[i].count = count;
        }
    }
    return highlightedItems;
};