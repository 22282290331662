import { combineReducers } from "redux";
import loginReducer, { LoginReducer } from "./loginReducer";
import curriculumReducer, { CurriculumReducer } from "./curriculumReducer";
import snackbarReducer, { SnackBarReducer } from "./snackbarReducer";
import overviewReducer, { OverviewReducer } from "./overviewReducer";
import calendarReducer, { CalendarReducer } from "./calendarReducer";
import notificationReducer, { NotificationReducer } from "./notificationReducer";
import createCurriculumReducer, { CreateCurriculumReducer } from "./createCurriculumReducer";
import confirmationReducer, { ConfirmationReducer } from "./confirmationReducer";
import notesReducer, { NotesReducer } from "./notesReducer";
import tenantReducer, { TenantReducer } from "./tenantReducer";
import offlineLicense, { OfflineLicense } from "./offlineLicense";
import academicYears, { AcademicYear } from "./academicYears";
import mobileMenuStatus, { mobileFixedMenu } from "./mobileFixedMenu";
import gradeReducer, {GradeReducer} from "./gradeReducer";
import orgSuperAdminReducer from "./orgSuperAdminReducer";
import publicationReducer, { PublicationReducer } from "./publicationReducer";
import categoryReducer, { CategoryReducer } from "./categoryReducer";
import topvalutReducer from "./topvaultReducer";
import { loadingReducer } from "./loadingReducer";
import {viewAllSearchFilterReducer} from "./curriculumRedirectReducer";
import { resetFilterReducer } from "./curriculumResetFilterReducer";

export type ReduxState = {
  login: LoginReducer;
  snackbar: SnackBarReducer;
  curriculum: CurriculumReducer;
  overview: OverviewReducer;
  calendar: CalendarReducer;
  notification: NotificationReducer;
  createCurriculum: CreateCurriculumReducer;
  confirmation: ConfirmationReducer;
  notes: NotesReducer;
  tenant: TenantReducer;
  offlineReducer: OfflineLicense;
  currentAcademic: AcademicYear;
  mobileMenuStatus: mobileFixedMenu;
  grade: GradeReducer;
  publication:PublicationReducer;
  category:CategoryReducer;
};

const RootReducer = combineReducers({
  login: loginReducer,
  snackbar: snackbarReducer,
  curriculum: curriculumReducer,
  overview: overviewReducer,
  calendar: calendarReducer,
  notification: notificationReducer,
  createCurriculum: createCurriculumReducer,
  confirmation: confirmationReducer,
  notes: notesReducer,
  tenant: tenantReducer,
  orgsuperadmin: orgSuperAdminReducer,
  offlineReducer: offlineLicense,
  currentAcademic: academicYears,
  mobileMenuStatus: mobileMenuStatus,
  grade: gradeReducer,
  publication:publicationReducer,
  category:categoryReducer,
  topvault:topvalutReducer,
  loadingVal:loadingReducer,
  viewAllSearchFilterReducer:viewAllSearchFilterReducer,
  resetFilterReducer:resetFilterReducer
});

export default RootReducer;
