import Axios, { AxiosInstance } from "axios";
import { constants, x_tenant_id, auth_token } from "../shared/constants";
import store from "../redux/store";
import { logout, setSnackbar } from "../redux/actions/loginActionTypes";
import * as Sentry from "@sentry/react";

const serives = (service: AxiosInstance) => {
  service.interceptors.request.use(
    (config ) => {
      if (localStorage.getItem("auth_token")) {
        config.headers["Authorization"] = `Bearer ${localStorage.getItem(
          "auth_token"
        )}`;
      }
      else{
        let auth_token = store.getState().login?.token || '' 
        config.headers["Authorization"] = `Bearer ${auth_token}`;
      }
      return config;
    },
    (error) => {
      Sentry.captureException(error);
      return Promise.reject(error);
    }
  );
  service.interceptors.response.use((resp) => {
    if (resp?.data?.status === 401 && resp?.status !==200 && resp?.status !==201 )
     {
      store.dispatch(logout());
      localStorage.clear();
      window.location.href = "/"
    } else {
      return resp;
    }
  },(error) => {
    if (
      error?.response?.status === 503 &&
      (error?.response?.data?.response?.maintenance?.length > 0 ||
        (error?.response?.data?.response?.message?.length > 0 &&
          error?.response?.data?.response?.message?.some((d: any)=>d?.ConfigKey === "MAINTENANCE_NAME")))) 
    {
      localStorage.clear();
      store.dispatch(logout());
      window.location.href = "/";
    }
    if(error && error?.response?.data && typeof(error?.response?.data?.response?.message?.message)==="string" && error?.response?.data?.response?.message?.message?.trim().toLowerCase() === "Application Access Denied, Please Contact Administrator"?.trim().toLowerCase()){
      store.dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: error?.response?.data?.response?.message?.message,
        })
      );
      setTimeout(()=>{
        localStorage.clear();
        store.dispatch(logout());
        window.location.href = "/";
      },3000)
    }
     else if(error?.response?.data?.statusCode == 401) {
      localStorage.clear();
      store.dispatch(logout());
      window.location.href = "/";
    }
    return Promise.reject(error);
  })
}

export const AuthApiCall = Axios.create({
  baseURL: constants.authURL,
  headers: { 'x-tenant-id': x_tenant_id },
});
serives(AuthApiCall)
export const TenantApiCall = Axios.create({
  baseURL: constants.tenantApiCall,
});
export const OfflineApiCall = Axios.create({
  baseURL: constants.tenantApiCall,
});
export const TeacherApiCall = Axios.create({
  baseURL: constants.teacherApiCall,
  headers: { 'x-tenant-id': x_tenant_id },
});
serives(TeacherApiCall)
export const FileApiCall = Axios.create({
  baseURL: constants.fileApiCall,
  headers: { 'x-tenant-id': x_tenant_id },
});
serives(FileApiCall)
export const LibraryApiCall = Axios.create({
  baseURL: constants.libraryApiCall,
  headers: { 'x-tenant-id': x_tenant_id },
});
serives(LibraryApiCall)
export const SaServiceApiCall = Axios.create({
  baseURL: constants.saServiceCall,
  headers: { 'x-tenant-id': x_tenant_id },
});
serives(SaServiceApiCall)
export const CyborgServiceApiCall = Axios.create({
  baseURL: constants.cyborgBaseURL,
  headers: { 'Access-Control-Allow-Origin': '*/*' },
});
serives(CyborgServiceApiCall);
export const StudentServiceApiCall = Axios.create({
  baseURL: constants.studentServiceCall,
  headers: { 'x-tenant-id': x_tenant_id },
});
serives(StudentServiceApiCall)
export const GenerateTemplatesCall = Axios.create({
  baseURL: constants.exportGenerateTemplateApiCall,
  headers: { 'x-tenant-id': x_tenant_id },
});
serives(GenerateTemplatesCall)
export const dlTemplateCall=Axios.create({
  baseURL:constants.adminBaseUrl,
  headers: { 'x-tenant-id': x_tenant_id },
})
serives(dlTemplateCall)
export const TemplateManagement=Axios.create({
  baseURL:constants.templateManagement,
  headers: { 'x-tenant-id': x_tenant_id },
})
serives(TemplateManagement)
export const TemplateManagement_V2=Axios.create({
  baseURL:constants.templateManagement_v2,
  headers: { 'x-tenant-id': x_tenant_id },
})
serives(TemplateManagement_V2)
// Wrapper for Download
export const TeacherApiDownloadCall = Axios.create({
  baseURL: constants.teacherApiCall,
  responseType: 'arraybuffer',
  headers: { 'x-tenant-id': x_tenant_id},
});
serives(TeacherApiDownloadCall)
export const SaServiceApiDownloadCall = Axios.create({
  baseURL: constants.saServiceCall,
  responseType: 'arraybuffer',
  headers: { 'x-tenant-id': x_tenant_id},
});
serives(SaServiceApiDownloadCall)
export const admissionOnBoardingApiCall = Axios.create({
  baseURL: constants.admissionModuleAPI,
  headers: { 'x-tenant-id': x_tenant_id }
});
serives(admissionOnBoardingApiCall)
export const FeeMangementAPICall = Axios.create({
  baseURL: constants.feeManagementAPI,
  headers: { 'x-tenant-id': x_tenant_id},
});
serives(FeeMangementAPICall)
export const AdvFeeMangementAPICall = Axios.create({
  baseURL: constants.feeManagementAdvanceAPI,
  headers: { 'x-tenant-id': x_tenant_id},
});
serives(AdvFeeMangementAPICall)
export const VisitorAPICall = Axios.create({
  baseURL: constants.visitorAPI,
  headers: { 'x-tenant-id': x_tenant_id},
});
serives(VisitorAPICall)
export const CyborgAPICall = Axios.create({
  baseURL: constants.reportsCyborgCall,
  headers: { 'x-tenant-id': x_tenant_id},
});
serives(CyborgAPICall)
// #TODO remove mock api
export const DashboardMockApiCall = Axios.create({
  baseURL: constants.dashboardMockAPI,
  headers: { 'Access-Control-Allow-Origin': '*/*' },
});
serives(DashboardMockApiCall);
export const assessmentApiCall = Axios.create({
  baseURL: constants.assessmentManagement,
  headers: { 'x-tenant-id': x_tenant_id },
});
serives(assessmentApiCall)
export const AdmissionManagement=Axios.create({
  baseURL:constants.admissionModuleAPI,
  headers: { 'x-tenant-id': x_tenant_id },
});
serives(AdmissionManagement)

export const NGWApiCall = Axios.create({
  baseURL: constants.ngwBaseUrl,
  headers: { "x-tenant-id": x_tenant_id },
});
serives(NGWApiCall);

export const TMSApiCall = Axios.create({
  baseURL: constants.tmsUrl,
  headers: { "x-tenant-id": x_tenant_id },
});
serives(TMSApiCall);

