import React from "react";
import { Snackbar, Alert, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import DeleteIcon from "../../../../assets/img/deleteIcon.svg";
import AlarmIcon from "../../../../assets/img/AlarmWhite.svg";
import AppointmentsViewWhite from "../../../../assets/img/AppointmentsViewWhite.svg";
import { NotificationReducer } from "../../../../redux/reducers/notificationReducer";
import { putNotifyDone } from "../../../../api/reminderNotification";
import { setSnackbar } from "../../../../redux/actions/loginActionTypes";
import "./ReminderNotifcation.css";
import moment from "moment";
import { reload } from "../../../../redux/actions/calendarActionTypes";

type Props = {
  open: boolean;
  toggleNotification: (id: number) => void;
  onCloseAll: () => void;
  notifyData: NotificationReducer[];
  setDltReminderIdAndType?: any;
  toggleDeleteRemainder?: () => void;
  isReminder:boolean;
};

const ReminderNotification: React.FC<Props> = ({open, toggleNotification, notifyData, onCloseAll, setDltReminderIdAndType, toggleDeleteRemainder, isReminder}) => {
  const dispatch = useDispatch();

  const putNotifyDoneApi = async(CalendarID: number, CalendarJobID: number) => {
    let response = await putNotifyDone(CalendarID);
    if (response?.status === "200" || response?.status === "201") {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: response.message,
        })
      );
      toggleNotification(CalendarJobID);
      dispatch(reload(true))
    } else {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage:
            (!!response?.data && response.data.message) || "Error Occured",
        })
      );
    }
  }

  return (
    <div className="notification-container">
      {!!notifyData && notifyData.map((data, index) => (
         <Snackbar
         open={open}
         onClose={onCloseAll}
         autoHideDuration={60000}
         key={index}
         className="notification-modal-container"
         anchorOrigin={{
           vertical: "top",
           horizontal: "right",
         }}
         ClickAwayListenerProps={{
           onClickAway: () => null
         }}
       >
         <div className="notification-wrapper">
           <CloseIcon
             className="notification_close"
             onClick={() => toggleNotification(data.CalendarJobID)}
           />
           <Alert
             elevation={6}
             variant="filled"
             icon={false}
             className="notification-alert"
           >
             <div className="notification-content">
               <div className="notification-header">
                 <div className="notification-header-title">
                   <div className="notification-icon calendar-remaind-violet_bg">
                     <img src={isReminder ? AlarmIcon : AppointmentsViewWhite} alt={""} />
                   </div>
                   <div className="notification-header-timer">
                    {isReminder ? 'Reminder': 'Appointment'} 
                   </div>
                 </div>
                 <div className="notification-header-timer">
                  {isReminder && `${moment(data.CalendarDateFrom).subtract('5', 'h').subtract('30', 'm').diff(moment(), 'm')} M`} 
                 </div>
               </div>
               <div className="notification-text-content">
                 <span title={data.Title} className="notification_title">
                   {data.Title}
                 </span>
                 <span title={data.Description} className="notification_desc">
                   {data.Description}
                 </span>
               </div>
               {isReminder &&
               <div className="notification-btn-container">
                 <Button
                   variant="text"
                   type="button"
                   className="notification-btn notification-mark-btn"
                   onClick={() => putNotifyDoneApi(data.CalendarID, data.CalendarJobID)}
                 >
                   MARK DONE
                 </Button>
                 <Button
                   startIcon={<img src={DeleteIcon} alt="" />}
                   className="notification-btn notification-dlt-btn"
                   variant="text"
                   type="button"
                   onClick={() => {
                    toggleDeleteRemainder && toggleDeleteRemainder();
                    setDltReminderIdAndType && setDltReminderIdAndType({
                    reminderId: data.CalendarJobID,
                    reminderType: data.RepeatID,
                    deleteDate: data.CalendarDateFrom,
                  })}}
                  >
                   Delete
                 </Button>
               </div>}
             </div>
           </Alert>
         </div>
       </Snackbar>
      ))}
    </div>
  );
};

export default ReminderNotification;
