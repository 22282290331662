import { dispatchType as TYPES } from "../dispatchTypes";

export type Action = {
  type: string;
  payload?: any;
};

export type TopvaultReducer = {
  loggedInUserID?: number;
  loggedUserRoleID?: number;
  topvaultUserID?: number;
  topvaultUserRoleID?: number;
  topvaultUserName?: string;
  isEdit?:boolean
};

const initstate = {
  loggedInUserID: null,
  loggedUserRoleID: null,
  topvaultUserID: null,
  topvaultUserRoleID: null,
  topvaultUserName: null,
  isEdit:null
};

const topvalutReducer = (state = initstate, action: Action) => {
  switch (action.type) {
    case TYPES.topvault.getTopvault: {
      return {
        ...state,
        ...(action.payload || {}),
      };
    }
    case TYPES.revoke:
      return initstate;

    default:
      return state;
  }
};

export default topvalutReducer;