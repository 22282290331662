import { API } from "../utils/API";
import { Analyze } from "../Helpers/Analyze";

var currentPages;
var BaseURL = process.env.reader_pdf_url;
var CrossSite =  process.env.CROSS_SITE;
var isAdmin = localStorage.getItem("isAdmin");
let bookVersionId = localStorage.getItem("bookVersionId");
let bookID = localStorage.getItem("bookId");
let bodyWidth;
let bodyHeight;

var windowHeight, windowWidth;

var heightRatio;// = 0.604615 //Height Ratio = 500 / 800 = 0.625
var widthRatio;// = 0.604615 //Width Ratio = 350 / 650 ≈ 0.5385

var isMobileView = false;

export const Interactives = (interactives, pages, isCommentHidden, isMobile, width, height) => {
  isMobileView = isMobile;
  widthRatio = width / 621;
  heightRatio = height / 788;
  currentPages = pages;
  interactives.map((interactive) => {
    switch (interactive.type) {
      case "image":
        // handleImage(interactive);
        pictureHandler(interactive);
        break;
      case "audio":
        // handleAudio(interactive);
        audioHandler(interactive);
        break;
      case "video":
        // handleVideo(interactive);
        videoHandler(interactive);
        break;
      case "doc":
      case "document":
        // handleDocument(interactive);
        docHandler(interactive);
        break;
      case "comment":
        // handleComment(interactive, isCommentHidden);
        commentHandler(interactive, isCommentHidden);
        break;
      case "exercise":
        if (
          interactive.content.questions &&
          interactive.content.questions[0].content.type === "mcq"
        ) {
          // handleExercise(interactive, "mcq");
          exerciseHandler(interactive, "mcq");
        }
        if (
          interactive.content.questions &&
          interactive.content.questions[0]?.content.type === "tf"
        ) {
          // handleExercise(interactive, "tf");
          exerciseHandler(interactive, "tf");
        }
        if (
          interactive.content.questions &&
          interactive.content.questions[0]?.content.type === "fb"
        ) {
          // handleExercise(interactive, "fb");
          exerciseHandler(interactive, "fb");
        }
        if (
          interactive.content.questions &&
          interactive.content.questions[0]?.content.type === "mf"
        ) {
          // handleExercise(interactive, "mf");
          exerciseHandler(interactive, "mf");
        }
        break;
      case "link":
        webLinkHandler(interactive);
        break;
      case "html-exercise":
        // if (!isMobileView) {
          htmlExerciseHandler(interactive);
        // }
        break;
      case "html-game":
        if (!isMobileView) {
          // handleGames(interactive);
          gameHandler(interactive);
        }
      default:
        break;
    }
  });
};

const createElement = (id, x, y, height, width, tag) => {
  let element = document.createElement(tag || "div");
  element.style.position = "absolute";
  element.style.zIndex = 100;
  element.style.left = x + "px";
  element.style.top = y + "px";
  if (height) {
    element.style.height = height + "px";
  }
  if (width) {
    element.style.width = width + "px";
  }
  element.style.cursor = "pointer";
  element.dataset.element = "true";
  return element;
};

const getAssetURL = async (id) => {
  let response = await API("get", `/api-interactivities/interactivities/${id}`);
  return response;
};

const getIframes = () => {
  let gettingIframes = document.querySelectorAll("iframe");
  if (currentPages.length > 1) {
    let body0 = gettingIframes[0].contentWindow.document.body;
    let body1 = gettingIframes[1].contentWindow.document.body;
    bodyWidth = window.getComputedStyle(body0).width;
    bodyHeight = window.getComputedStyle(body0).height;
    return {
      body0,
      body1,
    };
  } else {
    let body0 = gettingIframes[0].contentWindow.document.body;
    bodyWidth = window.getComputedStyle(body0).width;
    bodyHeight = window.getComputedStyle(body0).height;
    return {
      body0,
    };
  }
};

const handleImage = (interactive) => {
  const { x, y, h, w } = interactive.content;
  let pageNumber = interactive.content.page;
  let id = interactive._id;
  let fileName = interactive.content.asset.fileName;
  let getArea = document.getElementById("area");
  let url = interactive.content.asset.assetPresignUrl;
  let newImage = createImage(id, x, y, h, w, url, fileName, getArea);
  // if (currentPages.length > 1) {
  //   if (pageNumber % 2 === 0 || pageNumber === 1) {
  //     frames.body0.append(newImage);
  //   } else {
  //     frames.body1.append(newImage);
  //   }
  // } else if (currentPages.length === 1) {
  //   frames.body0.append(newImage);
  // }
  newImage.classList.add("interactive");
  document.body.append(newImage);
};

const handleAudio = (interactive) => {
  const { x, y } = interactive.content;
  let url = interactive.content.asset.assetPresignUrl;
  let id = interactive.content.asset._id;
  let pageNumber = interactive.content.page;
  let fileName = interactive.content.asset.fileName;
  let getArea = document.getElementById("area");
  let newAudio = createAudio(id, x, y, url, "audio", fileName, getArea);
  // if (currentPages.length > 1) {
  //   if (pageNumber % 2 === 0 || pageNumber === 1) {
  //     frames.body0.append(newAudio);
  //   } else {
  //     frames.body1.append(newAudio);
  //   }
  // } else if (currentPages.length === 1) {
  //   frames.body0.append(newAudio);
  // }
  newAudio.classList.add("interactive");

  document.body.append(newAudio);
};

const handleVideo = (interactive) => {
  const { x, y, h, w } = interactive.content;
  let url = interactive.content.asset.assetPresignUrl;
  let id = interactive.content.asset._id;
  let pageNumber = interactive.content.page;
  let getArea = document.getElementById("area");
  let fileName = interactive.content.asset.fileName;
  let newVideo = createNewVideo(id, x, y, url, h, w, "div", fileName, getArea);
  // if (currentPages.length > 1) {
  //   if (pageNumber % 2 === 0 || pageNumber === 1) {
  //     getArea.body0.append(newVideo);
  //   } else {
  //     getArea.body1.append(newVideo);
  //   }
  // } else if (currentPages.length === 1) {
  //   getArea.body0.append(newVideo);
  // }
  newVideo.classList.add("interactive");

  document.body.append(newVideo);
};

const handleDocument = (interactive) => {
  const { x, y, h, w } = interactive.content;
  let url = interactive.content.asset.assetPresignUrl;
  let id = interactive.content.asset._id;
  let pageNumber = interactive.content.page;
  let fileName = interactive.content.asset.fileName;
  let getArea = document.getElementById("area");
  let newDoc = createDocument(id, x, y, url, h, w, "div", fileName, getArea);
  // if (currentPages.length > 1) {
  //   if (pageNumber % 2 === 0 || pageNumber === 1) {
  //     frames.body0.append(newDoc);
  //   } else {
  //     frames.body1.append(newDoc);
  //   }
  // } else if (currentPages.length === 1) {
  //   frames.body0.append(newDoc);
  // }
  newDoc.classList.add("interactive");

  document.body.append(newDoc);
};

const handleExercise = (interactive, type) => {
  const { x, y, h, w } = interactive.content;
  let data = interactive.content.questions;
  let id = interactive._id;
  let pageNumber = interactive.content.page;
  let exerciseName = type;
  let getArea = document.getElementById("area");
  let newExercise = createExercise(id, x, y, data, h, w, getArea, exerciseName);
  // if (currentPages.length > 1) {
  //   if (pageNumber % 2 === 0 || pageNumber === 1) {
  //     getArea.body0.append(newExercise);
  //   } else {
  //     getArea.body1.append(newExercise);
  //   }
  // } else if (currentPages.length === 1) {
  //   getArea.body0.append(newExercise);
  // }
  newExercise.classList.add("interactive");

  document.body.append(newExercise);
};

const handleComment = (interactive, isCommentHidden) => {
  const { x, y } = interactive.content;
  let id = interactive._id;
  let pageNumber = interactive.content.page;
  let getArea = document.getElementById("area");
  let newAudio = createComment(id, x, y, interactive, getArea, isCommentHidden);
  // if (currentPages.length > 1) {
  //   if (pageNumber % 2 === 0 || pageNumber === 1) {
  //     getArea.body0.append(newAudio);
  //   } else {
  //     getArea.body1.append(newAudio);
  //   }
  // } else if (currentPages.length === 1) {
  //   getArea.body0.append(newAudio);
  // }
  newAudio.classList.add("interactive");

  document.body.append(newAudio);
};

const handleGames = (interactive, isCommentHidden = false) => {
  const { x, y } = interactive.content;
  let id = interactive._id;
  let pageNumber = interactive.content.page;
  let fileName = interactive.content.asset.fileName;
  let getArea = document.getElementById("area");
  let newGame = createGame(
    id,
    x,
    y,
    interactive,
    getArea,
    (isCommentHidden = false),
    fileName
  );
  // if (currentPages.length > 1) {
  //   if (pageNumber % 2 === 0 || pageNumber === 1) {
  //     frames.body0.append(newGame);
  //   } else {
  //     frames.body1.append(newGame);
  //   }
  // } else if (currentPages.length === 1) {
  //   frames.body0.append(newGame);
  // }
  newGame.classList.add("interactive");

  document.body.append(newGame);
};

async function htmlExerciseHandler(interactive) {
  const { x, y, h, w } = interactive.content;
  let url = interactive.content.asset.assetPresignUrl;
  let id = interactive.content.asset._id;
  let pageNumber = interactive.content.page;
  let areaElement = document.getElementById("area");
  let fileName = interactive.content.asset.fileName;
  let getGameElement = await createHtmlExerciseElement(id, x, y, url, h, w, "div", fileName, areaElement, interactive);
  // areaElement.appendChild(getGameElement);
  addElementToPage(pageNumber, getGameElement);
};

async function createHtmlExerciseElement(id, x, y, url, h, w, tag, fileName, areaElement, data) {
  let rectBounds = areaElement.getBoundingClientRect();
  //Creating Game Container
  let htmlExerciseElementContainer = document.createElement("div");
  htmlExerciseElementContainer.id = id;
  htmlExerciseElementContainer.className = "interactive";
  htmlExerciseElementContainer.style.position = "absolute";
  htmlExerciseElementContainer.style.zIndex = 999;
  htmlExerciseElementContainer.style.left = !isMobileView ? x + "px" : x * widthRatio + "px";
  htmlExerciseElementContainer.style.top = !isMobileView ? y + "px" : y * heightRatio + "px";
  htmlExerciseElementContainer.style.cursor = "pointer";
  htmlExerciseElementContainer.dataset.element = "true";
  htmlExerciseElementContainer.style.backgroundColor = "#c7f0fc";
  htmlExerciseElementContainer.draggable = true;
  htmlExerciseElementContainer.style.borderRadius = "5px";
  htmlExerciseElementContainer.style.height = "24px";
  htmlExerciseElementContainer.style.width = "24px";
  htmlExerciseElementContainer.style.transform = isMobileView ? "scale(0.7)" : null;
  htmlExerciseElementContainer.onclick = async function (event) {
    if (data?.content?.asset?.locked && !isAdmin) {
      window.parent.postMessage(
        { action: "showUnlockResource" },
        "*"
      )
    } else {
      let assetDetail = await getAssetURL(data._id);
      window.parent.postMessage({ action: "showHtmlExercise", data: assetDetail }, "*");
      // window.parent.postMessage({ action: "showHtmlExercise", data: data }, "*");
      Analyze(bookID, "int", "hexe", data._id, data.content.asset._id, data.content.page);
    }
  };
  //Creating Game Container

  //Inside Game Container GameIcon
  let htmlExerciseIcon = document.createElement("img");
  htmlExerciseIcon.src = `${BaseURL}/public/images/htmlExerciseIcons.svg`;
  htmlExerciseIcon.style.height = "24px";
  htmlExerciseIcon.style.width = "24px";
  htmlExerciseElementContainer.appendChild(htmlExerciseIcon);
  //Inside Game Container GameIcon

  var scaleOfBody = 1;
  htmlExerciseElementContainer.draggable = true;
  htmlExerciseElementContainer.style.zIndex = 997;
  let toolTip = addToolTip(htmlExerciseElementContainer, fileName);
  return htmlExerciseElementContainer;
};

const createExercise = (id, x, y, data, h, w, getArea, exerciseName) => {
  let areaRect = getArea.getBoundingClientRect();
  let toolTip;
  let element = createElement(id, x, y, h, w, "div");
  let svg = document.createElement("div");
  let title = exerciseName === "mcq" ? "MCQ" : "True or False";
  svg.innerHTML = `<img src="${BaseURL}/public/images/exerciseIcon.png" style="border-radius: 5px" height="28" width="28" />`;
  svg.style.borderRadius = "10px";
  element.appendChild(svg);
  element.draggable = true;
  element.style.zIndex = 100;
  var scaleOfBody = 1;
  element.style.top = y + "px";
  element.style.left = x + "px";
  element.onclick = function (e) {
    if (exerciseName === "mcq") {
      window.parent.postMessage({ action: "showMCQ", data: data }, "*");
    }
    if (exerciseName === "tf") {
      window.parent.postMessage({ action: "showTrueFalse", data: data }, "*");
    }
    if (exerciseName === "fb") {
      window.parent.postMessage({ action: "showFillBlank", data: data }, "*");
    }
    if (exerciseName === "mf") {
      window.parent.postMessage(
        { action: "showMatchFollowing", data: data },
        "*"
      );
    }
  };
  let fullExerciseName =
    exerciseName === "mcq"
      ? "MCQ"
      : exerciseName === "tf"
        ? "True False"
        : exerciseName === "fb"
          ? "Fill in Blanks"
          : "Match the Following";
  toolTip = addToolTip(element, fullExerciseName);
  toolTipPositionChanger(toolTip, x, y);
  return element;
};

const createImage = (id, x, y, h, w, url, fileName, getArea) => {
  const areaRect = getArea.getBoundingClientRect();
  let toolTip;
  let parentImage = document.createElement("div");
  parentImage.style.backgroundColor = "#c7f0fc";
  parentImage.style.borderRadius = "5px";
  let element = document.createElement("img");
  // eslint-disable-next-line no-undef
  parentImage.id = id || nanoid();
  element.src = `${BaseURL}/public/images/imageIcon.svg`;
  element.style.height = "26px";
  element.style.width = "26px";
  parentImage.style.position = "absolute";
  parentImage.style.zIndex = 100;
  parentImage.style.left = x + "px";
  parentImage.style.top = y + "px";
  parentImage.style.width = 24 + "px";
  parentImage.style.height = 24 + "px";
  parentImage.style.cursor = "pointer";
  parentImage.onclick = function () {
    window.parent.postMessage({ action: "showImage", src: url }, "*");
  };
  parentImage.appendChild(element);
  toolTip = addToolTip(parentImage, isAdmin ? fileName : "Picture");
  toolTipPositionChanger(toolTip, x, y);
  return parentImage;
};

const createVideo = (id, x, y, url, height, width, tag) => {
  let element = createElement(id, x, y, height, width, tag);
  let container = document.createElement("div");
  let video = document.createElement("video");
  let source = document.createElement("source");
  let overlay = document.createElement("img");
  container.style.position = "relative";
  overlay.src = BaseURL + "/public/images/playButton.png";
  overlay.style.width = "100%";
  overlay.style.height = height + "px";
  overlay.style.opacity = "0.5";
  overlay.style.position = "absolute";
  overlay.style.zIndex = 100;
  source.src = url;
  source.type = "video/mp4";
  video.height = height;
  video.width = width;
  video.style.position = "absolute";
  video.style.objectFit = "fill";
  video.style.display = "none";
  video.controls = true;
  video.disablePictureInPicture = "true";
  video.controlsList = "nodownload noplaybackrate";
  let fullscreenButton = document.createElement("button");
  fullscreenButton.innerHTML = `<svg id="fullScreenButton" width="24" height="24" viewBox="0 0 24 24" fill="#00AAD3" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 14H5V19H10V17H7V14ZM5 10H7V7H10V5H5V10ZM17 17H14V19H19V14H17V17ZM14 5V7H17V10H19V5H14Z" fill="#00AAD3"/>
    </svg>`;
  fullscreenButton.id = "fullScreenButton";
  fullscreenButton.style.position = "absolute";
  fullscreenButton.style.right = "0";
  fullscreenButton.style.cursor = "pointer";
  fullscreenButton.style.zIndex = 100;
  fullscreenButton.style.display = "none";
  fullscreenButton.style.border = "none";
  fullscreenButton.style.background = "none";
  const toggleFullscreen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    fullscreenButton.style.display = "block";
    fullscreenButton.style.left = "0";
    fullscreenButton.style.bottom = "0";
    window.parent.postMessage(
      { action: "playVideo", src: url, currentTime: video.currentTime },
      "*"
    );
  };
  fullscreenButton.onclick = (e) => toggleFullscreen(e);
  video.onclick = function (event) {
    event.preventDefault();
    let target = event.target;
    if (target.paused) {
      target.play();
    } else {
      target.pause();
      overlay.style.display = "block";
      video.style.display = "none";
      fullscreenButton.style.display = "none";
    }
  };
  overlay.onclick = function () {
    overlay.style.display = "none";
    video.style.display = "block";
    fullscreenButton.style.display = "block";
    video.play();
  };
  video.appendChild(source);
  container.appendChild(video);
  container.appendChild(overlay);
  element.appendChild(container);
  element.appendChild(fullscreenButton);
  return element;
};

const createNewVideo = (id, x, y, url, tag, h, w, fileName, getArea) => {
  let areaRect = getArea.getBoundingClientRect();
  let toolTip;
  let parentVideo = document.createElement("div");
  parentVideo.style.backgroundColor = "#c7f0fc";
  parentVideo.style.borderRadius = "5px";
  let element = document.createElement("img");
  // eslint-disable-next-line no-undef
  parentVideo.id = id || nanoid();
  element.src = `${BaseURL}/public/images/videoIcon.svg`;
  element.style.height = "28px";
  element.style.width = "28px";
  parentVideo.style.position = "absolute";
  parentVideo.style.zIndex = 100;
  parentVideo.style.left = x + "px";
  parentVideo.style.top = y + "px";
  parentVideo.style.width = 28 + "px";
  parentVideo.style.height = 28 + "px";
  parentVideo.style.cursor = "pointer";
  parentVideo.onclick = function () {
    window.postMessage({ action: "showVideo", src: url }, "*");
  };
  parentVideo.appendChild(element);
  toolTip = addToolTip(parentVideo, isAdmin ? fileName : "Video");
  toolTipPositionChanger(toolTip, x, y);
  return parentVideo;
};

const createAudio = (id, x, y, url, tag, fileName, getArea) => {
  let areaRect = getArea.getBoundingClientRect();

  let toolTip;
  let parentAudio = document.createElement("div");
  parentAudio.style.backgroundColor = "#c7f0fc";
  parentAudio.style.borderRadius = "5px";
  parentAudio.style.height = "28px";
  parentAudio.style.width = "28px";
  let element = document.createElement("div");
  parentAudio.id = id;
  parentAudio.style.position = "absolute";
  parentAudio.style.zIndex = 100;
  parentAudio.style.left = x + "px";
  parentAudio.style.top = y + "px";
  // element.style.padding = "10px";
  parentAudio.dataset.element = "true";
  parentAudio.id = id;
  parentAudio.style.border = "none";
  parentAudio.style.cursor = "pointer";
  element.innerHTML = `<svg width="28" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1996_55904)">
    <path d="M20 2H8C6.9 2 6 2.9 6 4V16C6 17.1 6.9 18 8 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM18 7H15V12.5C15 13.88 13.88 15 12.5 15C11.12 15 10 13.88 10 12.5C10 11.12 11.12 10 12.5 10C13.07 10 13.58 10.19 14 10.51V5H18V7ZM4 6H2V20C2 21.1 2.9 22 4 22H18V20H4V6Z" fill="#2f80AD"/>
    </g>
    <defs>
    <clipPath id="clip0_1996_55904">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg>`;
  parentAudio.onclick = function (event) {
    window.parent.postMessage({ action: "playAudio", src: url }, "*");
  };
  parentAudio.appendChild(element);
  toolTip = addToolTip(parentAudio, isAdmin ? fileName : "Audio");
  toolTipPositionChanger(toolTip, x, y);
  return parentAudio;
};

const createDocument = (id, x, y, url, height, width, tag, fileName, getArea) => {
  const areaRect = getArea.getBoundingClientRect();
  let toolTip;
  let element = createElement(id, x, y, 28, 28, "div");
  element.style.backgroundColor = "#c7f0fc";
  element.style.borderRadius = "5px";
  element.style.top = y + "px";
  element.style.left = x + "px";
  let svg = document.createElement("div");
  svg.innerHTML = `<svg width="28" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1996_55901)">
    <path d="M4 6H2V20C2 21.1 2.9 22 4 22H18V20H4V6ZM20 2H8C6.9 2 6 2.9 6 4V16C6 17.1 6.9 18 8 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM19 11H9V9H19V11ZM15 15H9V13H15V15ZM19 7H9V5H19V7Z" fill="#2f80AD"/>
    </g>
    <defs>
    <clipPath id="clip0_1996_55901">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg>`;
  element.appendChild(svg);
  element.onclick = function () {
    // window.open(url, "_blank");
    window.parent.postMessage({ action: "showDoc", src: url }, "*");
  };
  toolTip = addToolTip(element, isAdmin ? fileName : "Document");
  toolTipPositionChanger(toolTip, x, y);
  return element;
};

const createComment = (id, x, y, data, getArea, isCommentHidden) => {
  let areaRect = getArea.getBoundingClientRect();

  let toolTip;
  let element = createElement(id, x, y, "25", "25", "div");
  element.className = "comment";
  let svg = document.createElement("div");
  svg.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="30" height="30" x="0" y="0" viewBox="0 0 60 60" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g>
    <path d="M30,1.5c-16.542,0-30,12.112-30,27c0,5.204,1.646,10.245,4.768,14.604c-0.591,6.537-2.175,11.39-4.475,13.689
      c-0.304,0.304-0.38,0.769-0.188,1.153C0.275,58.289,0.625,58.5,1,58.5c0.046,0,0.092-0.003,0.139-0.01
      c0.405-0.057,9.813-1.411,16.618-5.339C21.621,54.71,25.737,55.5,30,55.5c16.542,0,30-12.112,30-27S46.542,1.5,30,1.5z M16,32.5
      c-2.206,0-4-1.794-4-4s1.794-4,4-4s4,1.794,4,4S18.206,32.5,16,32.5z M30,32.5c-2.206,0-4-1.794-4-4s1.794-4,4-4s4,1.794,4,4
      S32.206,32.5,30,32.5z M44,32.5c-2.206,0-4-1.794-4-4s1.794-4,4-4s4,1.794,4,4S46.206,32.5,44,32.5z" fill="#2f80ad" data-original="#000000" class=""></path>
    </g></svg>`;
  element.id = id;
  element.appendChild(svg);
  element = addCloseButton(element);
  if (isCommentHidden === "true") {
    element.style.display = "none";
  }
  element.style.left = x + "px";
  element.style.top = y + "px";
  element.draggable = true;
  element.style.zIndex = 100;
  var scaleOfBody = 1;
  element.draggable = true;
  element.style.zIndex = 100;
  element.ondragend = async function (event) {
    // setSelectedElement({ event });
    let marginLeftToApply = `-${window.getComputedStyle(getArea.body0).marginLeft
      }`;
    let target = event.currentTarget;
    let id = target.id;
    let x = event.clientX;
    if (
      (getArea.body0 && data.content.page % 2 === 0) ||
      data.content.page === 1
    ) {
      x = event.clientX + parseInt(marginLeftToApply.split("px")[0]);
    }
    let y = event.clientY;
    element.style.position = "absolute";
    element.style.top = y / scaleOfBody - element.offsetHeight / 2 + "px";
    element.style.left = x / scaleOfBody - element.offsetWidth / 2 + "px";
    toolTipPositionChanger(
      toolTip,
      x / scaleOfBody - element.offsetWidth / 2,
      y / scaleOfBody - element.offsetWidth / 2
    );
    let response = await API(
      "put",
      `/api-interactivities/admin/interactivities/${element.id}`,
      {
        bookVersion: bookVersionId,
        content: {
          x: x / scaleOfBody - element.offsetWidth / 2,
          y: y / scaleOfBody - element.offsetWidth / 2,
        },
      }
    );
  };
  svg.onclick = function () {
    window.parent.postMessage({ action: "showComment", data: data }, "*");
  };
  if (isAdmin) {
    toolTip = addToolTip(element, "Comment");
    toolTipPositionChanger(toolTip, x, y);
  }
  return element;
};

const createGame = (id, x, y, data, getArea, isCommentHidden, fileName) => {
  let areaRect = getArea.getBoundingClientRect();
  let toolTip;
  let element = createElement(id, x, y, "25", "25", "div");
  element.className = "game";
  let svg = document.createElement("div");
  svg.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="30" height="30" x="0" y="0" viewBox="0 0 53.626 53.626" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M48.831 15.334c-7.083-11.637-17.753-3.541-17.753-3.541-.692.523-1.968.953-2.835.955l-2.858.002c-.867.001-2.143-.429-2.834-.952 0 0-10.671-8.098-17.755 3.539C-2.286 26.97.568 39.639.568 39.639c.5 3.102 2.148 5.172 5.258 4.912 3.101-.259 9.832-8.354 9.832-8.354.556-.667 1.721-1.212 2.586-1.212l17.134-.003c.866 0 2.03.545 2.585 1.212 0 0 6.732 8.095 9.838 8.354 3.106.26 4.758-1.812 5.255-4.912-.001 0 2.858-12.667-4.225-24.302zm-28.457 9.472H16.7v3.541s-.778.594-1.982.579c-1.202-.018-1.746-.648-1.746-.648v-3.471h-3.47s-.433-.444-.549-1.613c-.114-1.169.479-2.114.479-2.114h3.675v-3.674s.756-.405 1.843-.374a4.86 4.86 0 0 1 1.885.443l-.015 3.604h3.47s.606.778.656 1.718c.05.941-.572 2.009-.572 2.009zm16.852 4.036a2.904 2.904 0 0 1-2.906-2.908 2.902 2.902 0 0 1 2.906-2.908 2.909 2.909 0 1 1 0 5.816zm0-8.001a2.903 2.903 0 0 1-2.906-2.907 2.902 2.902 0 0 1 2.906-2.908 2.909 2.909 0 0 1 2.909 2.908 2.91 2.91 0 0 1-2.909 2.907zm7.242 4.295a2.903 2.903 0 0 1-2.906-2.908 2.903 2.903 0 0 1 2.906-2.908 2.91 2.91 0 0 1 2.909 2.908 2.91 2.91 0 0 1-2.909 2.908z" style="" fill="#2f80ad" data-original="#010002" class=""></path></g></svg>`;
  element.id = id;
  element.appendChild(svg);
  if (isCommentHidden === "true") {
    element.style.display = "none";
  }
  element.style.left = x + "px";
  element.style.top = y + "px";
  element.draggable = true;
  element.style.zIndex = 100;
  var scaleOfBody = 1;
  element.draggable = true;
  element.style.zIndex = 100;
  svg.onclick = function () {
    window.parent.postMessage({ action: "showGame", data: data }, "*");
  };
  element = addCloseButton(element)
  toolTip = addToolTip(element, fileName);
  toolTipPositionChanger(toolTip, x, y);
  return element;
};

const addCloseButton = (element) => {
  let closeButton = document.createElement("div");
  closeButton.id = "closeButton";
  closeButton.style.position = "absolute";
  closeButton.style.cursor = "pointer";
  closeButton.style.top = "-19px";
  closeButton.style.right = "-19px";
  closeButton.style.zIndex = "100";
  closeButton.onclick = async function () {
    element.remove();
    let response = await API(
      "delete",
      `/api-interactivities/admin/interactivities/${element.id}`
    );
    if (response) {
      return response;
    }
  };
  closeButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" style="height:20px;width:20px;" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
      <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
    </svg>`;
  element.appendChild(closeButton);
  return element;
};

// const deleteElement = (event) => {
//   if (selectedElement) {
//     if (event.keyCode === 46) {
//       let id = selectedElement.id;
//       // Element.remove({ id });
//       selectedElement.remove();
//       selectedElement = null;
//     }
//   }
// };

const createToolTip = (name) => {
  let toolTipParent = document.createElement("div");
  toolTipParent.className = "tooltipParent";
  toolTipParent.innerHTML = name;
  toolTipParent.style.display = "none";
  toolTipParent.style.position = "fixed";
  toolTipParent.style.fontSize = "14px";
  toolTipParent.style.padding = "6px 8px";
  toolTipParent.style.backgroundColor = "#00AAD3";
  toolTipParent.style.color = "white";
  toolTipParent.style.borderRadius = "10px";
  toolTipParent.style.width = "fit-content";
  toolTipParent.style.fontWeight = "500";
  toolTipParent.style.letterSpacing = "1px";
  return toolTipParent;
};

export const addToolTip = (parentElement, fileName) => {
  let toolTip = createToolTip(fileName);
  parentElement.onmouseenter = function () {
    toolTip.style.display = "block";
  };
  parentElement.onmouseleave = function () {
    toolTip.style.display = "none";
  };
  parentElement.appendChild(toolTip);
  return toolTip;
};

export const toolTipPositionChanger = (toolTip, X, Y) => {
  let x = parseInt(X);
  let y = parseInt(Y);
  if (x > 500 && y < 375) {
    toolTip.style.position = "fixed";
    toolTip.style.right = "10px";
    toolTip.style.left = null;
    toolTip.style.top = y + 35 + "px";
    toolTip.style.bottom = null;
  }
  if (x > 500 && y > 375 && y < 700) {
    toolTip.style.left = null;
    toolTip.style.position = "fixed";
    toolTip.style.right = "10px";
    toolTip.style.top = y + 35 + "px";
    toolTip.style.bottom = null;
  }
  if (x > 500 && y > 700 && y < 750) {
    toolTip.style.position = "fixed";
    toolTip.style.left = null;
    toolTip.style.top = y > bodyHeight ? bodyHeight : y + "px";
    toolTip.style.right = bodyWidth - x + "px";
    toolTip.style.bottom = null;
  }
  if (x > 325 && x < 500 && y > 700) {
    toolTip.style.left = x + 30 + "px";
    toolTip.style.top = y + "px";
    toolTip.style.position = "fixed";
    // toolTip.style.right = "10px";
    // toolTip.style.bottom = "10px";
  }
  if (x < 325 && y > 700) {
    toolTip.style.position = "fixed";
    toolTip.style.left = x + 30 + "px";
    toolTip.style.top = y > bodyHeight ? bodyHeight : y + "px";
  }
  if (x < 500 && y < 650) {
    toolTip.style.top = null;
    toolTip.style.left = null;
    toolTip.style.position = null;
    toolTip.style.right = null;
    toolTip.style.bottom = null;
  }
};

//New Handler & Creator
async function videoHandler(interactive) {
  const { x, y, h, w } = interactive.content;
  let url = interactive.content.asset.assetPresignUrl;
  let id = interactive.content.asset._id;
  let pageNumber = interactive.content.page;
  let areaElement = document.getElementById("area");
  let fileName = interactive.content.asset.fileName;
  let numberOf = interactive.number || "";
  let getVideoElement = await createVideoElement(id, x, y, url, h, w, "div", fileName, areaElement, numberOf, interactive);
  // areaElement.appendChild(getVideoElement);
  addElementToPage(pageNumber, getVideoElement);
};

async function createVideoElement(id, x, y, url, h, w, tag, fileName, areaElement, numberOf, interactive) {
  let rectBounds = areaElement.getBoundingClientRect();
  //Creating Video Container
  let videoElementContainer = document.createElement("div");
  videoElementContainer.id = id;
  videoElementContainer.className = "interactive";
  videoElementContainer.style.position = "absolute";
  videoElementContainer.style.zIndex = 999;
  videoElementContainer.style.left = !isMobileView ? x + "px" : x * widthRatio + "px";
  videoElementContainer.style.top = !isMobileView ? y + "px" : y * heightRatio + "px";
  videoElementContainer.style.cursor = "pointer";
  videoElementContainer.dataset.element = "true";
  videoElementContainer.style.backgroundColor = "#c7f0fc";
  videoElementContainer.draggable = true;
  videoElementContainer.style.borderRadius = "5px";
  videoElementContainer.style.height = "24px";
  videoElementContainer.style.width = "24px";
  videoElementContainer.style.transform = isMobileView ? "scale(0.7)" : null;
  videoElementContainer.onclick = async function () {
    if (interactive?.content?.asset?.locked) {
      window.parent.postMessage(
        { action: "showUnlockResource" },
        "*"
      )
    } else {
      let assetDetail = await getAssetURL(interactive._id);
      window.postMessage({ action: "showVideo", src: assetDetail.content.asset.assetPresignUrl, analyzeData: { bookID: bookID, type: "int", subType: "vid", intId: interactive._id, assetId: interactive.content.asset._id, pageNumber: interactive.content.page } }, "*");
      // Analyze(bookID, "int", "vid", interactive._id, interactive.content.asset._id, interactive.content.page);
    }
  };
  //Creating Video Container

  //Inside Video Container VideoIcon
  let videoIcon = document.createElement("img");
  videoIcon.src = `${BaseURL}/public/images/videoIcon.svg`;
  videoIcon.style.height = "24px";
  videoIcon.style.width = "24px";
  videoElementContainer.appendChild(videoIcon);
  //Inside Video Container VideoIcon

  var scaleOfBody = 1;
  videoElementContainer.draggable = true;
  videoElementContainer.style.zIndex = 997;
  let toolTip = addToolTip(videoElementContainer, isAdmin ? fileName : `Video ${numberOf}`);
  return videoElementContainer;
};

async function audioHandler(interactive) {
  const { x, y, h, w } = interactive.content;
  let url = interactive.content.asset.assetPresignUrl;
  let id = interactive.content.asset._id;
  let pageNumber = interactive.content.page;
  let areaElement = document.getElementById("area");
  let fileName = interactive.content.asset.fileName;
  let numberOf = interactive.number || "";
  let getAudioElement = await createAudioElement(id, x, y, url, h, w, "div", fileName, areaElement, numberOf, interactive);
  // areaElement.appendChild(getAudioElement);
  addElementToPage(pageNumber, getAudioElement);
};

async function createAudioElement(id, x, y, url, h, w, tag, fileName, areaElement, numberOf, interactive) {
  let rectBounds = areaElement.getBoundingClientRect();
  //Creating Audio Container
  let audioElementContainer = document.createElement("div");
  audioElementContainer.id = id;
  audioElementContainer.className = "interactive";
  audioElementContainer.style.position = "absolute";
  audioElementContainer.style.zIndex = 999;
  audioElementContainer.style.left = !isMobileView ? x + "px" : x * widthRatio + "px";
  audioElementContainer.style.top = !isMobileView ? y + "px" : y * heightRatio + "px";
  audioElementContainer.style.cursor = "pointer";
  audioElementContainer.dataset.element = "true";
  audioElementContainer.style.backgroundColor = "#c7f0fc";
  audioElementContainer.draggable = true;
  audioElementContainer.style.borderRadius = "5px";
  audioElementContainer.style.height = "24px";
  audioElementContainer.style.width = "24px";
  audioElementContainer.style.transform = isMobileView ? "scale(0.7)" : null;
  audioElementContainer.onclick = async function (event) {
    if (interactive?.content?.asset?.locked) {
      window.parent.postMessage(
        { action: "showUnlockResource" },
        "*"
      )
    } else {
      let assetDetail = await getAssetURL(interactive._id);
      window.parent.postMessage({ action: "playAudio", src: assetDetail.content.asset.assetPresignUrl, analyzeData: { bookID: bookID, type: "int", subType: "aud", intId: interactive._id, assetId: interactive.content.asset._id, pageNumber: interactive.content.page } }, "*");
      // Analyze(bookID, "int", "aud", interactive._id, interactive.content.asset._id, interactive.content.page);
    }
  };
  //Creating Audio Container

  //Inside Audio Container VideoIcon
  let audioIcon = document.createElement("img");
  audioIcon.src = `${BaseURL}/public/images/audioIcon.svg`;
  audioIcon.style.height = "24px";
  audioIcon.style.width = "24px";
  audioElementContainer.appendChild(audioIcon);
  //Inside Audio Container VideoIcon

  var scaleOfBody = 1;
  audioElementContainer.draggable = true;
  audioElementContainer.style.zIndex = 997;
  let toolTip = addToolTip(audioElementContainer, isAdmin ? fileName : `Audio ${numberOf}`);
  return audioElementContainer;
};

async function docHandler(interactive) {
  const { x, y, h, w } = interactive.content;
  let url = interactive.content.asset.assetPresignUrl;
  let id = interactive.content.asset._id;
  let pageNumber = interactive.content.page;
  let areaElement = document.getElementById("area");
  let fileName = interactive.content.asset.fileName;
  let numberOf = interactive.number || "";
  let getDocElement = await createDocElement(id, x, y, url, h, w, "div", fileName, areaElement, numberOf, interactive);
  // areaElement.appendChild(getDocElement);
  addElementToPage(pageNumber, getDocElement);
};

async function createDocElement(id, x, y, url, h, w, tag, fileName, areaElement, numberOf, interactive) {
  let rectBounds = areaElement.getBoundingClientRect();
  //Creating Document Container
  let docElementContainer = document.createElement("div");
  docElementContainer.id = id;
  docElementContainer.className = "interactive";
  docElementContainer.style.position = "absolute";
  docElementContainer.style.zIndex = 999;
  docElementContainer.style.left = !isMobileView ? x + "px" : x * widthRatio + "px";
  docElementContainer.style.top = !isMobileView ? y + "px" : y * heightRatio + "px";
  docElementContainer.style.cursor = "pointer";
  docElementContainer.dataset.element = "true";
  docElementContainer.style.backgroundColor = "#c7f0fc";
  docElementContainer.draggable = true;
  docElementContainer.style.borderRadius = "5px";
  docElementContainer.style.height = "24px";
  docElementContainer.style.width = "24px";
  docElementContainer.style.transform = isMobileView ? "scale(0.7)" : null;
  docElementContainer.onclick = async function (event) {
    if (interactive?.content?.asset?.locked) {
      window.parent.postMessage(
        { action: "showUnlockResource" },
        "*"
      )
    } else {
      let assetDetail = await getAssetURL(interactive._id);
      window.parent.postMessage({ action: "showDoc", src: assetDetail.content.asset.assetPresignUrl }, "*");
      Analyze(bookID, "int", "doc", interactive._id, interactive.content.asset._id, interactive.content.page);
    }
  };
  //Creating Document Container

  //Inside Document Container VideoIcon
  let docIcon = document.createElement("img");
  docIcon.src = `${BaseURL}/public/images/docIcon.svg`;
  docIcon.style.height = "24px";
  docIcon.style.width = "24px";
  docElementContainer.appendChild(docIcon);
  //Inside Document Container VideoIcon

  var scaleOfBody = 1;
  docElementContainer.draggable = true;
  docElementContainer.style.zIndex = 997;
  let toolTip = addToolTip(docElementContainer, isAdmin ? fileName : `Document ${numberOf}`);
  return docElementContainer;
};

async function pictureHandler(interactive) {
  const { x, y, h, w } = interactive.content;
  let url = interactive.content.asset.assetPresignUrl;
  let id = interactive.content.asset._id;
  let pageNumber = interactive.content.page;
  let areaElement = document.getElementById("area");
  let fileName = interactive.content.asset.fileName;
  let numberOf = interactive.number || "";
  let getPictureElement = await createPictureElement(id, x, y, url, h, w, "div", fileName, areaElement, numberOf, interactive);
  // areaElement.appendChild(getPictureElement);
  addElementToPage(pageNumber, getPictureElement);
};

async function createPictureElement(id, x, y, url, h, w, tag, fileName, areaElement, numberOf, interactive) {
  let rectBounds = areaElement.getBoundingClientRect();
  //Creating Picture Container
  let pictureElementContainer = document.createElement("div");
  pictureElementContainer.id = id;
  pictureElementContainer.className = "interactive";
  pictureElementContainer.style.position = "absolute";
  pictureElementContainer.style.zIndex = 999;
  pictureElementContainer.style.left = !isMobileView ? x + "px" : x * widthRatio + "px";
  pictureElementContainer.style.top = !isMobileView ? y + "px" : y * heightRatio + "px";
  pictureElementContainer.style.cursor = "pointer";
  pictureElementContainer.dataset.element = "true";
  pictureElementContainer.style.backgroundColor = "#c7f0fc";
  pictureElementContainer.draggable = true;
  pictureElementContainer.style.borderRadius = "5px";
  pictureElementContainer.style.height = "24px";
  pictureElementContainer.style.width = "24px";
  pictureElementContainer.style.transform = isMobileView ? "scale(0.7)" : null;
  pictureElementContainer.onclick = async function (event) {
    if (interactive?.content?.asset?.locked) {
      window.parent.postMessage(
        { action: "showUnlockResource" },
        "*"
      )
    } else {
      let assetDetail = await getAssetURL(interactive._id);
      window.parent.postMessage({ action: "showImage", src: assetDetail.content.asset.assetPresignUrl }, "*");
      Analyze(bookID, "int", "img", interactive._id, interactive.content.asset._id, interactive.content.page);
    }
  };
  //Creating Picture Container

  //Inside Picture Container PictureIcon
  let pictureIcon = document.createElement("img");
  pictureIcon.src = `${BaseURL}/public/images/imageIcon.svg`;
  pictureIcon.style.height = "24px";
  pictureIcon.style.width = "24px";
  pictureElementContainer.appendChild(pictureIcon);
  let toolTip = addToolTip(pictureElementContainer, isAdmin ? fileName : `Picture ${numberOf}`);
  //Inside Picture Container PictureIcon

  var scaleOfBody = 1;
  pictureElementContainer.draggable = true;
  pictureElementContainer.style.zIndex = 997;
  return pictureElementContainer;
};

async function exerciseHandler(interactive, exerciseName) {
  const { x, y, h, w } = interactive.content;
  let id = interactive._id;
  let pageNumber = interactive.content.page;
  let areaElement = document.getElementById("area");
  let data = interactive.content.questions;
  let getExerciseElement = await createExerciseElement(id, x, y, h, w, "div", areaElement, exerciseName, data, interactive);
  // areaElement.appendChild(getExerciseElement);
  addElementToPage(pageNumber, getExerciseElement);
};

async function createExerciseElement(id, x, y, h, w, tag, areaElement, exerciseName, data, interactive) {
  let rectBounds = areaElement.getBoundingClientRect();
  //Creating Exercise Container
  let exerciseElementContainer = document.createElement("div");
  exerciseElementContainer.id = id;
  exerciseElementContainer.className = "interactive";
  exerciseElementContainer.style.position = "absolute";
  exerciseElementContainer.style.zIndex = 999;
  exerciseElementContainer.style.left = !isMobileView ? x + "px" : x * widthRatio + "px";
  exerciseElementContainer.style.top = !isMobileView ? y + "px" : y * heightRatio + "px";
  exerciseElementContainer.style.cursor = "pointer";
  exerciseElementContainer.dataset.element = "true";
  exerciseElementContainer.style.backgroundColor = "#c7f0fc";
  exerciseElementContainer.draggable = true;
  exerciseElementContainer.style.borderRadius = "5px";
  exerciseElementContainer.style.height = "24px";
  exerciseElementContainer.style.width = "24px";
  exerciseElementContainer.transform = isMobileView ? "scale(0.7)" : null;
  exerciseElementContainer.onclick = async function (event) {
    if (interactive?.content?.asset?.locked) {
      window.parent.postMessage(
        { action: "showUnlockResource" },
        "*"
      )
    } else {
      let assetDetail = await getAssetURL(interactive._id);
      if (exerciseName === "mcq") {
        window.parent.postMessage({ action: "showMCQ", data: assetDetail.content.questions }, "*");
      }
      if (exerciseName === "tf") {
        window.parent.postMessage({ action: "showTrueFalse", data: assetDetail.content.questions }, "*");
      }
      if (exerciseName === "fb") {
        window.parent.postMessage({ action: "showFillBlank", data: assetDetail.content.questions }, "*");
      }
      if (exerciseName === "mf") {
        window.parent.postMessage(
          { action: "showMatchFollowing", data: assetDetail.content.questions },
          "*"
        );
      }
      Analyze(bookID, "int", "exe", id, null, interactive.content.page);
    }
  };
  //Creating Exercise Container

  //Inside Exercise Container ExerciseIcon
  let exerciseIcon = document.createElement("img");
  exerciseIcon.src = `${BaseURL}/public/images/exerciseIcon.png`;
  exerciseIcon.style.height = "24px";
  exerciseIcon.style.width = "24px";
  exerciseElementContainer.appendChild(exerciseIcon);
  //Inside Exercise Container ExerciseIcon

  var scaleOfBody = 1;
  exerciseElementContainer.draggable = true;
  exerciseElementContainer.style.zIndex = 997;
  let fullExerciseName = exerciseName === "mcq" ? "MCQ" : exerciseName === "tf" ? "True False" : exerciseName === "fb" ? "Fill in Blanks" : "Match the Following";
  let toolTip = addToolTip(exerciseElementContainer, fullExerciseName);
  return exerciseElementContainer;
};

async function gameHandler(interactive) {
  const { x, y, h, w } = interactive.content;
  let url = interactive.content.asset.assetPresignUrl;
  let id = interactive.content.asset._id;
  let pageNumber = interactive.content.page;
  let areaElement = document.getElementById("area");
  let fileName = interactive.content.asset.fileName;
  let getGameElement = await createGameElement(id, x, y, url, h, w, "div", fileName, areaElement, interactive);
  // areaElement.appendChild(getGameElement);
  addElementToPage(pageNumber, getGameElement);
};

async function createGameElement(id, x, y, url, h, w, tag, fileName, areaElement, data) {
  let rectBounds = areaElement.getBoundingClientRect();
  //Creating Game Container
  let gameElementContainer = document.createElement("div");
  gameElementContainer.id = id;
  gameElementContainer.className = "interactive";
  gameElementContainer.style.position = "absolute";
  gameElementContainer.style.zIndex = 999;
  gameElementContainer.style.left = !isMobileView ? x + "px" : x * widthRatio + "px";
  gameElementContainer.style.top = !isMobileView ? y + "px" : y * heightRatio + "px";
  gameElementContainer.style.cursor = "pointer";
  gameElementContainer.dataset.element = "true";
  gameElementContainer.style.backgroundColor = "#c7f0fc";
  gameElementContainer.draggable = true;
  gameElementContainer.style.borderRadius = "5px";
  gameElementContainer.style.height = "24px";
  gameElementContainer.style.width = "24px";
  gameElementContainer.style.transform = isMobileView ? "scale(0.7)" : null;
  gameElementContainer.onclick = async function (event) {
    if (data?.content?.asset?.locked) {
      window.parent.postMessage(
        { action: "showUnlockResource" },
        "*"
      )
    } else {
      let assetDetail = await getAssetURL(data._id);
      window.parent.postMessage({ action: "showGame", data: assetDetail }, "*");
      Analyze(bookID, "int", "gme", data._id, data.content.asset._id, data.content.page);
    }
  };
  //Creating Game Container

  //Inside Game Container GameIcon
  let gameIcon = document.createElement("img");
  gameIcon.src = `${BaseURL}/public/images/gamesIcon.svg`;
  gameIcon.style.height = "24px";
  gameIcon.style.width = "24px";
  gameElementContainer.appendChild(gameIcon);
  //Inside Game Container GameIcon

  var scaleOfBody = 1;
  gameElementContainer.draggable = true;
  gameElementContainer.style.zIndex = 997;
  let toolTip = addToolTip(gameElementContainer, fileName);
  return gameElementContainer;
};
async function webLinkHandler(interactive) {
  const { x, y, h, w } = interactive.content;
  let id = interactive.content.asset._id;
  let pageNumber = interactive.content.page;
  let url = interactive.content.url;
  let text = interactive.content.text;
  let areaElement = document.getElementById("area");
  let getGameElement = await createWebLinkElement(id, x, y, url, h, w, "div", text, areaElement, interactive);
  // areaElement.appendChild(getGameElement);
  addElementToPage(pageNumber, getGameElement);
};

async function createWebLinkElement(id, x, y, url, h, w, tag, fileName, areaElement, data) {
  let rectBounds = areaElement.getBoundingClientRect();
  //Creating Game Container
  let gameElementContainer = document.createElement("div");
  gameElementContainer.id = id;
  gameElementContainer.className = "interactive";
  gameElementContainer.style.position = "absolute";
  gameElementContainer.style.zIndex = 999;
  gameElementContainer.style.left = !isMobileView ? x + "px" : x * widthRatio + "px";
  gameElementContainer.style.top = !isMobileView ? y + "px" : y * heightRatio + "px";
  gameElementContainer.style.cursor = "pointer";
  gameElementContainer.dataset.element = "true";
  gameElementContainer.style.backgroundColor = "#c7f0fc";
  gameElementContainer.draggable = true;
  gameElementContainer.style.borderRadius = "5px";
  gameElementContainer.style.height = "24px";
  gameElementContainer.style.width = "24px";
  gameElementContainer.style.transform = isMobileView ? "scale(0.7)" : null;
  gameElementContainer.onclick = async function (event) {
    if (data?.content?.asset?.locked) {
      window.parent.postMessage(
        { action: "showUnlockResource" },
        "*"
      )
    } else {
      let assetDetail = await getAssetURL(data._id);
      window.open(assetDetail.content.url, '_blank');
      Analyze(bookID, "int", "wlk", data._id, data.content.asset._id, data.content.page);
    }
  };
  //Creating Game Container

  //Inside Game Container GameIcon
  let gameIcon = document.createElement("img");
  gameIcon.src = `${BaseURL}/public/images/webLinkIcon.svg`;
  gameIcon.style.height = "24px";
  gameIcon.style.width = "24px";
  gameElementContainer.appendChild(gameIcon);
  //Inside Game Container GameIcon

  var scaleOfBody = 1;
  gameElementContainer.draggable = true;
  gameElementContainer.style.zIndex = 997;
  let toolTip = addToolTip(gameElementContainer, fileName);
  return gameElementContainer;
};

async function commentHandler(interactive) {
  const { x, y, h, w } = interactive.content;
  let id = interactive._id;
  let pageNumber = interactive.content.page;
  let areaElement = document.getElementById("area");
  let getCommentElement = await createCommentElement(id, x, y, h, w, "div", areaElement, interactive);
  // areaElement.appendChild(getCommentElement);
  addElementToPage(pageNumber, getCommentElement);
};

async function createCommentElement(id, x, y, h, w, tag, areaElement, data) {
  let rectBounds = areaElement.getBoundingClientRect();
  //Creating Comment Container
  let commentElementContainer = document.createElement("div");
  commentElementContainer.id = id;
  commentElementContainer.className = "interactive comment";
  commentElementContainer.style.position = "absolute";
  commentElementContainer.style.zIndex = 999;
  commentElementContainer.style.left = !isMobileView ? x + "px" : x * widthRatio + "px";
  commentElementContainer.style.top = !isMobileView ? y + "px" : y * heightRatio + "px";
  commentElementContainer.style.cursor = "pointer";
  commentElementContainer.dataset.element = "true";
  commentElementContainer.style.backgroundColor = "#c7f0fc";
  commentElementContainer.draggable = true;
  commentElementContainer.style.borderRadius = "5px";
  commentElementContainer.style.height = "24px";
  commentElementContainer.style.width = "24px";
  commentElementContainer.transform = isMobileView ? "scale(0.7)" : null;
  //Creating Comment Container

  //Inside Comment Container CommentIcon
  let commentIcon = document.createElement("img");
  commentIcon.src = `${BaseURL}/public/images/commentIcon.svg`;
  commentIcon.style.height = "24px";
  commentIcon.style.width = "24px";
  commentElementContainer.appendChild(commentIcon);
  commentIcon.onclick = function (event) {
    window.parent.postMessage({ action: "showComment", data: data }, "*");
  };
  //Inside Comment Container GameIcon

  commentElementContainer = addCloseButton(commentElementContainer);
  var scaleOfBody = 1;
  commentElementContainer.draggable = true;
  commentElementContainer.style.zIndex = 997;
  let toolTip = addToolTip(commentElementContainer, "Comment");
  return commentElementContainer;
};

async function addElementToPage(pageNumber, element) {
  var isDualPage = localStorage.getItem("isDualPage");
  if (isDualPage && pageNumber !== 1 && !isMobileView) {
    let getLeftPage = document.getElementById("leftPage");
    let getRightPage = document.getElementById("rightPage");
    if (pageNumber % 2 === 0) {
      getLeftPage.appendChild(element);
    } else {
      getRightPage.appendChild(element);
    }

  } else {
    let getLeftPage = document.getElementById("leftPage");
    getLeftPage.appendChild(element);
  }
};