import { dispatchType as TYPES } from "../dispatchTypes";

export type Action = {
  type: string;
  payload?: any;
};

export type OfflineLicense = {
  licenseStatus: boolean;
};

const initstate = {
  licenseStatus : true
};

const offlineLicense = (state = initstate, action: Action) => {
  switch (action.type) {
    case TYPES.offlineLicense.getLicense: {
      return {
        ...state,
        ...(action.payload || {}),
      };
    }
    case TYPES.revoke:
      return initstate;

    default:
      return state;
  }
};

export default offlineLicense;
