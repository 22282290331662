import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar, Alert } from "@mui/material";
import { ReduxState } from "../../redux/reducers";
import { setSnackbar } from "../../redux/actions/loginActionTypes";
import './CustomSnackbar.css'

const CustomSnackbar = () => {
  const dispatch = useDispatch();
  const snackbarOpen = useSelector((state: ReduxState) => state.snackbar.snackbarOpen);
  const snackbarType = useSelector((state: ReduxState) => state.snackbar.snackbarType);
  const snackbarMessage = useSelector((state: ReduxState) => state.snackbar.snackbarMessage);
  const isHtmlText = useSelector((state: ReduxState) => state.snackbar.isHtmlText);
  const handleClose = () => {
    dispatch(
      setSnackbar({
        snackbarOpen: false,
        snackbarType: snackbarType,
        snackbarMessage: snackbarMessage,
      })
    );
  };
  return (
    <div className="snackbar_container">
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        data-testid="snackBar"
        className="snackbar_css"
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => handleClose()}
          color={snackbarType}
          data-testid="alertBox"
          style={snackbarType==="success"?{backgroundColor:"#2e7d32",color:"#fff"}:{}}
          severity={snackbarType}
          // style={{backgroundColor:`${snackbarType === "success" ? "#76B947":""}`}}
        >
          {isHtmlText ? (
            <p className="mb-0" dangerouslySetInnerHTML={{ __html: snackbarMessage }} />
          ) : (
            snackbarMessage
          )}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CustomSnackbar;
