import { dispatchType as TYPES } from "../dispatchTypes";

export type Action = {
  type: string;
  payload?: any;
};

export interface CreateNotesReducer {
  NoteID: number;
  NoteTitle: string;
  Description: string;
  ClassID: number | null;
  RefID: number | null;
  BGColour: string;
  jobId: number;
  UserID: number;
}

export interface GetNotesReducer {
  NoteID: number;
  NoteTitle: string;
  Description: string;
  ClassID: number;
  RefID: number;
  BGColour: string | null;
  CalendarJobTypeID: number;
  updatedon: Date;
}

export interface NotesReducer {
  NoteId: number[];
}

const initstate = {
  notesId: [],
};

const notesReducer = (state = initstate, action: Action) => {
  switch (action.type) {
    case TYPES.notes.setId: {
      return {
        notesId: action.payload || [],
      };
    }
    case TYPES.revoke:
      return initstate;

    default:
      return state;
  }
};

export default notesReducer;
