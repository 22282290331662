export const dispatchType = {
  login: {
    loginUser: "LOGIN_USER",
    loginUserMobile: "LOGIN_USER_MOBILE",
    forgotPassword: "FORGOT_PASSWORD_EMAIL",
    otp: "OTP",
    changePassword: "CHANGE_PASSWORD",
    resetEmail: "RESET_EMAIL",
    resetPhoneNumber: "RESET_PHONE_NUMBER",
    setError: "SET_ERROR",
    userType: "SET_USER_TYPE",
    newPasswordSeted: 'NEW_PASSWORD_RESET',
    orgNewPasswordSeted: 'ORG_NEW_PASSWORD_RESET'
  },
  overview: {
    class: "CLASS_OVERVIEW",
  },
  grade: {
    gradeList: "GRADE_LIST",
  },
  curriculum: {
    courses: "GET_COURSES",
    courseSyllabus: "GET_COURSE_SYLLABUS",
    updateInnerCollapsible: "UPDATE_INNER_COLLAPSIBLE",
    updateInnerSyllabus: "UPDATE_INNER_INNER_COLLAPSIBLE",
    completeStatus: "COMPLETE_STATUS",
    setViewStatus:"SET_VIEW_STATUS",
    viewRedirect : "VIEW_REDIRECT",
    resetFilter : "RESET_FILTER"
  },
  createCurriculum: {
    chapters: "GET_CHAPTERS",
    delete: "DELETE_CHAPTER",
    getTopSchoolChapter : "GET_TOP_SCHOOL_CHAPTERS",
  },
  calendar: {
    getEvents: "GET_EVENTS",
    getFilters: "GET_FILTERS",
    getTimeTable: "GET_TIME_TABLE",
    reloadList: "RELOAD_LIST",
    getAdminEvents:'GET_ADMIN_Events'
  },
  stepper: "SET_STEPPER",
  resources: {
    open: "OPEN_DIALOG",
    close: "CLOSE_DIALOG"
  },
  notes: {
    setId: "SET_NOTESID"
  },
  tenant: {
    getTenant: "GET_TENANT"
  },
  orgsuperadmin: {
    getorgsuperadmin: "GET_ORGSUPERADMIN"
  },
  offlineLicense: {
    getLicense: "GET_LICENSE"
  },
  academicYears: {
    getAcademic: "GET_ACADEMIC"
  },
  mobileFixed: {
    getMobileFixed: "GET_MOBILEMENU",
    isMobileView:"IS_MOBILEVIEW"
  },
  topvault: {
    getTopvault: "GET_TOPVAULT"
  },
  publication: 'GET_PUBLICATION',
  category: 'GET_CATEGORY',
  revoke: "REVOKE",
};
