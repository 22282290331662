import moment from "moment";
import store from "../redux/store";
import _, { last } from "lodash";
import newVideoIcon from "../../src/assets/img/newVideoIcon.svg"
import newAudioIcon from "../../src/assets/img/newAudioIcon.svg"
import library from "../../src/assets/img/LiveClasses/library.svg"
import schoolMP4Icon from "../../src/assets/img/icons/schoolMP4Icon.svg"
import schoolMP3Icon from "../../src/assets/img/icons/schoolMP3Icon.svg"
import schoolPDFIcon from "../../src/assets/img/icons/schoolPDFIcon.svg"
import schoolDocICon from "../../src/assets/img/icons/schoolDocICon.svg"
import cyborgLocalPDFIcon from "../../src/assets/img/icons/cyborgLocalPDFIcon.svg"
import cyborgLocalMP4Icon from "../../src/assets/img/icons/cyborgLocalMP4Icon.svg"
import cyborgLocalMP3Icon from "../../src/assets/img/icons/cyborgLocalMP3Icon.svg"
import cyborgLocalDocIcon from "../../src/assets/img/icons/cyborgLocalDocIcon.svg"
import textBookIcon from "../../src/assets/img/textBookIcon.svg"
import interactiveVideoIcon from "../../src/assets/img/icons/interactiveVideoIcon.svg"
import cyborgLocalZipIcon from "../../src/assets/img/cyborgLocalZipIcon.svg"
import schoolPNGIcon from "../../src/assets/img/icons/schoolPNGIcon.svg"
import schoolJPGIcon from "../../src/assets/img/icons/schoolJPGIcon.svg"
import pptIcon from "../../src/assets/img/icons/ppt.svg"
export const constants = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  contentLibraryBase: process.env.REACT_APP_CONTENT_LIBRARY_URL,
  h5pAPI: process.env.REACT_APP_H5P_API,
  adminBaseUrl: process.env.REACT_APP_ADMIN_BASE_URL,
  authURL: process.env.REACT_APP_AUTH_URL,
  cyborgBaseURL: process.env.REACT_APP_CYBORG_URL,
  tenantApiCall: process.env.REACT_APP_TENANT_URL,
  fileApiCall: process.env.REACT_APP_FILE_URL,
  teacherApiCall: process.env.REACT_APP_TEACHER_URL,
  libraryApiCall: process.env.REACT_APP_LIBRARY_URL,
  saServiceCall: process.env.REACT_APP_SA_SERVICE_URL,
  studentServiceCall: process.env.REACT_APP_STUDENT_SERVICE_URL,
  fileRenderCall: process.env.REACT_APP_FILE_URL + "files/renderFile?filePath=",
  isOnline: process.env.REACT_APP_IS_ONLINE == "1" ? true : false,
  feeManagementAPI: process.env.REACT_APP_FEE_MANAGEMENT_URL,
  feeManagementAdvanceAPI: process.env.REACT_APP_FEE_MANAGEMENT_ADVANCE_URL,
  admissionModuleAPI: process.env.REACT_APP_ADMISSION_MODULE_BASE_URL,
  dashboardMockAPI: process.env.REACT_APP_DASHBOARD_MOCK_BASE_URL,
  exportGenerateTemplateApiCall: process.env.REACT_APP_GENERATE_TEMPLATES_URL,
  dlTemplateApi: process.env.REACT_APP_DL_TEMP_URL,
  templateManagement: process.env.REACT_APP_TEMPLATES_MANAGEMENT_URL,
  templateManagement_v2: process.env.REACT_APP_TEMPLATES_MANAGEMENT_URL_V2,
  mixPanelAdminToken: process.env.REACT_APP_ADMIN_TOKEN,
  mixPanelTeacherToken: process.env.REACT_APP_TEACHER_TOKEN,
  enableMixPanel: process.env.REACT_APP_ENABLE_MIX_PANEL == "1" ? true : false,
  visitorAPI : process.env.REACT_APP_VISITOR_API_URL,
  assessmentManagement: process.env.REACT_APP_ASSESSMENT_MANAGEMENT_URL,
  reportsCyborgCall:process.env.REACT_APP_CYBORG_URL,
  feeUrl: process.env.REACT_APP_FEE_BASE_URL,
  ngwBaseUrl : process.env.REACT_APP_NGW_BASE_URL,
  tmsUrl : process.env.REACT_APP_TMS_URL,
};

export const streamingBaseURL = process.env.REACT_APP_FILE_URL + "files/videoStreaming/"

export const isReactNative = !!(window as any)?.ReactNativeWebView;
export const URLS = {
  LOGIN: "auth/login",
  ORG_ADMIN_LOGIN: "auth/orgAdminLogin",
  MOBILE_LOGIN: "auth/loginOtp",
  VALIDATE_OTP: "auth/verifyOtp",
  FORGOT_PASSWORD: "auth/forgotPassword",
  ORGSUPERADMIN_FORGOT_PASSWORD: "auth/forgotPassword",
  ORGSUPERADMIN_CHANGE_PASSWORD: "auth/updatePassword",
  CHANGE_PASSWORD: "auth/updatePassword",
  CURRICULUM: {
    COURSES: "curriculum/courses/",
    COURSE_SYLLABUS: "curriculum/syllabus",
  },
  CREATE_CURRICULUM: {
    LIST_CURRICULUM: "curriculum/listCurriculum",
    DELETE_CURRICULUM: "curriculum/deleteCurriculum",
    TOPSCHOOL_CURRICULUM: 'curriculum/getCurriculumFromTopSchool',
  },
  MILE_STONE: {
    LIST: "milestone/lists",
    MARK_AS_COMPLETE: "milestone/markasComplete",
  },
  CLASS_OVERVIEW: {
    CLASSES: "admin/teacher/classes/",
  },
  CALENDAR: {
    GET_EVENTS: "calendar/lists",
    GET_FILTERS: "calendar/category/lists",
    GET_TIMETABLE: "timetable/lists",
    GET_ADMIN_Events: 'calendar/calendarList',
    GET_TEACHER_EXAM: 'calendar/getExamForTeacher',
    GET_TEACHER_EVENT: 'calendar/getEventForPerson'
  },
  ACADEMIC: {
    LIST: 'schoolAdmin/listAcademicYear'
  },
  PUBLICATION: 'library/getPublication',
  CATEORY: 'library/categories'
};

export const MSG = {
  USERNAME_INVALID: "Uh oh! Invalid username or password",
  FORGOT_EMAIL: "Uh oh! Email does not exist",
  MOBILE_NUMER: "Uh oh! Mobile Number does not exist",
  INVALID_USER: "Uh oh! Invalid user",
};

export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const months = ["January","February","March","April","May","June","July",
"August","September","October","November","December"];

export const reqColumnsTeacher = [
  "FullName",
  "EmailID",
  "DOB(YYYY-MM-DD)",
  "Gender(M/F)",
  "ContactNumber",
  "EmergencyContactName",
  "EmergencyContactNumber",
  "BloodGroup",
  "EmployeeID",
  "Designation",
  "Address1",
  "Address2",
  "PinCode"
]
export const reqColumnsHoliday = [
  "Holiday Title",
  "StartDate(YYYY-MM-DD)",
  "EndDate(YYYY-MM-DD)",
  "Holiday Description",
  "HolidayID"

]

export const reqColumnsStudent = [
  "Full Name",
  "DOB(DD/MM/YYYY)",
  "Gender(M/F/O)",
  "Contact Number",
  "BloodGroup",
  "Email address",
  "Guardian 1 Relation",
  "Guardian 1 Name",
  "Guardian 1 Contact Number",
  "Guardian 1 Email address",
  "Guardian 2 Relation",
  "Guardian 2 Name",
  "Guardian 2 Contact Number",
  "Guardian 2 Email address",
  "Guardian 3 Relation",
  "Guardian 3 Name",
  "Guardian 3 Contact Number",
  "Guardian 3 Email address",
  "Address1",
  "Address2",
  "PinCode",
  "Academic Year",
  "Admission Year",
  "Admission No",
  "Roll No",
];

export const reqColumnsStudentAms = [
  "Full Name",
  "DOB(DD/MM/YYYY)",
  "Gender(M/F/O)",
  "Contact Number",
  "BloodGroup",
  "Email address",
  "Guardian 1 Relation",
  "Guardian 1 Name",
  "Guardian 1 Contact Number",
  "Guardian 1 Email address",
  "Guardian 2 Relation",
  "Guardian 2 Name",
  "Guardian 2 Contact Number",
  "Guardian 2 Email address",
  "Guardian 3 Relation",
  "Guardian 3 Name",
  "Guardian 3 Contact Number",
  "Guardian 3 Email address",
  "Address1",
  "Address2",
  "PinCode",
  "Academic Year",
  "Admission Year",
  "Admission No",
  "Roll No",
  "Application Number",
];

export const reqColumnsOtherStaff = [
  "FullName",
  "DOB(YYYY-MM-DD)",
  "Gender(M/F/O)",
  "EmailID",
  "ContactNumber",
  "EmergencyContactName",
  "EmergencyContactNumber",
  "Designation",
  "EmployeeID",
  "Address1",
  "Address2",
  "PinCode",
  "Department",
];

export const reqColumnGradebook = [
  "RollNo",
  'StudentName',
  'Term',
  'Course',
  'TestType',
  'TheoryMark',
  'PracticalMark',
];

export const ReUploadreqColumnGradebook = [
  "RollNo",
  "StudentName",
  "Term",
  "Course",
  "TestType",
  "TheoryMark",
  "PracticalMark",
  "Status",
  "Comment"
]
export const uploadCurriculumChapter = [
  "ChapterNumber",
  "ChapterName",
  "ChapterDescription",
  "DurationFrom(YYYY-MM-DD)",
  "DurationTo(YYYY-MM-DD)",
  "ChapterDuration",
  "TopicName",
  "TopicDescription",
  "TopicDuration",
  "LearningObjective",
  "LearningOutcome",
];

export const uploadCurriculumTheme = [
  "ThemeName",
  "ThemeDescription",
  "DurationFrom(YYYY-MM-DD)",
  "DurationTo(YYYY-MM-DD)",
  "TotalSessions/Period",
  "ChapterNumber",
  "ChapterName",
  "ChapterDescription",
  "TopicName",
  "TopicDescription",
  "TopicDuration",
  "LearningObjective",
  "LearningOutcome",
];

export const reuploadAttendance = [
  "Roll Number",
  "Student Name",
  "Attendance(P/A/HD/ML)",
  "Status",
  "Comment",
];

export const elaExcelHeader = [
  "Roll No",
  "Student Name",
  "Attendance",
  "Learning Outcome",
  "Competency"
];

export const uploadAttendance = [
  "Roll Number",
  "Student Name",
  "Attendance(P/A/HD/ML)",
];

export const uploadTimeTable = [
  "Day Number",
  "Day Name",
  "Start Time",
  "End Time",
  "Email-ID",
  "Subject",
  "Teacher's name",
];

export const reuploadTimeTable = [
  "Day Number",
  "Day Name",
  "Start Time",
  "End Time",
  "Employee ID",
  "Teacher",
  "Course",
  "Status",
  "Comment",
];
export const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const promotionStatus = 10

export const userRoleId = {
  adminId: 1,
  studentId: 2,
  teacherId: 3,
  superadminId: 4,
  otherstaffId: 5
};

export const contentCategories = {
  interactiveVideo: 10,
  timeline: 11,
  cards: 12,
  lessonplan: 2,
  elaModule: 8
}

export const userRoleName = {
  admin: "Admin",
  student: "Student",
  teacher: "Teacher",
  orgsuperadmin: "orgsuperadmin"
};

export const userRoleNameDispaly = {
  Admin: "Admin",
  Student: "Student",
  Teacher: "Teacher",
  orgsuperadmin: "Super Admin"
};

export const supportFilterList = [
  {
    statusId: 8,
    statusName: "Pending",
  },
  {
    statusId: 19,
    statusName: "Resolved",
  },
  {
    statusId: 14,
    statusName: "Rejected",
  },
  {
    statusId: 18,
    statusName: "Sent to TopSchool",
  },
  {
    statusId: 20,
    statusName: "Resolved by TopSchool",
  }
];
export const supportFilterListAdmin = [
  {
    statusId: 18,
    statusName: "Send to TopSchool",
  },
  {
    statusId: 20,
    statusName: "Resolved by TopSchool",
  }
];
export const SupportRejectedList = {
  statusId: 14,
  statusName: "Rejected",
}

export const SOCKET_URL =
  process.env.REACT_APP_SOCKET_URL || "https://estt-dev-api.easyngo.com:3000";

export const COURSE_IDS = {
  ELEVEN: 13,
  TWELVE: 12,
};

export const NOTES_FROM = {
  classes: 4,
  reminder: 5
}
/* socket Keys for Doubts Creations*/
export const socket_doubt_created = 'doubt_created'
export const socket_reply_doubt = 'reply_doubt'
export const socket_delete_doubt = 'delete_doubt'
export const socket_replay_delete_doubt = 'doubt_reply'

export const RESOLVED_STATUS = 19;
export const DEACTIVE_STATUS = 11;
export const ALUMINI_STATUS = 21;

export const ORGANIZATION_LOGO_MAX_FILE_SIZE = 5242880;

export const zoomLink = "https://zoom.us/j/3416131968?pwd=UW5qdkRDM0lBTXl0RG1KL1ZuNmptQT09";

export const x_tenant_id = store.getState().tenant?.tenantId
export const auth_token = store.getState().login?.token || ''

export const Role = {
  admin: 1,
  student: 2,
  teacher: 3,
  orgsuperadmin: 4,
  otherstaff: 5
}

export const defaultPrivileges = {
  calendar: { canView: false, canEdit: false },
  user: { canView: false, canEdit: false },
  admin: { canView: false, canEdit: false },
  school: { canView: false, canEdit: false },
  curriculum: { canView: false, canEdit: false, canApprove: false },
  timetable: { canView: false, canEdit: false },
  feeManagement: { canView: false, canEdit: false, canApprove: false },
  admissionManagement: { canView: false, canEdit: false },
  feeCollection: { canView: false, canEdit: false },
  gradeBook: { canView: false, canEdit: false },
  report360: { canView: false, canEdit: false, canPrint: false },
  supportTickets: { canView: false, canApprove: false },
  leaveRequest:{canView: false, canApprove: false},
  lessonPlan:{canView: false, canApprove: false},
  noticeBoard: { canView: false, canEdit: false },
  topVault:{ canView: false, canEdit: false },
  qpApproval: { canView: false, canEdit: false, canApprove: false },
  visitor:{ canView: false, canEdit: false },
  downloadableReport:{canView:false}

};

export const adminPrivileges = {
  calendar: { canView: true, canEdit: true },
  user: { canView: true, canEdit: true },
  admin: { canView: true, canEdit: true },
  school: { canView: true, canEdit: true },
  curriculum: { canView: true, canEdit: true, canApprove: true },
  timetable: { canView: true, canEdit: true },
  feeManagement: { canView: true, canEdit: true, canApprove: true },
  admissionManagement: { canView: true, canEdit: true },
  feeCollection: { canView: true, canEdit: true },
  gradeBook: { canView: true, canEdit: true },
  report360: { canView: true, canEdit: true, canPrint: true },
  supportTickets: { canView: true, canApprove: true },
  leaveRequest:{canView: true, canApprove: true},
  lessonPlan:{canView: true, canApprove: true},
  noticeBoard: { canView: true, canEdit: true },
  otherStaff: { canView: true, canEdit: true },
  topVault:{ canView: true, canEdit: true },
  qpApproval: { canView: true, canEdit: true, canApprove: true },
  visitor:{ canView: true, canEdit: true },
  downloadableReport:{canView:true}
};

export const privDomainIds = {
  school: 1,
  user: 2,
  calendar: 3,
  curriculum: 4,
  timetable: 5,
  admin: 6,
  feeManagement: 7,
  feeCollection: 8,
  gradeBook: 9,
  report360: 10,
  supportTickets: 11,
  noticeBoard: 12,
  admissionManagement: 13,
  topVault:14,
  leaveRequest:15,
  lessonPlan:16,
  qpApproval:17,
  visitorRegistor:18,
  gateStaff:19,
  downloadableReport:20
}

export const offlinedownloadstatus = {
  Online: "0",
  AvailableOffline: "1",
  DownloadInprogress: "2",
  DownloadInterrupted: "3",
}

/*Teacher - Request Change description Limit*/
export const teacherRequestChange = {
  descriptionLimit: 3000,
}

export const studentMessage = {
  subjectLimit: 150,
  messageLimit: 500,
  allowedSize: 10485760,
  fileTypes: ['.doc', '.docx', '.ppt', '.pptx', '.pdf']
}

/*Regex used in Table (Alpha and Number regex) */
export const reA = /[^a-zA-Z]/g;
export const reN = /[^0-9]/g;
export const reANS = /^[A-Za-z0-9 .,]+$/; //Alphanumeric and space

export const containCharacter = /^(?=.*).{8,}$/ //to checking containes 8 characters
export const containAlphabets = /[A-Z]/ //to checking containes capital Alphabets
export const containSpecialCharacter = /[0-9!@#$%^&*()_-]/ //to checking containes Special characters

export const studentWorkloadGrades = JSON.parse(process.env.REACT_APP_GRADE_GROUP)

export const topSchoolModules = {
  lms: 'LMS001',
  erp: 'ERP001'
}

export const typeOfFile = {
  pdfFile: 1,
  path: 2,
  video: 3,
  presentation: 4,
  pdf: 5,
  document: 6,
  planPath: 7,
  interactiveVideo: 10,
  timeline: 11,
  flashCards: 12,
  elaModuleId: 8
}

/* class card & Lesson Plan Status Id */

export const statusId_LP ={
  approved : 15,
  UsedBeforeApproval:1,
  Pending:8,
  sentForRework:24
}

export const statusId_CP ={
  Published : 15,
  Pending:13,
  SentForRework:24
}

export const selectCategories  : any= ["1", "3", "4", "5", "6", "7"] // for HW and LP

//Due to the Id mismatch diff env, moving the const to env file.
export const contentIds = JSON.parse(process.env.REACT_APP_CONTENT_IDS)

export const curriculumPlan = {
  topics: "Topics",
  themes: "Themes"
}
export function uniqueByKey(array: any, key: string) {
  return [...new Map(array?.map((x) => [x[key], x])).values()];
}

export const supportTicketMsg = {
  allowedSize: 5242880,
  fileTypes: ['.doc', '.docx', '.jpeg', '.jpg', '.xlsx', '.xls', '.pdf'],
  maxCount: 5,
  allowedSizeInMB: 5
}

export const feedbackAttachments = {
  allowedSize: 5242880,
  fileTypes: ['.doc', '.docx', '.jpeg', '.jpg', '.xlsx', '.xls', '.pdf'],
  maxCount: 5,
  allowedSizeInMB: 5
}

export function fileType(type: string) {
  switch (type) {
    case "pdf":
      return "PdfIcon";

    case "txt":
      return "TextIcon";

    case "doc":
      return "DocIcon";

    case "docx":
      return "DocIcon";

    case "jpg":
      return "JpgImg";

    case "jpeg":
      return "JpgImg";

    default:
      return "DocRedIcon";
  }
};

export function getExtension(fileName: string) {
  const fileNameSplit = fileName?.split(".");
  const fileExtension = last(fileNameSplit);
  return fileExtension as string;
};

export const supportTicketStatus = {
  reject: {
    headerText: "Reason for Rejection",
    labelText: "Reason *",
    btnText: "Reject",
    statusId: 14
  },
  resolve: {
    headerText: "Remarks",
    labelText: "Remarks",
    btnText: "Resolve",
    statusId: 19
  },
  sendToTopschool: {
    headerText: "Remarks",
    labelText: "Remarks",
    btnText: "Send to TopSchool",
    statusId: 18
  },
  resolvedByTopschool: {
    headerText: "Remarks",
    labelText: "Remarks",
    btnText: "Resolved by TopSchool",
    statusId: 20
  },
  pending: {
    headerText: "Pending",
    labelText: "Pending",
    btnText: "Pending",
    statusId: 8
  }
}

/*converting Date & Time GMT to UTC */

export function convertGMTtoUTC(gmtDate: any) {
  /* Get the offset from GMT for the given date */
  const offset = gmtDate.getTimezoneOffset() * 60 * 1000;
  /* Subtract the offset from the GMT date to get the UTC date */
  const utcDate = new Date(gmtDate.getTime() - offset);
  return utcDate;
}

export const studentPageCount = 15
export const otherStaffPageCount = 8
export const admissionManagementId = 15;
export const markGradebookPublishType = 1;

export const markstoGradeconvert = (mark: number, maxMark: number) => {
  let val = Math.round((mark / maxMark) * 100);
  return (
    val >= 91 && val <= 100
      ? "A1"
      : val >= 81 && val <= 90
        ? "A2"
        : val >= 71 && val <= 80
          ? "B1"
          : val >= 61 && val <= 70
            ? "B2"
            : val >= 51 && val <= 60
              ? "C1"
              : val >= 41 && val <= 50
                ? "C2"
                : val >= 33 && val <= 40
                  ? "D"
                  : val <= 32
                    ? "E"
                    : "")
}
export const coScholasticGradeHandler = (mark: number) => {
  return (
    mark === 5 ? "A" : mark === 4 ? "B" : mark === 3 ? "C" : mark === 2 ? "D" : mark === 1 ? "E" : ""
  )
}

export const getCourseAndTeacherNames = (data:any) => { 

  let courseNames = ''
  let teacherNames = ''
  let totalTeachers: any[] = [];
  let courseIds : any[] = [];
  let teacherIds: any [] = [];
  if(data && data.length > 0) {
    for(let i = 0; i < data.length; i++) {
      if(!courseIds.includes(data[i].courseId)) {
        courseNames =  courseNames + data[i].courseName + '/';
        courseIds.push(data[i].courseId); 
      }
      if(!teacherIds.includes(data[i].userId)) {
        teacherIds.push(data[i].userId);
        totalTeachers.push(data[i].userName)
        if(teacherIds.length > 1) {
          teacherNames = teacherIds.length + 'Teachers'
        } else {
          teacherNames = data[i].userName
        }
      }
    }
    courseNames = courseNames.substring(0, courseNames.length - 1)
  }
  return [courseNames, teacherNames, totalTeachers];
}

export const getCourseNamesForTeacher = (data: any) => {
  let courseNames = ''
  let courseIds: any[] = [];
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      if (!courseIds.includes(data[i].es_courseID)) {
        courseNames = courseNames + data[i].courseDisplayName + '/';
        courseIds.push(data[i].es_courseID);
      }
    }
    courseNames = courseNames.substring(0, courseNames.length - 1)
  }
  return [courseNames];
}

export const disCourseName = (val: any) => val?.courseDisplayName ? val?.courseDisplayName : val?.courseDisplayname ? val?.courseDisplayname : val?.CourseDisplayName ? val?.CourseDisplayName : val?.coursedisplayname ? val?.coursedisplayname : val?.courseName ? val?.courseName : val?.CourseName ? val?.CourseName : val?.coursename
export const timeAgo = (prevDate: number) => {
  const diff = Number(new Date()) - prevDate as number;
  const minute = 60 * 1000 as number;
  const hour = minute * 60 as number;
  const day = hour * 24 as number;
  const month = day * 30 as number;
  const year = day * 365 as number;
  switch (true) {
    case diff < minute:
      const seconds: Number = Math.round(diff / 1000) as number;
      return `${seconds} ${seconds > 1 ? 'seconds' : 'second'} ago` as string;
    case diff < hour:
      const minutes = Math.round(diff / minute) as number
      return `${minutes} ${minutes > 1 ? 'minutes' : 'minute'} ago` as string;
    case diff < day:
      const hours = Math.round(diff / hour) as number;
      return `${hours} ${hours > 1 ? 'hours' : 'hour'} ago` as string;
    case diff < month:
      const days = Math.round(diff / day) as number;
      return `${days} ${days > 1 ? 'days' : 'day'} ago` as string;
    case diff < year:
      const months = Math.round(diff / month) as number;
      return `${months} ${months > 1 ? 'months' : 'month'} ago` as string;
    case diff > year:
      const years = Math.round(diff / year) as number;
      return `${years} ${years > 1 ? 'years' : 'year'} ago` as string;
    default:
      return "" as string;
  }
};

//orgSuperAdmin
export const chartLabel = {
  branchWiseAttendance: {
    title: 'Branch-wise Attendance',
    subTitle: 'Check the Branch-wise Attendance recorded for all students of each grade'
  },
  branchWiseContentPerformance: {
    title: 'Branch-wise Content Performance',
    subTitle: 'Check the Branch-wise Content Performance for all content of all branches'
  },
  branchWiseResult: {
    title: 'Branch-wise Result',
    subTitle: 'Check the Branch-wise Result for all grades and branches'
  },
  branchWiseStudentRegistrations: {
    title: 'Branch-wise Student Registrations',
    subTitle: 'Check the yearly students registration for all branches.'
  }
}
export const chartPieColorPalette = ['#5B78E0', '#7D93E1', '#9FAEE2', '#C1C9E3'];
export const chartBarColorPalette = '#01B58A';
export const chartDurationDateFormate = 'YYYY-MM-DD';
export const chartDurationDisplayDateFormate = 'DD-MM-YYYY';
export const chartDurationFilterCustom = 'Custom';
export const chartFilterAll = 'all';

export const contentCategoryIDs = {
  TEXTBOOK: 1,
  VIDEO: 3,
  ELA: 8,
  LESSON_PLAN: 7
}

export const teacherContentList = [
  { key: 1, value: "Homework" },
  { key: 2, value: "ELA Assigned" },
  { key: 3, value: "ELA Evaluated" },
  { key: 4, value: "Lesson Plans Created" },
  { key: 5, value: "Doubt Resolved" },
  { key: 6, value: "Content Usage" }
]

export const studentContentList = [
  { key: 7, value: "Homework Viewed" },
  { key: 8, value: "ELA Viewed" },
  { key: 9, value: "Doubt Created" },
  { key: 6, value: "Content Usage" }
]

export const otherStaffApi = {
  GetOtherStaffAccountList: "other-staff/staff",
  GetOtherStaffAccountDetails: "other-staff/staff",
  GetOtherStaffDepartmentList: "other-staff/department",
  AddOtherStaffAccount: "other-staff/staff",
  DeleteOtherStaffAccount: "other-staff/staff",
  ActivateOrDeactivateOtherStaff: "other-staff/activateOrDeactivateOtherStaff",
}
export const percentageValue = /^([0-9]|[1-9][0-9]|100)%?$/ //only allows 0% t0 100%
export const numbersOnly = new RegExp("(^[0-9]+$|^$)")//only 10 numbers are allowed
export const guardianContactNo = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/  //only 10 numbers will be allowed 
export const guardianMailId = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;//basic emailid validation expressionalphabets a-z,0-9 numbers and @ , . symbols are allowed
export const infrastructureFloors = /^[0-9\b]+$/; // only numbers will be allowed
export const assignMarkValue = /^[0-9][0-9]?$|^100$/ // numbers from 0 to 100 are allowed
export const alphabetsOnly = /^[aA-zZ\s]+$/ // only allows alphabets
export const nameRegex = /^[a-zA-Z ,.'-]+$/ // only allows alphabets, dot, spaces, comma, hyphen and apostrophe

export const MIXPANEL_MODULES = {
  "Page Viewed": "Page Viewed",
  "Whiteboard Initiated": "Whiteboard Initiated",
  "Assessment Initiated": "Assessment Initiated",
  "Homework Added": "Homework Added",
  "Offline Setup Initiated": "Offline Setup Initiated",
  "Support Ticket Resolved": "Support Ticket Resolved",
  "Support Ticket Reject": "Support Ticket Reject",
  "Student Added": "Student Added",
  "Student Updated": "Student Updated",
  "Student Reset": "Student Reset",
  "Teacher Reset": "Teacher Reset",
  "Mail Sent Student": "Mail Sent Student",
  "Mail Sent Parent": "Mail Sent Parent",
  "Notice Created": "Notice Created",
  "School Infrastructure Added": "School Infrastructure Added",
  "School Infrastructure Updated": "School Infrastructure Updated",
  "School Information Updated": "School Information Updated",
  "Stream Added": "Stream Added",
  "Stream Updated": "Stream Updated",
  "Teacher Threshold Updated": "Teacher Threshold Updated",
  "Student Threshold Updated": "Student Threshold Updated",
  "Admin Added": "Admin Added",
  "Admin Updated": "Admin Updated",
  "Reminder Added": "Reminder Added",
  "Holiday Added": "Holiday Added",
  "360 Degree Report Printed": "360 Degree Report Printed",
  "Leave Request Resolved": "Leave Request Resolved",
  "Leave Request Rejected": "Leave Request Rejected",
  "ELA Added": "ELA Added",
  "Live Class Added": "Live Class Added",
  "Event Added": "Event Added",
  "Appointment Added": "Appointment Added",
  "Exam Added": "Exam Added",
  "Role Removed": "Role Removed",
  "Role Added": "Role Added",
  "Role Updated": "Role Updated",
  "Doubts Resolved": "Doubts Resolved",
  "Class Session Initiated": "Class Session Initiated",
  "Gradebook Template Added": "Gradebook Template Added",
  "Gradebook Template Updated": "Gradebook Template Updated",
  "Gradebook Template Published": "Gradebook Template Published",
  "Gradebook Published": "Gradebook Published",
  "Publication Change Requested": "Publication Change Requested",
  "Content Added": "Content Added",
  "Content Shared": "Content Shared",
  "Content Viewed": "Content Viewed",
  "Content Searched": "Content Searched",
  "Pending Action Viewed": "Pending Action Viewed",
  "Section Updated": "Section Updated",
  "Section Added": "Section Added",
  "Section Removed": "Section Removed",
  "Lesson Plan Created": "Lesson Plan Created",
  "Pending Action Completed": "Pending Action Completed",
  "Curriculum Created": "Curriculum Created",
}

export const MIXPANEL_PLATFORM = {
  WEB: "Web"
}

export const mixPanelPageName = {
  "/admin/dashboard": "Admin Dashboard",
  "/admin/school": "School",
  "/admin/basic-information": "Basic Information",
  "/admin/gradesStreams": "Grades and Streams",
  "/admin/infrastructure": "Infrastructure",
  "/admin/create-curriculum": "Create Curriculum",
  "/admin/school/timetableList": "Time Table List",
  "/admin/adminRoles": "Roles",
  "/admin/grade-setup": "Grade Setup",
  "/admin/add-department": "Add Department",
  "/admin/teacherAccounts": "Teacher Account",
  "/admin/timetable": "Time Table",
  "/admin/calendar": "Calendar",
  "/admin/content/contentlibrary": "Content Library",
  "/admin/content/myResources": "My Resources",
  "/admin/content/publicResources": "Public Resources",
  "/admin/contentlibrary/underUtilised": "Under Utilised",
  "/admin/reports/gradebook": "Gradebook",
  "/admin/gradebook/create-new-template": "Create New Template",
  "/admin/gradebook/preview-template": "Preview Template",
  "/admin/reports/studentReport": "Student Report",

  "/teacher/dashboard": "Teacher Dashboard",
  "/teacher/overview": "Teacher Overview",
  "/teacher/support-feedback/": "Teacher Support Feedback",
  "/teacher/mycalendar": "Teacher Calendar",
  "/teacher/class-attendance": "Teacher Class Attendance",
  "/teacher/absentYesterday": "Teacher Absent Yesterday",
  "/teacher/subject-attendance": "Teacher Subject Attendance",
  "/teacher/curriculum": "Teacher Curriculam",
  "/teacher/reports/gradebook": "Teacher Report Gradbook"
}

export const videoLiked = 1;
export const videoLikeRemoved = 0;
export const tollFreeNumber = '1800-266-6676';
export const studentActive = 1
interface StatusColorProps{
id:number;
statusName:string;
colorSet:{bgColor:string,color:string,statusName:string}
}
const listOfLpStatus: StatusColorProps[] = [
  { id: 0, statusName: "In-built".trim(), colorSet: { bgColor: "rgba(1, 181, 138, 0.1)", color: "#01B58A" ,statusName: "In-built".trim()} },
  { id: 1, statusName: "Used before Approval".trim(), colorSet: { bgColor: "rgba(249, 88, 67, 0.1)", color: "#F95843", statusName: "Used before Approval".trim() } },
  { id: 8, statusName: "Pending".trim(), colorSet: { bgColor: "rgba(246, 188, 12, 0.1)", color: "#F6BC0C",statusName: "Pending".trim(), } },
  { id: 24, statusName: "Rework Required".trim(), colorSet: { bgColor: "rgba(56, 93, 223, 0.1)", color: "#385DDF" , statusName: "Rework Required".trim()} },
  { id: 15, statusName: "Approved".trim(), colorSet: { bgColor: "rgba(1, 181, 138, 0.1)", color: " #01B58A",statusName: "Approved".trim() } }]

export const getLessonPlanStatusColor = (lpStatus: string):StatusColorProps["colorSet"]  => {
  const colorMap = new Map();
  listOfLpStatus.forEach((status: StatusColorProps) => colorMap.set(status.statusName, status.colorSet));
  const color = colorMap.get(lpStatus?.trim());
  return color;
}
export const getLessonPlanStatusColorById = (lpStatusId: number):StatusColorProps["colorSet"]  => {
  const colorMap = new Map();
  listOfLpStatus.forEach((status: StatusColorProps) => colorMap.set(status.id, status.colorSet));
  const color = colorMap.get(lpStatusId);
  return color;
}

export const hasStatusTag = (tags:string[],tagIndex:number):boolean=>( _.compact(tags)?.length>1 && tagIndex === 2);

export const reworkRequiredId = 24;
export const lessonPlanNotifId = 23;
export const classCardNotifId = 24;

export const lessonPlanStatusNames = {
  pending : "Pending",
  rework : "Rework Required",
  approved : "Approved",
  used_before_approval : "Used before Approval",
  in_built:"In-built"
}

export const BIOMETRIC_EVENT = {
  ENABLE_FACE_ID : 'ENABLE_FACE_ID',
  ENABLE_FACE_ID_SUCCESS : 'ENABLE_FACE_ID_SUCCESS',
  ENABLE_FINGER_PRINT : 'ENABLE_FINGER_PRINT',
  ENABLE_FINGER_PRINT_SUCCESS: 'ENABLE_FINGER_PRINT_SUCCESS',
  ENABLED_FACE_ID: 'ENABLED_FACE_ID',
  DISABLE_FACE_ID_SUCCESS : 'DISABLE_FACE_ID_SUCCESS',
  ENABLED_FINGER_PRINT: 'ENABLED_FINGER_PRINT',
  DISABLE_FINGER_PRINT_SUCCESS: 'DISABLE_FINGER_PRINT_SUCCESS',
  LOGOUT: 'LOGOUT',
  CANCEL: 'CANCEL',
  FETCH_INITIAL_BIOMETRICS:"FETCH_INITIAL_BIOMETRICS"
}
// biometric Flow constants
export const FETCH_LOGOUT_USER_EVENT="FETCH_LOGOUT_USER_EVENT";
export const  ENABLE_FACE_ID="ENABLE_FACE_ID";
export const  ENABLE_FINGER_PRINT="ENABLE_FINGER_PRINT";
export const  DISABLE_FACE_ID="DISABLE_FACE_ID";
export const  DISABLE_FINGER_PRINT="DISABLE_FINGER_PRINT";
export const  ENABLE_FACE_ID_SUCCESS="ENABLE_FACE_ID_SUCCESS";
export const  ENABLE_FINGER_PRINT_SUCCESS="ENABLE_FINGER_PRINT_SUCCESS";
export const  DISABLE_FACE_ID_SUCCESS="DISABLE_FACE_ID_SUCCESS";
export const  DISABLE_FINGER_PRINT_SUCCESS="DISABLE_FINGER_PRINT_SUCCESS";
export const  ENABLE_FACE_ID_FAIL="ENABLE_FACE_ID_FAIL";
export const  ENABLE_FINGER_PRINT_FAIL="ENABLE_FINGER_PRINT_FAIL";
export const  DISABLE_FACE_ID_FAIL="DISABLE_FACE_ID_FAIL";
export const  DISABLE_FINGER_PRINT_FAIL="DISABLE_FINGER_PRINT_FAIL";
export const NO_CENSOR = "NO_CENSOR"
export const LOGOUT = "LOGOUT"
export const OPEN_NOTIFICATION_LIST="OPEN_NOTIFICATION_LIST";

// Academic year status
export const academicYearsConstants ={
  current: 0,
  next:1
}
// calendar class card status list,admin approver id post key
export const adminApproverStatusId = 8;
export const classCompletedStatus = 10;

const redirectPaths = [
  {
    fileType: ["mp4"],
    path: `/admin/content/details/video`,
  },
  {
    fileType: ["pdf", "doc", "docx", "ppt", "pptx"],
    path: `/admin/content/read/article`,
  },
  {
    fileType: ["mp3"],
    path: `/admin/content/details/audio`,
  },
];
const teacherRedirectPaths = [
  {
    fileType: ["mp4"],
    path: `/teacher/content/details/video`,
  },
  {
    fileType: ["mp3"],
    path: `/teacher/content/details/audio`,
  },
  {
    fileType: ["pdf", "doc", "docx", "ppt", "pptx"],
    path: `/teacher/content/read/article`,
  },
];

export const getRedirectpath = (card: any, filename: any,userType:string) => {
  if (card && card?.contentCategoryId === 10) {
    return `/${userType}/content/details/interactive-video`;
  }
  if (card && card?.contentCategoryId === 11) {
    // return `/${userType}/content/details/timeline`;
     return `/${userType}/content/details/audio`;
  }
  if (card && card?.contentCategoryId === 12) {
    return `/${userType}/content/details/flash-cards`;
  }
  if (card && card?.contentCategoryId === 2) {
    return `/${userType}/content/lessonplan`;
  }
  if (card && card?.contentCategoryId === 135) {
    return `/${userType}/content/interactivities`;
  }
  if (filename) {
    let contArr = filename.split(".");
    let cont = contArr[contArr.length - 1];
    let redirectObj = userType === "admin" ? redirectPaths.find((ele) =>
      ele.fileType.includes(cont.toLowerCase())):teacherRedirectPaths.find((ele) =>
      ele.fileType.includes(cont.toLowerCase()))
      
    let redirectUri = redirectObj ? redirectObj.path : "";
    if (cont === "pdf") {
      redirectUri = redirectUri + "/pdf";
    } else if (cont === "doc" || cont === "docx") {
      redirectUri = redirectUri + "/document";
    } else if (cont === "ppt" || cont === "pptx") {
      redirectUri = redirectUri + "/presentation";
    }
    return redirectUri;
  } else return `/${userType}/content/details/video`;
};

export const getConcatChapName = (chapArr:any[]):{
  title:string;
  showText:string;
} =>{
  const chapRefinedArray = _.compact(chapArr);
  const title = chapRefinedArray?.join(",");
  const showText = chapRefinedArray?.length >0?(chapRefinedArray?.length === 1 ? chapRefinedArray[0]: chapRefinedArray[0] + "..."):"";
  return {
    title,
    showText
  }
}
export const fileTypesExtensions = [
  {
      fileType: ["pdf"],
      name: `pdf`,
  },
  {
      fileType: ["doc", "docx"],
      name: `document`,
  },
  {
      fileType: ["ppt", "pptx"],
      name: `presentation`,
  },
  {
      fileType: ["jpg", "jpeg", "png"],
      name: `image`,
  },
  {
      fileType: ["mp4"],
      name: `video`,
  },
  {
      fileType: ["zip"],
      name: `interactive`,
  }
];
export const convertDateToUTC = (date:Date)=>{
  return moment(date).utc(true);
}
export const interactivesBucketUrl = `https://${process.env.REACT_APP_INTERACTIVE_BUCKET_NAME}.s3.ap-south-1.amazonaws.com`

// id for resources uploaded in class card
export const classResourceGroupID = 9;

export const calculateDateRangeFromTwoDates = (ranges: any) => {
  const beginDate = moment(ranges?.selection?.startDate);
  const endDate = moment(ranges?.selection?.endDate);
  const today = moment();
  const currentYear = today.year();
  let text = '';
  let selectedDateDifference;
  let specificTimeSpan = true;
  const duration: any = moment.duration(endDate.diff(beginDate));
  if (duration?._data?.months > 0) {
    selectedDateDifference = parseInt(endDate.format('YYYY')) < currentYear ? 40 : 41;
  } else {
    selectedDateDifference = duration.get('days');
  }
  switch (selectedDateDifference) {
    case 40:
      text = "Last Year";
      break;
    case 41: text = "This Year";
      break;
    case 30:
    case 29:
      text = moment(endDate).isSame(today, 'month') ? 'Current Month' : 'Last Month';
      break;
    case 7:
    case 6:
      text = moment(endDate).isSame(today, 'week') ? 'This Week' : 'Last Week';
      break;
    case 1:
    case 0:
      if (duration?._data?.months == 0) {
        text = moment(endDate).isSame(today, 'day') ? 'Today' : 'Yesterday';
      }
      break;
    default:
      text = "Custom";
      specificTimeSpan = false;
  }
  return ({
    text,
    specificTimeSpan
  })
};
//To get date in day-month-year format ie,24-07-2023 .
export const getDayMonthYearDateFormat = (data: any) => {
  const day = String(data?.getDate()).padStart(2, '0');
  const month = String(data?.getMonth() + 1).padStart(2, '0');
  const year = data?.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;
  return formattedDate;
}
export const ReportURLs = {
  userCount : "school-reports/user-count/",
  gradeWiseAttendance : "school-reports/getGradewiseAttendance",
  teacherUsageReport : "school-reports/teacherUsageReport/",
  teacherContentUsageReport : "school-reports/teacherContentUsageReport",
  teacherMilestoneReport: "school-reports/courseCurriculumMilestoneReport",
  studentContentUsageReport: "school-reports/studentContentUsageReport",
  studentPlatformUsageReport: "/school-reports/studentUsageReport",
  overallResultReport: "school-reports/overallResultReport",
  studentRegistrationReport: "school-reports/getStudentRegistrationReport",
  templateGeneration: "template/report/export",
  admissionReportGeneration: "report/export",
  feeReminderGeneration: "dashboard/reports/reminders/export",
  feeStructureGeneration: "dashboard/reports/feeStructures/export",
  feeCollectionGeneration: "dashboard/reports/collection/export",
  admissionDropOffGeneration: "report/dropOff/export",
  adminUsageReport:"school-reports/adminUsageReport",
  adminStandardUsageReport:"school-reports/adminStandardUsageReport",
  assessmentUsageReport:"reports/assessment/usage",
  gradeWiseGradeobokReport: 'school-reports/gradebookReport'
};

export const LeaveTypes = {
  "Sick Leave": 1,
  "Duty Leave": 2,
  "Vacation Leave": 3,
  "Maternity/Paternity Leave": 4,
  "Others": 5,
  "Paid Leave": 6,
  "Unpaid Leave": 7
}
export const staticApiForOnboarding = {
  Post: "post/onboarding/url"
}
export const studentRegistration = {
  postDynamicForm: "post/dynamic-form",
  formLinkRes: "post/onboarding",
  profileUpload: process.env.REACT_APP_PROFILE_UPLOAD,
  profileSignedUrl: process.env.REACT_APP_PROFILE_SIGNED_URL,
  gradeStream: "post/grades",
  getStudentFieldRegex: "referencedata/fieldtype",
  postStudentRegist: "applicant",
  postStudentFee:"/fee",
  postDynamicFormInstitute: "/instituteDetailsByTenant",
  sibling:"/sibling/exists?",
  admissionNumber:"admissionNumber",
  gradeId:"&gradeId",
  nameOfSibling:"&nameOfSibling",
  paymentDetails:"/payment-gateway/accounts/getValidAccountDetails/ADM",
  emailVerification:"applicant/verifyEmail"
}
export const viewdetails = {
  inputField: "dynamic-form",
  shuffle: "dynamic-form/shuffle"
}
export const postInputField = {
  inputField: "dynamic-form/section/field",
  deleteInputField: "dynamic-form",
}
export const postCustomSection = "dynamic-form/section"
export interface LocalStorageState {
  onboardingAction: {
    tenant: {
      tenantId: string;
      instituteName: string;
    };
  },
  tenant: {
    tenantId: string
  }
}
export const getTenant = () => {
  const stateDetail: LocalStorageState | null = JSON.parse(localStorage.getItem("state") || "{}")  
  return stateDetail?.tenant?.tenantId || ''
}
export const mcqOptionsLimit = 5;
export const dropDownAndCheckBoxOptionsLimit = 10;
export const phoneNumberValidation = new RegExp("^\\d{1,10}$");
export const aadharNumberValidation = new RegExp("^\\d{1,12}$");
export const pinCodeRegex = new RegExp("^\\d{1,6}$");
export const studentNameRegex = /^(?!(?:[a-zA-Z]+'\s{2}[a-zA-Z]+)$)(?:[a-zA-Z]+(?:\s-|\s'|'\s|\s\.)(?:\s[a-zA-Z]+)*|[a-zA-Z]+(?:\s?[-'.]\s?[a-zA-Z.]+)*)$|^(?:[a-zA-Z]+(?:['\s-][a-zA-Z.]+)*|(?!\s\s)[a-zA-Z]+(?:['\s-][a-zA-Z.]+)*)$/;
// pattern to match a signed URL
export const signedUrlPattern = /X-Amz-Signature=[a-zA-Z0-9]+/ 

export const errorDisplayMessages = {
  saveCreatedSectionMessage : "Please save your work to prevent loss of data in the section."
}

export const academicDetailsRequestKeys = {
  academicYear : "academicDetails_academicYear"
}

export const formSectionRequestKeys = {
  academicDetails : "academicDetails"
}

export const cyborgFileTypes = [
  { type: ["doc", "docx"], icon: textBookIcon },
  { type: ["pdf"], icon: textBookIcon },
  { type: ["mp4","mkv","mov","avi"], icon: newVideoIcon },
  { type: ["mp3"], icon: newAudioIcon },
  { type: ["zip","html"], icon: interactiveVideoIcon },
  { type: ["none"], icon: library },
  {type: ["ppt","pptx"],icon:pptIcon },
]
export const cyborgLocalFileTypes = [
  { type: ["doc", "docx"], icon: cyborgLocalDocIcon },
  { type: ["pdf"], icon: cyborgLocalPDFIcon },
  { type: ["mp4","mkv","mov","avi"], icon: cyborgLocalMP4Icon },
  { type: ["mp3"], icon: cyborgLocalMP3Icon },
  { type: ["zip","html"], icon: cyborgLocalZipIcon },
  { type: ["none"], icon: library },
  {type: ["ppt","pptx"],icon:pptIcon },
]
export const schoolFileTypes=[
  { type: ["doc", "docx"], icon: schoolDocICon },
  { type: ["pdf"], icon: schoolPDFIcon },
  { type: ["mp4","mkv","mov","avi"], icon: schoolMP4Icon },
  { type: ["mp3"], icon: schoolMP3Icon },
  { type: ["none"], icon: library },
  {type: ["jpg"],icon:schoolJPGIcon},
  { type:["png"], icon: schoolPNGIcon },
  {type: ["ppt","pptx"],icon:pptIcon },

]
export const reportMenuIds = {
  studentGradeBook_report: 27,
  gradeWiseGradeBook_report: 28,
  student360_report: 29
}
