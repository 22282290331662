import React, { useState, useEffect } from "react";
import {
    Modal,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    LinearProgress,
    Button
} from "@mui/material";
import "./AcademicSwitchModal.css";
import { Link } from 'react-router-dom';
import tickIcon from "../../assets/img/milestoneImgs/mileStnCompleted.png";
import failed from "../../assets/img/milestoneImgs/mileStnDelay.png";
import inProgress from "../../assets/img/milestoneImgs/mileStnOnGoing.png";
import { academicYeararchive, academicYearStatus } from "../../api/admin/AcademicYear";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/loginActionTypes";

type props = {
    open: boolean;
};

const AcademicSwitchModal: React.FC<props> = ({ open }) => {

    const isPromotionInProgress = useSelector((state: any) => state?.login?.userData.isPromotionInProgress);
    const [academicStatus, setAcademicStatus] = useState([]);
    const [progressVal, setProgressVal] = useState(0)
    const [completedArchive, setCompletedArchive] = useState(false)
    const [retryProgress, setRetryProgress] = useState<any>([])
    const [failedCount, setFailedCount] = useState<number>(0)
    const [isFailed, setIsFailed] = useState<boolean>(isPromotionInProgress)

    const dispatch = useDispatch();

    const getAcademicYearStatus = async () => {
        const res = await academicYearStatus();
        if (res?.status === '200' || res?.status === '201') {
            setFailedCount(0)
            setAcademicStatus(res?.data)
            setRetryProgress([])
            let failed = res?.data?.filter((module: any) => module?.StatusID == 14);
            let inProgress = res?.data?.filter((module: any) => module?.StatusID == 4);
            if (inProgress?.length > 0) {
                setTimeout(() => {
                    getAcademicYearStatus();
                }, 2000);
            } else if (failed?.length == 0 && inProgress?.length == 0) {
                setCompletedArchive(true);
            } else if (failed?.length > 0) {
                setIsFailed(true);
            }
        } else {
            setFailedCount(failedCount + 1)
            if (failedCount < 4) {
                setTimeout(() => {
                    getAcademicYearStatus();
                }, 5000);
            }
        }
    }

    useEffect(() => {
        setTimeout(() => {
            getAcademicYearStatus();
        }, 2000);
    }, [])

    useEffect(() => {
        if (academicStatus?.length > 0) {
            setProgressVal((academicStatus?.filter((d: any) => d?.StatusID === 3).length / academicStatus?.length) * 100)
        }
    }, [academicStatus])

    const getClassName = (status: string) => {
        switch (status) {
            case "Completed":
                return "passed"
            case "Failed":
                return "retry"
            default:
                return ""
        }
    }

    const retryArchive = async (id: number, index: number) => {
        setIsFailed(false);
        retryProgress[index] = true;
        setRetryProgress(retryProgress);
        academicYeararchive({ archive: [id] })
        getAcademicYearStatus()
    }

    const getStatusIcon = (Status: string) => {
        switch (Status) {
            case "Completed":
                return { icon: tickIcon, altstr: 'success' }
            case "In Progress":
                return { icon: inProgress, altstr: 'in progress' }
            case "Failed":
                return { icon: failed, altstr: 'failed' }
            default:
                return { icon: inProgress, altstr: 'in progress' }
        }
    }

    const handleLogout = () => {
        localStorage.clear();
        dispatch(logout());
        window.location.href = "/"
    }

    return (
        <Modal
            open={open}
            className="switch_modal-container"
        >
            <div className="switch_modal-wrapper">
                <div className="switch_modal-content float-left w-100">
                    <Box
                        sx={{ width: "100%" }}
                        className="academic-switch-title"
                    >
                        <h3>Promoting Students and Setting up New Academic Year</h3>
                    </Box>
                    <Box
                        sx={{ width: "100%" }}
                        className="academic-switch-progress"
                    >
                        <LinearProgress
                            variant="determinate"
                            value={progressVal}
                            color={(!completedArchive && isFailed) ? "error" : "success"}
                            //@ts-ignore
                            classes={`file-upload-bar file-upload-uploading`}
                        />
                    </Box>
                    <Box
                        sx={{ width: "100%" }}
                        className="academic-switch-table"
                    >
                        {completedArchive && !isFailed && <Button
                            className='completeButton'
                            type="button"
                            variant="contained"
                            component={Link}
                            to="/admin/dashboard"
                        >
                            Go to Dashboard
                        </Button>}
                        {completedArchive && isFailed && <Button
                            className='completeButton'
                            type="button"
                            variant="contained"
                            onClick={handleLogout}
                        >
                            Go to Login
                        </Button>}
                        {!completedArchive && isFailed && <div className="failureMsg">
                            <p>Setting up the new academic year failed. Please contact the support.</p>
                        </div>}
                        <TableContainer
                            className="cloneProgressTable"
                            style={{
                                border: "1px solid #DEDEDE",
                                borderRadius: "10px",
                                width: "50%",
                                minWidth: "550px",
                                scrollbarWidth: "thin"
                            }}
                        >
                            <Table sx={{ width: "100%" }} className="studentTable" stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: "20%", textAlign: "center !important" }}>
                                            Result
                                        </TableCell>
                                        <TableCell sx={{ width: "50%" }}>
                                            Module
                                        </TableCell>
                                        <TableCell sx={{ width: "30%" }}>
                                            Status
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {academicStatus?.map((d: any, i: number) => {
                                        const { icon, altstr } = getStatusIcon(d?.Status);
                                        return (
                                            <TableRow>
                                                <TableCell className="progressIcon">
                                                    <img src={icon} alt={altstr} width={22} />
                                                </TableCell>
                                                <TableCell className="progressModule">
                                                    {d?.ArchiveModule?.replace("_", " ")}
                                                </TableCell>
                                                <TableCell className={`progressStatus ${!retryProgress[i] ? getClassName(d?.Status) : ""}`}>
                                                    {d?.Status == "Failed" ? !retryProgress[i] ? <span
                                                        onClick={() => retryArchive(d?.ArchiveModuleID, i)}>
                                                        Failed. Click to Retry
                                                    </span> : "In Progress" : d?.Status}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </div>
            </div>
        </Modal>
    );
};

export default AcademicSwitchModal;