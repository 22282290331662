import React, { useEffect } from 'react'
import { Analyze } from "../Helpers/Analyze";
import { transcriptions } from '../i18n/languages';
import ExitFullScreen from '../Assets/fullscreenModeMinimize.svg'
import fullIcon from "../Assets/fullscreenMode.svg";
import { useAppState } from '../Context';
let ViewerHeightAsPerScreen = window.innerHeight * 80 / 100;
if (ViewerHeightAsPerScreen > 533) {
  ViewerHeightAsPerScreen = 533;
}
const Fullscreen = ({ fullScreenIcon, setFullScreenIcon, isMobileView }) => {
  const { setScaleSize,scaleSize } = useAppState("reader");
  const languagePrefered = localStorage.getItem("selectedLang") || "en";
  const closeFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) { /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE11 */
      document.msExitFullscreen();
    }
  }


  const fullScreenToggle = (isFullScreen) => {
    const element = document.body;
    const pdfWrapper = document.getElementById("pdfViewerParent");
    const areaContainer = document.getElementById("area");
    const leftPage = document.getElementById("leftPage");
    const canvas  = document.getElementById("the-canvas");
    if (isFullScreen) {
      setFullScreenIcon(true);
      const pdfContainer = document.getElementById("pdf-viewer-container");
      pdfWrapper.style.height = "calc(100vh - 58px)";
      pdfContainer.style.position= "fixed";
      pdfContainer.style.top= "0px";
      pdfContainer.style.left= "0px";
      pdfContainer.style.height= "100vh";
      areaContainer.style.height= "calc(100vh - 58px)";
      let canvasHeight = canvas.height;
      if(scaleSize !== 1 ){
        canvasHeight = canvas.height / scaleSize;
      }
      const newScale = areaContainer.clientHeight / canvasHeight;
      setScaleSize(newScale);
      if(isMobileView){
        leftPage.style.height = "100%";
      }
      pdfContainer.style.width= "100%";
      pdfContainer.style.zIndex= "9999999";
      pdfContainer.style.background= "white";
      let requestMethod =
        element.requestFullScreen ||
        element.webkitRequestFullScreen ||
        element.mozRequestFullScreen ||
        element.msRequestFullScreen;
      if (requestMethod) {
        requestMethod.call(element);
      } else if (typeof window.ActiveXObject !== "undefined") {
        let wscript = new window.ActiveXObject("WScript.Shell");
        if (wscript !== null) {
          wscript.SendKeys("{F11}");
        }
      }
    }
    else {
      setFullScreenIcon(false);
      const pdfContainer = document.getElementById("pdf-viewer-container");
      pdfWrapper.style.height = "auto";
      pdfContainer.style.position= "relative";
      pdfContainer.style.height= "auto";
      if(isMobileView){
      areaContainer.style.height= ViewerHeightAsPerScreen+"px";
    }
    setScaleSize(1);
      pdfContainer.style.width= "100%";
      pdfContainer.style.zIndex= "10";
      pdfContainer.style.background= "transparent";
      closeFullscreen();
    }
  }

  useEffect(() => {
    window.addEventListener('resize', (evt) => {
      // console.log('fullscreenchange: ', document.fullscreenElement);
      if (!document.fullscreenElement) {
          // Full-screen mode exited
          fullScreenToggle(false);
      }
  });
  }, [fullScreenIcon]);

  return (
    <div className="fullscreen-container">
      <button
        data-tip={`${!fullScreenIcon ? `${transcriptions.header.Tooltip.fullScreen[languagePrefered]}` : `${transcriptions.header.Tooltip.exitFullScreen[languagePrefered]}`}`}
        onClick={() => {
          fullScreenToggle(!fullScreenIcon);
        }}>
        {
          fullScreenIcon ? <img src={ExitFullScreen} style={{ marginTop: "5px" }} /> : isMobileView ? 
          <img src={fullIcon} height={30} width={30} /> : 
          <img src={fullIcon} style={{ marginTop: "5px" }} /> 
        }
      </button>
    </div>
  )
}

export default Fullscreen